import React, { useState, useEffect, useContext } from "react";
import More from "../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { GET_API, POST_API } from "../../Services/api";
import UserListTable from "../DataTable/UserListTable";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Search from "../../Assets/Icons/Search.svg";
import Edit from "../../Assets/Icons/Edit.svg";
import Spinner from "../Spinner";
import alertify from "alertifyjs";
import Validation from "../../Validation/Validation";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../PrivilegeContext";
import { handleDeleteList } from "../../Helper/DeleteAPI";

function RoleList() {
  const [userList, setuserList] = useState([]);
  const [filteredUser, setFilteredUser] = useState([]);
  const [errors, seterrors] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [roleDetails, setRoleDetails] = useState({
    name: "",
    description: "",
  });
  const [addAccess, setAddAccess] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const { access } = useContext(PrivilegeContext);

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "role", label: "Role" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getUserList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getUserList = async (page, size, search) => {
    setLoading(true);
    try {
      const roleList = await GET_API(
        `/RolesList?page=${page}&size=${size}&search=${search}`
      );
      if (roleList.status === 200) {
        if (
          roleList?.data?.data?.current_page > roleList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }

        setuserList(roleList?.data?.data?.data);
        setFilteredUser(roleList?.data?.data?.data);
        setLastPage(roleList?.data?.data?.last_page);
        setLoading(false);
        return;
      }

      if (
        roleList?.data?.data?.current_page > roleList?.data?.data?.last_page
      ) {
        isPageNotExit();
        return;
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Role list...");
    }
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getUserList(1, pageSize, searchKey);
  };

  const handleDelete = (row_id) => {
    handleDeleteList(
      row_id,
      "Role",
      getUserList,
      "deleteRoles",
      currentPage,
      setCurrentPage,
      pageSize,
      searchKey
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleDetails({ ...roleDetails, [name]: value });
  };

  const addRole = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.ValidateRole(roleDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const createFloor = await POST_API("/addRoles", roleDetails);
    if (createFloor.status === 200) {
      setButtonDisabled(false);
      setRoleDetails({ name: "", description: "" });
      getUserList(1, pageSize, searchKey);
      alertify.success(createFloor?.data?.message);
      return;
    }
    alertify.error(createFloor?.response?.data?.message);
  };

  const editRole = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.ValidateRole(roleDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    const editFloor = await POST_API("/updateRoles", roleDetails);
    if (editFloor.status === 200) {
      setButtonDisabled(false);
      setRoleDetails({ name: "", description: "" });
      getUserList(1, pageSize, searchKey);
      setEditStatus(false);
      alertify.success(editFloor?.data?.message);
      return;
    }
    alertify.error(editFloor?.response?.data?.message);
  };

  const resetForm = () => {
    setEditStatus(false);
    setRoleDetails({ ...roleDetails, name: "", description: "" });
  };

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "role") {
        setAddAccess(item?.add);
        setEditAccess(item?.edit);
        setDeleteAccess(item?.delete);
      }
      return "";
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Add Role</h4>
          <form onSubmit={() => getUserList(currentPage, pageSize, searchKey)}>
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
            </div>
          </form>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Name <span className="required-symbol">*</span>
              </label>

              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Enter Role Name"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={roleDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">Description</label>
              <textarea
                name="description"
                className="input-field"
                placeholder="Enter Description"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={roleDetails.description}
                autoComplete="off"
                rows={5}
              />
              {errors.description ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.description}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editAccess && editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      editRole(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  addAccess && (
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        addRole(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      <span className="button-label">Add New</span>
                      <img src={ColorAdd} alt="add" />
                    </button>
                  )
                )}
              </div>

              {addAccess && (
                <Link className="edit-button" onClick={() => resetForm()}>
                  <span className="button-label">Cancel</span>
                  <img src={Edit} alt="add" />
                </Link>
              )}
            </div>
          </div>
          <UserListTable
            list={filteredUser}
            columns={columns}
            icon={More}
            editStatus={setEditStatus}
            editList={setRoleDetails}
            multiple={false}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            deleteAccess={deleteAccess}
            editAccess={editAccess}
          />
        </div>
      </div>
    </div>
  );
}

export default RoleList;
