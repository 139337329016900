import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import config from "../../Services/config.json";


export default function StickyHeadTable({
  list,
  columns,
  icon,
  showDetails,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
  multiple,
  enableMoreOption,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    className="cursor-pointer table-column cursor-text"
                  >
                    <TableCell className="table-body !w-[7%]">
                      {(currentPage - 1) * pageSize + (i + 1)}
                    </TableCell>
                    {
                      <TableCell className="!w-[7%] table-body">
                      {row?.profile_image !== "" ? (
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "40px",
                          }}
                          src={config?.path + row?.employee_image}
                          alt="profile_image"
                        />
                      ) : (
                        <div
                          className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                          style={{ backgroundColor: "#ccc" }}
                        >
                          {row?.first_name?.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </TableCell>
                    }
                    <TableCell className="table-body">
                      <Link
                        to={`/manualAttendance/details/${row?.employeeId}`}
                        target="_blank"
                        style={{ color: "#0284C7" }}
                        className="text-sky-600 border-b-[1px] border-b-[#0284C7]"
                      >
                        {row.employeeCode}
                      </Link>
                    </TableCell>
                    <TableCell className="table-body">
                      {row?.first_name} {row?.fathers_name}
                    </TableCell>

                    <TableCell className="table-body">
                      {row?.departmentname}
                    </TableCell>

                    <TableCell className="table-body">
  {row?.from_date && new Date(row?.from_date).toLocaleDateString('en-GB').replace(/\//g, '-')}
</TableCell>
<TableCell className="table-body">
  {row?.to_date && new Date(row?.to_date).toLocaleDateString('en-GB').replace(/\//g, '-')}
</TableCell>


                    <TableCell className="table-body">
                      {row?.status === 0 ? (
                        <span
                          className=" bg-[#26ab27] p-1"
                          style={{
                            color: "#FFF",
                            borderRadius: "4px",
                          }}
                        >
                          Approved
                        </span>
                      ) : row?.status === 1 ? (
                        <span
                          className="bg-[#ff7900] p-1"
                          style={{
                            color: "#FFF",
                            borderRadius: "4px",
                          }}
                        >
                          Pending
                        </span>
                      ) : row?.status === 2 ? (
                        <span
                          className="bg-[#ff0000] p-1"
                          style={{
                            color: "#FFF",
                            borderRadius: "4px",
                          }}
                        >
                          Rejected
                        </span>
                      ) : null}
                    </TableCell>
                    <TableCell className="table-body">
                      {row?.created_by_name}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
