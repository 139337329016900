import React from "react";
import { Link, useLocation } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Edit from "../../../Assets/Icons/Edit.svg";

function DesignationDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Designation Name",
      value: listDetails?.name ? listDetails?.name : "-",
    },
    {
      label: "Department Name",
      value: listDetails?.DepartmentName ? listDetails?.DepartmentName : "-",
    },
    {
      label: "Description",
      value: listDetails?.description ? listDetails?.description : "-",
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/designation" className="in-active">
              Designation List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Designation Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Designation Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details?.map((list) => {
                return (
                  <div className="list">
                    <label className="list-label">{list?.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list?.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesignationDetails;
