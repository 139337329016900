import React, { useState } from "react";
import FMS from "../Assets/Icons/FMS.js";
import IMS from "../Assets/Icons/IMS.js";
import HRMS from "../Assets/Icons/HRMS.js";
import User from "../Assets/Logo/User.png";
import { Link } from "react-router-dom";
import Home from "../Assets/Icons/Home.js";
import $ from "jquery";

function StaticSideBar() {
  const [currentTab, setCurrentTab] = useState("Home");
  const sideBar = [
    {
      label: "Home",
      Icon: Home,
      to: "/home",
    },
    {
      label: "Masters",
      Icon: FMS,
      to: "/master/hrms/role",
    },
    {
      label: "HRMS",
      Icon: HRMS,
      to: "/dashboard",
    },
    {
      label: "PO",
      Icon: IMS,
      to: "po/dashboard",
    },
  ];

  const POsideBar = [
    {
      label: "Home",
      Icon: Home,
    },
    {
      label: "Masters",
      Icon: FMS,
      to: "/master/hrms/role",
    },
    {
      label: "PO",
      Icon: IMS,
      to: "po/dashboard",
    },
  ];
  const handleToggle = () => {
    $("#toggle-menu").animate({
      width: "toggle",
    });
  };

  const staticSideBar =
    localStorage?.getItem("user_role") === "3" ? POsideBar : sideBar;
  return (
    <div className="h-screen w-[80px] p-[11px] border-r-[1px] border border-primary flex flex-col gap-5 justify-start">
      <div className=" flex flex-col justify-center items-center gap-2">
        <img className="rounded-full w-[48px]" src={User} alt="" />
        <span className="text-xs font-normal font-karla">Admin</span>
      </div>
      {sideBar?.map((list, i) => {
        return (
          <Link
            key={i}
            onClick={() => {
              setCurrentTab(list.label);
              handleToggle();
            }}
            className="hover:bg-clr-hover  rounded-[6px] "
            to={list.to}
            style={{
              color: currentTab === list.label && "#D0A34C",
              backgroundColor: currentTab === list.label && "#FFF2EC",
            }}
          >
            <div className="py-1.5 flex flex-col items-center gap-1.5">
              <list.Icon
                fill={currentTab === list.label ? "#D99719" : "#494C54"}
              />
              <span
                style={{
                  fontWeight: window?.location?.pathname === list?.to && "600",
                }}
                className="text-xs font-normal font-karla hover:font-semibold "
              >
                {list.label}
              </span>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
export default StaticSideBar;
