import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AllowanceListItems from "./AllowanceListItems";
import Delete from "../../../Assets/Icons/Delete.svg";
import "alertifyjs/build/css/alertify.css";

function EmployeeAllowance({ allowanceTypeList, allowanceValue, empAllowanceValue, setallowanceValue, deleteAllowance }) {

    const [isOpen, setIsOpen] = useState(false);
    const addMoreList = () => {
        const newList = {
            allowance_type_id: "",
            in_compliance: false,
            calculation_method: "",
            amount: "",
        };
        setallowanceValue([...allowanceValue, { ...newList }]);
    };

    const deleteList = (index) => {
        const newItems = [...allowanceValue];
        newItems.splice(index, 1);
        setallowanceValue(newItems);
    };

    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const errorModal = () => {
        return (
            <div>
                <Dialog
                    className="testing"
                    open={isOpen}
                    onClose={handleModal}
                    disableBackdropClick={false}
                    PaperProps={{
                        style: {
                            minWidth: "400px",
                            minHeight: "220px",
                        },
                    }}
                >
                    <DialogTitle></DialogTitle>
                    <DialogContent className="text-center">
                        <CancelOutlinedIcon className="text-[#ef4444] text-7xl emp-allowance-error" />
                        <p className="mt-4">
                            Please Enter/Select all the Mandatory fields...
                        </p>
                    </DialogContent>
                    <DialogActions className="text-center">
                        <button
                            type="button"
                            className="reset-button"
                            onClick={handleModal}
                        >
                            <span className="reset-button-label">Close</span>
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    return (
        <div className="border-t-[1px] border-t-[#D9D9D9]">
            <div
                className="w-3/12 p-3 bg-primary-color text-center"
                style={{ borderRadius: "0px 0px 100px 0px" }}
            >
                <span className="text-white-color font-karla font-base ">
                    Salary Info
                </span>
            </div>
            <div className="card-wrapper border-b-[1px] border-b-[#D9D9D9]">
                {allowanceValue?.map((list, i) => {
                    return (
                        <AllowanceListItems
                            index={i}
                            allowanceTypeList={allowanceTypeList}
                            addMoreList={addMoreList}
                            state={EmployeeAllowance}
                            deleteList={deleteList}
                            item={list}
                            allowanceValue={allowanceValue}
                            setallowanceValue={setallowanceValue}
                        />
                    );
                })}
            </div>
            <div className="list-container py-4">
                <div className=" grid grid-cols-5 gap-5">
                    {empAllowanceValue?.length > 0 &&
                        empAllowanceValue?.map((item, i) => {
                            return (
                                <>
                                    <div className="list-full">
                                        <label className="list-label">Salary Type </label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value">
                                            {item?.allowance_type_name}
                                        </div>
                                    </div>
                                    <div className="list-full">
                                        <label className="list-label">In Compliance</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value">
                                            {item?.in_compliance === 1 ? "True" : "False"}
                                        </div>
                                    </div>
                                    <div className="list-full">
                                        <label className="list-label">Calculation Method</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value">
                                            {item?.calculation_method}
                                        </div>
                                    </div>
                                    <div className="list-full">
                                        <label className="list-label">Amount</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value">
                                            {item?.amount}
                                        </div>
                                    </div>
                                    <div className="list-full">
                                        {/* <label className="list-label">Amount</label> */}
                                        <div className="list-value">
                                            <button className="mt-5" onClick={() => { deleteAllowance(item?.id) }} >
                                                <img src={Delete} alt="" className="w-6 h-6" />
                                            </button>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                </div>
            </div>
            {errorModal()}
        </div>
    );
}

export default EmployeeAllowance;
