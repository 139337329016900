import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useGeneralSettings } from "../../GeneralSettingsContext";
import dayjs from "dayjs";
import config from "../../Services/config.json";

export default function DataTable({
  list,
  setSelectedRows,
  printVoucher,
  totalAmount,
}) {
  const { generalSettings } = useGeneralSettings();
  const columns = [
    { field: "sno", headerName: "S.No", minWidth: 60 },
    {
      field: "profile_image",
      headerName: "User",
      minWidth: 100,
      renderCell: (params) => {
        const profileImageUrl = config?.path + params.row.profile_image; // Access the profile_image from each row
        return (
          <img
            src={profileImageUrl}
            alt="User Profile"
            className="h-[40px] w-[40px] rounded"
          />
        );
      },
    },
    { field: "empCode", headerName: "Code", minWidth: 100 },
    { field: "empName", headerName: "Name", minWidth: 200 },
    { field: "floor_name", headerName: "Floor", minWidth: 240 },
    { field: "allowanceName", headerName: "Allowance Name", minWidth: 200 },
    { field: "actualEntryTime", headerName: "Entry Time", minWidth: 130 },
    { field: "actualExitTime", headerName: "Exit Time", minWidth: 130 },
    { field: "amount", headerName: "Amount", minWidth: 100 },
  ];

  const handleSelectionModelChange = (selectionModel) => {
    const selectedRows = selectionModel.map((selectedId) =>
      list.find((row) => row.id === selectedId)
    );
    setSelectedRows(selectedRows);
  };

  const heightStyle =
    list?.length === 0 ? { height: 320, width: "100%" } : { width: "100%" };

  return (
    <div style={heightStyle} ref={printVoucher} className="relative top-6 ">
      <div
        className="flex justify-between px-3 py-5 voucher-header"
        style={{ visibility: "hidden" }}
      >
        <div className="flex justify-start items-center gap-3">
          <img
            style={{ height: "30px", width: "30px" }}
            src={generalSettings?.logo_to_show}
            alt=""
          />
          <div className="text-special-text-color font-bold font-montserrat text-lg">
            Sumangali Silks
          </div>
        </div>
        <div className="font-semibold">
          Date : {dayjs(new Date()).format("DD-MM-YYYY")}
        </div>
      </div>
      <DataGrid
        rows={list}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={handleSelectionModelChange}
      />
      <div className="grid grid-cols-6 p-2 bg-white-color">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div className="text-text-dark font-karla text-end">Total Amount</div>
        <div className="text-center text-text-dark font-karla">
          {totalAmount}
        </div>
      </div>
    </div>
  );
}
