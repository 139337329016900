import React from "react";
import { Route } from "react-router-dom";

import EmployeeReport from "../EnterpriseMaster/EmployeeReport/EmployeeReport";
import EmployeeReportDetails from "../EnterpriseMaster/EmployeeReport/EmployeeReportDetails";

import EmployeeMasterReport from "../Reports/EmployeeMasterReport/EmployeeMasterReport";

import Termination from "../Reports/TerminationReport/TerminationReportList";
import TerminationDetails from "../Reports/TerminationReport/TerminationReportDetails";

import Attendance from "../Reports/AttendanceReport/AttendanceReportList";
import AttendanceReportDetails from "../Reports/AttendanceReport/AttendanceReportDetails";

import Leave from "../Reports/LeaveReport/LeaveReportList";
import LeaveDetails from "../Reports/LeaveReport/LeaveReportDetails";

import LongLeave from "../Reports/LongLeaveReport/LongLeaveReport";
import LongLeaveDetails from "../Reports/LongLeaveReport/LongLeaveReportDetails";

import LoanLedger from "../Reports/LoanLedger/LoanLedgerReport";
import LoanLedgerDetails from "../Reports/LoanLedger/LoanLedgerReportDetails";

import Absent from "../Reports/AbsentReport/AbsentReport";
import AbsentDetails from "../Reports/AbsentReport/AbsentReportDetails";

import Exit from "../Reports/ExitReport/ExitReport";
import ExitDetails from "../Reports/ExitReport/ExitReportDetails";

import Onduty from "../Reports/OnDutyReport/OnDutyReport";

import LateFine from "../Reports/LateFineReport/LateFineReport";

import Advance from "../Reports/AdvanceReport/AdvanceReport";
import AdvanceDetails from "../Reports/AdvanceReport/AdvanceReportDetails";

import Loan from "../Reports/LoanReport/LoanReport";
import LoanDetails from "../Reports/LoanReport/LoanReportDetails";

import Payment from "../Reports/PaymentReport/PaymentReport";
import PaymentDetails from "../Reports/PaymentReport/PaymentReportDetails";

import Expense from "../Reports/ExpenseReport/ExpenseReport";
import ExpenseDetails from "../Reports/ExpenseReport/ExpenseReportDetails";

import CostReport from "../Reports/CostReport/CostReportTable";
import CostReportDetails from "../Reports/CostReport/CostReportDetails";

import Movement from "../Reports/MovementReport/MovementReport";
import MovementDetails from "../Reports/MovementReport/MovementReportsDetails";

import PartTimeEmployee from "../Reports/PartTimeEmployeeReport/PartTimeEmployeeReport";
import PartTimeEmployeeDetails from "../Reports/PartTimeEmployeeReport/PartTimeEmployeeReportDetails";

import FoodAllowanceReport from "../Reports/FoodAllowanceReport/FoodAllowanceReport";

import EarlyExitReport from "../Reports/EarlyExit/EarlyExitReportList";
import EarlyExitReportDetails from "../Reports/EarlyExit/EarlyExitReportDetails";

import LateEntryReport from "../Reports/LateEntryReport";

import BranchWiseReport from "../Reports/BranchWiseReport/BranchWiseReport";

import FloorwiseReport from "../Reports/FloorReport/FloorwiseReport";
import ConsolidateAttendanceReport from "../Reports/ConsolidateAttendanceReport";
import AgentCommCreationReport from "../Reports/AgentCommCreationReport";
import AgentCommActualReport from "../Reports/AgentCommActualReport";
import AgentCommissionnActualReportDetails from "../Reports/AgentCommActualReportDetails";
import InvalidAttendanceList from "../Reports/InvalidAttendanceReport";
import PayrollReport from "../Reports/PayrollReport/PayrollReportList";
import PayrollReportDetail from "../Reports/PayrollReport/PayrollReportDetail";

import TerminatedEmployeeLoan from "../Reports/TerminatedEmployeeLoanReport/TerminatedLoanReport";
import TerminatedEmployeeLoanDetails from "../Reports/TerminatedEmployeeLoanReport/TerminatedLoanReportDetail";

import ReimbursementReport from "../Reports/ReimbursementReport/ReimbursementReport";


function ReportRoutes() {
  return (
    <>
      <Route path="employee" element={<EmployeeReport />}></Route>
      <Route
        path="employee/details/:id"
        element={<EmployeeReportDetails />}
      ></Route>

      <Route path="employeemaster" element={<EmployeeMasterReport />}></Route>

      <Route path="termination" element={<Termination />}></Route>
      <Route
        path="termination/details/:id"
        element={<TerminationDetails />}
      ></Route>

      <Route path="attendance" element={<Attendance />}></Route>
      <Route
        path="attendance/details/:id"
        element={<AttendanceReportDetails />}
      ></Route>

      <Route path="leave" element={<Leave />}></Route>
      <Route path="leave/details/:id" element={<LeaveDetails />}></Route>

      <Route path="long/leave" element={<LongLeave />}></Route>
      <Route
        path="long/leave/details/:id"
        element={<LongLeaveDetails />}
      ></Route>

      <Route path="loan/ledger" element={<LoanLedger />}></Route>
      <Route
        path="loan/ledger/details/:id"
        element={<LoanLedgerDetails />}
      ></Route>

      <Route path="absent" element={<Absent />}></Route>
      <Route path="absent/details/:id" element={<AbsentDetails />}></Route>

      <Route path="exit" element={<Exit />}></Route>
      <Route path="exit/details/:id" element={<ExitDetails />}></Route>

      <Route path="onduty" element={<Onduty />}></Route>

      <Route path="latefine" element={<LateFine />}></Route>

      <Route path="advance" element={<Advance />}></Route>
      <Route path="advance/details/:id" element={<AdvanceDetails />}></Route>

      <Route path="loan" element={<Loan />}></Route>
      <Route path="loan/details/:id" element={<LoanDetails />}></Route>

      <Route path="payment" element={<Payment />}></Route>
      <Route path="payment/details/:id" element={<PaymentDetails />}></Route>

      <Route path="expense" element={<Expense />}></Route>
      <Route path="expense/details/:id" element={<ExpenseDetails />}></Route>

      <Route path="cost" element={<CostReport />}></Route>
      <Route path="cost/details/:id" element={<CostReportDetails />}></Route>

      <Route path="movement" element={<Movement />}></Route>
      <Route path="movement/details/:id" element={<MovementDetails />}></Route>

      <Route path="parttimeEmployee" element={<PartTimeEmployee />}></Route>
      <Route
        path="parttimeEmployee/details/:id"
        element={<PartTimeEmployeeDetails />}
      ></Route>

      <Route path="foodallowance" element={<FoodAllowanceReport />}></Route>

      <Route path="earlyExit" element={<EarlyExitReport />}></Route>
      <Route
        path="earlyExit/details/:id"
        element={<EarlyExitReportDetails />}
      ></Route>

      <Route path="lateEntry" element={<LateEntryReport />}></Route>
      <Route
        path="invalidAttendance"
        element={<InvalidAttendanceList />}
      ></Route>

      <Route path="branchwise" element={<BranchWiseReport />}></Route>

      <Route path="floorwise" element={<FloorwiseReport />}></Route>

      <Route
        path="consolidate"
        element={<ConsolidateAttendanceReport />}
      ></Route>

      <Route
        path="agentCommCreation"
        element={<AgentCommCreationReport />}
      ></Route>

      <Route path="agentCommActual" element={<AgentCommActualReport />}></Route>
      <Route
        path="agentCommActual/details"
        element={<AgentCommissionnActualReportDetails />}
      ></Route>

      <Route path="payroll" element={<PayrollReport />}></Route>
      <Route
        path="payroll/details"
        element={<PayrollReportDetail />}
      ></Route>

    <Route path="terminated-employee-loan-report" element={<TerminatedEmployeeLoan />}></Route>
    <Route path="terminated-employee-loan-report/details/:id" element={<TerminatedEmployeeLoanDetails />}></Route>

    <Route path="reimbursement-report" element={<ReimbursementReport />}></Route>

    </>
  );
}

export default ReportRoutes;
