import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Save from "../../../Assets/Icons/Save.svg";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import Spinner from "../../Spinner";

function EditBranch() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [editBranchDetails, setEditBranchDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    no_of_floors: listData?.no_of_floors.toString(),
    // gst_no: listData?.gst_no,
    address: listData?.address,
    phone: listData?.phone,
    town: listData?.town,
    state: listData?.state,
    pincode: listData?.pincode,
    contact_person: listData?.contact_person,
    // contact_email: listData?.contact_email,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getFloorList();
  }, []);

  const getFloorList = async () => {
    try {
      setLoading(true);
      const floorList = await GET_API("/floorDropdown");
      if (floorList.status === 200) {
        const department = floorList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setFloorList(department);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
      setFloorList([]);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditBranchDetails(() => ({ ...editBranchDetails, [name]: value }));
  };

  const handleOptionChange = (e, name) => {
    setEditBranchDetails(() => ({ ...editBranchDetails, [name]: e?.value }));
  };
  const saveBranch = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateBranch(editBranchDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const updateBranch = await POST_API("/updatebranch", editBranchDetails);
      if (updateBranch?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(updateBranch?.data?.message);
        navigate("/master/hrms/branch");
      } else {
        alertify.error(updateBranch?.data?.message);
        setButtonDisabled(false);
      }
    } catch (e) {
      setButtonDisabled(false);
      alertify.error("Failed to Update Branch List");
    }
  };

  const resetForm = () => {
    setEditBranchDetails({
      id: listData?.id,
      name: listData?.name,
      no_of_floors: listData?.no_of_floors.toString(),
      // gst_no: listData?.gst_no,
      address: listData?.address,
      phone: listData?.phone,
      town: listData?.town,
      state: listData?.state,
      pincode: listData?.pincode,
      contact_person: listData?.contact_person,
      // contact_email: listData?.contact_email,
    });
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/branch" className="in-active">
              Branch List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Branch</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Branch</h4>

          {/***************Form************* **/}

          <form className="form">
            <div className="field-block">
              <label className="label-name">
                Branch Name
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Branch Name"
                name="name"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.name}
              />
              {errors.name ? (
                <div className="validation-error-label">{errors.name}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                No of Floors
                <span className="required-symbol">*</span>
              </label>
              <Select
                options={floorList}
                value={floorList?.find(
                  (option) =>
                    option?.value === Number(editBranchDetails?.no_of_floors)
                )}
                onChange={(e) => handleOptionChange(e, "no_of_floors")}
                name="no_of_floors"
                className=""
                isSearchable
              />
              {errors.no_of_floors ? (
                <div className="validation-error-label">
                  {errors.no_of_floors}
                </div>
              ) : null}
            </div>
            {/* <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                GSTIN
                <span className="required-symbol">*</span>
              </label>
              <input
                name="gst_no"
                className="input-field"
                type="text"
                placeholder="Please Enter GST Number"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails?.gst_no}
              />
              {errors.gst_no ? (
                <div className="validation-error-label">{errors.gst_no}</div>
              ) : null}
            </div> */}
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Address
                <span className="required-symbol">*</span>
              </label>
              <textarea
                className="input-field"
                type="text"
                placeholder="Please Enter Address"
                name="address"
                onChange={(e) => {
                  handleChange(e);
                }}
                rows={5}
                autoComplete="off"
                value={editBranchDetails?.address}
              />
              {errors.address ? (
                <div className="validation-error-label">{errors.address}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Town / City
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Town / City"
                name="town"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails?.town}
              />
              {errors.town ? (
                <div className="validation-error-label">{errors.town}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                State
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter State"
                name="state"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.state}
              />
              {errors.state ? (
                <div className="validation-error-label">{errors.state}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Pincode
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Pincode"
                name="pincode"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.pincode}
              />
              {errors.pincode ? (
                <div className="validation-error-label">{errors.pincode}</div>
              ) : null}
            </div>

            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Contact Person
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Contact Person"
                name="contact_person"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.contact_person}
              />
              {errors.contact_person ? (
                <div className="validation-error-label">
                  {errors.contact_person}
                </div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Contact Number
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Contact Number"
                name="phone"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.phone}
              />
              {errors.phone ? (
                <div className="validation-error-label">{errors.phone}</div>
              ) : null}
            </div>
            {/* <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Contact Email
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Pincode"
                name="contact_email"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.contact_email}
              />
              {errors.contact_email ? (
                <div className="validation-error-label">
                  {errors.contact_email}
                </div>
              ) : null}
            </div> */}
          </form>

          <div className="submit-reset-button">
            <button onClick={() => resetForm()}>
              <button type="button" className="cancel-button">
                <span className="reset-button-label">Reset</span>
              </button>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveBranch()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBranch;
