import React, { useState, useEffect, useContext } from "react";
import LoanSkipCarryApprovalTable from "../DataTable/LoanSkipCarryApprovalTable";
import Spinner from "../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { GET_API, POST_API } from "../../Services/api";
import { PrivilegeContext } from "../../PrivilegeContext";

function LoanSkipCarryApproval() {
  const [loading, setLoading] = useState(false);
  const [masterList, setMasterList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);

  useEffect(() => {
    getSkipLoanApprovalList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getSkipLoanApprovalList(1, pageSize);
  };

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "approve_master") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const getSkipLoanApprovalList = async (page, size) => {
    setLoading(true);
    try {
      const masterList = await GET_API(
        `/loanSkipCarryApprovalList?page=${page}&size=${size}`
      );
      if (masterList?.status === 200) {
        if (
          masterList?.data?.data?.current_page >
          masterList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setMasterList(masterList?.data?.data?.data);
        setLastPage(masterList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setMasterList([]);
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  const handleApprove = async (type) => {
    const data = {
      selectedRows,
      appType: type,
    };
    if (selectedRows?.length <= 0) {
      alertify
        .alert("Please select Row to Approve/Reject")
        .setHeader("<b> Alert </b> ");
      return;
    }
    try {
      const approveMasters = await POST_API(
        "/updateLoanSkipCarryApproveStatus",
        data
      );
      if (approveMasters?.status === 200) {
        // alertify.success(
        //   `User ${
        //     data?.appType === "approve"
        //       ? data?.appType + "d"
        //       : data?.appType + "ed"
        //   } successfully...!`
        // );
        alertify.success(approveMasters?.message);
        getSkipLoanApprovalList(currentPage, pageSize);
        return;
      }
    } catch (error) {
      alertify.error(`Failed ${data?.appType}d status`);
    }
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Loan Skip and Carry Approval Logs</h4>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            <div className="flex gap-3">
              {addAccess && (
                <>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("reject");
                    }}
                  >
                    <button
                      to=""
                      className="add-new-button"
                      disabled={buttonDisabled}
                    >
                      <span>Reject</span>
                    </button>
                  </div>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("approve");
                    }}
                  >
                    <button
                      to=""
                      className="add-new-button"
                      disabled={buttonDisabled}
                    >
                      <span>Approve</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {/**************** * Table ********************/}
          <div className="p-5 min-h-[400px]">
            <LoanSkipCarryApprovalTable
              list={masterList}
              setSelectedRows={setSelectedRows}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanSkipCarryApproval;
