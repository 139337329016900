import React, { useEffect, useState } from "react";
import Select from "react-select";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import { GET_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";

function FoodAllowanceDetails({ id }) {
  const [allowanceList, setAllowanceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllowanceLoading, setIsAllowanceLoading] = useState(false);
  const [allowance, setAllowance] = useState([]);
  const [allowanceData, setAllowanceData] = useState([]);

  useEffect(() => {
    getFoodList();
  }, []);

  useEffect(() => {
    if (id) {
      getFoodAllowance();
    }
  }, [id]);

  useEffect(() => {
    if (allowanceList?.length) {
      const val = allowanceList?.filter((item) => {
        return allowanceData?.includes(item?.value);
      });
      setAllowance(val);
    }
  }, [allowanceData, allowanceList?.length, id]);

  const getFoodList = async () => {
    setIsLoading(true);
    try {
      const foodList = await GET_API("/FoodAllowanceAll");
      if (foodList.status === 200) {
        const list = foodList?.data?.data?.map((opt) => {
          return { label: opt?.name, value: opt?.id };
        });
        setAllowanceList(list);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify.error("Failed to Get Allowance List");
    }
  };

  const getFoodAllowance = async () => {
    setIsAllowanceLoading(true);
    try {
      const foodList = await GET_API(`/mapFoodAllowanceEmp/${id}`);
      if (foodList.status === 200) {
        setAllowanceData(foodList?.data?.data);
        setIsAllowanceLoading(false);
      }
    } catch (error) {
      setIsAllowanceLoading(false);
      alertify.error("Failed to Get Allowance List");
    }
  };

  if (isLoading || isAllowanceLoading) {
    return <Spinner />;
  }

  return (
      <div className="card-wrapper">
        <div className="p-6">
          <label className="list-label">Food Allowance</label>
          <Select
            options={allowanceList}
            value={allowance}
            name="allowance"
            className=""
            isSearchable
            isMulti
            isDisabled
          />
        </div>
      </div>
  );
}

export default FoodAllowanceDetails;
