import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SortIcon from "../../Assets/Icons/SortIcon.svg";
import Pagination from "./Pagination";


export default function StickyHeadTable({
  list,
  columns,
  pageSize,
  currentPage,
  setPageNo,
  setPageSize,
  lastPage,
  sorting,
  handleSort,
}) {
  return (
    <Paper className="branch-wise-report-table">
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label=" table text-center w-2/4">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                  onClick={() => handleSort(column.id, column.sortable)}
                >
                  <div className="flex">
                    {column?.label}
                    {column.sortable && sorting.orderBy === column.id ? (
                      sorting.orderByType === "desc" ? (
                        <img src={SortIcon} alt="sort icon" />
                      ) : (
                        <img src={SortIcon} alt="sort icon" />
                      )
                    ) : column.sortable && <img src={SortIcon} alt="sort icon" />}
                  </div>

                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    <TableCell className="table-body !w-[7%]">
                      {(currentPage - 1) * pageSize + (i + 1)}
                    </TableCell>
                    <TableCell className="table-body border border-[#d9d9d9]">
                      {row?.employee_code}
                    </TableCell>
                    <TableCell className="table-body border border-[#d9d9d9]">
                      {row?.employee_name}
                    </TableCell>
                    <TableCell className="table-body border border-[#d9d9d9]">
                      {row?.deduct_from_employee === 1 ? row?.amount : ""}
                    </TableCell>
                    <TableCell className="table-body border border-[#d9d9d9]">
                      {row?.deduct_from_employee === 0 ? row?.amount : ""}
                    </TableCell>
                    <TableCell className="table-body border border-[#d9d9d9]">
                      {row?.paid_amount !== "0.00" ? row?.paid_amount : ""}
                    </TableCell>
                    <TableCell className="table-body border border-[#d9d9d9]">
                      {row?.date}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
