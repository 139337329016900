import React, { useState, useEffect } from "react";
import alertify from "alertifyjs";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import FoodAllowanceReportTable from "../../DataTable/FoodAllowanceReportTable";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import Filter from "../../../Assets/Icons/Filter.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Select from "react-select";
import ButtonLoader from "../../ButtonLoader";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import PDFExport from "../../../Assets/Icons/pdf-export.svg";
import "alertifyjs/build/css/alertify.css";

function FoodAllowance() {
  const [foodAllowanceList, setfoodAllowanceList] = useState([]);
  const [filteredFood, setFilteredFood] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [hragentList, sethragentList] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [filter, setFilter] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [selectedFloor, setSelectedFloor] = useState([]);
  const [foodAllowanceOption, setFoodAllowanceOption] = useState([]);
    const [selectedFoodAllowance, setSelectedFoodAllowance] = useState([]);
  

  const [isFloorLoading, setIsFloorLoading] = useState(false);
  const [floorList, setFloorList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [filterParams, setFilterParams] = useState("");

  // Export Excel and PDF
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_id: "",
    department_id: "",
    year: "",
    month: "",
    employee_type_id: "",
    shift: "",
    agent: "",
    date: "",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "user", label: "User" },

    { id: "employeeCode", label: "Code" },
    { id: "employeeName", label: "Name" },
    { id: "allowance_name", label: "Allowance Name" },
    { id: "date", label: "Date" },
    { id: "actualEntryTime", label: "Entry Time" },
    { id: "actualExitTime", label: "Exit Time" },
    { id: "amount", label: "Amount" },
  ];

  useEffect(() => {
    getBranchList();
    getEmployeeName();
    getDepartmentList();
    getEmployeeNameList();
    getDesignationList();
    getEmployeeType();
    getFloorList();
    getAllFoodAllowanceList();
  }, []);

  useEffect(() => {
    getFoodList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getFoodList(1, pageSize, filterParams, searchKey);
  };


  const getFloorList = async () => {
    setIsFloorLoading(true);
    try {
      const getfloorList = await GET_API("/floorDropdown");
      if (getfloorList?.data?.status === 200) {
        const floorList = getfloorList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floorList.unshift({ value: "", label: "Select..." });
        setFloorList(floorList);
        setIsFloorLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Floor List");
      setIsFloorLoading(false);
    }
  };

  const getAllFoodAllowanceList = async () => {
    try {
      // setIsFoodOptionLoading(true);
      const foodList = await GET_API("/FoodAllowanceAll");
      if (foodList.status === 200) {
        const list = foodList?.data?.data?.map((opt) => {
          return { label: opt?.name, value: opt?.id };
        });
        setFoodAllowanceOption(list);
        // setIsFoodOptionLoading(false);
      }
    } catch (error) {
      // setIsFoodOptionLoading(false);
      setFoodAllowanceOption([]);
      alertify.error("Failed to Get Allowance List");
    }
  };

  const getFoodList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const foodList = await GET_API(
        `/foodVoucherReport?page=${page}&size=${size}${filters}&search=${search}`
      );

      if (foodList.status === 200) {
        if (
          foodList?.data?.data?.current_page > foodList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setfoodAllowanceList(foodList?.data?.data?.list);
        setFilteredFood(foodList?.data?.data?.list);
        setTotalAmount(foodList?.data?.data?.totalAmount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Food Allowance List");
    }
  };

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const getBranchList = async () => {
    try {
      setLoading(true);
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeName = async () => {
    setLoading(true);
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.first_name };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getEmployeeNameList = async () => {
    setLoading(true);
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const getShiftList = async () => {
    setLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList.status === 200) {
        const floor = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setShiftList(floor);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Shift List");
      setLoading(false);
    }
  };

  const getHRAgentList = async () => {
    setLoading(true);
    try {
      const HRAgent = await GET_API("/agentDropdown/security_agent");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.contact_person };
        });
        agent.unshift({ value: "", label: "Select..." });
        sethragentList(agent);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get HR Agent List...");
      setLoading(false);
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  

  const exportData = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-foodAllowanceReport?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "foodAllowanceReport.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const handleExportChange = (event) => {
    const value = event.target.value;

    if (value === 'excel') {
      setIsExportLoading(true);
      exportData(); // Call your export function
    } else if (value === 'pdf') {
      setIsExportPdfLoading(true);
      exportAsPDF(); // Call your export function
    }
  };

  const exportAsPDF = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(
        `foodvoucherReportPdf?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "foodAllowanceReport.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };


  const getFloorID = (e) => {
    let floorID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    return floorID;
  };


  const getFilteredFoodAllowanceID = (e) => {
    let foodAllowanceID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    return foodAllowanceID;
  };

  const handleFilter = async (e, name, type) => {
    const value =
      name === "dateMonth"
        ? new Date(e)
        : name === "date"
        ? e?.target?.value
        : e?.value;
    if (name === "dateMonth") {
      filterList["year"] = value?.getFullYear();
      filterList["month"] = value?.getMonth() + 1;
    } else {

      if (name === "floor") {
        var fId = getFloorID(e);
        setSelectedFloor(e);
      }

      if (name === "food_allowance_id") {
        var id = getFilteredFoodAllowanceID(e);
        setSelectedFoodAllowance(e);
      }

      const valueMap = {
        "food_allowance_id": id,
        "floor": fId
    };

    filterList[name] = valueMap[name] !== undefined ? valueMap[name] : value;

      // filterList[name] = value;
      // filterList[name] = name === "floor" ? id : value;
    }
    const list = { ...filterList };

    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0;
    };

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <div className="card-wrapper">
            <h4 className="card-header">Food Allowance Report</h4>
            <form
            onSubmit={() =>
              getFoodList(
                currentPage,
                pageSize,
                searchKey
              )
            }
          >
              <div className="search-block">
              <div className="search">
                  <input
                    type="text"
                    className="search-input-box"
                    placeholder="Search..."
                    autoComplete="off"
                    onChange={(e) => setSearchKey(e?.target?.value)}
                    style={{ outline: "none", border: "none" }}
                    value={searchKey}
                  />

                  <button type="submit">
                    <img src={Search} alt="" className="cursor-pointer" />
                  </button>
                </div>
                <div className="flex gap-3">
                  <div
                    className="filter-button"
                    onClick={() => setFilter((prev) => !prev)}
                  >
                    <span className="filter-btn-label">Filter</span>
                    <img src={Filter} alt="" />
                  </div>
                  
                  <div className="export-dropdown dropdown-select-to-second">
                      <select onChange={handleExportChange} defaultValue="" style={{ backgroundColor: 'rgb(217 151 25)', padding: '10px', borderRadius: '5px' }}>
                        <option value="" disabled>Select Export Type</option>
                        <option value="excel">
                          {isExportLoading ? (
                            <ButtonLoader isBtnDisable={true} />
                          ) : (
                            <>
                              Export as Excel
                              <img src={ExcelExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                            </>
                          )}
                        </option>
                        <option value="pdf">
                          {isExportPdfLoading ? (
                            <ButtonLoader isBtnDisable={true} />
                          ) : (
                            <>
                              Export as PDF
                              <img src={PDFExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                            </>
                          )}
                        </option>
                      </select>
                  </div>
                  
                </div>
              </div>
            </form>
            {filter && (
              <form>
                <div className="form ">
                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Branch Name</label>
                    <Select
                      options={branchList}
                      value={branchList?.find(
                        (option) => option?.value === filterList.branch_id
                      )}
                      onChange={(e) => handleFilter(e, "branch_id", "selecct")}
                      name="branch_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Employee Name</label>
                    <Select
                      options={employeeName}
                      value={employeeName?.find(
                        (option) => option?.value === filterList.employee_id
                      )}
                      onChange={(e) =>
                        handleFilter(e, "employee_id", "selecct")
                      }
                      name="employee_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Employee Type</label>
                    <Select
                      options={employeetype}
                      value={employeetype?.find(
                        (option) =>
                          option?.value === filterList.employee_type_id
                      )}
                      onChange={(e) =>
                        handleFilter(e, "employee_type_id", "selecct")
                      }
                      name="employee_type_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-third">
                    <label className="label-name">Department</label>
                    <Select
                      options={department}
                      value={department?.find(
                        (option) => option?.value === filterList.department_id
                      )}
                      onChange={(e) =>
                        handleFilter(e, "department_id", "selecct")
                      }
                      name="department_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-third">
                    <label className="label-name">Designation</label>
                    <Select
                      options={designation}
                      value={designation?.find(
                        (option) => option?.value === filterList.designation_id
                      )}
                      onChange={(e) =>
                        handleFilter(e, "designation_id", "selecct")
                      }
                      name="designation_id"
                      className=""
                      isSearchable
                    />
                  </div>

                  <div className="field-block dropdown-select-to-first">
                    <label className="label-name">Floor</label>
                    <Select
                      options={floorList}
                      value={selectedFloor ? selectedFloor : []}
                      onChange={(e) => handleFilter(e, "floor", "select")}
                      name="floor"
                      className=""
                      isSearchable
                      isMulti
                    />
                  </div>


                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Food Allowance</label>
                    <Select
                      options={foodAllowanceOption}
                      value={selectedFoodAllowance ? selectedFoodAllowance : []}
                      onChange={(e) => handleFilter(e, "food_allowance_id")}
                      name="food_allowance_id"
                      className=""
                      isSearchable
                      isMulti
                    />
                  </div>
                 
                  <div className="field-block mb-5">
                    <label className="label-name">Allowance Date</label>
                    <input
                      type="date"
                      name="date"
                      className="input-field"
                      onChange={(e) => {
                        handleFilter(e, "date", "date");
                      }}
                      value={filterList?.date}
                    />
                  </div>
                  <div className="field-block flex justify-center">
                    <div className="add-new-button-block w-[40%] h-max">
                      <button
                        className="add-new-button"
                        onClick={(e) => {
                          showFilterResult(e);
                        }}
                      >
                        <span style={{ cursor: "pointer" }}>Show Result</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
            {/**************** * Table ********************/}
            <FoodAllowanceReportTable
              list={filteredFood}
              totalAmount={totalAmount}
              // setPageNo={setCurrentPage}
              // pageSize={pageSize}
              // setPageSize={setPageSize}
              // lastPage={lastPage}
              // currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodAllowance;
