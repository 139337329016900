import React, { useState, useEffect, useRef, useContext } from "react";
import alertify from "alertifyjs";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import { GET_API } from "../../../Services/api";
import VoucherFoodAllowanceTable from "../../DataTable/VoucherFoodAllowanceTable";
import Search from "../../../Assets/Icons/Search.svg";
import Filter from "../../../Assets/Icons/Filter.svg";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../../PrivilegeContext";
import ButtonLoader from "../../ButtonLoader";
import PrintableFoodAllowanceList from './PrintableFoodAllowanceList';

function FoodAllowance() {
  const componentRef = useRef();
  const [foodAllowanceList, setfoodAllowanceList] = useState([]);
  const [filteredFood, setFilteredFood] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [employeeList, setEmployee] = useState([]);
  const [employeeType, setEmployeeType] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filter, setFilter] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [filterParams, setFilterParams] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();

  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [isFloorLoading, setIsFloorLoading] = useState(false);

  // Get all food allowance list
  const [foodAllowanceOption, setFoodAllowanceOption] = useState([]);
  const [selectedFoodAllowance, setSelectedFoodAllowance] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState([]);
  // const [isFoodOptionLoading, setIsFoodOptionLoading] = useState(false);

  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");


  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_id: "",
    employee_type_id: "",
    department_id: "",
    designation_id: "",
    floor: "",
    date: "",
  });

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getEmployeeList();
    getDepartmentList();
    getDesignationList();
    getAllFoodAllowanceList();
    getFloorList();
  }, []);

  useEffect(() => {
    getFoodList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "food_allowance") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const getFloorList = async () => {
    try {
      setIsFloorLoading(true);
      const floorList = await GET_API("/floorDropdown");
      if (floorList?.status === 200) {
        setIsFloorLoading(false);
        const floor = floorList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setFloorList(floor);
      }
    } catch (error) {
      setIsFloorLoading(false);
      alertify.error("Failed to get Floor List...");
    }
  };

  const getAllFoodAllowanceList = async () => {
    try {
      // setIsFoodOptionLoading(true);
      const foodList = await GET_API("/FoodAllowanceAll");
      if (foodList.status === 200) {
        const list = foodList?.data?.data?.map((opt) => {
          return { label: opt?.name, value: opt?.id };
        });
        setFoodAllowanceOption(list);
        // setIsFoodOptionLoading(false);
      }
    } catch (error) {
      // setIsFoodOptionLoading(false);
      setFoodAllowanceOption([]);
      alertify.error("Failed to Get Allowance List");
    }
  };

  const getBranchList = async () => {
    try {
      setIsBranchLoading(true);
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((exp, i) => {
          return { value: exp?.id, label: exp?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeeType(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getEmployeeList = async () => {
    setIsEmpLoading(true);
    try {
      const empList = await GET_API("/employeeDropdown");
      if (empList.status === 200) {
        const employees = empList?.data?.data.map((exp, i) => {
          return {
            value: exp?.id,
            label: `${exp?.first_name} (${exp?.emp_code})`,
          };
        });
        employees.unshift({ value: "", label: "Select..." });
        setEmployee(employees);
        setIsEmpLoading(false);
      }
    } catch (e) {
      setIsEmpLoading(false);
      alertify.error("Failed to Get Employee List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const getFilteredFoodAllowanceID = (e) => {
    let foodAllowanceID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    return foodAllowanceID;
  };

  const getFloorID = (e) => {
    let floorID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    return floorID;
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    if (name === "food_allowance_id") {
      var id = getFilteredFoodAllowanceID(e);
      setSelectedFoodAllowance(e);
    }

    if (name === "floor") {
      var fId = getFloorID(e);
      setSelectedFloor(e);
    }

    const valueMap = {
      "food_allowance_id": id,
      "floor": fId
  };
  
  filterList[name] = valueMap[name] !== undefined ? valueMap[name] : value;

    // filterList[name] = name === "food_allowance_id" ? id : value;
    
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
      return;
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const getFoodList = async (page, size, filters, search) => {
    setLoading(true);
    try {
      const foodList = await GET_API(
        `/foodApplicableEmp?page=${page}&size=${size}${filters}&search=${search}`
      );
      if (foodList?.data.status === 200) {
        setfoodAllowanceList(foodList?.data?.data);
        setFilteredFood(foodList?.data?.data);
        setSelectedRows(foodList?.data?.data);
        setTotalAmount(foodList?.data?.totalAmt);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setLastPage("");
      setfoodAllowanceList([]);
      setFilteredFood([]);
      alertify.error("Failed to Get Food Allowance Voucher List");
    }
  };

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      getFoodList(currentPage, pageSize, filterParams, searchKey);
      setIsPrintVoucher(false);
    },
  });

  const handlePrint = async () => {
    const checkbox = document?.getElementsByClassName("MuiCheckbox-root");
    const voucherHeader = document?.getElementsByClassName("voucher-header");
    for (let i = 0; i < checkbox.length; i++) {
      checkbox[i].style.display = "none";
    }

    for (var i = 0; i < voucherHeader.length; i++) {
      voucherHeader[i].style.visibility = "visible";
    }
    setIsPrintVoucher(true);
    setFilteredFood(selectedRows);
    setTimeout(printVoucher, 3000);
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading ||
    isFloorLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow" style={{ width: "80%" }}>
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <div className="card-wrapper">
            <div className="card-header">Food Allowance</div>
            <form
              onSubmit={() => getFoodList(currentPage, pageSize, searchKey)}
            >
              <div className="search-block">
                <div className="search">
                  <input
                    type="text"
                    className="search-input-box"
                    placeholder="Search..."
                    autoComplete="off"
                    onChange={(e) => setSearchKey(e?.target?.value)}
                    style={{ outline: "none", border: "none" }}
                    value={searchKey}
                  />

                  <button type="submit">
                    <img src={Search} alt="" className="cursor-pointer" />
                  </button>
                </div>

                <div className="flex gap-3">
                  <div
                    className="filter-button"
                    onClick={() => setFilter((prev) => !prev)}
                  >
                    <span className="filter-btn-label">Filter</span>
                    <img src={Filter} alt="" />
                  </div>

                  {addAccess && (
                    <>
                      <div>
                        {isPrintVoucher ? (
                          <ButtonLoader isBtnDisable={isPrintVoucher} />
                        ) : (
                          <button
                            className="submit-button"
                            onClick={handlePrint}
                          >
                            <span className="submit-button-label">
                              Print Voucher
                            </span>
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
            {filter && (
              <form>
                <div className="form ">
                  <div className="field-block dropdown-select-to-first">
                    <label className="label-name">Branch Name</label>
                    <Select
                      options={branchList}
                      value={branchList.find(
                        (option) => option?.value === filterList.branch_id
                      )}
                      onChange={(e) => handleFilter(e, "branch_id")}
                      name="branch_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-first">
                    <label className="label-name">Employee Name</label>
                    <Select
                      options={employeeList}
                      value={employeeList.find(
                        (option) => option?.value === filterList.employee_id
                      )}
                      onChange={(e) => handleFilter(e, "employee_id")}
                      name="employee_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-first">
                    <label className="label-name">Employee Type</label>
                    <Select
                      options={employeeType}
                      value={employeeType.find(
                        (option) =>
                          option?.value === filterList.employee_type_id
                      )}
                      onChange={(e) => handleFilter(e, "employee_type_id")}
                      name="employee_type_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Department</label>
                    <Select
                      options={department}
                      value={department.find(
                        (option) => option?.value === filterList.department_id
                      )}
                      onChange={(e) => handleFilter(e, "department_id")}
                      name="department_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Designation</label>
                    <Select
                      options={designation}
                      value={designation.find(
                        (option) => option?.value === filterList.designation_id
                      )}
                      onChange={(e) => handleFilter(e, "designation_id")}
                      name="designation_id"
                      className=""
                      isSearchable
                    />
                  </div>

                  <div className="field-block">
                    <label className="label-name">Finance Date</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="date"
                      value={filterList?.date}
                      onChange={(e) => handleFilter(e, "date", "date")}
                    />
                </div>

                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Floor</label>
                    <Select
                      options={floorList}
                      value={selectedFloor ? selectedFloor : []}
                      onChange={(e) => handleFilter(e, "floor")}
                      name="floor"
                      className=""
                      isSearchable
                      isMulti
                    />
                  </div>
                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Food Allowance</label>
                    <Select
                      options={foodAllowanceOption}
                      value={selectedFoodAllowance ? selectedFoodAllowance : []}
                      onChange={(e) => handleFilter(e, "food_allowance_id")}
                      name="food_allowance_id"
                      className=""
                      isSearchable
                      isMulti
                    />
                  </div>
                

                  <div className="add-new-button-block w-[40%] h-max">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
            {/**************** * Table ********************/}
            <VoucherFoodAllowanceTable
              list={filteredFood}
              setSelectedRows={setSelectedRows}
              printVoucher={componentRef}
              totalAmount={totalAmount}
            />
            {isPrintVoucher && (
              <PrintableFoodAllowanceList
                ref={componentRef}
                rows={filteredFood}
                totalAmount={totalAmount}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodAllowance;
