import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import Pagination from "./Pagination";
import dayjs from "dayjs";

export default function StickyHeadTable({
  list,
  columns,
  icon,
  editStatus,
  editList,
  multiple,
  deleteRow,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
 
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className="table-column"
                    >
                      {
                        <TableCell className="table-body !w-[7%]">
                          {(currentPage - 1) * pageSize+ (i + 1)}
                        </TableCell>
                      }
                      {<TableCell className="table-body">{row.name}</TableCell>}
                      {
                        <TableCell
                          className="table-body"
                          style={{ wordBreak: "break-word" }}
                        >
                          {row?.description}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {dayjs(row?.created_at).format("DD-MM-YYYY hh:mm:ss")}
                        </TableCell>
                      }
                      {
                        <MoreOption
                          icon={icon}
                          editStatus={editStatus}
                          editList={editList}
                          row={row}
                          multiple={multiple}
                          deleteRow={deleteRow}
                        />
                      }
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
