import React from "react";
import { Link, useLocation } from "react-router-dom";

function AbsentReportDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const father_name = listDetails?.fathers_name
    ? listDetails?.fathers_name
    : "";
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.employee_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.employee_code,
    },
    {
      label: "Phone",
      value: listDetails?.mobile_number,
    },

    {
      label: "Gender",
      value: listDetails?.gender,
    },
    {
      label: "Department",
      value: listDetails?.department_name,
    },
    { label: "Designation", value: listDetails?.designation_name },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/absent" className="in-active">
              Absent Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Absent Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Employee Absent Report Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AbsentReportDetails;
