import React, { useState, useEffect } from "react";
import More from "../../../../Assets/Icons/More.svg";
import { Link, useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../../Services/api";
import Search from "../../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import WarehouseTable from "../../../DataTable/PurchaseOrder/WarehouseTable";

function POWarehouse() {
  const navigate = useNavigate();
  const [warehouseList, setWarehouseList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState([]);

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "code", label: "Code" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getWarehouseList();
  }, []);
  const getWarehouseList = async () => {
    setLoading(true);
    try {
      const warehouseList = await GET_API("/po/warehouse");
      if (warehouseList?.data?.status === 200) {
        setPagination(warehouseList?.data);
        setWarehouseList(warehouseList?.data?.data?.data);
        setFilteredList(warehouseList?.data?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = warehouseList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredList(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Warehouse..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(`/po/deleteWarehouse/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getWarehouseList();
              return;
            }
            alertify.error("Failed To Delete Warehouse");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigateEditPage = (listDetail) => {
    navigate(`/master/po/warehouse/edit/${listDetail?.id}`, {
      state: {
        list: listDetail?.id,
      },
    });
  };
  const showBranchDetails = (listDetail) => {
    navigate(`/master/po/warehouse/details/${listDetail?.id}`, {
      state: {
        list: listDetail?.id,
      },
    });
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Warehouse</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>

            <div className="add-new-button-block">
              <Link to="/master/po/warehouse/create" className="add-new-button">
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}

          <WarehouseTable
            list={filteredList}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}
export default POWarehouse;
