import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import "alertifyjs/build/css/alertify.css";

function CreateDesignation() {
  const navigate = useNavigate();
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [designation, setDesignation] = useState({
    name: "",
    slug: "",
    description: "",
  });

  const createDesignation = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateDesingnation(designation);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/addDesignation", designation)
      .then(async function (data) {
        alertify.success("Designation added successfully!");
        navigate("/master/hrms/designation");
        setButtonDisabled(false);
      })
      .catch((error) => alertify.error("Some error occured"));
  };

  // useEffect(() => {
  //   getDepartmentList();
  // }, []);

  // const getDepartmentList = async () => {
  //   try {
  //     setLoading(true);
  //     const departmentList = await GET_API("/departmentDropdown");
  //     if (departmentList?.data?.status === 200) {
  //       const department = departmentList?.data?.data.map((dep, i) => {
  //         return { value: dep?.id, label: dep?.name };
  //       });
  //       setDepartmentList(department);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setDepartmentList([]);
  //     setLoading(false);
  //     alertify?.error("Failed to get Department List...");
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setDesignation({ ...designation, [name]: value });
  };

  const resetForm = () => {
    setDesignation({
      name: "",
      slug: "",
      description: "",
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/designation" className="in-active">
              Deisgnation List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Designation</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Designation</h4>
          <form onSubmit={(e) => createDesignation(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Designation Name
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Designation Name"
                  name="name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={designation?.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Slug
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Slug Name"
                  name="slug"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={designation?.slug}
                />
                {errors.slug ? (
                  <div className="validation-error-label">{errors.slug}</div>
                ) : null}
              </div>

              {/* <div className="field-block">
                <label className="label-name">
                  Department
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <Select
                  options={departmentList}
                  value={
                    leaveDetails.department_id
                      ? departmentList?.find(
                          (option) =>
                            option?.value === leaveDetails.department_id
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "department_id")}
                  name="department_id"
                  className=""
                  isSearchable
                />
                {errors.department_id ? (
                  <div className="validation-error-label">
                    {errors.department_id}
                  </div>
                ) : null}
              </div> */}
              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  rows={5}
                  autoComplete="off"
                  placeholder="Enter Description"
                  name="description"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={designation?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateDesignation;
