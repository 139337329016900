import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Edit from "../../../../Assets/Icons/Edit.svg";
import View from "../../../../Assets/Icons/View.svg";
import Spinner from "../../../Spinner";
import { GET_API } from "../../../../Services/api";
import alertify from "alertifyjs";

function SupplierDetails() {
  const [loading, setLoading] = useState(false);
  const [detailsList, setDetailsList] = useState([]);
  const { state } = useLocation();
  const listDetails = state?.list;
  const id = listDetails?.id;
  useEffect(() => {
    getSupplierDetails();
  }, []);

  const getSupplierDetails = async () => {
    setLoading(true);
    try {
      const details = await GET_API(`po/getSupplier/${id}`);
      if (details?.data?.status === 200) {
        setDetailsList(details?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get supplier details");
    }
  };

  const Details = [
    {
      label: "Name",
      value: detailsList?.suppliers?.name,
    },

    {
      label: "Code",
      value: detailsList?.suppliers?.supplier_code,
    },

    {
      label: "Email",
      value: detailsList?.suppliers?.email,
    },

    {
      label: "Email",
      value: detailsList?.suppliers?.email,
    },
    {
      label: "Mobile Number",
      value: detailsList?.suppliers?.mobile_number,
    },

    {
      label: "GSTN Number",
      value: detailsList?.suppliers?.gstn_no,
    },
    {
      label: "Billing Address",
      value: detailsList?.suppliers?.billing_address,
    },

    {
      label: "Billing State",
      value: detailsList?.suppliers?.billing_state,
    },
    {
      label: "Billing City",
      value: detailsList?.suppliers?.billing_city,
    },

    {
      label: "Billing Country",
      value: detailsList?.suppliers?.billing_country,
    },
    {
      label: "Billing Pincode",
      value: detailsList?.suppliers?.billing_pincode,
    },
    {
      label: "Bank Name",
      value: detailsList?.suppliers?.bank_name,
    },
    {
      label: "Account Number",
      value: detailsList?.suppliers?.account_number,
    },
    {
      label: "Ifsc Code",
      value: detailsList?.suppliers?.ifsc_code,
    },
    {
      label: "Bank Branch",
      value: detailsList?.suppliers?.bank_branch,
    },
    {
      label: "Discount",
      value: detailsList?.suppliers?.discount,
    },
    {
      label: "Remarks",
      value: detailsList?.suppliers?.remarks,
    },
  ];

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/master/po/suppliers" className="in-active">
                Supplier List
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">Supplier Details</Link>
            </div>
          </div>
          <div className="card-wrapper">
            <h4 className="card-header">Supplier Details</h4>

            <div className="list-container">
              <div className="list-content-block">
                {Details.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list.value}
                      </div>
                    </div>
                  );
                })}

                <div className="list ">
                  <label className="list-label">Department</label>
                  <ul>
                    {detailsList?.poSupplierDepartment?.map((department) => {
                      return (
                        <li
                          style={{ wordBreak: "break-word" }}
                          className="list-value"
                        >
                          {department?.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SupplierDetails;
