import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
// import LoanPrintVoucher from "./LoanPrintVoucher";
import "alertifyjs/build/css/alertify.css";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";

function CreateLoan() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const [loanDetails, setLoanDetails] = useState({
    employee_id: [],
    type: "loan",
    selecttype: "cash",
    finance_category_id: "",
    amount: "",
    duration: "",
    finance_date: dayjs(new Date()).format("YYYY-MM-DD"),
    approved_by: "",
    file: "",
    loan_deduction_amount: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  // const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  // const [printVoucherData, setPrintVoucherData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isErrors, setIsErrors] = useState({ employee_id: "" });
  const [existingLoanDetails, setExistingLoanDetails] = useState([]);
  const [fileType, setFileType] = useState("");
  const [voiceDet, setVoiceDet] = useState("");

  const [salary, setSalary] = useState("");
  const [loanDedAmt, setLoanDedAmt] = useState("");
  const [loanRemark, setLoanRemark] = useState("");

  useEffect(() => {
    getLoanList();
    getEmployeeList();
  }, []);

  const getLoanList = async () => {
    setLoading1(true);
    try {
      const loanList = await GET_API("/financeCategoriesDropdownLoan");
      if (loanList.status === 200) {
        const loan = loanList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        loan.unshift({ value: "", label: "Select..." });
        setLoading1(false);
      }
    } catch (error) {
      alertify.error("Failed to get Loan list...");
      setLoading1(false);
    }
  };

  const getEmployeeList = async () => {
    setLoading2(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const res = employeeList?.data?.data.map((emp, i) => {
          return {
            label: `${emp?.first_name} ${
              emp?.fathers_name ? emp?.fathers_name : ""
            } (${emp?.emp_code})`,
            value: emp?.id,
            salary: emp?.basic_salary,
            loan_deduction_amount: emp?.loan_deduction_amount,
          };
        });
        const approval = employeeList?.data?.data.map((emp, i) => {
          return {
            label: `${emp?.first_name} ${
              emp?.fathers_name ? emp?.fathers_name : ""
            } (${emp?.emp_code})`,
            value: emp?.id,
            salary: emp?.basic_salary,
            loan_deduction_amount: emp?.loan_deduction_amount,
          };
        });
        approval.unshift({ value: "", label: "Select..." });
        setEmployeeList(res);
        setLoading2(false);
      }
    } catch (error) {
      setLoading2(false);
      alertify?.error("Failed to get Employee List...");
    }
  };


  const handleProfileImage = (e) => {
    const type = e?.target?.files[0]?.type?.split("/").shift();
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setLoanDetails({ ...loanDetails, file: fileReader?.result });
        setFileType(type);
      }
    };
    fileReader?.readAsDataURL(e?.target?.files[0]);
  };


  const handleVoiceNote = (e) => {
    const type = e?.target?.files[0]?.type?.split("/").shift();
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setLoanDetails({ ...loanDetails, voice_note: fileReader?.result });
        setVoiceDet(type);
      }
    };
    fileReader?.readAsDataURL(e?.target?.files[0]);
  };


  const handleChange = (e) => {
    const { name, value } = e?.target;
    setLoanDetails(() => ({ ...loanDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setLoanDetails(() => ({ ...loanDetails, [name]: e?.value }));
  };

  // const printVoucher = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  // useEffect(() => {
  //   if (isPrintVoucher) {
  //     printVoucher();
  //     navigate("/expense/loan");
  //   }
  // }, [isPrintVoucher, printVoucher]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (loanDetails.employee_id.length === 0) {
      newErrors.employee_id = "Employee Name is required";
      valid = false;
      setButtonDisabled(false);
    }
    setIsErrors(newErrors);
    return valid;
  };

  const createLoan = async (e) => {
    e.preventDefault();
    const isValid = Validation.validateFinanceLoan(loanDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    if (validateForm()) {
      const userId = localStorage.getItem("user_id");
      POST_API("/addFinanceDetails", {
        ...loanDetails,
        created_by: userId,
        finance_category_id: loanDetails?.finance_category_id,
        approved_by: loanDetails?.approved_by,
        selecttype: loanDetails?.selecttype,
        loan_deduction_amount: loanDetails?.loan_deduction_amount,
        // employee_id: loanDetails?.employee_id?.value,
      })
        .then(async function (data) {
          setButtonDisabled(false);
          alertify.success(data?.data?.message);
          navigate("/expense/loan");
          // setPrintVoucherData(data?.data?.data);
          // if (selectedOptions?.length <= 1) {
          //   setTimeout(() => {
          //     printVoucher();
          //     setIsPrintVoucher(true);
          //   }, 500);
          // } else {
          //   navigate("/expense/loan");
          // }
        })

        .catch((error) => {
          setButtonDisabled(false);
          alertify.error(error?.response?.data?.message);
        });
    }
  };

  const checkExisting = async (emp) => {
    // const employee_id = emp.map((item) => item?.value);
    if (emp?.length === 0) return;
    try {
      setLoading1(true);
      const existingLoanList = await POST_API(`checkExistingFinance/loan`, {
        employee_ids: emp,
      });

      if(existingLoanList?.data?.count >= 1) {
        alertify.error("Already loan has been provided to this employee for this month.");
        setLoading2(false);
        
        setLoanDetails((prevDetails) => ({
          ...prevDetails,  
          remarks: "Loan count of this month is " + (existingLoanList?.data?.count)
        }));
        setLoanRemark("Loan count of this month is " + (existingLoanList?.data?.count));
      } else {
        setLoanDetails((prevDetails) => ({
          ...prevDetails,  
          remarks: "FIRST LOAN"
        }));
        setLoanRemark("FIRST LOAN");
      }

      if (existingLoanList?.status === 200) {
        setExistingLoanDetails(existingLoanList?.data?.data);
        setLoading1(false);
      }
    } catch (e) {
      setLoading1(false);
      alertify.error("Failed To get Existing Loan Details");
    }
  };

  const handleEmployeeName = async (e) => {
    setSelectedOptions(e);
    const id = [e?.value];
    const salary = [e?.salary];
    const loanDedAmt = [e?.loan_deduction_amount];
    setLoanDetails((prevDetails) => ({
      ...prevDetails,
      employee_id: id,
      loan_deduction_amount: loanDedAmt
    }));
    checkExisting(id);
    setSalary(salary);
    // setLoanDedAmt(loanDedAmt)
    setButtonDisabled(false);
    if(loanDedAmt[0] === "0.00") {
      alertify.error("Loan deduction amount field should not be empty. Kindly update it on employee details.");
      setButtonDisabled(true);
    }
  };

  const selectTypes = [
    { value: "", label: "Please Select" },
    { value: "bank", label: "Bank" },
    { value: "cash", label: "Cash" },
  ];

  const today = new Date().toISOString().split('T')[0];

  const resetForm = () => {
    // setdesignationName("");
    // setdepartmentName("");
    // setbranchName("");
    setLoanDetails({
      type: "loan",
      finance_category_id: "",
      approved_by: "",
      selecttype: "",
      amount: "",
      duration: "",
      employee_id: [],
      loan_deduction_amount : ""
    });
    setSelectedOptions([]);
  };

  if (loading1 || loading2) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/loan" className="in-active">
              Loan List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Loan</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Loan</div>

          {/***************Form************* **/}
          {/* {isPrintVoucher && (
            <LoanPrintVoucher
              ref={componentRef}
              printVoucherData={printVoucherData}
            />
          )} */}
          <form onSubmit={(e) => createLoan(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  value={selectedOptions ? selectedOptions : ""}
                  onChange={(e) => handleEmployeeName(e)}
                  options={employeeList}
                  name="employee_id"
                />
                {isErrors["employee_id"] ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {isErrors["employee_id"]}
                  </div>
                ) : null}
              </div>


              <div className="field-block">
                <label className="label-name">
                  Loan Deduction Amount
                </label>
                <input
                  type="text"
                  name="loan_deduction_amount"
                  className="input-field"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={loanDetails?.loan_deduction_amount}
                  // readOnly
                />
              </div>

              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="amount"
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={loanDetails?.amount}
                  placeholder="Enter Amount"
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Payment Type</label>
                <Select
                  options={selectTypes}
                  value={
                    loanDetails.selecttype
                      ? selectTypes?.find(
                          (option) => option?.value === loanDetails.selecttype
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "selecttype")}
                  name="selecttype"
                  className=""
                  isSearchable
                />
                {errors.selecttype ? (
                  <div className="validation-error-label">
                    {errors.selecttype}
                  </div>
                ) : null}
              </div>
              

              <div className="field-block">
                <label className="label-name">
                  Basic Salary
                </label>
                <input
                  type="text"
                  name="basic_salary"
                  className="input-field"
                  value={salary}
                  readOnly
                />
              </div>


              <div className="field-block">
                <label className="label-name">Expense Date</label>
                <input
                  type="date"
                  name="finance_date"
                  className="input-field"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={loanDetails?.finance_date}
                  min={today}
                  max={today}
                />
                {errors.finance_date ? (
                  <div className="validation-error-label">
                    {errors.finance_date}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Select File
                  {/* <span className="required-symbol">*</span> */}
                </label>
                <div className="">
                  <div className="flex gap-2 items-center">
                    {fileType !== "image" ? (
                      <FilePresentIcon className="employee-profile-picture" />
                    ) : (
                      <img
                        name="file"
                        className="h-[35px] w-[35px]"
                        src={loanDetails?.file}
                        alt=""
                      />
                    )}
                    <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                      <label for="files" className="label-name cursor-pointer">
                        {loanDetails?.file === "" ? "Choose" : "Replace"}
                      </label>
                      <input
                        className="w-0 cursor-pointer"
                        id="files"
                        style={{ visibility: "hidden" }}
                        type="file"
                        // accept=".jpeg,.jpg,.png"
                        onChange={(e) => handleProfileImage(e)}
                      />
                      <img src={FileUpload} alt="" />
                    </div>
                  </div>
                  {errors?.file ? (
                    <div className="validation-error-label" key={errors?.file}>
                      {errors?.file}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="field-block">
                <label className="label-name">
                  Select Voice Note
                  {/* <span className="required-symbol">*</span> */}
                </label>
                <div className="">
                  <div className="flex gap-2 items-center">
                    {voiceDet !== "image" ? (
                      <FilePresentIcon className="employee-profile-picture" />
                    ) : (
                      <img
                        name="voice_note"
                        className="h-[35px] w-[35px]"
                        src={loanDetails?.voice_note}
                        alt=""
                      />
                    )}
                    <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                      <label for="voice_note" className="label-name cursor-pointer">
                        {loanDetails?.file === "" ? "Choose" : "Replace"}
                      </label>
                      <input
                        className="w-0 cursor-pointer"
                        id="voice_note"
                        style={{ visibility: "hidden" }}
                        type="file"
                        // accept=".jpeg,.jpg,.png"
                        onChange={(e) => handleVoiceNote(e)}
                      />
                      <img src={FileUpload} alt="" />
                    </div>
                  </div>
                  {errors?.voice_note ? (
                    <div className="validation-error-label" key={errors?.voice_note}>
                      {errors?.voice_note}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="field-block">
                <label className="label-name">
                  Loan Remarks
                </label>
                <textarea name="remarks"
                  className="input-field" readOnly
                  >{loanRemark}</textarea>
              </div>

            </div>

            <div className="px-5 pb-4">
              <span className="font-karla text-text-dark text-xl">
                Existing Loan Details
              </span>
              <div>
                <table className="log-table mt-[4px] w-full">
                  <tr className="bg-[#FFEDC2]">
                    <td className="log-table log-table-data content-label font-extrabold">
                      Employee Code
                    </td>
                    <td className="log-table log-table-data content-label font-extrabold">
                      Finance Date
                    </td>
                    <td className="log-table log-table-data content-label font-extrabold">
                      Amount
                    </td>
                    <td className="log-table log-table-data content-label font-extrabold">
                      Payment Type
                    </td>
                    <td className="log-table log-table-data content-label font-extrabold">
                      PF Employee
                    </td>
                  </tr>

                  {existingLoanDetails?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={6}
                        className="p-4 text-text-light font-karla text-center"
                      >
                        Existing Loan Details For Current Month Not found..!
                      </td>
                    </tr>
                  ) : (
                    existingLoanDetails?.map((item) => {
                      return (
                        <tr>
                          <td className="log-table log-table-data content-label whitespace-nowrap">
                            {item?.employeeCode}
                          </td>
                          <td className="log-table log-table-data content-label whitespace-nowrap">
                            {item?.finance_date}
                          </td>
                          <td className="log-table log-table-data content-label whitespace-nowrap font-black">
                            <span>&#8377;{item?.amount}</span>
                          </td>
                          <td className="log-table log-table-data content-label">
                            {item?.payment_type}
                          </td>
                          <td className="log-table log-table-data content-label">
                            {item?.pf_complaint === 1 ? "Yes" : "No"}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </table>
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateLoan;
