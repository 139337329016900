import React from "react";
import { Link } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";

function EducationalInfo({ listDetails }) {
  const Details = [
    {
      label: "Qualification",
      value: listDetails?.education ? listDetails?.education : "-",
    },
    {
      label: "Passed Out",
      value: listDetails?.passed_out ? listDetails?.passed_out : "-",
    },
    {
      label: "Remarks",
      value: listDetails?.remarks ? listDetails?.remarks : "-",
    },
  ];

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      <div
        className="p-3 w-3/12 bg-primary-color text-center"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Education Info
        </span>
      </div>
      <div className="list-container mt-3">
        <div className="list-content-block">
          {Details.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EducationalInfo;
