import React from "react";
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

function MovementReportDetails() {
  const { state } = useLocation();
  const [employeeDetails, setEmployeeDetails] = useState(null);

  useEffect(() => {
    // Retrieve data from localStorage
    const details = localStorage.getItem('selectedEmployeeDetails');
    if (details) {
      setEmployeeDetails(JSON.parse(details));  // Parse the stored JSON string into an object
    }

    // Optionally, remove the data after retrieval
    localStorage.removeItem('selectedEmployeeDetails');
  }, []);

  if (!employeeDetails) {
    return <div>Loading...</div>;  // Or some fallback UI
  }

  const convertToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${hours} hour(s) and ${remainingMinutes} minute(s)`;
  };

  
  const Details = [
    {
      label: "Employee Name",
      value: employeeDetails?.EmployeeName,
    },
    {
      label: "Employee Code",
      value: employeeDetails?.employeeCode,
    },

    {
      label: "Branch Name",
      value: employeeDetails?.branchName,
    },
    {
      label: "Designation Name",
      value: employeeDetails?.designation_name,
    },
    {
      label: "Department Name",
      value: employeeDetails?.department_name,
    },
    {
      label: "Gender",
      value: employeeDetails?.Gender,
    },
    {
      label: "Employee Type",
      value: employeeDetails?.employeeTypeName,
    },
    {
      label: "Today Working Duration",
      value: employeeDetails?.working_hrs,
    },
    {
      label: "Today Break Duration",
      value:  convertToHoursAndMinutes(employeeDetails.break_duration),
    },
    {
      label: "In Time",
      value: employeeDetails?.clock_in_time ? employeeDetails?.clock_in_time : "-",
    },
    {
      label: "Exit Time",
      value: employeeDetails?.clock_out_time ? employeeDetails?.clock_out_time : "-",
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/movement" className="in-active">
              Movement Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Movement Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Movement Report Details</h4>

          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="pt-2">
                <label className="list-label">Break Details</label>
                <div className="pt-2">
                  <table className="w-full border border-[#d9d9d9] border-collapse">
                    <thead>
                      <tr>
                        <th className="p-2 text-left border-b">Out</th>
                        <th className="p-2 text-left border-b">In</th>
                        <th className="p-2 text-left border-b">Current Duration</th>
                        <th className="p-2 text-left border-b">Actual Duration</th>
                        <th className="p-2 text-left border-b">Extra Time</th>
                        <th className="p-2 text-left border-b">Extra Fine</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeDetails.break_detail && employeeDetails.break_detail !== "null" ? (
                        JSON.parse(employeeDetails.break_detail).map((logs, index) => (
                          <tr key={index} className="border-b">
                            <td className="p-2">{logs?.clock_out}</td>
                            <td className="p-2">{logs?.clock_in}</td>
                            <td className="p-2">{logs?.break_time}</td>
                            <td className="p-2">{logs?.predefined_break_duration ? logs?.predefined_break_duration : '-'}</td>
                            <td className="p-2">{logs?.extra_time ? Math.round(logs.extra_time * 100) / 100 : '-'}</td>
                            <td className="p-2">{logs?.extra_charge ? Math.round(logs.extra_charge * 100) / 100 : '-'}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="p-2 text-center">
                            No break details available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovementReportDetails;
