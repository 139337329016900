import React, { useContext, useEffect, useState } from "react";
import { GET_API, POST_API } from "../../Services/api";
import Spinner from "../Spinner";
import alertify from "alertifyjs";
import EmployeeApprove from "../DataTable/EmployeeApproveTable";
import { PrivilegeContext } from "../../PrivilegeContext";
import config from "../../Services/config.json";

function EarlyExitApproval() {
  const [loading, setLoading] = useState(false);
  const [earlyExitList, setEarlyExitList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);

  useEffect(() => {
    getEmployeeApproveList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "approve_early_exit") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const columns = [
    {
      field: "profile_image",
      headerName: "Profile",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <img
            src={config?.path + params?.value}
            alt=""
            className="h-[40px] w-[40px] rounded"
          />
        );
      },
    },
    { field: "first_name", headerName: "Name", minWidth: 200 },
    { field: "emp_code", headerName: "Code", minWidth: 150 },
    { field: "departmentname", headerName: "Department", minWidth: 200 },
    { field: "designationname", headerName: "Designation", minWidth: 200 },
    { field: "statusName", headerName: "Status", minWidth: 150 },
  ];

  const isPageNotExit = () => {
    setCurrentPage(1);
    getEmployeeApproveList(1, pageSize);
  };

  const getEmployeeApproveList = async (page, size) => {
    setLoading(true);
    try {
      const earlyExit = await GET_API(
        `/approvalEmployeesList?page=${page}&size=${size}`
      );
      if (earlyExit?.status === 200) {
        if (
          earlyExit?.data?.data?.current_page > earlyExit?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setEarlyExitList(earlyExit?.data?.data?.data);
        setLastPage(earlyExit?.data?.data?.last_page);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleApprove = async (type) => {
    const data = {
      selectedRows,
      appType: type,
    };
    if (selectedRows?.length <= 0) {
      alertify
        .alert("Please select Row to Approve/Reject")
        .setHeader("<b> Alert </b> ");
      return;
    }
    try {
      const approveMasters = await POST_API("/approveRejectEmployee", data);
      if (approveMasters?.status === 200) {
        alertify.success(
          `Masters ${
            data?.appType === "approve"
              ? data?.appType + "d"
              : data?.appType + "ed"
          } successfully...!`
        );
        getEmployeeApproveList(currentPage, pageSize);
        return;
      }
    } catch (error) {
      alertify.error(`Failed to ${data?.appType}d Masters`);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow grow w-[calc(100%-80px-20%)]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Employee Logs</h4>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            <div className="flex gap-3">
              {addAccess && (
                <>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("reject");
                    }}
                  ></div>
                  <>
                    <div
                      className="add-new-button-block"
                      onClick={() => {
                        handleApprove("reject");
                      }}
                    >
                      <button to="" className="add-new-button">
                        <span>Reject</span>
                      </button>
                    </div>
                    <div
                      className="add-new-button-block"
                      onClick={() => {
                        handleApprove("approve");
                      }}
                    >
                      <button to="" className="add-new-button">
                        <span>Approve</span>
                      </button>
                    </div>
                  </>
                </>
              )}
            </div>
          </div>
          {/**************** * Table ********************/}
          <div className="p-5 min-h-[400px]">
            <EmployeeApprove
              columns={columns}
              list={earlyExitList}
              setSelectedRows={setSelectedRows}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarlyExitApproval;
