import React from "react";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import Delete from "../../../Assets/Icons/Delete.svg";

function AllowanceListItems({
  allowanceTypeList,
  index,
  deleteList,
  addMoreList,
  breakValue,
  setBreakValue,
  item,
}) {


  const handleChange = (index, e) => {
    const { name, value } = e?.target;
    const newItems = [...breakValue];
    newItems[index][name] = value;
    setBreakValue(newItems);
  };



  return (
    <div className="p-6 grid grid-cols-5 gap-5">
      
      <div className="input-block-allowance" name="amount">
        <label className="label-name">Break Name</label>
        <input
          type="text"
          name="name"
          placeholder="Enter Name"
          className="input-field"
          onChange={(e) => handleChange(index, e)}
          value={item !== "undefined" ? item["name"] : ''}
        />
      </div>

      <div className="input-block-allowance" name="amount">
        <label className="label-name">Start</label>
        <input
          type="time"
          name="start"
          className="input-field"
          onChange={(e) => handleChange(index, e)}
          value={item !== "undefined" ? item["start"] : ''}
        />
      </div>

      <div className="input-block-allowance" name="amount">
        <label className="label-name">End</label>
        <input
          type="time"
          name="end"
          className="input-field"
          onChange={(e) => handleChange(index, e)}
          value={item !== "undefined" ? item["end"] : ''}
        />
      </div>

      <div className="input-block-allowance" name="amount">
        <label className="label-name">Break Duration(in min)</label>
        <input
          type="number"
          name="duration"
          placeholder="Enter Duration"
          className="input-field"
          onChange={(e) => handleChange(index, e)}
          value={item !== "undefined" ? item["duration"] : ''}
        />
      </div>

      {index > 0 ? (
        <button
          onClick={() => {
            deleteList(index);
          }}
        >
          <img src={Delete} alt="" className="w-6 h-6" />
        </button>
      ) : (
        <div className="flex items-center">
          <div
            className="add-new-button-block add-new-button-container mt-5"
            onClick={addMoreList}
          >
            <button className="add-new-button">
              <span>Add</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AllowanceListItems;
