import React from "react";
import { Route } from "react-router-dom";

import FoodAllowanceList from "../Allowance/FoodAllowance/FoodAllowanceList";
import CreateFoodAllowance from "../Allowance/FoodAllowance/CreateFoodAllowance";
import UpdateFoodAllowance from "../Allowance/FoodAllowance/UpdateFoodAllowance";
import FoodAllowanceDetails from "../Allowance/FoodAllowance/FoodAllowanceDetails";

function AllowanceRoutes() {
  return (
    <>
      <Route path="foodallowance" element={<FoodAllowanceList />}></Route>
      <Route
        path="foodallowance/create"
        element={<CreateFoodAllowance />}
      ></Route>
      <Route
        path="foodallowance/edit/:id"
        element={<UpdateFoodAllowance />}
      ></Route>
      <Route
        path="foodallowance/details/:id"
        element={<FoodAllowanceDetails />}
      ></Route>
    </>
  );
}

export default AllowanceRoutes;
