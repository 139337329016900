import React, { useState, useEffect } from "react";
import More from "../../../../Assets/Icons/More.svg";
import { DELETE_API, GET_API } from "../../../../Services/api";
import Search from "../../../../Assets/Icons/Search.svg";
import Spinner from "../../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import AgentTable from "../../../DataTable/PurchaseOrder/AgentTable";
import { Link, useNavigate } from "react-router-dom";
import ColorAdd from "../../../../Assets/Icons/ColorAdd.svg";

function POAgent() {
  const [agentList, setAgentList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "desc", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  const isPageNotExit = () => {
    setCurrentPage(1);
    getagentList(1, pageSize, searchKey);
  };

  useEffect(() => {
    getagentList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const getagentList = async (page, size, search) => {
    setLoading(true);
    try {
      const agentList = await GET_API(
        `/po/poAgentList?page=${page}&size=${size}&search=${search}`
      );
      if (agentList?.data?.status === 200) {
        if (
          agentList?.data?.data?.current_page > agentList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setPagination(agentList?.data);
        setAgentList(agentList?.data?.data?.data);
        setFilteredList(agentList?.data?.data?.data);
        setLastPage(agentList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Agent List");
      setCurrentPage(1);
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Agent..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(`/po/deletePoAgent/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getagentList(currentPage, pageSize, searchKey);
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete Agent");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/master/po/agent/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const showAgentDetails = (listDetail) => {
    navigate("/master/po/agent/details", {
      state: {
        list: listDetail,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Agent</h4>
          <form onSubmit={() => getagentList(currentPage, pageSize, searchKey)}>
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
              <div className="add-new-button-block">
                <Link to="/master/po/agent/create" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </form>

          {/**************** * Table ********************/}

          <AgentTable
            list={filteredList}
            columns={columns}
            icon={More}
            multiple={true}
            deleteRow={handleDelete}
            pagination={pagination}
            setState={setAgentList}
            setSearch={setFilteredList}
            loader={setLoading}
            showDetails={showAgentDetails}
            editPage={navigateEditPage}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
export default POAgent;
