import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../../Validation/POValidation";
import { GET_API, PUT_API } from "../../../../Services/api";
import Select from "react-select";
import Spinner from "../../../Spinner";

function EditPODepartment() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const { state } = useLocation();
  const id = state?.list;
  const [errors, seterrors] = useState({});
  const [editdepartmentDetails, setEditDepartmentDetails] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setDepartment();
  }, []);

  const setDepartment = async () => {
    setLoading2(true);
    try {
      const departmentData = await GET_API(`po/getDepartment/${id}`);
      if (departmentData?.data?.status === 200) {
        const listData = departmentData?.data?.data;
        setEditDepartmentDetails({
          id: listData?.id,
          name: listData?.name,
          section: listData?.section,
          hsn_code: listData?.hsn_code,
          category: listData?.category,
          description: listData?.description,
        });
        setLoading2(false);
        getCategoryList(listData?.category);
      }
    } catch (error) {
      setLoading2(false);
      alertify?.error("Failed to get Department details");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditDepartmentDetails(() => ({
      ...editdepartmentDetails,
      [name]: value,
    }));
  };

  const updateDepartment = async (e) => {
    e?.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.Department(editdepartmentDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const updateDepartment = await PUT_API(
        "/po/updatePoDepartment",
        editdepartmentDetails
      );
      if (updateDepartment?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(updateDepartment?.data?.message);
        navigate("/master/po/department/");
      } else {
        alertify.error(updateDepartment?.data?.message);
        setButtonDisabled(false);
      }
    } catch (e) {
      setButtonDisabled(false);
      for (const key in e?.response?.data?.data) {
        if (e?.response?.data?.data.hasOwnProperty(key)) {
          e?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };

  const getCategoryList = async (cateID) => {
    setLoading(true);
    try {
      const category = await GET_API("po/categoryDropdown");
      if (category?.data?.status === 200) {
        const cateList = category?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        cateList.unshift({ value: "", label: "Select..." });
        setCategoryList(cateList);
        setCategory(cateList, cateID);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get category list...");
    }
  };

  const setCategory = (list, id) => {
    const values = [];
    list?.map((dept) => {
      if (id.includes(dept?.value)) {
        return values.push(dept);
      }
    });
    setSelectedCategory(values);
  };

  const handleSelectChange = (e) => {
    setSelectedCategory(e);
    let deptID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    setEditDepartmentDetails(() => ({
      ...editdepartmentDetails,
      department: deptID,
    }));
  };

  const resetForm = () => {
    setEditDepartmentDetails({
      id: editdepartmentDetails?.id,
      name: editdepartmentDetails?.name,
      department: editdepartmentDetails?.department,
      description: editdepartmentDetails?.description,
      landline_no: editdepartmentDetails?.landline_no,
      address: editdepartmentDetails?.address,
      contact_person_name: editdepartmentDetails?.contact_person_name,
      contact_person_mobile: editdepartmentDetails?.contact_person_mobile,
      contact_person_email: editdepartmentDetails?.contact_person_email,
    });
  };

  if (loading || loading2) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/po/department" className="in-active">
              Department List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Department</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Department</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => updateDepartment(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Department Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Department Name"
                  value={editdepartmentDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Section Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="section"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Section Name"
                  value={editdepartmentDetails?.section}
                />
                {errors.section ? (
                  <div className="validation-error-label">{errors.section}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  HSN Code
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="hsn_code"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter HSN Code"
                  value={editdepartmentDetails?.hsn_code}
                />
                {errors.hsn_code ? (
                  <div className="validation-error-label">
                    {errors.hsn_code}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Category
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={categoryList}
                  value={selectedCategory ? selectedCategory : []}
                  onChange={(e) => handleSelectChange(e)}
                  isMulti
                />
                {errors.category ? (
                  <div className="validation-error-label">
                    {errors.category}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="description"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Description"
                  value={editdepartmentDetails?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Save</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditPODepartment;
