import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { Link, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { numberToWords } from "../NumbersToWords/NumbersToWords";

function AgentCommissionnActualReportDetails() {
  const { state } = useLocation();
  const componentRef = useRef();

  const listDetails = state?.list;

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/report/agentCommActual" className="in-active">
                Agent Commission Actual Report
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">
                Agent Commission Actual Report Details
              </Link>
            </div>
          </div>
          <div className="card-wrapper">
            <h4 className="card-header">
              Agent Commission Actual Report Details
            </h4>
            <div
              style={{
                paddingTop: "10px",
                paddingRight: "10px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <button
                type="button"
                className="submit-button"
                onClick={() => printVoucher()}
              >
                <span className="submit-button-label">Print</span>
              </button>
            </div>

            <div ref={componentRef} className="p-5">
              <div className="bank-voucher-border p-5">
                <h2 className="advance-voucher-title text-center">
                  Agent Commission voucher
                </h2>
                <div className="flex flex-col gap-4">
                  <div className="flex justify-end">
                    {listDetails?.voucher_no && (
                      <QRCode
                        value={listDetails?.voucher_no}
                        style={{ height: "80px" }}
                      />
                    )}
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="text-xl font-karla flex gap-3">
                      <span className="text-text-light">Date</span>
                      <span className="text-text-dark">
                        {listDetails?.date}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="text-xl font-karla flex gap-3">
                      <span className="text-text-light">Agent Name</span>
                      <span className="text-text-dark">
                        {listDetails?.agent_name}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="text-xl font-karla flex gap-3">
                      <span className="text-text-light">Amount</span>
                      <span className="text-text-dark">
                        {listDetails?.amount}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="">
                      <div
                        className="bank-voucher-border advance-voucher-bold p-5"
                        style={{ fontSize: "45px" }}
                      >
                        <span>&#8377;{listDetails?.amount}</span>
                      </div>
                    </div>
                  </div>
                  <div className="text-xl font-karla flex gap-3 text-text-dark">
                    <div className="text-text-light">Amount in words:</div>

                    <div>
                      <span>
                        &#8377;{numberToWords(parseFloat(listDetails?.amount))}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-around text-text-dark font-karla p-3">
                    <div>
                      <div className="text-xl text-text-light">Admit By</div>
                      <div></div>
                    </div>

                    <div>
                      <div className="text-xl text-text-light">Checked By</div>
                    </div>

                    <div>
                      <div className="text-xl text-text-light">
                        Employee Signature
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AgentCommissionnActualReportDetails;
