import React, { useState, useEffect, useContext } from "react";
import alertify from "alertifyjs";
import LeaveApproveTable from "../../DataTable/LeaveApproveTable";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";
import dayjs from "dayjs";
import { PrivilegeContext } from "../../../PrivilegeContext";

function LeaveApproval() {
  const [loading, setLoading] = useState(false);
  const [leaveList, setLeaveList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [userID, setuserID] = useState(localStorage.getItem("user_id"));
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);

  useEffect(() => {
    getLeaveList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "approve_leave") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const columns = [
    { field: "EmployeeCode", headerName: "Employee Code", minWidth: 150 },
    { field: "employee_name", headerName: "Employee Name", minWidth: 150 },
    { field: "type", headerName: "Type", minWidth: 150 },

    {
      field: "from_date",
      headerName: "From",
      minWidth: 150,
      renderCell: (params) => {
        const rowData = params.row;
        return <div>{dayjs(rowData.from_date).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      field: "to_date",
      headerName: "To",
      minWidth: 150,
      renderCell: (params) => {
        const rowData = params.row;
        return <div>{dayjs(rowData.to_date).format("DD-MM-YYYY")}</div>;
      },
    },
  ];

  const isPageNotExit = () => {
    setCurrentPage(1);
    getLeaveList(1, pageSize);
  };

  const getLeaveList = async (page, size) => {
    setLoading(true);
    try {
      const leaveList = await GET_API(
        `/employeeLeaveandOndutyList?page=${page}&size=${size}`
      );
      if (leaveList?.status === 200) {
        if (
          leaveList?.data?.data?.current_page > leaveList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLeaveList(leaveList?.data?.data?.data);
        setLastPage(leaveList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const handleApprove = async (type) => {
    const data = {
      selectedRows,
      appType: type,
      approved_by: userID,
    };
    if (selectedRows.length <= 0) {
      alertify
        .alert("Please select Row to Approve/Reject")
        .setHeader("<b> Alert </b> ");
      return;
    }
    try {
      const approveLeaves = await POST_API("/leaveOndutyApprovalstatus", data);
      if (approveLeaves?.status === 200) {
        alertify.success(`Leave ${data?.appType}d successfully...!`);
        getLeaveList(currentPage, pageSize);
        return;
      }
    } catch (error) {
      alertify.error(`Failed to ${data?.appType}d Leave`);
    }
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Leave and Onduty Logs</h4>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            <div className="flex gap-3">
              {addAccess && (
                <>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("reject");
                    }}
                  >
                    <button to="" className="add-new-button">
                      <span>Reject</span>
                    </button>
                  </div>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("approve");
                    }}
                  >
                    <button to="" className="add-new-button">
                      <span>Approve</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {/**************** * Table ********************/}
          <div className="p-5 min-h-[400px]">
            <LeaveApproveTable
              columns={columns}
              list={leaveList}
              setSelectedRows={setSelectedRows}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveApproval;
