import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";

function CreateBranch() {
  const navigate = useNavigate();
  useEffect(() => {
    getFloorList();
  }, []);

  const [branchDetails, setbranchDetails] = useState({
    name: "",
    no_of_floors: "",
    address: "",
    phone: "",
    town: "",
    state: "",
    pincode: "",
    contact_person: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getFloorList = async () => {
    setLoading(true);
    try {
      const floorList = await GET_API("/floorDropdown");
      if (floorList.status === 200) {
        const floor = floorList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setFloorList(floor);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to get Floor List...");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setbranchDetails(() => ({ ...branchDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setbranchDetails(() => ({ ...branchDetails, [name]: e?.value }));
  };

  const createBranch = async (e) => {
    e.preventDefault();

    setButtonDisabled(true);
    // const isValid = Validation.validateBranch(branchDetails);
    // if (isValid && isValid?.error) {
    //   setButtonDisabled(false);
    //   seterrors({
    //     [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
    //   });
    //   return;
    // } else {
    //   seterrors({});
    // }
    POST_API("/addBranch", branchDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        navigate("/master/hrms/branch");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };
  const resetForm = () => {
    setbranchDetails({
      name: "",
      no_of_floors: "",
      address: "",
      phone: "",
      town: "",
      state: "",
      pincode: "",
      contact_person: "",
    });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/branch" className="in-active">
              Branch List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Branch</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Branch</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createBranch(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Branch Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Branch Name"
                  value={branchDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  No of Floors
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={floorList}
                  defaultValue={branchDetails.no_of_floors}
                  value={floorList?.find(
                    (option) => option?.value === branchDetails.no_of_floors
                  )}
                  onChange={(e) => handleSelectChange(e, "no_of_floors")}
                  name="no_of_floors"
                  className=""
                  isSearchable
                />
                {errors.no_of_floors ? (
                  <div className="validation-error-label">
                    {errors.no_of_floors}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Address
                  <span className="required-symbol">*</span>
                </label>
                <textarea
                  className="input-field"
                  type="text"
                  name="address"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Address"
                  value={branchDetails?.address}
                />
                {errors.address ? (
                  <div className="validation-error-label">{errors.address}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Town / City
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="town"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Town / City"
                  value={branchDetails?.town}
                />
                {errors.town ? (
                  <div className="validation-error-label">{errors.town}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  State
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="state"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter State"
                  value={branchDetails?.state}
                />
                {errors.state ? (
                  <div className="validation-error-label">{errors.state}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Pincode
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="pincode"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Pincode"
                  value={branchDetails?.pincode}
                />
                {errors.pincode ? (
                  <div className="validation-error-label">{errors.pincode}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Person
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Contact Person Name"
                  value={branchDetails?.contact_person}
                />
                {errors.contact_person ? (
                  <div className="validation-error-label">
                    {errors.contact_person}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Contact Number
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="phone"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Contact Number"
                  value={branchDetails?.phone}
                />
                {errors.phone ? (
                  <div className="validation-error-label">{errors.phone}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateBranch;
