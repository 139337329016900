import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GET_API } from "../../Services/api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import config from "../../Services/config.json";
import alertify from "alertifyjs";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Spinner from "../Spinner";

function AttendanceDetails() {
  const { id } = useParams();
  const [empDetail, setEmpDetail] = useState({});
  const [attndcDetail, setAttndcDetail] = useState([]);
  const [totalTime, setTotalTime] = useState("");
  const [date, setDate] = useState("");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAttendanceDetails();
  }, []);
  const getAttendanceDetails = async () => {
    setLoading(true);
    try {
      const attendanceDetails = await GET_API(`/attendanceDetail/${id}`);
      if (attendanceDetails?.status === 200) {
        setLoading(false);
        setEmpDetail(attendanceDetails?.data?.data?.employeeDetail);
        setAttndcDetail(attendanceDetails?.data?.data?.attendance);
        setTotalTime(attendanceDetails?.data?.data?.totalTime);
        setDate(attendanceDetails?.data?.data?.date);
      }
    } catch (error) {
      setLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  var sNo = 0;
  const fathers_name = empDetail?.fathers_name ? empDetail?.fathers_name : "";
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/attendance" className="in-active">
              Attendance List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Attendance Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Attendance Details</div>

          <div className="list-container">
            <div className="grid grid-cols-3 border-b-[1px] border-b-[#D9D9D9] font-montserrat">
              <div>
                <div className="flex py-4 gap-8 w-full">
                  <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                    Employee Code
                  </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="text-[#7B7B7B] text-base font-karla w-6/12"
                  >
                    {empDetail?.empCode}
                  </div>
                </div>
                <div className="flex py-4 gap-8 w-full">
                  <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                    Employee Name
                  </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="text-[#7B7B7B] text-base font-karla w-6/12"
                  >
                    {empDetail?.first_name + " " + fathers_name}
                  </div>
                </div>
                <div className="flex py-4 gap-8 w-full">
                  <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                    Branch
                  </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="text-[#7B7B7B] text-base font-karla w-6/12"
                  >
                    {empDetail?.branchName}
                  </div>
                </div>
                <div className="flex py-4 gap-8 w-full">
                  <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                    Date
                  </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="text-[#7B7B7B] text-base font-karla w-6/12"
                  >
                    {date}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex py-4 gap-8 w-full">
                  <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                    Department
                  </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="text-[#7B7B7B] text-base font-karla w-6/12"
                  >
                    {empDetail?.deptName}
                  </div>
                </div>
                <div className="flex py-4 gap-8 w-full">
                  <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                    Designation
                  </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="text-[#7B7B7B] text-base font-karla w-6/12"
                  >
                    {empDetail?.desName}
                  </div>
                </div>

                <div className="flex py-4 gap-8 w-full">
                  <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                    Shift
                  </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="text-[#7B7B7B] text-base font-karla w-6/12"
                  >
                    {empDetail?.shiftName}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="flex py-4 px-4 gap-8">
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="text-[#7B7B7B] text-base font-karla"
                  >
                    <img
                      src={`${config.path}${empDetail?.profile_image}`}
                      alt=""
                      style={{ height: "120px", width: "120px" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-header">S.No</TableCell>
                      <TableCell className="table-header">In at</TableCell>
                      <TableCell className="table-header">Out at</TableCell>
                      <TableCell className="table-header">Duration</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attndcDetail &&
                      attndcDetail.map((list, i) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          var totaltime = {};
                          var timeStart =
                            attndcDetail[i] &&
                            new Date(attndcDetail[i]["clock_time"]);
                          var timeEnd =
                            attndcDetail[i + 1] &&
                            new Date(attndcDetail[i + 1]["clock_time"]);
                          if (timeEnd) {
                            var hourDiff = timeEnd - timeStart; //in ms

                            var minDiff = hourDiff / 60 / 1000; //in minutes

                            totaltime.hours = Math.floor(
                              (hourDiff % 86400000) / 3600000
                            );
                            totaltime.minutes = minDiff - 60 * totaltime.hours;
                          }
                        }
                        return (
                          i % 2 === 0 && (
                            <TableRow key={i} className="cursor-pointer">
                              <TableCell className="table-body">
                                {(sNo = sNo + 1)}
                              </TableCell>

                              <TableCell className="table-body">
                                {attndcDetail[i]["f_clock_time"]}
                              </TableCell>
                              <TableCell className="table-body">
                                {attndcDetail[i + 1] &&
                                  attndcDetail[i + 1]["f_clock_time"]}
                              </TableCell>
                              <TableCell className="table-body">
                                {attndcDetail[i + 1] &&
                                  `${Math.round(
                                    totaltime.hours
                                  )} hrs ${Math.round(totaltime.minutes)} mins`}
                              </TableCell>
                            </TableRow>
                          )
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <p className="p-2 text-text-dark text-base font-karla flex justify-center">
              Total Working Duration : <b>{totalTime}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceDetails;
