import React from "react";
import { Route } from "react-router-dom";
import PurchaseOrderDashboard from "../../../Components/PurchaseOrder/Dashboard";

import PORoutes from "./PORoutes.jsx";

import PRRoutes from "./PRRoutes";

import InventoryRoutes from "./InventoryRoutes";

import InvoiceRoutes from "./InvoiceRoutes";

import LogisticsRoutes from "./LogisticsRoutes.js";

import BundleEntryRoutes from "./BundleEntryRoutes.js";

function PurhcaseOrderRoutes() {
  return (
    <>
      <Route path="dashboard" element={<PurchaseOrderDashboard />}></Route>

      <Route path="/po">{PORoutes()}</Route>

      <Route path="/po/pr">{PRRoutes()}</Route>

      <Route path="/po/inventory">{InventoryRoutes()}</Route>

      <Route path="/po/invoice">{InvoiceRoutes()}</Route>

      <Route path="/po/logistics">{LogisticsRoutes()}</Route>

      <Route path="/po/bundleEntry">{BundleEntryRoutes()}</Route>
    </>
  );
}

export default PurhcaseOrderRoutes;
