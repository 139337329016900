import React, { useEffect, useState } from "react";
import Arrow from "../../../Assets/Icons/RightArrow.svg";
import UseOutsideClick from "../../OutsideClick";
import config from "../../../Services/config.json";

function ItemDetails({ detail }) {
  const [toggle, setToggle] = useState(false);
  const [attribute, setAttribute] = useState([]);
  const [image, setImage] = useState([]);
  const [product, setProduct] = useState({});
  const [sizeDetails, setSizeDetails] = useState([]);

  const actionModel = UseOutsideClick(() => {
    setToggle(false);
  });

  useEffect(() => {
    setValue();
  }, []);

  const setValue = () => {
    setAttribute(detail?.attributes);
    setImage(detail?.images);
    setSizeDetails(detail?.size_details);
    setProduct(detail?.product);
  };

  return (
    <div className={`pr-item-details-card `}>
      <div
        className={`pr-item-details-card-label `}
        onClick={() => setToggle((prev) => !prev)}
      >
        <span>{product?.product_name}</span>
        <div>
          <img
            src={Arrow}
            alt=""
            style={{
              transform: toggle ? "rotate(90deg)" : "rotate(0)",
            }}
          />
        </div>
      </div>
      {toggle && (
        <>
          <div className={`product-details`} ref={actionModel}>
            <div>
              <div className="label">Product Name</div>
              <div className="value">{product?.product_name}</div>
            </div>
            <div>
              <div className="label">HSN Code</div>
              <div className="value">{product?.hsn_code}</div>
            </div>
            <div>
              <div className="label">UOM (unit of measurement)</div>
              <div className="value">{product?.uom}</div>
            </div>
            {attribute?.map((attributeItem, i) => {
              return (
                <>
                  <div>
                    <div className="label">{attributeItem?.attr_name}</div>
                    <div className="value">{attributeItem?.attr_value}</div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="product-images pt-4">
            <div className="flex w-[50%] justify-between pt-2">
              {image?.map((image) => {
                return (
                  <img
                    src={config.path + image?.image}
                    alt=""
                    className="h-[150px] w-[150px] rounded-md"
                  />
                );
              })}
            </div>
          </div>

          <div className="product-images pt-4">
            <div className="flex w-[80%] justify-between pt-2">
              <table className="log-table w-[80%]">
                <tr className="p-1">
                  <th className="log-table p-1 text-left">S.No</th>
                  <th className="log-table p-1 text-left">Size</th>
                  <th className="log-table p-1 text-left">Quantity</th>
                </tr>
                <tbody>
                  {sizeDetails?.map((size, i) => {
                    return (
                      <tr>
                        <td className="log-table log-table-data p-1 text-text-light font-karla">
                          {i + 1}
                        </td>
                        <td className="log-table log-table-data p-1 text-text-light font-karla">
                          {size?.attr_value}
                        </td>
                        <td className="log-table log-table-data p-1 text-text-light font-karla">
                          {size?.quantity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ItemDetails;
