import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { TablePagination } from "@mui/material";

export default function PaginationRanges({
  setPageNo,
  setPageSize,
  lastPage,
  currentPage,
  pageSize,
}) {
  const handlePage = async (pageNo) => {
    setPageNo(pageNo);
  };

  const handleRowPerPage = (e) => {
    setPageSize(e?.target?.value);
  };

  return (
    <div className="flex flex-row-reverse justify-between items-center">
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 20, 50, 100, 200]}
        onRowsPerPageChange={(e) => {
          handleRowPerPage(e);
        }}
        sx={{
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
          ".MuiTablePagination-actions": {
            display: "none",
          },
        }}
        rowsPerPage={pageSize}
      />
      <Stack spacing={2}>
        <Pagination
          count={lastPage}
          page={currentPage}
          defaultPage={1}
          siblingCount={1}
          boundaryCount={2}
          onChange={(e, page) => {
            handlePage(page);
          }}
        />
      </Stack>
    </div>
  );
}
