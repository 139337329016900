import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import dayjs from "dayjs";
import config from "../../Services/config.json";
import { Link } from "react-router-dom";


export default function StickyHeadTable({
  list,
  columns,
  totalSalary,
  totalDed,
  totalPaidSalary,
  totalBankAdvance,
  totalBankLoan,
  totalCashAdvance,
  totalCashLoan,
  totalPfSalary,
  totalFoodAllowanceAmt,
}) {
  return (
    <div className=" bg-[#fff] grow">
      <div className=" sm-container">
      
          <div className="py-2 pl-3 pr-3">
            <div className="py-4">
              <h4 className="">
                <b>Salary Details</b>
              </h4>
            </div>
            <table className="log-table mt-[4px]" style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th className="log-table" style={{ width: "70%", padding: "8px", textAlign: "left" }}>Label</th>
                  <th className="log-table" style={{ width: "30%", padding: "8px", textAlign: "right" }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px" }}>
                    Bank Salary
                  </td>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px", textAlign: "right" }}>
                    {totalPfSalary}
                  </td>
                </tr>

                <tr>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px" }}>
                    Bank Advance
                  </td>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px", textAlign: "right" }}>
                    {totalBankAdvance}
                  </td>
                </tr>

                <tr>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px" }}>
                    Bank Loan
                  </td>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px", textAlign: "right" }}>
                    {totalBankLoan}
                  </td>
                </tr>

                <tr>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px" }}>
                    Cash Salary
                  </td>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px", textAlign: "right" }}>
                    {totalPaidSalary}
                  </td>
                </tr>

                <tr>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px" }}>
                    Cash Advance
                  </td>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px", textAlign: "right" }}>
                    {totalCashAdvance}
                  </td>
                </tr>

                <tr>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px" }}>
                    Cash Loan
                  </td>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px", textAlign: "right" }}>
                    {totalCashLoan}
                  </td>
                </tr>

                <tr>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px" }}>
                    Food Allowance
                  </td>
                  <td className="log-table log-table-data content-label" style={{ padding: "8px", textAlign: "right" }}>
                    {totalFoodAllowanceAmt}
                  </td>
                </tr>

                <tr>
                  <td className="log-table log-table-data content-label text-end" style={{ padding: "8px", textAlign: "right" }}>
                    Total Paid
                  </td>
                  <td className="log-table log-table-data content-label text-end" style={{ padding: "8px", textAlign: "right", color: 'red' }}>
                    <b>&#8377;{totalSalary}</b>
                  </td>
                </tr>
              </tbody>
            </table>

        </div>
      </div>
    </div>
  );
}
