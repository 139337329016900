import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import alertify from "alertifyjs";
import { GET_API } from "../../../Services/api";
import config from "../../../Services/config.json";
import { colors } from "@mui/material";

function LoanLedgerReportDetails() {
  const [loanList, setLoanList] = useState([]);
  const [emp, setEmp] = useState({});
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getLeaveReportList();
  }, []);

  const getLeaveReportList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const list = await GET_API(`/loanledgerReport/${id}`);
      if (list.status === 200) {
        setLoading(false);
        setLoanList(list?.data?.data?.log);
        setEmp(list?.data?.data?.employee);
        setAmount(list?.data?.data?.outstandingAmount);
      }
    } catch (error) {
      alertify.error("Failed to get Employee Report List");
      setLoading(false);
    }
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/loan/ledger" className="in-active">
              Loan Ledger
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Loan Ledger Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Employee Loan Ledger Report Details</h4>

          <h4 className="payslip-header">
            <div className="flex justify-between items-center" style={ {color: 'red'}}>
              <div>Outstanding Amount : &#8377;{amount}</div>
              <div className="flex gap-3">
                <img
                  src={config?.path + "" + emp?.profile_image}
                  className="h-[60px] w-[60px]"
                  alt=""
                />
              </div>
            </div>
          </h4>
          <div className="p-1 pl-3 pr-3">
            <div className="flex justify-between">
              <div>
                <div className="flex">
                  <label className="list-label">Employee Code: </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="advance-section-list-value"
                  >
                    {emp?.emp_code}
                  </div>
                </div>
                <div className="flex">
                  <label className="list-label">Employee Name: </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="advance-section-list-value"
                  >
                    {emp?.first_name + " " + emp?.fathers_name}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex">
                  <label className="list-label">Department: </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="advance-section-list-value"
                  >
                    {emp?.department_name}
                  </div>
                </div>
                <div className="flex">
                  <label className="list-label">Designation: </label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="advance-section-list-value"
                  >
                    {emp?.designation_name}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-2 pl-3 pr-3">
            <div className="py-4">
              <h4 className="">
                <b>Loan Details</b>
              </h4>
            </div>
            <table className="log-table mt-[4px]">
              <tr>
                <th className="log-table">Date</th>
                <th className="log-table">Remarks</th>
                <th className="log-table" style={{ width: "20%" }}>
                  Debit
                </th>
                <th className="log-table" style={{ width: "20%" }}>
                  Credit
                </th>
                <th className="log-table" style={{ width: "20%" }}>
                  Balance
                </th>
              </tr>
              {loanList.map((value, index) => {
                return (
                  <tr>
                    <td className="log-table log-table-data content-label">
                      {value?.date}
                    </td>
                    <td className="log-table log-table-data content-label">
                      {value?.remarks}
                    </td>
                    <td className="log-table log-table-data content-label text-end" style={ {color: 'red'}}>
                      <span>&#8377;{value?.debit}</span>
                    </td>
                    <td className="log-table log-table-data content-label text-end" style={ {color: 'green'}}>
                      <span>&#8377;{value?.credit}</span>
                    </td>
                    <td className="log-table log-table-data content-label text-end" style={ {color: 'red'}}>
                      <b>&#8377;{value?.balance}</b>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td
                  className="log-table log-table-data content-label text-end"
                  colSpan={4}
                >
                  Total Balance
                </td>
                <td className="log-table log-table-data content-label text-end" style={ {color: 'red'}}>
                  <b>&#8377;{amount}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoanLedgerReportDetails;
