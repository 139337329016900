import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import Pagination from "./Pagination";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import config from "../../Services/config.json";


export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editPage,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
  enableMoreOption,
}) {
  const navigate = useNavigate();
  const showDetailsPage = (listDetail) => {
    const url = "/expense/bonus/details";
    const newTab = window.open(url, "_blank"); // Open the URL in a new tab
  
    // If you want to pass the state or data, you can store it in localStorage or sessionStorage
    localStorage.setItem("listDetail", JSON.stringify(listDetail));
    };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                const father_name = row?.fathers_name ? row?.fathers_name : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                     {
                      <TableCell className="!w-[7%] table-body">
                      {row?.profile_image !== "" ? (
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "40px",
                          }}
                          src={config?.path + row?.employee_image}
                          alt="profile_image"
                        />
                      ) : (
                        <div
                          className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                          style={{ backgroundColor: "#ccc" }}
                        >
                          {row?.first_name?.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </TableCell>
                    }
                    {
                      <TableCell
                        className="table-body cursor-pointer"
                        onClick={() => showDetailsPage(row)}
                      >
                        <Link
                          style={{ color: "#0284C7" }}
                          className="text-sky-600 border-b-[1px] border-b-[#0284C7]"
                        >
                          {row.EmployeeCode}
                        </Link>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_name + " " + father_name}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.finance_category_name}
                      </TableCell>
                    }

                    {
                      <TableCell
                        className="table-body loan-advance-expence-table"
                        style={{ textAlign: "right" }}
                      >
                        <span>&#8377;{row?.amount}</span>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body ">
                        {row?.voucher_status === 1 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Paid
                          </span>
                        ) : (
                          <span
                            className="bg-[#FF5C42]  p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Unpaid
                          </span>
                        )}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.remarks}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.status === 0 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Approved
                          </span>
                        ) : row?.status === 1 ? (
                          <span
                            className="bg-[#EEC9C9] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Pending
                          </span>
                        ) : row?.status === 2 ? (
                          <span
                            className="bg-[#ed452a] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Rejected
                          </span>
                        ) : null}
                      </TableCell>
                    }
                    {row?.status === 0 && (
                      <MoreOption
                        icon={icon}
                        row={row}
                        showDetails={showDetails}
                        activeEdit={false}
                        activeDelete={false}
                        multiple={multiple}
                        deleteRow={deleteRow}
                        editPage={editPage}
                        enableMoreOption={enableMoreOption}
                      />
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
