import React from "react";
import { Link } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import config from "../../../Services/config.json";
function PositionInfo({ listDetails, isEditAndView, breakDetails }) {
  const joining = new Date(listDetails?.date_of_joining);
  const joing_year = joining.getFullYear();
  const joing_month = String(joining.getMonth() + 1).padStart(2, "0");
  const joing_date = String(joining.getDate()).padStart(2, "0");
  const fathers_name =
    listDetails?.fathers_name !== null ? listDetails?.fathers_name : "";
  const Details = [
    {
      label: "Profile",
      value: listDetails?.profile_image,
    },
    {
      label: "Employee Name",
      value: listDetails?.first_name + " " + fathers_name,
    },
    {
      label: "Employee Type Name",
      value: listDetails?.employeeTypeName
        ? listDetails?.employeeTypeName
        : "-",
    },
    {
      label: "Department Name",
      value: listDetails?.department_name ? listDetails?.department_name : "-",
    },
    {
      label: "Designation",
      value: listDetails?.designation_name
        ? listDetails?.designation_name
        : "-",
    },
    {
      label: "Employee Code",
      value: listDetails?.emp_code ? listDetails?.emp_code : "-",
    },
    {
      label: "Branch Name",
      value: listDetails?.branch_name ? listDetails?.branch_name : "-",
    },
    {
      label: "Floor",
      value: listDetails?.floor_name
        ? listDetails?.floor_name
        : listDetails?.floorname
        ? listDetails?.floorname
        : "-",
    },
    {
      label: "Shift",
      value: listDetails?.shiftName
        ? listDetails?.shiftName
        : listDetails?.shift_name
        ? listDetails?.shift_name
        : "-",
    },
    {
      label: "Reporting",
      value: listDetails?.reportingtoname ? listDetails?.reportingtoname : "-",
    },
    {
      label: "Holiday Scheme",
      value: listDetails?.holidayschemename
        ? listDetails?.holidayschemename
        : "-",
    },
    {
      label: "Date of Joining",
      value: joing_year + "-" + joing_month + "-" + joing_date,
    },
  ];

  return (
    <>
      <div
        className="p-3 w-3/12 bg-primary-color text-center "
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Position Info
        </span>
      </div>
      <div className="list-container employee-break-details-border mt-3">
        <div className="list-content-block">
          {Details.map((list) => {
            return list?.label === "Profile" ? (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <img
                  src={config?.path + "" + list?.value}
                  alt=""
                  className="h-[40px] w-[40px]"
                />
              </div>
            ) : (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {breakDetails?.length ? (
        <>
          <h4 className="employee-break-details">Break Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {breakDetails?.map((list) => {
                return (
                  <>
                    <div className="list">
                      <label className="list-label">Break Name</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list?.name}
                      </div>
                    </div>
                    <div className="list">
                      <label className="list-label">From Time</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list?.from_time}
                      </div>
                    </div>
                    <div className="list">
                      <label className="list-label">To Time</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list?.to_time}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>{" "}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default PositionInfo;
