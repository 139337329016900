import React, { useState, useEffect } from "react";
import { DELETE_API, GET_API } from "../../Services/api";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Select from "react-select";
import GovernemntIdProofListTable from "../DataTable/GovernmentIdProofTable";
import Search from "../../Assets/Icons/Search.svg";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import { Link } from "react-router-dom";
import Edit from "../../Assets/Icons/Edit.svg";
import More from "../../Assets/Icons/More.svg";
import Spinner from "../Spinner";

function IDProof() {
  const [filter, setfilter] = useState([]);
  const [idproofList, setIdproofList] = useState([]);
  const [errors, seterrors] = useState({});
  const [buttonLabel, setbuttonLabel] = useState("Add");
  const [editStatus, setEditStatus] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const [idproofDetails, setIdproofDetails] = useState({
    name: "",
    mandatory: "",
    mandatory_lable: "",
    type: "idproof",
  });

  const header = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Government ID Proof Name" },
    { id: "mandatory", label: "Mandatory" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  const mandatoryOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  useEffect(() => {
    getIdproofList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getIdproofList(1, pageSize, searchKey);
  };

  const getIdproofList = async (page, size, search) => {
    try {
      setLoading(true);
      const proofList = await GET_API(
        `/govermentidproofs?page=${page}&size=${size}&search=${search}`
      );
      if (proofList.status === 200) {
        if (
          proofList?.data?.data?.current_page > proofList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLoading(false);
        setIdproofList(proofList?.data?.data?.data);
        setLastPage(proofList?.data?.data?.last_page);
        setfilter(proofList?.data?.data?.data);
      }
    } catch (e) {
      setLoading(false);
      setIdproofList([]);
      setLastPage("");
      setfilter([]);
      alertify.error("Failed to get Govt ID Proof Details");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIdproofDetails(() => ({ ...idproofDetails, [name]: value }));
  };

  const handleOptionChange = (e, name) => {
    setIdproofDetails(() => ({
      ...idproofDetails,
      [name]: e?.value,
      mandatory_lable: e,
    }));
  };

  const createProofList = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.ValidateGovernmentIdProof(idproofDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }

    const addProof = await POST_API("/addGovermentIdProofs", idproofDetails);
    if (addProof.status === 200) {
      alertify.success(addProof?.data?.message);
      getIdproofList(currentPage, pageSize);
      setIdproofDetails((prevState) => ({
        ...prevState,
        name: "",
        mandatory: "",
      }));
      setButtonDisabled(false);
      return;
    }
    alertify.error(e?.response?.data?.message);
  };

  const editProofList = async () => {
    setButtonDisabled(true);
    const isValid = Validation.ValidateGovernmentIdProof(idproofDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    try {
      const editProof = await POST_API(
        "/updateGovermentIdProofs",
        idproofDetails
      );
      if (editProof.status) {
        alertify.success(editProof?.data?.message);
        getIdproofList(currentPage, pageSize);
        setIdproofDetails((prevState) => ({
          ...prevState,
          name: "",
          mandatory: "",
        }));
        setButtonDisabled(false);
        setEditStatus(false);
      }
    } catch (e) {
      alertify.error(e?.response?.data?.message);
    }
  };
  const resetFieldsValue = () => {
    if (buttonLabel === "Update") {
      setbuttonLabel("Add");
    }
    setIdproofDetails((prevState) => ({
      ...prevState,
      name: "",
      mandatory: "",
      mandatory_lable: "",
    }));
    setEditStatus(false);
  };
  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Proof..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(
              `/deleteGovermentIdProofs/${row_id}`
            );
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getIdproofList(currentPage, pageSize);
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  const handleProofSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = idproofList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setfilter(searchedList);
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Government ID Proof List</h4>
          <form
            onSubmit={() => getIdproofList(currentPage, pageSize, searchKey)}
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
            </div>
          </form>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Proof Name <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                name="name"
                placeholder="Enter Govt ID Proof Name"
                autoComplete="off"
                value={idproofDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block dropdown-select-to-first">
              <label className="add-new-input-label">
                Is This Mandatory....?{" "}
                <span className="required-symbol">*</span>
              </label>
              <Select
                placeholder="Please Select"
                options={mandatoryOptions}
                value={
                  idproofDetails.mandatory
                    ? mandatoryOptions?.find(
                        (option) => option.value === idproofDetails.mandatory
                      )
                    : ""
                }
                onChange={(e) => handleOptionChange(e, "mandatory")}
                name="mandatory"
                isSearchable
                style={{ zIndex: 10000 }}
              />
              {errors.mandatory ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.mandatory}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    disabled={buttonDisabled}
                    onClick={(e) => {
                      editProofList(e);
                    }}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    disabled={buttonDisabled}
                    onClick={(e) => {
                      createProofList(e);
                    }}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetFieldsValue()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>

          <GovernemntIdProofListTable
            columns={header}
            list={filter}
            icon={More}
            deleteRow={handleDelete}
            editList={setIdproofDetails}
            editStatus={setEditStatus}
            setState={setIdproofDetails}
            setSearch={setfilter}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default IDProof;
