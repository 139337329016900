import React, { useEffect, useState } from "react";
import PreviousPurchaseRequestTable from "../../DataTable/PurchaseOrder/PreviousPurchaseRequest";
import Search from "../../../Assets/Icons/Search.svg";
import Select from "react-select";
import { DELETE_API, GET_API } from "../../../Services/api";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import More from "../../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";

function PreviousPrList() {
  const [loading, setLoading] = useState(false);
  const [prList, setPrList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "pr_number", label: "PR Number" },
    { id: "supplier_name", label: "Supplier Name" },
    { id: "warehouse_name", label: "Ware House Name" },
    { id: "status", label: "Status" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getPurchaseRequestList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getPurchaseRequestList(1, pageSize, searchKey);
  };

  const getPurchaseRequestList = async (page, size, search) => {
    setLoading(true);
    try {
      const prList = await GET_API(
        `po/purchaseRequestList?page=${page}&size=${size}&search=${search}`
      );
      if (prList?.data?.status === 200) {
        if (prList?.data?.data?.current_page > prList?.data?.data?.last_page) {
          isPageNotExit();
          return;
        }
        setPrList(prList?.data?.data?.data);
        setLastPage(prList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify?.error("Failed to get PR List");
    }
  };

  const showBranchDetails = (listDetail) => {
    const id = listDetail?.id;
    navigate(`/po/pr/details/${id}`, {
      state: {
        list: id,
      },
    });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete PR List..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(`/deletebranch/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getPurchaseRequestList(currentPage, pageSize, searchKey);
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete PR List");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="po-container">
      <div className="po-header">Purchase Request</div>
      <div className="po-contect-box">
        <div className="header-name">PR History</div>
        <form className="po-form">
          <div className="previous-pr">
            <div className="input-field-block z-20">
              <span className="label-name">Ware House</span>
              <Select name="category_id" isSearchable />
            </div>

            <div className="input-field-block z-20">
              <span className="label-name">Supplier</span>
              <Select name="category_id" isSearchable />
            </div>

            <div className="input-field-block z-20">
              <span className="label-name">Approval</span>
              <Select name="category_id" isSearchable />
            </div>

            <div className="input-field-block z-20">
              <span className="label-name">Search</span>
              <div className="po-search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search PR Number"
                  autoComplete="off"
                  style={{ outline: "none", border: "none" }}
                  onChange={(e) => {
                    setSearchKey(e?.target?.value);
                  }}
                  value={searchKey}
                />

                <img
                  src={Search}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    getPurchaseRequestList(currentPage, pageSize, searchKey);
                  }}
                />
              </div>
            </div>
          </div>
          <PreviousPurchaseRequestTable
            columns={columns}
            list={prList}
            icon={More}
            multiple={true}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            activeEdit={false}
            currentPage={currentPage}
          />
        </form>
      </div>
    </div>
  );
}

export default PreviousPrList;
