import React from "react";
// import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import { numberToWords } from "../../NumbersToWords/NumbersToWords";
import { useLocation } from "react-router-dom";

const LoanPrintVoucher = (props, ref) => {
  const { state } = useLocation();
  const listDetails = state?.list;
  const printData = listDetails ? listDetails : props?.printVoucherData;
  const createdDate = new Date(printData?.created_at);
  const year = createdDate.getFullYear();
  const month = String(createdDate.getMonth() + 1).padStart(2, "0");
  const date = String(createdDate.getDate()).padStart(2, "0");
  const hour = createdDate.getUTCHours();
  var ampm = hour >= 12 ? "PM" : "AM";
  const minute = createdDate.getUTCMinutes();

  return (
    <div ref={ref} className="p-5">
      <div className="bank-voucher-border p-5">
        <h2 className="advance-voucher-title text-center">Loan Voucher</h2>
        <div className="flex flex-col gap-4">
          <div className="flex justify-end">
            <QRCode
              value={printData?.voucher_no ? printData?.voucher_no : 0}
              style={{ height: "80px" }}
            />
          </div>
          <div className="flex justify-between w-full">
            <div className="text-xl font-karla flex gap-3">
              <span className="text-text-light">Voucher No</span>
              <span className="text-text-dark">{printData?.voucher_no}</span>
            </div>
            <div className="text-xl font-karla flex gap-3">
              <span className="text-text-light">Date</span>
              <span className="text-text-dark">
                {" " +
                  date +
                  "/" +
                  month +
                  "/" +
                  year +
                  " " +
                  "at" +
                  " " +
                  hour +
                  ":" +
                  minute +
                  "" +
                  ampm}
              </span>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="text-xl font-karla flex gap-3">
              <span className="text-text-light">Emp Code</span>
              <span className="text-text-dark">{printData?.EmployeeCode}</span>
            </div>
            <div className="text-xl font-karla flex gap-3">
              <span className="text-text-light">Designation</span>
              <span className="text-text-dark">
                {printData?.designation_name}
              </span>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="text-xl font-karla flex gap-3">
              <span className="text-text-light">Emp Name</span>
              <span className="text-text-dark">{printData?.employee_name}</span>
            </div>
            <div className="text-xl font-karla flex gap-3">
              <span className="text-text-light">Department</span>
              <span className="text-text-dark">
                {printData?.department_name}
              </span>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="">
              <div
                className="bank-voucher-border advance-voucher-bold p-5"
                style={{ fontSize: "45px" }}
              >
                <span>&#8377;{printData?.amount}</span>
              </div>
            </div>
            <div className="font-karla flex gap-3 text-text-dark text-xl">
              <span className="text-text-light">Payment Mode</span>
              <span>{printData?.payment_type}</span>
            </div>
          </div>
          <div className="text-xl font-karla flex gap-3 text-text-dark">
            <div className="text-text-light">Amount in words:</div>
            <div>
              <span>
                &#8377;{numberToWords(parseFloat(printData?.amount)) + "only"}
              </span>
            </div>
          </div>
          <div className="flex justify-around text-text-dark font-karla p-3">
            <div>
              <div className="text-xl text-text-light">Admit By</div>
              <div></div>
            </div>

            <div>
              <div className="text-xl text-text-light">Checked By</div>
              <div className="text-sm">
                {printData?.created_by_name ? printData?.created_by_name : "-"}
              </div>
            </div>

            <div>
              <div className="text-xl text-text-light">Employee Signature</div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(LoanPrintVoucher);
