import React from "react";

function Password({ active }) {
  const color = active ? "#2A251B" : "#494C54";
  return (
    <>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1600_4042)">
          <path
            fill-rule="evenodd"
            clipRule="evenodd"
            d="M9.5 7.26123C9.206 7.26123 8.91487 7.31914 8.64325 7.43165C8.37163 7.54416 8.12482 7.70907 7.91693 7.91696C7.70904 8.12485 7.54413 8.37166 7.43162 8.64328C7.31911 8.9149 7.2612 9.20603 7.2612 9.50003C7.2612 9.8914 7.36368 10.2722 7.55377 10.6066L10.6065 7.5538C10.2722 7.36371 9.89137 7.26123 9.5 7.26123ZM8.18881 6.33454C8.60451 6.16235 9.05005 6.07373 9.5 6.07373C10.4087 6.07373 11.2802 6.43471 11.9228 7.07727C12.1546 7.30914 12.1546 7.68509 11.9228 7.91696L7.91693 11.9228C7.68506 12.1547 7.30911 12.1547 7.07724 11.9228C6.43468 11.2802 6.0737 10.4087 6.0737 9.50003C6.0737 9.05008 6.16232 8.60454 6.33451 8.18884C6.5067 7.77314 6.75908 7.39543 7.07724 7.07727C7.3954 6.75911 7.77311 6.50673 8.18881 6.33454Z"
            fill={color}
          />
          <path
            fill-rule="evenodd"
            clipRule="evenodd"
            d="M1.78718 7.13072C3.67999 4.15535 6.45368 2.35938 9.5 2.35938C11.2809 2.35938 12.9877 2.97973 14.465 4.0941C14.7268 4.29157 14.779 4.66388 14.5815 4.92567C14.3841 5.18747 14.0117 5.23962 13.7499 5.04215C12.4565 4.06652 10.9965 3.54688 9.5 3.54688C6.95728 3.54688 4.52191 5.04408 2.7889 7.76847L2.7884 7.76925C2.50769 8.20903 2.34729 8.83857 2.34729 9.50417C2.34729 10.1698 2.50769 10.7993 2.7884 11.2391L2.78867 11.2395C3.38541 12.1762 4.07638 12.978 4.82048 13.618C5.0691 13.8318 5.09732 14.2067 4.8835 14.4553C4.66969 14.7039 4.29481 14.7321 4.04618 14.5183C3.20695 13.7966 2.44125 12.9043 1.78716 11.8776C1.35556 11.2012 1.15979 10.3346 1.15979 9.50417C1.15979 8.67367 1.35557 7.80713 1.78718 7.13072ZM15.5008 5.81547C15.7563 5.60994 16.13 5.65047 16.3356 5.90599C16.6395 6.28382 16.938 6.68983 17.2134 7.12373C17.6446 7.8 17.8402 8.66613 17.8402 9.49625C17.8402 10.3266 17.6445 11.193 17.2131 11.8693C15.3202 14.8449 12.5464 16.641 9.5 16.641C8.44191 16.641 7.4059 16.4173 6.43542 16.0087C6.1332 15.8814 5.99136 15.5333 6.11861 15.2311C6.24586 14.9288 6.59402 14.787 6.89624 14.9142C7.73076 15.2656 8.61059 15.4535 9.5 15.4535C12.0427 15.4535 14.4781 13.9563 16.2111 11.2319L16.2116 11.2312C16.4923 10.7914 16.6527 10.1618 16.6527 9.49625C16.6527 8.83065 16.4923 8.20112 16.2116 7.76133L16.2108 7.76002C15.9636 7.37058 15.6922 7.00076 15.4103 6.65026C15.2047 6.39474 15.2453 6.02099 15.5008 5.81547Z"
            fill={color}
          />
          <path
            fill-rule="evenodd"
            clipRule="evenodd"
            d="M17.8365 1.16365C18.0684 1.39553 18.0684 1.77147 17.8365 2.00334L11.9228 7.91709C11.6909 8.14896 11.3149 8.14896 11.0831 7.91709C10.8512 7.68522 10.8512 7.30928 11.0831 7.0774L16.9968 1.16365C17.2287 0.931778 17.6046 0.931778 17.8365 1.16365ZM12.3919 9.47147C12.7138 9.53398 12.9241 9.84561 12.8616 10.1675C12.7318 10.8359 12.4052 11.4503 11.9237 11.9318C11.4422 12.4133 10.8279 12.7399 10.1594 12.8697C9.83753 12.9322 9.5259 12.7219 9.46338 12.4C9.40087 12.0781 9.61115 11.7665 9.93305 11.704C10.3691 11.6193 10.7699 11.4062 11.084 11.0921C11.3981 10.778 11.6112 10.3772 11.6959 9.94114C11.7584 9.61923 12.07 9.40895 12.3919 9.47147ZM7.91692 11.0832C8.1488 11.3151 8.1488 11.691 7.91692 11.9229L2.00317 17.8367C1.7713 18.0685 1.39536 18.0685 1.16348 17.8367C0.93161 17.6048 0.93161 17.2289 1.16348 16.997L7.07723 11.0832C7.30911 10.8514 7.68505 10.8514 7.91692 11.0832Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_1600_4042">
            <rect width="19" height="19" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default Password;
