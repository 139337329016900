import React from "react";
import { Route } from "react-router-dom";

import BundleEntryList from "../../PurchaseOrder/BundleEntry/BundleEntryList";
import BundleEntryDetails from "../../../Components/PurchaseOrder/BundleEntry/BundleEntryDetails";
import CreateBundleEntry from "../../../Components/PurchaseOrder/BundleEntry/CreateBundleEntry";

function LogisticsRoutes() {
  return (
    <>
      <Route path="previous" element={<BundleEntryList />}></Route>
      <Route path="new" element={<CreateBundleEntry />}></Route>
      <Route path="details/:id" element={<BundleEntryDetails />}></Route>
    </>
  );
}

export default LogisticsRoutes;
