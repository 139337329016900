import React, { useState, useEffect, useContext } from "react";
import alertify from "alertifyjs";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import More from "../../../Assets/Icons/More.svg";
import { DELETE_API, GET_API } from "../../../Services/api";
import PayslipsTable from "../../DataTable/PayslipsTable";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import Filter from "../../../Assets/Icons/Filter.svg";
import "alertifyjs/build/css/alertify.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PrivilegeContext } from "../../../PrivilegeContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function PayslipsList() {
  const navigate = useNavigate();
  const [payslipsList, setPayslipsList] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [filteredPayslips, setFilteredPayslips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [designation, setDesignation] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [isShiftLoading, setIsShiftLoading] = useState(false);
  const [isHrAgentLoading, setIsHrAgentLoading] = useState(false);
  const [shiftList, setShiftList] = useState([]);
  const [hragentList, sethragentList] = useState([]);

  const [isFloorLoading, setIsFloorLoading] = useState(false);
  const [isBloodGroupLoading, setIsBloodGroupLoading] = useState(false);
  const [isReligionLoading, setIsReligionLoading] = useState(false);
  const [isCasteLoading, setIsCasteLoading] = useState(false);
  const [isAgentLoading, setIsAgentLoading] = useState(false);
  const { access } = useContext(PrivilegeContext);
  const [showAccess, setShowAccess] = useState(false);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [year, setYear] = useState(
    localStorage.getItem("payslipYear")
      ? localStorage.getItem("payslipYear")
      : new Date().getFullYear()
  );
  const [month, setMonth] = useState(
    localStorage.getItem("payslipMonth")
      ? localStorage.getItem("payslipMonth")
      : new Date().getMonth() + 1
  );

  const [floorList, setFloorList] = useState([]);
  const [bloodgroupList, setBloodgroupList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [casteList, setCasteList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    from_date: "",
    district: "",
    town: "",
    pf_employee: "",
    to_date: "",
    approve_status: "",
    valid_emp: "valid",
  });
  const showPayslipsDetails = (listDetail) => {
    // navigate(`/payroll/payslip/details/${listDetail.employee_Id}`, {
    //   state: {
    //     list: listDetail,
    //     month: month,
    //     year: year,
    //   },
    // });
    const url = `/payroll/payslip/details/${listDetail.employee_Id}`;
    const params = {
      month1: month,
      year1: year,
    };

    const queryString = new URLSearchParams(params).toString();
    const newTab = window.open(`${url}?${queryString}`, "_blank");
    newTab.focus(); // Optional: Focus on the newly opened tab
  };

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "generate_payslip") {
        setShowAccess(item?.view);
      }
      return "";
    });
  };
  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "user", label: "User" },
    { id: "employee_code", label: "Code" },
    { id: "employee_name", label: "Name" },
    { id: "department", label: "Department" },
    { id: "designation", label: "Designation" },
    { id: "floor", label: "Floor" },
    { id: "salaryToBePaid", label: "Actual Salary" },
    { id: "employee_active", label: "Status" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  const getBranchList = async () => {
    try {
      setIsBranchLoading(true);
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((exp) => {
          return { value: exp?.id, label: exp?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const getEmpDropdownList = async () => {
    setIsEmpLoading(true);
    try {
      const empList = await GET_API("/employeeDropdown");
      if (empList.status === 200) {
        const emp = empList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });

        emp.unshift({ value: "", label: "Select..." });
        setEmployeeList(emp);
        setIsEmpLoading(false);
      }
    } catch (e) {
      setIsEmpLoading(false);
      alertify.error("Failed to Get Employee List");
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const approveStatusList = [
    { label: "Please Select", value: "" },
    { label: "All", value: "all" },
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
    { label: "Peding", value: "pending" },
    { label: "Terminated", value: "terminated" },
  ];

  const validEmployee = [
    { label: "Valid", value: "valid" },
    { label: "Invalid", value: "invalid" },
  ];

  const employeePFTypeList = [
    { label: "Please Select", value: "" },
    { label: "PF Employee", value: 1 },
    { label: "Non PF Employee", value: 0 },
  ];

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getEmployeeList();
    getDepartmentList();
    getDesignationList();
    getShiftList();
    getHRAgentList();
    getEmpDropdownList();
    getFloorList();
    getEmployeeNameList();

    getBloodBroupList();
    getReligion();
    getCaste();
    getAgent();
  }, []);

  useEffect(() => {
    getPayslipsList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getPayslipsList(1, pageSize, filterParams, searchKey);
  };

  const getEmployeeNameList = async () => {
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
      }
    } catch (error) {
      setEmployeeName([]);
      alertify.error("Failed to get Employee List");
    }
  };

  const getAgent = async () => {
    setIsAgentLoading(true);
    try {
      const getType = await GET_API("/agentDropdown/consultancy");
      if (getType.status === 200) {
        const agent = getType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select Agent" });
        setAgentList(agent);
        setIsAgentLoading(false);
      }
    } catch (error) {
      setIsAgentLoading(false);
      setAgentList([]);
      alertify.error("Failed to get Agent List...");
    }
  };

  const getBloodBroupList = async () => {
    setIsBloodGroupLoading(true);
    try {
      const bloodGroup = await GET_API("/bloodGroupDropdown");
      if (bloodGroup?.data?.status === 200) {
        const blood = bloodGroup?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        blood.unshift({ value: "", label: "Select..." });
        setBloodgroupList(blood);
        setIsBloodGroupLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Blood group list");
      setIsBloodGroupLoading(false);
    }
  };

  const getReligion = async () => {
    setIsFloorLoading(true);
    try {
      const religionList = await GET_API("/religionDropdown");
      if (religionList.status === 200) {
        const religion = religionList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        religion.unshift({ value: "", label: "Select..." });
        setReligionList(religion);
        setIsReligionLoading(false);
      }
    } catch (e) {
      setIsReligionLoading(false);
      alertify.error("Failed to Get Religions List");
    }
  };

  const getCaste = async () => {
    setIsCasteLoading(true);
    try {
      const casteList = await GET_API("/casteDropdown");
      if (casteList.status === 200) {
        const caste = casteList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        caste.unshift({ value: "", label: "Select..." });
        setCasteList(caste);
        setIsCasteLoading(false);
      }
    } catch (e) {
      setIsCasteLoading(false);
      alertify.error("Failed to Get Caste List");
    }
  };
  const getShiftList = async () => {
    setIsShiftLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList.status === 200) {
        const floor = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setShiftList(floor);
        setIsShiftLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Shift List");
      setIsShiftLoading(false);
    }
  };

  const getHRAgentList = async () => {
    setIsHrAgentLoading(true);
    try {
      const HRAgent = await GET_API("/agentDropdown/security_agent");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select..." });
        sethragentList(agent);
        setIsHrAgentLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get HR Agent List...");
      setIsHrAgentLoading(false);
    }
  };
  const getEmployeeList = async () => {
    setIsEmpLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.status === 200) {
        const empList = employeeList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      setEmployeeList([]);
      alertify.error("Failed to get Employee List");
    }
  };
  const getPayslipsList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const url = `http://202.170.206.201:8080/sumangali-Backend/api/employeesList?page=${page}&size=${size}${filters}&search=${search}`;

      // Extracting query parameters
      const params = new URLSearchParams(url);

      // Checking if valid_emp parameter is present
      let axiosUrl = `/pfEmployeesList?page=${page}&size=${size}${filters}&search=${search}&valid_emp=valid&month=${month}&year=${year}`;
      if (params.has("valid_emp")) {
        axiosUrl = `/pfEmployeesList?page=${page}&size=${size}${filters}&search=${search}&month=${month}&year=${year}`;
      }

      const empList = await GET_API(axiosUrl);
      if (empList?.data?.status === 200) {
        if (
          empList?.data?.data?.current_page > empList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setPayslipsList(empList?.data?.data?.data);
        setFilteredPayslips(empList?.data?.data?.data);
        setLastPage(empList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Payslip List");
    }
  };

  const getFloorList = async () => {
    try {
      setIsFloorLoading(true);
      const floorList = await GET_API("/floorDropdown");
      if (floorList?.status === 200) {
        setIsFloorLoading(false);
        const floor = floorList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setFloorList(floor);
      }
    } catch (error) {
      setIsFloorLoading(false);
      alertify.error("Failed to get Floor List...");
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Payslips",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deletepayslips/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getPayslipsList(currentPage, pageSize, filterParams, searchKey);
              return;
            }
            alertify.error("Failed To Delete Payslip");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigatePaySlipPage = (listDetail) => {
    navigate("/payslip-details", {
      state: {
        list: listDetail,
      },
    });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name) => {
    const { value } = e;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading
  ) {
    return <Spinner />;
  }

  const handlePayslipBasedonMonthAndYear = async (value) => {
    const dateObject = new Date(value.$d);
    const year = dateObject?.getFullYear();
    const month = dateObject?.getMonth() + 1;
    localStorage.setItem("payslipMonth", month);
    localStorage.setItem("payslipYear", year);
    setYear(year);
    setMonth(month);
  };

  const getCurrentMonthAndYear = (payslipmonth) => {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const currentMonth = months?.filter((month, i) => {
      return i + 1 === parseInt(payslipmonth);
    });
    return currentMonth;
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Generate Payslip</div>
          <form
            onSubmit={() =>
              getPayslipsList(currentPage, pageSize, filterParams, searchKey)
            }
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="flex gap-3 justify-center items-center ">
                <div className="dropdown-datepicker">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={'"Month" and "Year"'}
                      views={["month", "year"]}
                      slotProps={{
                        field: { clearable: true, onClear: () => false },
                      }}
                      className="dropdown-datepicker"
                      value={dayjs(getCurrentMonthAndYear(month) + " " + year)}
                      onChange={(value, context) => {
                        handlePayslipBasedonMonthAndYear(value);
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div
                  className="filter-button"
                  onClick={() => setFilter((prev) => !prev)}
                >
                  <span className="filter-btn-label">Filter</span>
                  <img src={Filter} alt="" />
                </div>
              </div>
            </div>
          </form>
          {filter && (
            <form>
              <div className="form ">
                <div
                  className="field-block dropdown-select-to-first"
                  style={{ zIndex: "1000000" }}
                >
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-first"
                  style={{ zIndex: "1000000" }}
                >
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-first"
                  style={{ zIndex: "1000000" }}
                >
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "100000" }}
                >
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "100000" }}
                >
                  <label className="label-name">Floor</label>
                  <Select
                    options={floorList}
                    value={floorList?.find(
                      (option) => option?.value === filterList.floor
                    )}
                    onChange={(e) => handleFilter(e, "floor")}
                    name="floor"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "10000" }}
                >
                  <label className="label-name">Religion</label>
                  <Select
                    options={religionList}
                    value={religionList?.find(
                      (option) => option?.value === filterList.religion_id
                    )}
                    onChange={(e) => handleFilter(e, "religion_id")}
                    name="religion_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "1000" }}
                >
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "1000" }}
                >
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-third z-4">
                  <label className="label-name">Valid Employee</label>
                  <Select
                    options={validEmployee}
                    value={validEmployee?.find(
                      (option) => option?.value === filterList.valid_emp
                    )}
                    onChange={(e) => handleFilter(e, "valid_emp")}
                    name="valid_emp"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-third z-4">
                  <label className="label-name">Employee PF Type</label>
                  <Select
                    options={employeePFTypeList}
                    value={employeePFTypeList?.find(
                      (option) => option?.value === filterList.pf_employee
                    )}
                    onChange={(e) => handleFilter(e, "pf_employee")}
                    name="pf_employee"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block flex justify-center z-5">
                  <div className="add-new-button-block w-[40%] h-max ">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          {/**************** * Table ********************/}

          <PayslipsTable
            list={filteredPayslips}
            columns={columns}
            icon={More}
            multiple={showAccess}
            paySlip={showAccess ? navigatePaySlipPage : false}
            showDetails={showPayslipsDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            enableMoreOption={showAccess}
          />
        </div>
      </div>
    </div>
  );
}

export default PayslipsList;
