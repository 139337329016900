import React from "react";
import { Link, useLocation } from "react-router-dom";

function HolidayDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Holidays Name",
      value: listDetails?.name,
    },

    {
      label: "Religion",
      value: listDetails?.religion_name,
    },
    {
      label: "Description",
      value: listDetails?.description,
    },
    {
      label: "Leave Type",
      value: listDetails?.leave_type,
    },
    {
      label: "Financial Year",
      value: listDetails?.financial_year,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/holiday" className="in-active">
              Holiday List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Holiday Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Holiday Details</h4>

          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list" key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HolidayDetails;
