import React, { useContext, useEffect, useState } from "react";
import StockMovementTable from "../../DataTable/PurchaseOrder/StockMovement";
import Search from "../../../Assets/Icons/Search.svg";
import Select from "react-select";
import { Link } from "react-router-dom";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import { PrivilegeContext } from "../../../PrivilegeContext";

function StockMovement() {
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "stock_movement") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "po_number", label: "PO Number" },
    { id: "from", label: "From" },
    { id: "to", label: "To" },
    { id: "bundle_no", label: "Bundle No" },
    { id: "dispatch_no", label: "Dispatch No" },
    { id: "status", label: "Status" },
    { id: "more_icon", label: "" },
  ];

  return (
    <div className="po-container">
      <div className="po-contect-box">
        <div className="header-name">Stock in Movement</div>
        <div className="search-block" style={{ justifyContent: "flex-end" }}>
          {addAccess && (
            <div className="add-new-button-block">
              <Link
                to="/po/inventory/stock/newDispatch"
                className="add-new-button"
              >
                <span>New Dispatch</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
          )}
        </div>
        <form className="po-form">
          <div className="previous-pr">
            <div className="input-field-block">
              <span className="label-name">Ware House</span>
              <Select name="category_id" isSearchable />
            </div>

            <div className="input-field-block">
              <span className="label-name">Supplier</span>
              <Select name="category_id" isSearchable />
            </div>

            <div className="input-field-block">
              <span className="label-name">Supplier</span>
              <input
                className="input-field"
                type="date"
                autoComplete="off"
                placeholder="Select From Date"
                name="from_date"
              />
            </div>

            <div className="input-field-block">
              <span className="label-name">Search</span>
              <div className="po-search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  style={{ outline: "none", border: "none" }}
                />

                <img src={Search} alt="" />
              </div>
            </div>
          </div>
          <StockMovementTable columns={columns} list={[]} />
        </form>
      </div>
    </div>
  );
}

export default StockMovement;
