import React from 'react';
import { ClipLoader } from 'react-spinners';
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const ButtonLoader = ({ isBtnDisable }) => {
    return (
        <button type='button' className="submit-button cursor-not-allowed">
            <ClipLoader
                css={override}
                size={20}
                color={"#fff"}
                loading={isBtnDisable}
            />
            <span className="submit-button-label">processing...</span>
        </button>
    )
}

export default ButtonLoader