import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";

function CreateConsultany() {
  const navigate = useNavigate();
  const [agentDetails, setagentDetails] = useState({
    company_name: "",
    contact_person: "",
    phone: "",
    contact_email: "",
    address: "",
    town_city: "",
    state: "",
    pincode: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setagentDetails(() => ({ ...agentDetails, [name]: value }));
  };
  const addConsultancy = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const addType = { ...agentDetails };
    addType.type = "consultancy";
    const isValid = Validation.validateAgent(addType);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    }
    try {
      const addAgent = await POST_API("/addAgents", addType);
      if (addAgent?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(addAgent?.data?.message);
        navigate("/master/hrms/agent/consultancy");
        return;
      }
      if (addAgent?.data?.status === 403) {
        setButtonDisabled(false);
        alertify.error(addAgent?.data?.message);
      }
    } catch (e) {
      setButtonDisabled(false);
      alertify.error(e?.response?.data?.message);
    }
  };
  const resetForm = () => {
    setagentDetails({
      company_name: "",
      contact_person: "",
      phone: "",
      contact_email: "",
      address: "",
      town_city: "",
      state: "",
      pincode: "",
    });
  };
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/master/hrms/agent/consultancy" className="in-active">
                Consultancy List
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">Add Consultancy</Link>
            </div>
          </div>
          <div className="card-wrapper">
            <h4 className="card-header">Add Consultancy Agent</h4>
            <form onSubmit={(e) => addConsultancy(e)}>
              <div className="form">
                <div className="field-block">
                  <label className="label-name">
                    Company Name
                    <span className="font-bold" style={{ color: "#FF5C42" }}>
                      *
                    </span>
                  </label>
                  <input
                    className="input-field"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                    placeholder="Enter Company name"
                    name="company_name"
                    value={agentDetails?.company_name}
                  />
                  {errors.company_name && (
                    <span className="validation-error-label">
                      {errors.company_name}
                    </span>
                  )}
                </div>

                <div className="field-block">
                  <label className="label-name">
                    Contact Person
                    <span className="font-bold" style={{ color: "#FF5C42" }}>
                      *
                    </span>
                  </label>
                  <input
                    className="input-field"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                    placeholder="Enter Contact Person name"
                    name="contact_person"
                    value={agentDetails?.contact_person}
                  />
                  {errors.contact_person && (
                    <span className="validation-error-label">
                      {errors.contact_person}
                    </span>
                  )}
                </div>

                <div className="field-block">
                  <label className="label-name">
                    Contact Number
                    <span className="font-bold" style={{ color: "#FF5C42" }}>
                      *
                    </span>
                  </label>
                  <input
                    className="input-field"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                    placeholder="Enter Number"
                    name="phone"
                    value={agentDetails?.phone}
                  />
                  {errors.phone && (
                    <span className="validation-error-label">
                      {errors.phone}
                    </span>
                  )}
                </div>

                <div className="field-block">
                  <label className="label-name">
                    E-mail
                    <span className="font-bold" style={{ color: "#FF5C42" }}>
                      *
                    </span>
                  </label>
                  <input
                    className="input-field"
                    type="email"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                    placeholder="Enter Email"
                    name="contact_email"
                    value={agentDetails?.contact_email}
                  />
                  {errors.contact_email && (
                    <span className="validation-error-label">
                      {errors.contact_email}
                    </span>
                  )}
                </div>

                <div className="field-block">
                  <label className="label-name">
                    Address
                    <span className="font-bold" style={{ color: "#FF5C42" }}>
                      *
                    </span>
                  </label>
                  <textarea
                    className="input-field"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                    placeholder="Enter Address"
                    name="address"
                    rows={5}
                    value={agentDetails?.address}
                  />
                  {errors.address && (
                    <span className="validation-error-label">
                      {errors.address}
                    </span>
                  )}
                </div>
              </div>
              <div className="submit-reset-button">
                <button
                  type="button"
                  className="reset-button"
                  onClick={() => resetForm()}
                >
                  Reset
                </button>
                <button
                  disabled={buttonDisabled}
                  type="submit"
                  className="submit-button"
                >
                  <span className="submit-button-label">Save</span>
                  <img src={Save} alt="" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateConsultany;
