import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import config from "../../Services/config.json";


export default function StickyHeadTable({
  list,
  columns,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
  addAccess,
  handlePopup,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }

{
                      <TableCell className="!w-[7%] table-body">
                      {row?.employee_image !== "" ? (
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "40px",
                          }}
                          src={config?.path + row?.employee_image}
                          alt="profile_image"
                        />
                      ) : (
                        <div
                          className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                          style={{ backgroundColor: "#ccc" }}
                        >
                          {row?.first_name?.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.emp_code}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.employee_name}
                      </TableCell>
                    }

                    {<TableCell className="table-body">{row?.doj}</TableCell>}
                    {
                      <TableCell className="table-body">
                        {row?.present_days}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.agent_employee_amount}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.agent_company_amount}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {addAccess && (
                          <div className="add-new-button-block w-max">
                            <Link
                              className="add-new-button"
                              onClick={() => {
                                handlePopup(row);
                              }}
                            >
                              <span>Add</span>
                            </Link>
                          </div>
                        )}
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
