import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import Search from "../../../Assets/Icons/Search.svg";
import { DELETE_API, GET_API, POST_API } from "../../../Services/api";
import ReligionListTable from "../../DataTable/ReligionTable";
import Edit from "../../../Assets/Icons/Edit.svg";
import Spinner from "../../Spinner";
import Validation from "../../../Validation/Validation";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function AllReligionList() {
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, seterrors] = useState({});
  const [religionList, setReligionList] = useState([]);
  const [filteredReligion, setFilteredReligion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [religionDetails, setreligionDetails] = useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setreligionDetails({ ...religionDetails, [name]: value });
  };

  const header = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "name", label: "Religion Name", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "", label: "", minWidth: 50 },
  ];

  useEffect(() => {
    getReligionList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getReligionList(1, pageSize, searchKey);
  };

  const getReligionList = async (page, size, search) => {
    setLoading(true);
    try {
      const religionList = await GET_API(
        `/ReligionList?page=${page}&size=${size}&search=${search}`
      );
      if (religionList?.status === 200) {
        if (
          religionList?.data?.data?.current_page >
          religionList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLoading(false);
        setReligionList(religionList?.data?.data?.data);
        setLastPage(religionList?.data?.data?.last_page);
        setFilteredReligion(religionList?.data?.data?.data);
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
      setReligionList([]);
      setLastPage([]);
      setFilteredReligion([]);
      setLoading(false);
    }
  };

  const addReligion = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateReligion(religionDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const createFloor = await POST_API("/addReligion", religionDetails);
    if (createFloor.status === 200) {
      setButtonDisabled(false);
      setreligionDetails({ name: "", description: "" });
      getReligionList(currentPage, pageSize, searchKey);
      alertify.success(createFloor?.data?.message);
      return;
    }
    alertify.error(createFloor?.response?.data?.message);
  };

  const editReligion = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.validateReligion(religionDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const editFloor = await POST_API("/updateReligion", religionDetails);
    if (editFloor.status === 200) {
      setButtonDisabled(false);
      setreligionDetails({ name: "", description: "" });
      getReligionList(currentPage, pageSize, searchKey);
      setEditStatus(false);
      alertify.success(editFloor?.data?.message);
      return;
    }
    alertify.error(editFloor?.response?.data?.message);
  };

  const resetForm = () => {
    setEditStatus(false);
    setreligionDetails({ ...religionDetails, name: "", description: "" });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Religion..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteReligion/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getReligionList(currentPage, pageSize, searchKey);
              return;
            }
            alertify.error("Failed To Delete Religion");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <div className="card-wrapper">
            <h4 className="card-header">Add Religion</h4>

            <form
              onSubmit={() => getReligionList(currentPage, pageSize, searchKey)}
            >
              <div className="search-block">
                <div className="search">
                  <input
                    type="text"
                    className="search-input-box"
                    placeholder="Search..."
                    autoComplete="off"
                    onChange={(e) => setSearchKey(e?.target?.value)}
                    style={{ outline: "none", border: "none" }}
                    value={searchKey}
                  />

                  <button type="submit">
                    <img src={Search} alt="" className="cursor-pointer" />
                  </button>
                </div>
              </div>
            </form>
            <div className="add-list-container">
              <div className="input-block">
                <label className="add-new-input-label">
                  Religion Name <span className="required-symbol">*</span>
                </label>

                <input
                  type="text"
                  name="name"
                  className="input-field"
                  placeholder="Enter Religion name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  autoComplete="off"
                  value={religionDetails.name}
                />
                {errors.name ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {errors.name}
                  </div>
                ) : null}
              </div>
              <div className="input-block">
                <label className="add-new-input-label">Description</label>
                <textarea
                  name="description"
                  className="input-field"
                  placeholder="Enter the Description"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={religionDetails.description}
                  autoComplete="off"
                  rows={5}
                />
                {errors.description ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {errors.description}
                  </div>
                ) : null}
              </div>
              <div className="flex gap-3">
                <div className="add-new-button-block">
                  {editStatus ? (
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        editReligion(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      <span className="button-label">Save</span>
                      <img src={ColorAdd} alt="add" />
                    </button>
                  ) : (
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        addReligion(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      <span className="button-label">Add New</span>
                      <img src={ColorAdd} alt="add" />
                    </button>
                  )}
                </div>
                <Link className="edit-button" onClick={() => resetForm()}>
                  <span className="button-label">Reset</span>
                  <img src={Edit} alt="add" />
                </Link>
              </div>
            </div>

            {/**************** * Table ********************/}

            <ReligionListTable
              list={filteredReligion}
              columns={header}
              icon={More}
              editStatus={setEditStatus}
              editList={setreligionDetails}
              multiple={false}
              deleteRow={handleDelete}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllReligionList;
