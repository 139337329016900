import React from "react";
import { Route } from "react-router-dom";
import EmployeeManagementRoutes from "../../Components/Routes/EmployeeManagementRoutes";
import PayslipRoutes from "../../Components/Routes/PayslipRoutes";
import LoanAndExpenseRoutes from "../../Components/Routes/LoanAndExpenseRoutes";
import ReportRoutes from "../../Components/Routes/ReportRoutes";
import ApproveRoutes from "../../Components/Routes/ApproveRoutes";
import Dashboard from "../Dashboard/dashboard";
import QRcodeDetection from "../QRcodeDetection";
import VoucherRoutes from "../../Components/Routes/VoucherRoutes";
import UserPrivileges from "../Header/Settings/Userprivileges";
import GeneralSettings from "../Header/Settings/GeneralSettings";
import AttendanceRoutes from "../../Components/Routes/AttendanceMasterRoutes";

function HomeRoutes() {
  return (
    <>
      <Route path="/dashboard" element={<Dashboard />}></Route>
      <Route path="/userPrivileges" element={<UserPrivileges />}></Route>
      <Route path="/general-settings" element={<GeneralSettings />}></Route>

      <Route path="/">{AttendanceRoutes()}</Route>

      <Route path="/employee">{EmployeeManagementRoutes()}</Route>

      <Route path="/payroll">{PayslipRoutes()}</Route>

      <Route path="/expense">{LoanAndExpenseRoutes()}</Route>

      <Route path="/report">{ReportRoutes()}</Route>

      <Route path="/approve">{ApproveRoutes()}</Route>

      <Route path="/detection" element={<QRcodeDetection />}></Route>

      <Route path="/voucher">{VoucherRoutes()}</Route>
    </>
  );
}

export default HomeRoutes;
