import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Divider1 from "../../Assets/Logo/IDCard/Divider_1.svg";
import Divider2 from "../../Assets/Logo/IDCard/Divider_2.svg";

import Id1 from "../../Assets/Logo/IDCard/Id_1.svg";
import Id2 from "../../Assets/Logo/IDCard/botton_address.svg";

import Address1 from "../../Assets/Logo/IDCard/Address1.png";
import Address2 from "../../Assets/Logo/IDCard/Address2.png";
import Human from "../../Assets/Logo/IDCard/Human.png";
import User from "../../Assets/Logo/IDCard/User.svg";
import NameBlocker from "../../Assets/Logo/IDCard/NameBlocker.svg";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import config from "../../Services/config.json";

function IDCard() {
  const { state } = useLocation();
  const componentRef = useRef();

  const details = state?.list;

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/employee/list" className="in-active">
              Employee List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">ID Card Preview</Link>
          </div>
        </div>
        <div className="flex justify-end items-center">
          <button
            type="button"
            className="submit-button"
            onClick={() => printVoucher()}
          >
            <span className="submit-button-label">Print</span>
          </button>
        </div>
        <div
          className=" h-full justify-center gap-3 w-full"
          style={{ padding: "0px" }}
          ref={componentRef}
        >
          <div
            className="idcard-front h-[100%] border-[1px] border-primary pb-10"
            style={{ width: "325px", height: "507px" }}
          >
            <img
              src={Divider1}
              alt="divider"
              style={{
                width: "100%",
                transform: "rotate(180deg)",
              }}
            />
            <div      
              className="px-6 py-5 flex gap-5"
              style={{ height: "30%", backgroundColor: "#BF1E1B" }}
            >
              <div>
                <img src={Human} alt="" className="h-[60px]" />
              </div>
              <div className="pt-1">
                <img src={Id1} alt="" style={{ height: "100px" }} />
              </div>
            </div>
            <img
              src={Divider1}
              alt="divider"
              style={{ width: "100%" }}
            />
            <div
              style={{ height: "30%", width: "100%" }}
              className="flex flex-col justify-center items-center relative"
            >
              <div
                className="absolute"
                style={{
                  minHeight: "160px",
                  minWidth: "160px",
                  top: "-28px",
                  backgroundColor: "#fff",
                  borderRadius: "100px",
                  border: "3px solid #fcb415",
                }}
              >
                <img
                  style={{
                    height: "200px",
                    width: "200px",
                    borderRadius: "160px",
                  }}
                  src={
                    details?.profile_image
                      ? config?.path + details?.profile_image
                      : User
                  }
                  alt=""
                />
              </div>
            </div>
            <div
              style={{ height: "29%", width: "100%" }}
              className="py-5 flex justify-between"
            >
              <img src={NameBlocker} alt="" />
              <div className="flex flex-col justify-center items-center">
                <div className="text-text-light font-bold text-xl" style={{ color: "#BF1E1B" }}>
                  {details?.first_name}
                </div>
                <div
                  className="font-bold text-xl"
                  style={{ color: "#BF1E1B" }}
                >
                  {details?.designationname}
                </div>
                <div className="text-xl text-text-light font-bold" style={{ color: "#BF1E1B" }}>
                  Emp No : {details?.emp_code}
                </div>
              </div>
              <img src={NameBlocker} alt="" />
            </div>
            <img
              src={Divider2}
              alt="divider"
              style={{
                height: "5%",
                width: "100%",
              }}
            />
          </div>
          {/* <div>
            <br></br>
          </div> */}
          <div
            className="idcard-back h-full  border-[1px] border-primary"
            style={{ width: "325px", height: "506px", transform: 'rotate(180deg)' }}
          >
            <div
              className="text-white-color font-semibold"
              style={{ height: "70%", backgroundColor: "#BF1E1B" }}
            >
              <div
                className="bg-[#fff] h-inherit flex flex-col justify-around items-center "
                style={{
                  height: "96%",
                  backgroundColor: "#C9281C",
                  borderRadius: "160px",
                }}
              >
                <div className="flex flex-col gap-3 justify-center items-center w-full" >
                  <div className="flex justify-center px-5 pt-5 gap-3" style={{ paddingLeft : '2.25rem', paddingRight: '2.25rem' }}>
                    <div className="flex flex-col gap-3 text-xs" style={{ fontSize : '0.85rem' }}>
                      <div>DOB</div>
                      <div>MOBILE</div>
                      <div>BG</div>
                      <div>ADDRESS</div>
                    </div>
                    <div className="flex flex-col gap-3 text-xs">
                      <div>:</div>
                      <div>:</div>
                      <div>:</div>
                      <div>:</div>
                    </div>
                    <div className="flex flex-col gap-3 text-xs" style={{ fontSize : '1rem', fontWeight: 900 }}>
                      <div>
                        {details?.date_of_birth ? details?.date_of_birth : "-"}
                      </div>
                      <div>{details?.phone_no ? details?.phone_no : "-"}</div>
                      <div>
                        {details?.blood_group_name
                          ? details?.blood_group_name
                          : "-"}
                      </div>
                      <div>{details?.present_address}</div>
                    </div>
                  </div>
                </div>

                <div
                  className="qr-code-border h-[100px] flex justify-center items-center"
                  style={{}}
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      border: "3px solid #f27a20",
                      padding: "5px",
                    }}
                  >
                    <QRCode
                      value=""
                      style={{
                        height: "95px",
                        width: "max-content",
                      }}
                    />
                  </div>
                </div>
              </div>

              <img
                src={Divider1}
                alt="divider"
                style={{
                  width: "100%",
                  transform: "rotate(180deg)",
                }}
              />
            </div>

            <div style={{ height: "33%" }}>
              <div style={{ height: "82%" }}>
                <div
                  className="bg-[#fff] flex justify-center items-center"
                  style={{ height: "100%" }}
                >
                  <img
                    src={Id2}
                    alt=""
                    style={{ height: "120px"}}
                  />
                </div>
                {/* <div
                  className="bg-[#fff] flex justify-center items-center"
                  style={{ height: "12%" }}
                >
                  <img src={Address1} alt="" />
                </div>
                <div
                  className="bg-[#fff] flex justify-center items-center"
                  style={{ height: "12%" }}
                >
                  <img src={Address2} alt="" />
                </div> */}
              </div>
              <img
                src={Divider2}
                alt="divider"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IDCard;
