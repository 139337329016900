import React from "react";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Spinner = ({ loading }) => {
  return (
    <div className="grow flex justify-center items-center gap-2 bg-[#F7F6F4] min-h-[250px]">
      <ClipLoader
        css={override}
        size={20}
        color={"#D99719"}
        loading={loading}
      />
      <span className="text-text-dark font-karla">Loading...</span>
    </div>
  );
};

export default Spinner;
