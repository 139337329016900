import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";
function CreateSecurity() {
  const navigate = useNavigate();
  const [agentDetails, setagentDetails] = useState({
    company_name: "",
    contact_person: "",
    phone: "",
    contact_email: "",
    address: "",
    type: "security_agent",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setagentDetails(() => ({ ...agentDetails, [name]: value }));
  };
  const addSecurity = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateAgent(agentDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }

    try {
      const createSecurity = await POST_API("/addAgents", agentDetails);
      if (createSecurity?.data?.status === 200) {
        alertify.success(createSecurity.data.message);
        navigate("/master/hrms/agent/security");
        setButtonDisabled(false);
        return;
      }
      if (createSecurity?.data?.status === 403) {
        setButtonDisabled(false);
        alertify.error(createSecurity.data.message);
        return;
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
      setButtonDisabled(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const resetForm = () => {
    setagentDetails({
      company_name: "",
      contact_person: "",
      phone: "",
      contact_email: "",
      address: "",
      type: "security_agent",
    });
  };
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/agent/security" className="in-active">
              Security Agent List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Security agent</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Security Agent</h4>
          <form onSubmit={(e) => addSecurity(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Company Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  placeholder="Enter Company name"
                  name="company_name"
                  value={agentDetails?.company_name}
                />
                {errors.company_name && (
                  <span className="validation-error-label">
                    {errors.company_name}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Person
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  placeholder="Enter Contact Person name"
                  name="contact_person"
                  value={agentDetails?.contact_person}
                />
                {errors.contact_person && (
                  <span className="validation-error-label">
                    {errors.contact_person}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Number
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  placeholder="Enter Number"
                  name="phone"
                  value={agentDetails?.phone}
                />
                {errors.phone && (
                  <span className="validation-error-label">{errors.phone}</span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">
                  E-mail
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="email"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  placeholder="Enter Email"
                  name="contact_email"
                  value={agentDetails?.contact_email}
                />
                {errors.contact_email && (
                  <span className="validation-error-label">
                    {errors.contact_email}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Address
                  <span className="required-symbol">*</span>
                </label>
                <textarea
                  className="input-field"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  placeholder="Enter Address"
                  name="address"
                  value={agentDetails?.address}
                  rows={5}
                />
                {errors.address && (
                  <span className="validation-error-label">
                    {errors.address}
                  </span>
                )}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="submit"
                className="submit-button"
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Save</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateSecurity;
