import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";
import { GET_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import PaymentReportTableList from "../../DataTable/PaymentReportTableList";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import Filter from "../../../Assets/Icons/Filter.svg";

function PaymentReportList() {
  const navigate = useNavigate();
  const [paymentList, setPaymentList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterList] = useState({
    branch_id: "",
    emp_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    joining_from_date: "",
    joining_to_date: "",
  });

  const showDetails = (listDetail) => {
    const id = listDetail?.employee_Id;
    navigate(`/report/payment/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_name", label: "Employee Name" },
    { id: "employeeCode", label: "Employee Code" },
    { id: "department_name", label: "Department Name" },
    { id: "designation_name", label: "Designation Name" },
    { id: "amount", label: "Amount" },
    { id: "paid_amount", label: "Paid Amount" },
    { id: "interest", label: "Interest" },
    { id: "more_icon", label: "" },
  ];

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = paymentList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setfilteredList(searchedList);
  };

  useEffect(() => {
    getPaymentReportList();
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
  }, []);

  const getPaymentReportList = async () => {
    setLoading(true);
    try {
      const list = await GET_API("/paymentReport");
      if (list.status === 200) {
        setPaymentList(list?.data?.data);
        setfilteredList(list?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alertify.error("Failed to get Employee Report List");
      setLoading(false);
    }
  };

  const getBranchList = async () => {
    try {
      setLoading(true);
      const branchList = await GET_API("/branchList");
      if (branchList.status === 200) {
        setBranchList(branchList?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeetype");
      if (empType.status === 200) {
        setEmployeetype(empType?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList.status === 200) {
        setDepartment(departmentList.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationList");
      if (designationList.status === 200) {
        setDesignation(designationList?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const genderList = [
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
  ];

  const handleFilter = async (e) => {
    const { name, value } = e.target;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "/paymentReport?";
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      if (concateURL === "") {
        concateURL += newURL;
      } else {
        concateURL += "&" + newURL;
      }
    });
    const filteredList = await GET_API(concateURL);

    if (filteredList.status === 200) {
      setfilteredList(filteredList?.data?.data);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <h1 className="heading-text">Report</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Payment Report List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
                onChange={(e) => handleBranchSearch(e)}
              />
              <img src={Search} alt="" />
            </div>
            <div
              className="filter-button"
              onClick={() => setFilter((prev) => !prev)}
            >
              <span className="filter-btn-label">Filter</span>
              <img src={Filter} alt="" />
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block">
                  <label className="label-name">
                    Branch Name
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="branch_id"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Branch</option>
                    {branchList?.map((branch, i) => {
                      return (
                        <option key={i} value={branch?.id}>
                          {branch?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Employee Type
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="emp_type_id"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Employee Type</option>
                    {employeetype?.map((employee, i) => {
                      return (
                        <option key={i} value={employee?.id}>
                          {employee?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Gender
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="gender"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Gender</option>
                    {genderList?.map((item, i) => {
                      return (
                        <option key={i} value={item?.value}>
                          {item?.key}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Department
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="department_id"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Department</option>
                    {department.map((item, i) => {
                      return (
                        <option key={i} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Designation
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="designation_id"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Designation</option>
                    {designation?.map((item, i) => {
                      return (
                        <option key={i} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Joining Date
                    <span className="required-symbol">*</span>
                  </label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_from_date"
                      onChange={(e) => {
                        handleFilter(e);
                      }}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_to_date"
                      onChange={(e) => {
                        handleFilter(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
          <PaymentReportTableList
            list={filteredList}
            columns={columns}
            icon={More}
            showDetails={showDetails}
            activeEdit={false}
            activeDelete={false}
            multiple={true}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentReportList;
