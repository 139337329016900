import React, { useState, useEffect, useContext, useRef } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Search from "../../Assets/Icons/Search.svg";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import {
  GET_API,
  DELETE_API,
  BASE_PATH,
  BASE_URL,
  POST_API,
} from "../../Services/api";
import PDFExport from "../../Assets/Icons/pdf-export.svg";

import LeaveOnDutyListTable from "../DataTable/LeaveOnDutyListTable";
import More from "../../Assets/Icons/More.svg";
import Spinner from "../Spinner";
import Filter from "../../Assets/Icons/Filter.svg";
import ExcelExport from "../../Assets/Icons/excel-export.svg";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../PrivilegeContext";
import ButtonLoader from "../ButtonLoader";
import Add from "../../Assets/Icons/Add.svg";
import { read, utils } from "xlsx";

function LeaveOndutyList() {
  const navigate = useNavigate();
  const [leaveList, setLeaveOndutyList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { access } = useContext(PrivilegeContext);
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
    const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);
  
  const inputFile = useRef(null);

  const [filterList, setfilterList] = useState({
    employee_id: "",
    branch_id: "",
    employee_type: "",
    gender: "",
    department_id: "",
    designation_id: "",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "user", label: "User" },

    { id: "emp_code", label: "Code" },
    { id: "employee_name", label: "Name" },
    { id: "from_date", label: "From Date" },
    { id: "to_date", label: "To Date" },
    { id: "no_of_days", label: "No of Days" },
    { id: "type", label: "Type" },
    { id: "status", label: "Status" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getEmployeeList();
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
  }, []);

  useEffect(() => {
    getLeaveOndutyList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "leave_onduty") {
        setAddAccess(item?.add);
        setShowAccess(item?.view);
      }
      return "";
    });
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getLeaveOndutyList(1, pageSize, filterParams, searchKey);
  };

  const getLeaveOndutyList = async (page, size, filters, search) => {
    setLoading(true);
    try {
      const leaveOnDutyList = await GET_API(
        `/employeeLeaveList?page=${page}&size=${size}${filters}&search=${search}`
      );
      if (leaveOnDutyList?.data?.status === 200) {
        if (
          leaveOnDutyList?.data?.data?.current_page >
          leaveOnDutyList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLoading(false);
        setLeaveOndutyList(leaveOnDutyList?.data?.data?.data);
        setFilteredList(leaveOnDutyList?.data?.data?.data);
        setLastPage(leaveOnDutyList?.data?.data?.last_page);
      }
    } catch (error) {
      setLoading(false);
      setLeaveOndutyList([]);
      setFilteredList([]);
      setLastPage("");
      setCurrentPage(1);
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value =
      type === "date" || type === "input" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };

    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
    });
    setFilterParams(concateURL);
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const getEmployeeList = async () => {
    try {
      setIsEmpLoading(true);
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const attendanceListArr = [];
        employeeList?.data?.data.map((item) => {
          attendanceListArr.push(item);
        });
        const empList = employeeList?.data?.data.map((item) => {
          return {
            label: `${item?.first_name} (${item?.emp_code})`,
            value: item?.id,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        // setFilteredBranch(attendanceListArr);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
    }
  };

  const getBranchList = async () => {
    setIsBranchLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };


    const exportData = async () => {
        const search = searchKey ? `&search=${searchKey}` : "";
        try {
          setIsExportLoading(true);
          const downloadForm = await GET_API(
            `${BASE_URL}export-leave-list?page=${currentPage}&size=${pageSize}${filterParams}&search=${searchKey}`
          );
          if (downloadForm?.data?.status === 200) {
            const alink = document.createElement("a");
            alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
            alink.setAttribute("target", "_blank");
            alink.download = "Leave-Onduty.xlsx";
            alink.click();
            setIsExportLoading(false);
          }
        } catch (error) {
          setIsExportLoading(false);
        }
      };
    
      const handleExportChange = (event) => {
        const value = event.target.value;
    
        if (value === 'excel') {
          setIsExportLoading(true);
          exportData(); // Call your export function
        } else if (value === 'pdf') {
          setIsExportPdfLoading(true);
          exportAsPDF(); // Call your export function
        }
      };
    
      const exportAsPDF = async () => {
        const search = searchKey ? `&search=${searchKey}` : "";
        try {
          setIsExportPdfLoading(true);
          const downloadForm = await GET_API(
            `leaveOndutyListPdf?${filterParams}&search=${searchKey}`
          );
          if (downloadForm?.data?.status === 200) {
            const alink = document.createElement("a");
            alink.href = downloadForm?.data?.filename;
            alink.setAttribute("target", "_blank");
            alink.download = "Bonus.pdf";
            alink.click();
            setIsExportPdfLoading(false);
          }
        } catch (error) {
          setIsExportPdfLoading(false);
        }
      };


  const navigateEditPage = (listDetail) => {
    navigate("/branch-edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const leaveType = [
    { label: "Select...", value: "" },
    { label: "Leave", value: "leave" },
    { label: "OD", value: "onduty" },
  ];

  const showBranchDetails = (listDetail) => {
    const url = `/leaveonduty/details/${listDetail?.id}`;
    const state = {
      list: listDetail,
    };
    const newTab = window.open(url, "_blank");
    newTab.history.pushState(state, "", url);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Leave & Onduty",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deletebranch/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getLeaveOndutyList(
                currentPage,
                pageSize,
                filterParams,
                searchKey
              );
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete Leave & Onduty");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const onImportClick = () => {
    inputFile.current.click();
  };

  const fileUpload = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files.length) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const wb = read(e.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            raw: false,
            dateNF: "dd-mm-yyyy hh:mm:ss",
          });
          try {
            const importLeaveOnduty = await POST_API("/importLeaveOnduty", {
              data: rows,
            });
            if (importLeaveOnduty?.data?.status === 200) {
              alertify.success(importLeaveOnduty?.data?.message);
              getLeaveOndutyList(
                currentPage,
                pageSize,
                filterParams,
                searchKey
              );
            }
          } catch (error) {
            alertify?.error("Failed to import...");
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Leave & On Duty List</div>
          <form
            onSubmit={() =>
              getLeaveOndutyList(currentPage, pageSize, filterParams, searchKey)
            }
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="flex gap-3">
                <div
                  className="filter-button"
                  onClick={() => setFilter((prev) => !prev)}
                >
                  <span className="filter-btn-label">Filter</span>
                  <img src={Filter} alt="" />
                </div>

                <div className="export-dropdown dropdown-select-to-second">
                    <select onChange={handleExportChange} defaultValue="" style={{ backgroundColor: 'rgb(217 151 25)', padding: '10px', borderRadius: '5px', color: '#fff' }}>
                      <option value="" disabled>Select Export Type</option>
                      <option value="excel">
                        {isExportLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as Excel
                            <img src={ExcelExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                      <option value="pdf">
                        {isExportPdfLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as PDF
                            <img src={PDFExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                    </select>
                </div>

                {addAccess && (
                  <div className="add-new-button-block">
                    <Link to="/leaveonduty/add" className="add-new-button">
                      <span>Add New</span>
                      <img src={ColorAdd} alt="add" />
                    </Link>
                  </div>
                )}
                {addAccess && (
                  <div className="add-new-button-block">
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                      onChange={fileUpload}
                    />

                    <button
                      className="add-new-button"
                      onClick={() => onImportClick()}
                    >
                      <span style={{ cursor: "pointer" }}>Import</span>
                      <img src={Add} alt="" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>

          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Employee</label>
                  <Select
                    options={employeeList}
                    value={employeeList?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type
                    )}
                    onChange={(e) => handleFilter(e, "employee_type", "select")}
                    name="employee_type"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id", "select")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Leave Type</label>
                  <Select
                    options={leaveType}
                    value={leaveType?.find(
                      (option) => option?.value === filterList.type
                    )}
                    onChange={(e) => handleFilter(e, "type", "select")}
                    name="type"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Date</label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="from_date"
                      onChange={(e) => {
                        handleFilter(e, "from_date", "date");
                      }}
                      value={filterList?.from_date}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="to_date"
                      onChange={(e) => {
                        handleFilter(e, "to_date", "date");
                      }}
                      value={filterList?.to_date}
                    />
                  </div>
                </div>


                <div className="field-block flex justify-center z-5">
                  <div className="add-new-button-block w-[40%] h-max ">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <LeaveOnDutyListTable
            list={filteredList}
            columns={columns}
            icon={More}
            multiple={showAccess}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            enableMoreOption={showAccess}
          />
        </div>
      </div>
    </div>
  );
}
export default LeaveOndutyList;
