import React from "react";
import { Link, useLocation } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
function BreakTimeDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const fromTime = listDetails?.from_time;
  const hour = fromTime?.split(":");
  const ampm = hour?.[0] > 12 ? "pm" : "am";
  const Details = [
    {
      label: "Break Name",
      value: listDetails?.name,
    },
    {
      label: "From Time",
      value: listDetails?.from_time + " " + ampm,
    },
    {
      label: "To Time",
      value: listDetails?.to_time + " " + ampm,
    },
    {
      label: "For Shift",
      value: listDetails?.shift_name,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/break" className="in-active">
              Break List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Break Time Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Break Time Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreakTimeDetails;
