import React from "react";
import { Link, useLocation } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";

function BankDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Branch Name",
      value: listDetails.branch_name ? listDetails.branch_name : "-",
    },
    {
      label: "Bank Name",
      value: listDetails.bank_name ? listDetails.bank_name : "-",
    },
    {
      label: "IFSC Code",
      value: listDetails.ifsc_code ? listDetails.ifsc_code : "-",
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/bank" className="in-active">
              Bank List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Bank Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Bank Details</h4>

          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list) => {
                return (
                  <div className="list">
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankDetails;
