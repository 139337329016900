import Validation from "../Validation/Validation";
import { POST_API } from "../Services/api";
import alertify from "alertifyjs";

const handleLogin = async (
  e,
  state,
  navigate,
  setButtonDisabled,
  setErrors
) => {
  e.preventDefault();
  setButtonDisabled(true);
  const isValid = Validation.validateLoginForm(state);
  if (isValid && isValid.error) {
    setButtonDisabled(false);
    setErrors({
      [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
    });
    return;
  } else {
    setErrors({});
  }
  try {
    const authenticateUser = await POST_API("login", state);
    setButtonDisabled(false);
    const token = authenticateUser?.data?.data?.token;
    const user_id = authenticateUser?.data?.data?.user_id;
    const user_name = authenticateUser?.data?.data?.user_name;
    const user_role = authenticateUser?.data?.data?.role_id;
    localStorage.setItem("token", token);
    localStorage.setItem("user_id", user_id);
    localStorage.setItem("user_role", user_role);
    localStorage.setItem("user_name", user_name);

    navigate("/home");
  } catch (e) {
    setButtonDisabled(false);
    alertify.error(e?.response?.data?.message);
  }
};

export { handleLogin };
