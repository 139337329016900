import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../Services/api";
import FoodAllowanceListTable from "../../DataTable/FoodAllowanceListTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function FoodAllowance() {
  const navigate = useNavigate();
  const [foodAllowanceList, setfoodAllowanceList] = useState([]);
  const [filteredFood, setFilteredFood] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const showFoodDetails = (listDetail) => {
    navigate(`/master/hrms/foodallowance/details/${listDetail?.id}`);
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "from_time", label: "Before Time" },
    { id: "to_time", label: "After Time" },
    { id: "amount", label: "Amount" },
    { id: "description", label: "Description" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getFoodList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getFoodList(1, pageSize, searchKey);
  };

  const getFoodList = async (page, size, search) => {
    setLoading(true);
    try {
      const foodList = await GET_API(
        `/FoodAllowance?page=${page}&size=${size}&search=${search}`
      );
      if (foodList.status === 200) {
        if (
          foodList?.data?.data?.current_page > foodList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setfoodAllowanceList(foodList?.data?.data?.data);
        setFilteredFood(foodList?.data?.data?.data);
        setLastPage(foodList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setfoodAllowanceList([]);
      setFilteredFood([]);
      setLastPage("");
      setCurrentPage(1);
      setLoading(false);
      alertify.error("Failed to Get Food Allowance List");
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Food Allowance..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(
              `/deleteFoodAllowance/${row_id}`
            );
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getFoodList(currentPage, pageSize, searchKey);
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete  Food Allowance");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigateEditPage = (listDetail) => {
    navigate(`/master/hrms/foodallowance/edit/${listDetail?.id}`);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <div className="card-wrapper">
            <h4 className="card-header">Food Allowance List</h4>
            <form
              onSubmit={() => getFoodList(currentPage, pageSize, searchKey)}
            >
              <div className="search-block">
                <div className="search">
                  <input
                    type="text"
                    className="search-input-box"
                    placeholder="Search..."
                    autoComplete="off"
                    onChange={(e) => setSearchKey(e?.target?.value)}
                    style={{ outline: "none", border: "none" }}
                    value={searchKey}
                  />

                  <button type="submit">
                    <img src={Search} alt="" className="cursor-pointer" />
                  </button>
                </div>

                <div className="add-new-button-block">
                  <Link
                    to="/master/hrms/foodallowance/create"
                    className="add-new-button"
                  >
                    <span>Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </Link>
                </div>
              </div>
            </form>

            {/**************** * Table ********************/}

            <FoodAllowanceListTable
              list={filteredFood}
              columns={columns}
              icon={More}
              multiple={true}
              editPage={navigateEditPage}
              showDetails={showFoodDetails}
              deleteRow={handleDelete}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodAllowance;
