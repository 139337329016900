import React from "react";
import { Route } from "react-router-dom";

import Inventory from "../../../Components/PurchaseOrder/Inventory/InventoryList";
import AddInventory from "../../../Components/PurchaseOrder/Inventory/AddInventory";
import EditInventory from "../../../Components/PurchaseOrder/Inventory/EditInventory";
import InventoryDetails from "../../../Components/PurchaseOrder/Inventory/InventoryDetails";

import StockMovement from "../../../Components/PurchaseOrder/Inventory/StockMovement";

function InventoryRoute() {
  return (
    <>
      <Route path="product" element={<Inventory />}></Route>
      <Route path="product/new" element={<AddInventory />}></Route>
      <Route path="product/edit/:id" element={<EditInventory />}></Route>
      <Route path="product/details/:id" element={<InventoryDetails />}></Route>

      <Route path="stockMovement" element={<StockMovement />}></Route>
    </>
  );
}

export default InventoryRoute;
