import React, { useState, useEffect, useContext } from "react";
import AgentTable from "../DataTable/AgentsTable";
import { GET_API, POST_API, BASE_PATH, BASE_URL } from "../../Services/api";
import alertify from "alertifyjs";
import Select from "react-select";
import { PrivilegeContext } from "../../PrivilegeContext";
import Validation from "../../Validation/Validation";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "../Spinner";
import { Checkbox } from "@mui/material";
import Filter from "../../Assets/Icons/Filter.svg";
import ExcelExport from "../../Assets/Icons/excel-export.svg";
import PDFExport from "../../Assets/Icons/pdf-export.svg";
import ButtonLoader from "../ButtonLoader";

function AllCommisionList() {
  const [filteredList, setFilteredList] = useState([]);
  const [employeeCommissionList, setEmployeeCommissionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const [filterList, setfilterList] = useState({
    employee_id: "",
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    attendance_status: "",
    joining_from_date: "",
    joining_to_date: "",
  });
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [errors, setErrors] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [isFloorLoading, setIsFloorLoading] = useState(false);

  
  const [searchKey, setSearchKey] = useState("");
  const [floorList, setFloorList] = useState([]);

  const [agentName, setAgentName] = useState({});
  const [agentList, setAgentList] = useState([]);
  const [isAgentLoading, setIsAgentLoading] = useState(false);

  // Export Excel and PDF
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);

  const handleFilter = async (e, name) => {
    const { value } =
      name === "joining_from_date" || name === "joining_to_date"
        ? e?.target
        : e;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    getEmployeeList();
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getFloorList();
  }, []);

  const [agentCommisssionList, setAgentCommisssionList] = useState({
    agent_id: "",
    employee_id: "",
    present_days: "",
    date: "",
    amount: "",
    deduct_from_employee: false,
    created_by: "",
  });

  const [selectedList, setSelectedList] = useState({});

  const handleSelectChange = (e) => {
    if (e?.value === "") return;
    setAgentName(e);
    getData(filterParams, e?.value);
  };

  const getFloorList = async () => {
    setIsFloorLoading(true);
    try {
      const floorList = await GET_API("/floorDropdown");
      if (floorList.status === 200) {
        const floor = floorList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setFloorList(floor);
        setIsFloorLoading(false);
      }
    } catch (e) {
      setIsFloorLoading(false);
      alertify.error("Failed to Get Floor List");
    }
  };

  const validEmployee = [
    { label: "Please Select", value: "" },
    { label: "Valid", value: "valid" },
    { label: "Invalid", value: "invalid" },
  ];

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "user", label: "User" },

    { id: "emp_code", label: "Code" },
    { id: "employee_name", label: "Name" },
    { id: "doj", label: "DOJ" },
    { id: "present_days", label: "Present Days" },
    { id: "agent_employee_amount", label: "Employee Amount" },
    { id: "agent_company_amount", label: "Company Amount" },
    { id: "more_icon", label: "" },
  ];

  const getAgentList = async () => {
    setIsAgentLoading(true);
    try {
      const getType = await GET_API("/agentDropdown/consultancy");
      if (getType.status === 200) {
        const agent = getType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select Agent" });
        setAgentList(agent);
        setIsAgentLoading(false);
      }
    } catch (error) {
      setIsAgentLoading(false);
      setAgentList([]);
      alertify.error("Failed to get Agent List...");
    }
  };

  useEffect(() => {
    getAccess();
  }, [access]);

  useEffect(() => {
    getAgentList();
  }, []);

  useEffect(() => {
    if (agentName?.value) {
      getData(filterParams, agentName?.value);
    }
  }, [showFilterResultData, currentPage, pageSize]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "agent_commission") {
        setAddAccess(item?.add);
        setShowAccess(item?.view);
      }
      return "";
    });
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getData(filterParams, agentName?.value);
  };

  const getData = async (filterParams, agentId) => {
    setLoading(true);
    try {
      const agentCommissionList = await GET_API(
        `/empWiseAgentCommissionList/${agentId}?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (agentCommissionList.status === 200) {
        if (
          agentCommissionList?.data?.data?.current_page >
          agentCommissionList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setEmployeeCommissionList(agentCommissionList?.data?.data?.data);
        setFilteredList(agentCommissionList?.data?.data?.data);
        setLastPage(agentCommissionList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (e) {
      setEmployeeCommissionList([]);
      setFilteredList([]);
      setLastPage("");
      setCurrentPage(1);
      setLoading(false);
      alertify.error("Failed to get Agent Commission List");
    }
  };

  const getEmployeeList = async () => {
    try {
      setIsEmpLoading(true);
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const attendanceListArr = [];
        employeeList?.data?.data.map((item) => {
          return attendanceListArr.push(item);
        });
        const empList = employeeList?.data?.data.map((item) => {
          return {
            label: `${item?.first_name} (${item?.emp_code})`,
            value: item?.id,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
    }
  };

  const getBranchList = async () => {
    setIsBranchLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handlePopup = (list) => {
    setOpenPopup(true);
    setSelectedList({
      employee_name: list?.employee_name,
      emp_code: list?.emp_code,
      agent_name: list?.agent_name,
    });

    setAgentCommisssionList({
      ...agentCommisssionList,
      agent_id: list?.agent_id,
      employee_id: list?.employee_id,
      present_days: list?.present_days,
      created_by: localStorage?.getItem("user_id"),
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e?.target;
    setAgentCommisssionList({
      ...agentCommisssionList,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const addCommission = async () => {
    setButtonDisabled(true);
    const isValid = Validation.validateAgentCommission(agentCommisssionList);
    if (isValid && isValid?.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setButtonDisabled(false);
      setErrors({});
    }

    try {
      const addData = await POST_API(
        "addEmpAgentCommission",
        agentCommisssionList
      );
      if (addData?.data?.status === 200) {
        alertify?.success(addData?.data?.message);
        setOpenPopup(false);
        setAgentCommisssionList({
          agent_id: "",
          employee_id: "",
          present_days: "",
          date: "",
          amount: "",
          deduct_from_employee: false,
          created_by: localStorage?.getItem("user_id"),
        });
        getData(filterParams, agentName?.value);
      }
    } catch (error) {
      setAgentCommisssionList({
        agent_id: "",
        employee_id: "",
        present_days: "",
        date: "",
        amount: "",
        deduct_from_employee: false,
        created_by: localStorage?.getItem("user_id"),
      });
      alertify?.error("Failed to add commission");
      setOpenPopup(false);
      getData(filterParams, agentName?.value);
    }
  };

  const exportData = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-agentCommission/${agentName?.value}?${filterParams}${search}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Attendance.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const handleExportChange = (event) => {
    const value = event.target.value;

    if (value === 'excel') {
      setIsExportLoading(true);
      exportData(); // Call your export function
    } else if (value === 'pdf') {
      setIsExportPdfLoading(true);
      exportAsPDF(); // Call your export function
    }
  };

  const exportAsPDF = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(
        `agentcommissionPdf/${agentName?.value}?${filterParams}${search}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "Loan.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };
  if (
    loading ||
    isAgentLoading ||
    isEmpLoading ||
    isBranchLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading
  ) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <div className="bg-[#F7F6F4] grow">
        <div className="sm-container">
          <div className="card-wrapper">
            <div className="card-header">Agent Commission List</div>

            <div className="search-block" style={{ paddingTop: '40px' }}>
              <div className="dropdown-select-to-second">
                <label>Select Agent:</label>
                <Select
                  options={agentList}
                  value={agentName}
                  onChange={(e) => handleSelectChange(e)}
                  placeholder="Select Agent"
                  name="agent_id"
                  className="w-[250px] z-1"
                  isSearchable
                />
              </div>
              <div
                className="flex gap-3"
                style={{ position: "relative", zIndex: "100" }}
              >
                <div className="flex gap-3">
                  <div
                    className="filter-button"
                    onClick={() => setFilter((prev) => !prev)}
                  >
                    <span className="filter-btn-label">Filter</span>
                    <img src={Filter} alt="" />
                  </div>
                 
                  <div className="export-dropdown dropdown-select-to-second">
                    <select onChange={handleExportChange} defaultValue="" style={{ backgroundColor: 'rgb(217 151 25)', padding: '10px', borderRadius: '5px' }}>
                      <option value="" disabled>Select Export Type</option>
                      <option value="excel">
                        {isExportLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as Excel
                            <img src={ExcelExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                      <option value="pdf">
                        {isExportPdfLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as PDF
                            <img src={PDFExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                    </select>
                  </div>

                </div>
              </div>
            </div>
            {filter && (
              <form>
                <div className="form ">
                  <div className="field-block dropdown-select-to-third">
                    <label className="label-name">Employee</label>
                    <Select
                      options={employeeList}
                      value={employeeList?.find(
                        (option) => option?.value === filterList.employee_id
                      )}
                      onChange={(e) => handleFilter(e, "employee_id")}
                      name="employee_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Branch Name</label>
                    <Select
                      options={branchList}
                      value={branchList?.find(
                        (option) => option?.value === filterList.branch_id
                      )}
                      onChange={(e) => handleFilter(e, "branch_id")}
                      name="branch_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-second">
                    <label className="label-name">Employee Type</label>
                    <Select
                      options={employeetype}
                      value={employeetype?.find(
                        (option) =>
                          option?.value === filterList.employee_type_id
                      )}
                      onChange={(e) => handleFilter(e, "employee_type_id")}
                      name="employee_type_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-third">
                    <label className="label-name">Gender</label>
                    <Select
                      options={genderList}
                      value={genderList?.find(
                        (option) => option?.value === filterList.gender
                      )}
                      onChange={(e) => handleFilter(e, "gender")}
                      name="gender"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-third">
                    <label className="label-name">Department</label>
                    <Select
                      options={department}
                      value={department?.find(
                        (option) => option?.value === filterList.department_id
                      )}
                      onChange={(e) => handleFilter(e, "department_id")}
                      name="department_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block dropdown-select-to-third">
                    <label className="label-name">Designation</label>
                    <Select
                      options={designation}
                      value={designation?.find(
                        (option) => option?.value === filterList.designation_id
                      )}
                      onChange={(e) => handleFilter(e, "designation_id")}
                      name="designation_id"
                      className=""
                      isSearchable
                    />
                  </div>
                  <div className="field-block">
                    <label className="label-name">Joining Date</label>
                    <div className="flex gap-1 justify-center items-center">
                      <input
                        className="input-field "
                        type="date"
                        autoComplete="off"
                        name="joining_from_date"
                        onChange={(e) => {
                          handleFilter(e, "joining_from_date");
                        }}
                        value={filterList?.joining_from_date}
                      />
                      <label className="label-name">to</label>
                      <input
                        className="input-field "
                        type="date"
                        autoComplete="off"
                        name="joining_to_date"
                        onChange={(e) => {
                          handleFilter(e, "joining_to_date");
                        }}
                        value={filterList?.joining_to_date}
                      />
                    </div>
                  </div>

                  <div
                    className="field-block dropdown-select-to-second"
                  >
                    <label className="label-name">Floor</label>
                    <Select
                      options={floorList}
                      value={floorList?.find(
                        (option) => option?.value === filterList.floor
                      )}
                      onChange={(e) => handleFilter(e, "floor")}
                      name="floor"
                      className=""
                      isSearchable
                    />
                  </div>

                  <div className="field-block dropdown-select-to-third z-4">
                    <label className="label-name">Valid Employee</label>
                    <Select
                      options={validEmployee}
                      value={validEmployee?.find(
                        (option) => option?.value === filterList.valid_emp
                      )}
                      onChange={(e) => handleFilter(e, "valid_emp")}
                      name="valid_emp"
                      className=""
                      isSearchable
                    />
                  </div>

                  <div className="field-block flex justify-center">
                    <div className="add-new-button-block w-[40%]">
                      <button
                        className="add-new-button"
                        onClick={(e) => {
                          showFilterResult(e);
                        }}
                      >
                        <span style={{ cursor: "pointer" }}>Show Result</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
            <div className="z-0">
              <AgentTable
                list={employeeCommissionList}
                columns={columns}
                setPageNo={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                lastPage={lastPage}
                currentPage={currentPage}
                addAccess={addAccess}
                handlePopup={handlePopup}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog open={openPopup} onClose={handleClose}>
          <DialogTitle id="customized-dialog-title">
            ADD AGENT COMMISSION
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <div className="font-karla">
            <div
              className="gap-3"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div className="text-text-dark">Employee Name</div>
                <div className="text-text-dark">Employee Code</div>
                <div className="text-text-dark">Agent Name</div>
                <div
                  className="text-text-dark"
                  style={{ padding: "6px 0 6px 0" }}
                >
                  Amount
                </div>
                <div
                  className="text-text-dark"
                  style={{ padding: "6px 0 6px 0" }}
                >
                  Date
                </div>
                <div>Is Deduct From Employee</div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div className="text-text-light">
                  {selectedList?.employee_name}
                </div>
                <div className="text-text-light">{selectedList?.emp_code}</div>
                <div className="text-text-light">
                  {selectedList?.agent_name}
                </div>
                <div>
                  <input
                    type="number"
                    name="amount"
                    className="w-full"
                    placeholder="Enter Amount"
                    style={{
                      padding: "6px",
                      border: "1px solid #D99719",
                      borderRadius: "4px",
                    }}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={agentCommisssionList?.amount}
                  />

                  {errors.amount ? (
                    <div className="validation-error-label">
                      {errors.amount}
                    </div>
                  ) : null}
                </div>

                <div>
                  <input
                    type="date"
                    name="date"
                    className="w-full"
                    style={{
                      padding: "6px",
                      border: "1px solid #D99719",
                      borderRadius: "4px",
                    }}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={agentCommisssionList?.date}
                  />

                  {errors.date ? (
                    <div className="validation-error-label">{errors.date}</div>
                  ) : null}
                </div>
                <div style={{ display: "flex" }}>
                  {/* <input
                    type="checkbox"
                    name="deduct_from_employee"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  /> */}
                  <Checkbox
                    className="p-0 bg-primary-color"
                    name="deduct_from_employee"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    // checked={
                    //   item?.in_compliance === "1" || item?.in_compliance === 1
                    //     ? true
                    //     : false
                    // }
                  />
                  {errors.deduct_from_employee ? (
                    <div className="validation-error-label">
                      {errors.deduct_from_employee}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <DialogActions>
            <Button
              disabled={buttonDisabled}
              autoFocus
              onClick={() => addCommission()}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default AllCommisionList;
