import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API, GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";

function EditReimbursementScheme() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employementTypeList, setEmployementTypeList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [EditReimbursementSchemeDetails, setEditReimbursementSchemeDetails] =
    useState({
      id: listData?.id,
      employee_id: listData?.employee_id,
      deduction_date: listData?.deduction_date,
      reimbursement_date: listData?.reimbursement_date,
      amount: listData?.amount,
      reason: listData?.remarks,
    });

  useEffect(() => {
    // getEmploymentTypeList();
    getEmploymentList();
  }, []);

  // const getEmploymentTypeList = async () => {
  //   try {
  //     setLoading(true);
  //     const empTypeList = await GET_API("/employeeTypeDropdown");
  //     if (empTypeList?.status === 200) {
  //       const empType = empTypeList?.data?.data.map((dep, i) => {
  //         return { value: dep?.id, label: dep?.name };
  //       });
  //       setEmployementTypeList(empType);

  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     alertify.error(error?.response?.data?.message);
  //   }
  // };

  const getEmploymentList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const emp = employeeList?.data?.data.map((list, i) => {
          return {
            value: list?.id,
            label: `${list?.first_name} (${list?.emp_code})`,
          };
        });
        emp.unshift({ value: "", label: "Select..." });
        setEmployeeList(emp);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get employee details");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditReimbursementSchemeDetails(() => ({
      ...EditReimbursementSchemeDetails,
      [name]: value,
    }));
  };

  const handleSelectChange = (e, name) => {
    setEditReimbursementSchemeDetails(() => ({
      ...EditReimbursementSchemeDetails,
      [name]: e?.value,
    }));
  };

  const saveScheme = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateReimbursementScheme(
      EditReimbursementSchemeDetails
    );
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateReimbursementScheme", EditReimbursementSchemeDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        navigate("/master/hrms/reimbursementScheme");
      })
      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error.response.data.message);
      });
  };

  const resetForm = () => {
    setEditReimbursementSchemeDetails({
      id: listData?.id,
      employee_id: listData?.employee_id,
      deduction_date: listData?.deduction_date,
      reimbursement_date: listData?.reimbursement_date,
      amount: listData?.amount,
      reason: listData?.remarks,
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/reimbursementScheme" className="in-active">
              Reimbursement Scheme List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Reimbursement Scheme</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Reimbursement Scheme</h4>

          <form className="form">
            <div className="field-block">
              <label className="label-name">
                Employee Name
                <span className="required-symbol">*</span>
              </label>
              <Select
                options={employeeList}
                value={employeeList?.find(
                  (option) =>
                    option?.value === EditReimbursementSchemeDetails.employee_id
                )}
                onChange={(e) => handleSelectChange(e, "employee_type_id")}
                name="employee_type_id"
                className=""
                isSearchable
              />
              {errors.employee_type_id ? (
                <div className="validation-error-label">
                  {errors.employee_type_id}
                </div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">
                Reimbursement Date
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field "
                type="date"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="reimbursement_date"
                placeholder="Enter Reimbursement Date"
                value={EditReimbursementSchemeDetails.reimbursement_date}
              />
              {errors.reimbursement_date ? (
                <div className="validation-error-label">
                  {errors.reimbursement_date}
                </div>
              ) : null}
            </div>

            <div className="field-block">
              <label className="label-name">
                Deduction Date
                <span className="required-symbol">*</span>
              </label>
              <input
                name="deduction_date"
                className="input-field"
                type="date"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Enter To Date"
                value={EditReimbursementSchemeDetails?.deduction_date}
              />
              {errors.deduction_date ? (
                <div className="validation-error-label">
                  {errors.deduction_date}
                </div>
              ) : null}
            </div>

            <div className="field-block">
              <label className="label-name">
                amount
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="number"
                name="amount"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Enter Per Day Salary"
                value={EditReimbursementSchemeDetails?.amount}
              />
              {errors.amount ? (
                <div className="validation-error-label">{errors.amount}</div>
              ) : null}
            </div>

            <div className="field-block">
                <label className="label-name">
                  Reason
                  <span className="required-symbol">*</span>
                </label>
                <textarea
                  rows={5}
                  className="input-field"
                  name="reason"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={EditReimbursementSchemeDetails?.reason}
                />
                {errors.reason ? (
                  <div className="validation-error-label">{errors.reason}</div>
                ) : null}
            </div>

          </form>

          <div className="submit-reset-button" onClick={resetForm}>
            <button type="button" className="reset-button">
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveScheme()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditReimbursementScheme;
