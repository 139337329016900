import React, { useEffect, useState } from "react";
import alertify from "alertifyjs";
import Select from "react-select";
import { GET_API } from "../../../Services/api";
import BranchWiseReportTable from "../../DataTable/BranchWiseReportTable";
import Spinner from "../../Spinner";
import dayjs from "dayjs";
import Filter from "../../../Assets/Icons/Filter.svg";

function AbsentReportList() {
  const [isLoading, setIsLoading] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [branchDate, setBranchData] = useState([]);
  const [branchName, setBranchName] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [currentMonth, setCurrentMonth] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [employeeName, setEmployeeName] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [floorList, setFloorList] = useState([]);
  const [isFloorLoading, setIsFloorLoading] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState([]);
  const [filterList, setfilterList] = useState({
    employee_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();

  useEffect(() => {
    const currentMonth = dayjs().month() + 1;
    const currentYear = dayjs().format("YYYY");
    getBranchList();
    setCurrentMonth(`${currentYear}-${currentMonth}`);
  }, []);

  const getBranchList = async () => {
    setIsBranchLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchName(branch[1]);
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      setBranchName("");
      setBranchList([]);
      alertify.error("Failed to Get Branch List");
    }
  };


  const getFloorList = async () => {
    try {
      setIsFloorLoading(true);
      const floorList = await GET_API("/floorDropdown");
      if (floorList?.status === 200) {
        setIsFloorLoading(false);
        const floor = floorList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setFloorList(floor);
      }
    } catch (error) {
      setIsFloorLoading(false);
      alertify.error("Failed to get Floor List...");
    }
  };

  useEffect(() => {
    getBranchWiseReportList(currentPage, pageSize, showFilterResultData);
  }, [branchList?.length, currentPage, pageSize, showFilterResultData]);

  const getBranchWiseReportList = async (page, size, filters) => {
    try {
      setIsLoading(true);
      const branchList = await GET_API(
        `/Branchwiseattendance?year=${dayjs(currentMonth).format(
          "YYYY"
        )}&month=${dayjs(currentMonth).format("MM")}&branch_id=${
          branchName?.value
        }&page=${page}&size=${size}${filters}`
      );
      if (branchList.status === 200) {
        const date = branchList?.data?.dates;
        const branch = branchList?.data?.branchData?.map((item) => {
          return {
            empCode: item?.empCode,
            profile_image: item?.profile_image,
            name: item?.name,
            ...item?.attendance,
          };
        });
        setLastPage(branchList?.data?.employees?.last_page);
        setColumnsData(["S.NO", "User", "Employee Code", "Employee Name", ...date]);
        setBranchData(branch);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setColumnsData([]);
      setBranchData([]);
      alertify.error("Failed to Get Branch Wise Report List");
    }
  };

  useEffect(() => {
    getEmployeeNameList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getFloorList();
  }, []);

  const getEmployeeNameList = async () => {
    setIsEmpLoading(true);
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      setEmployeeName([]);
      alertify.error("Failed to get Employee List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      setEmployeetype([]);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      setDepartment([]);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      setDesignation([]);
      alertify.error("Failed to Get Designation List");
    }
  };

  const handleDateChange = (e) => {
    setCurrentMonth(e.target.value);
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };


  const getFloorID = (e) => {
    let floorID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    return floorID;
  };

  const handleFilter = async (e, selectName) => {
    const { value } = e;

    if (selectName === "floor") {
      var id = getFloorID(e);
      setSelectedFloor(e);
    }

    // filterList[selectName] = value;
    filterList[selectName] = selectName === "floor" ? id : value;

  
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [selectName]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (
    loading ||
    isLoading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4] w-[80%]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Branch Wise Report List</h4>
          <div className="search-block">
            <div className="flex gap-3 justify-center items-center">
              <div className="field-block dropdown-select-to-second">
                <label className="label-name">Branch Name</label>
                <Select
                  options={branchList}
                  value={branchName}
                  onChange={(e) => setBranchName(e)}
                  name="branch_id"
                  className=""
                  isSearchable
                />
              </div>

              <div className="field-block">
                <label className="label-name">Month</label>
                <input
                  type="month"
                  name="date"
                  className="input-field"
                  placeholder="Enter Month"
                  onChange={handleDateChange}
                  value={currentMonth}
                  max={dayjs().format("YYYY-MM")}
                />
              </div>

              <div>
                <button
                  onClick={() =>
                    getBranchWiseReportList(currentPage, pageSize, filterParams)
                  }
                  className="submit-button"
                >
                  <span className="submit-button-label">Search</span>
                </button>
              </div>
            </div>
            <div
              className="filter-button"
              onClick={() => setFilter((prev) => !prev)}
            >
              <span className="filter-btn-label">Filter</span>
              <img src={Filter} alt="" />
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-fourth">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-fourth">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Floor</label>
                  <Select
                    options={floorList}
                    value={selectedFloor ? selectedFloor : []}
                    onChange={(e) => handleFilter(e, "floor")}
                    name="floor"
                    className=""
                    isSearchable
                    isMulti
                  />
                </div>

                <div className="add-new-button-block w-[40%] h-max">
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      showFilterResult(e);
                    }}
                  >
                    <span style={{ cursor: "pointer" }}>Show Result</span>
                  </button>
                </div>
              </div>
            </form>
          )}
          <div className="mx-4">
            <BranchWiseReportTable
              list={branchDate}
              columns={columnsData}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AbsentReportList;
