import React, { forwardRef } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    border: 1px solid #000; /* Add border to table cells */
    padding: 8px; /* Adjust padding as needed */
    text-align: left; /* Align text to the left */
  }

  @media print {
    th, td {
      padding: 3px;
      font-size: 12px;
    }

    thead {
      display: table-header-group;
    }

    tbody {
      display: table-row-group;
    }

    @page {
      margin: 1in;
    }
  }
`;

const TableHeader = styled.thead`
  background: rgb(217, 151, 25);
  color: #fff;
`;

const VoucherFoodAllowanceTable = forwardRef((props, ref) => {
  const row = props?.rows || [];
  const totalAmount = props?.totalAmount;

  return (
    <div ref={ref}>
      <h1 style={{ textAlign: 'center', marginBottom: '10px' }}>
        Food Allowance List - {dayjs(new Date()).format("DD-MM-YYYY")}
      </h1>
      {row.length > 0 ? (
        <Table>
          <TableHeader>
            <tr>
              <th>S.No</th>
              <th>Code</th>
              <th>Name</th>
              <th>Floor</th>
              <th>Allowance Name</th>
              <th>Entry Time</th>
              <th>Exit Time</th>
              <th>Amount</th>              
            </tr>
          </TableHeader>
          <tbody>
            {row.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.empCode || 'N/A'}</td>
                <td>{item?.empName || 'N/A'}</td>
                <td>{item?.floor_name || 'N/A'}</td>
                <td>{item?.allowanceName || 'N/A'}</td>
                <td>{item?.actualEntryTime || 'N/A'}</td>
                <td>{item?.actualExitTime || 'N/A'}</td>
                <td>{item?.amount || 'N/A'}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={7}>Total Amount</td>
              <td>{totalAmount || 'N/A'}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <p>No food allowance lists available.</p>
      )}
    </div>
  );
});

export default VoucherFoodAllowanceTable;
