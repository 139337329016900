import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import SalaryDetailTable from "../../DataTable/SalaryDetailTable";
import Spinner from "../../Spinner";
import Filter from "../../../Assets/Icons/Filter.svg";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import PDFExport from "../../../Assets/Icons/pdf-export.svg";
import Select from "react-select";
import ButtonLoader from "../../ButtonLoader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

function SalaryDetail() {
  const navigate = useNavigate();
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeFilter, setemployeeFilter] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [hragentList, sethragentList] = useState([]);
  const [casteList, setcasteList] = useState([]);
  const [religionList, setreligionList] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filter, setFilter] = useState(false);
  const [exportList, setExportList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [isHrAgentLoading, setIsHrAgentLoading] = useState(false);
  const [isReligionLoading, setIsReligionLoading] = useState(false);
  const [isFloorLoading, setIsFloorLoading] = useState(false);
  const [isShiftLoading, setIsShiftLoading] = useState(false);
  const [isCasteLoading, setIsCasteLoading] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);
  const [downloadPDF, setDownloadPDF] = useState(false);

  const [totalSalary, setTotalSalary] = useState("");
  const [totalDed, setTotalDed] = useState("");
  const [totalPaidSalary, setTotalPaidSalary] = useState("");
  const [totalPfSalary, setTotalPfSalary] = useState("");


  const [totalBankAdvance, setTotalBankAdvance] = useState("");
  const [totalBankLoan, setTotalBankLoan] = useState("");
  const [totalCashAdvance, setTotalCashAdvance] = useState("");
  const [totalCashLoan, setTotalCashLoan] = useState("");
  const [totalFoodAllowanceAmt, setTotalFoodAllowanceAmt] = useState("");

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    from_date: "",
    to_date: "",
    employee_id: "",
    shift: "",
    agent: "",
    caste: "",
    religion: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();

  const header = [
    { id: "s.no", label: "S.No" },
    { id: "user", label: "User" },
    { id: "emp_code", label: "Code" },
    { id: "first_name", label: "Name" },

    // { id: "amount", label: "Total Amount" },
    // { id: "amount", label: "Deduction Amount" },

    { id: "amount", label: "Bank Salary" },
    { id: "bank_advance", label: "Bank Advance" },
    { id: "bank_loan", label: "Bank Loan" },

    { id: "amount", label: "Cash Salary" },
    { id: "cash_advance", label: "Cash Advance" },
    { id: "cash_loan", label: "Cash Loan" },
    // { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getEmployeeNameList();
    getShiftList();
    getFloorList();
    getHRAgentList();
    getCasteList();
    getReligionList();
    
  }, []);

  useEffect(() => {
    getEmployeeList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const getEmployeeList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const employeeList = await GET_API(
        `/salaryReport?page=${page}&size=${size}${filters}&search=${search}`
      );

      if (employeeList.status === 200) {
        if (
          employeeList?.data?.data?.current_page >
          employeeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }


        setTotalSalary(employeeList?.data?.totalSalarySum);
        setTotalDed(employeeList?.data?.totalDedSum);
        setTotalPaidSalary(employeeList?.data?.totalFinalSalSum);
        setTotalPfSalary(employeeList?.data?.totalFinalPfSalSum);

        setTotalBankAdvance(employeeList?.data?.totalBankAdvance);
        setTotalBankLoan(employeeList?.data?.totalBankLoan);
        setTotalCashAdvance(employeeList?.data?.totalCashAdvance);
        setTotalCashLoan(employeeList?.data?.totalCashLoan);

        setTotalFoodAllowanceAmt(employeeList?.data?.totalFoodAllowanceAmt);


        setLoading(false);
        const employeeListArr = [];
        employeeList?.data?.data?.data?.map((item) => {
          return employeeListArr.push(item);
        });
        setLastPage(employeeList?.data?.data?.last_page);
        setEmployeeList(employeeListArr);
        setemployeeFilter(employeeListArr);
        getexportData(employeeListArr);
        if (
          employeeList?.data?.data?.current_page >
          employeeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
    }
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getEmployeeList(1, pageSize, filterParams, searchKey);
  };

  const getexportData = (data) => {
    const attributesToRemove = ["empId", "EmployeeshiftId"];
    const updatedList = data.map((obj) => {
      const updatedObj = { ...obj };
      attributesToRemove.forEach((attr) => delete updatedObj[attr]);
      return updatedObj;
    });
    setExportList(updatedList);
  };

  const getBranchList = async () => {
    setIsBranchLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      setBranchList([]);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      setEmployeetype([]);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getEmployeeNameList = async () => {
    setIsEmpLoading(true);
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      setEmployeeName([]);
      alertify.error("Failed to get Employee List");
    }
  };

  const getShiftList = async () => {
    setIsShiftLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList.status === 200) {
        const floor = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setShiftList(floor);
        setIsShiftLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Shift List");
      setIsShiftLoading(false);
      setShiftList([]);
    }
  };

  const getHRAgentList = async () => {
    setIsHrAgentLoading(true);
    try {
      const HRAgent = await GET_API("/agentDropdown/security_agent");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select..." });
        sethragentList(agent);
        setIsHrAgentLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get HR Agent List...");
      setIsHrAgentLoading(false);
      sethragentList([]);
    }
  };

  const getCasteList = async () => {
    setIsCasteLoading(true);
    try {
      const casteList = await GET_API("/casteDropdown");
      if (casteList?.data?.status === 200) {
        setIsCasteLoading(false);
        const cast = casteList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        cast.unshift({ value: "", label: "Select..." });
        setcasteList(cast);
      }
    } catch (error) {
      alertify?.error("Failed to get Caste List");
      setIsCasteLoading(false);
      setcasteList([]);
    }
  };

  const getReligionList = async () => {
    setIsReligionLoading(true);
    try {
      const religionList = await GET_API("/religionDropdown");
      if (religionList?.data?.status === 200) {
        const religion = religionList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        religion.unshift({ value: "", label: "Select..." });
        setreligionList(religion);
        setIsReligionLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Religion List");
      setIsReligionLoading(false);
      setreligionList([]);
    }
  };

  const getFloorList = async () => {
    setIsFloorLoading(true);
    try {
      const getfloorList = await GET_API("/floorDropdown");
      if (getfloorList?.data?.status === 200) {
        const floorList = getfloorList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floorList.unshift({ value: "", label: "Select..." });
        setFloorList(floorList);
        setIsFloorLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Floor List");
      setIsFloorLoading(false);
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      setDepartment([]);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      setDesignation([]);
      alertify.error("Failed to Get Designation List");
    }
  };

 
  const exportData = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-salaryReport?page=${currentPage}&size=${pageSize}${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "payrollReport.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const handleExportChange = (event) => {
    const value = event.target.value;

    if (value === 'excel') {
      setIsExportLoading(true);
      exportData(); // Call your export function
    } else if (value === 'pdf') {
      setIsExportPdfLoading(true);
      exportAsPDF(); // Call your export function
    }
  };

  const exportAsPDF = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(
        `salaryReportPdf?page=${currentPage}&size=${pageSize}${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "salaryReport.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };


  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const employeePFTypeList = [
    { label: "Please Select", value: "" },
    { label: "PF Employee", value: 1 },
    { label: "Non PF Employee", value: 0 },
  ];

  const handleFieldChange = (e) => {
    const month = dayjs(e).format("MM");
    const year = dayjs(e).format("YYYY");
    filterList["year"] = year;
    filterList["month"] = month;
    const list = { ...filterList };
    handleFilter(list, "");
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const showDetails = (listDetail) => {
    const id = listDetail?.employee_id;
    navigate(`/report/employee/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isCasteLoading ||
    isReligionLoading ||
    isHrAgentLoading ||
    isShiftLoading ||
    isDesignationLoading ||
    isDepartmentLoading ||
    isEmpTypeLoading ||
    isFloorLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <div className="card-wrapper">
            <h4 className="card-header">Salary Details</h4>
            <form
              onSubmit={() =>
                getEmployeeList(currentPage, pageSize, filterParams, searchKey)
              }
            >
              <div className="search-block">
                <div className="search">
                  <input
                    type="text"
                    className="search-input-box"
                    placeholder="Search..."
                    autoComplete="off"
                    onChange={(e) => setSearchKey(e?.target?.value)}
                    style={{ outline: "none", border: "none" }}
                    value={searchKey}
                  />

                  <button type="submit">
                    <img src={Search} alt="" className="cursor-pointer" />
                  </button>
                </div>

                <div className="flex gap-3">
                  <div
                    className="filter-button"
                    onClick={() => setFilter((prev) => !prev)}
                  >
                    <span className="filter-btn-label">Filter</span>
                    <img src={Filter} alt="" />
                  </div>

                  <div className="export-dropdown dropdown-select-to-second">
                    <select onChange={handleExportChange} defaultValue="" style={{ backgroundColor: 'rgb(217 151 25)', padding: '10px', borderRadius: '5px' }}>
                      <option value="" disabled>Select Export Type</option>
                      <option value="excel">
                        {isExportLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as Excel
                            <img src={ExcelExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                      <option value="pdf">
                        {isExportPdfLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as PDF
                            <img src={PDFExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                    </select>
                </div>

                </div>
              </div>
            </form>

            {filter && (
              <form>
                <div className="form">
                  
                  <div className="field-block">
                    <label className="label-name">Month</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker", "DatePicker", "DatePicker"]}
                      >
                        <DatePicker
                          label={'"month" and "year"'}
                          views={["month", "year"]}
                          name="month"
                          className="custom-input-field"
                          onChange={(e) => {
                            handleFieldChange(e);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="field-block flex justify-center">
                    <div className="add-new-button-block w-[40%] h-max">
                      <button
                        className="add-new-button"
                        onClick={(e) => {
                          showFilterResult(e);
                        }}
                      >
                        <span style={{ cursor: "pointer" }}>Show Result</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}

            <SalaryDetailTable
              list={employeeList}
              totalSalary={totalSalary}
              totalDed={totalDed}
              totalPaidSalary={totalPaidSalary}
              totalPfSalary={totalPfSalary}
              totalBankAdvance={totalBankAdvance}
              totalBankLoan={totalBankLoan}
              totalCashAdvance={totalCashAdvance}
              totalCashLoan={totalCashLoan}
              totalFoodAllowanceAmt={totalFoodAllowanceAmt}
              columns={header}
              icon={More}
              showDetails={showDetails}
              activeEdit={false}
              activeDelete={false}
              multiple={true}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage ? lastPage : 1}
              currentPage={currentPage}
            />

          </div>
        </div>
      </div>
    </div>
  );
}

export default SalaryDetail;
