import React from "react";
import { Route } from "react-router-dom";
import CreateEmployee from "../Employee/createEmployee";
import EmployeeList from "../Employee/AllemployeesList";
import EditEmployee from "../Employee/EmployeeEdit";
import EmployeeDetails from "../Employee/EmployeeDetails";
import EmployeeAllowance from "../Employee/EmployeeAllowance";
import AddFoodAllowance from "../Employee/AddFoodAllowance";
import RejoinEmployee from "../Employee/RejoinEmployee/RejoinEmployee";
import EmployeeIDCardPreview from "../Employee/IDCard";

function EmployeeManagementRoutes() {
  return (
    <>
      <Route path="list" element={<EmployeeList />}></Route>
      <Route path="add" element={<CreateEmployee />}></Route>
      <Route path="edit/:id" element={<EditEmployee />}></Route>
      <Route path="details/:id" element={<EmployeeDetails />}></Route>
      <Route path="allowance/:id" element={<EmployeeAllowance />}></Route>
      <Route path="allowance/create/:id" element={<AddFoodAllowance />}></Route>
      <Route path="rejoin" element={<RejoinEmployee />}></Route>
      <Route
        path="/employee/idCard/preview/:id"
        element={<EmployeeIDCardPreview />}
      ></Route>
    </>
  );
}

export default EmployeeManagementRoutes;
