import React from "react";
import { Route } from "react-router-dom";

import MastersApproval from "../Approval/Masters/Approval";

import EmployeeApproval from "../Approval/EmployeeApproval";

import MastersLog from "../Approval/Masters/LogDetails";

import BonusApproval from "../Approval/BonusApproval/BonusApproval";

import ExpenseApproval from "../Approval/ExpenseApproval/ExpenseApproval";

import FinanceApproval from "../Approval/FinanceApproval/FinanceApproval";

import LeaveApproval from "../Approval/LeaveApproval/LeaveApproval";

import LateEntryApproval from "../Approval/LateEntryApproval/LateEntryApproval";

import EarlyExitApproval from "../Approval/LateEntryApproval/EarlyExitApproval";

import EarlyExitApprovalDetails from "../Approval/LateEntryApproval/EarlyExitApprovalDetails";

import ManualAttendanceApproval from "../Approval/ManualAttendanceApproval";

import AttendanceStatusApproval from "../Approval/AttendanceStatusApproval";

import UserApproval from "../Approval/UserApproval";

import LoanSkipCarryApproval from "../Approval/LoanSkipCarryApproval";

function ApproveRoutes() {
  return (
    <>
      <Route path="employee" element={<EmployeeApproval />}></Route>

      <Route path="masters" element={<MastersApproval />}></Route>

      <Route path="masters/log/:id" element={<MastersLog />}></Route>

      <Route path="bonus" element={<BonusApproval />}></Route>

      <Route path="expense" element={<ExpenseApproval />}></Route>

      <Route path="finance" element={<FinanceApproval />}></Route>

      <Route path="leave" element={<LeaveApproval />}></Route>

      <Route path="lateentry" element={<LateEntryApproval />}></Route>

      <Route path="earlyExit" element={<EarlyExitApproval />}></Route>

      <Route
        path="earlyExit/details/:id"
        element={<EarlyExitApprovalDetails />}
      ></Route>

      <Route
        path="manualAttendance"
        element={<ManualAttendanceApproval />}
      ></Route>

      <Route
        path="attendanceStatus"
        element={<AttendanceStatusApproval />}
      ></Route>

      <Route
        path="user"
        element={<UserApproval />}
      ></Route>

      <Route
        path="loan-skip-carry"
        element={<LoanSkipCarryApproval />}
      ></Route>

    </>
  );
}

export default ApproveRoutes;
