import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GET_API, BASE_URL, BASE_PATH } from "../../Services/api";
import Spinner from "../Spinner";
import AgentCommActualReportTable from "../DataTable/AgentCommActualReportTable";
import alertify from "alertifyjs";
import { Link, useNavigate } from "react-router-dom";
import ButtonLoader from "../ButtonLoader";
import ExcelExport from "../../Assets/Icons/excel-export.svg";
import PDFExport from "../../Assets/Icons/pdf-export.svg";

function AgentCommActualReport() {
  const navigate = useNavigate();
  const [agentList, setAgentList] = useState([]);
  const [agentCode, setAgentCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [agentCommData, setAgentCommData] = useState([]);
  const [overallData, setOverallData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [selectAgentID, setSelectAgentID] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);

  const columns = [
    { id: "s.no", label: "S.No", sortable: false },
    { id: "employeeCode", label: "Agent Name", sortable: true },
    { id: "companyAmount", label: "Type", sortable: false },
    { id: "employeeName", label: "Amount", sortable: true },
    { id: "companyAmount", label: "Paid Amount", sortable: false },
    { id: "companyAmount", label: "Date", sortable: false },
    { id: "btn", label: "", sortable: false },
  ];

  const [sorting, setSorting] = useState({
    orderBy: "",
    orderByType: "asc",
  });

  const handleSort = (columnId, sortable) => {
    if (!sortable) return;
    const isAsc =
      sorting.orderBy === columnId && sorting.orderByType === "desc";
    const newOrderByType = isAsc ? "asc" : "desc";
    getData(selectAgentID, currentPage, pageSize, {
      orderBy: columnId,
      orderByType: newOrderByType,
    });
    setSorting({
      orderBy: columnId,
      orderByType: newOrderByType,
    });
  };

  useEffect(() => {
    getAgentList();
  }, []);

  useEffect(() => {
    getData(selectAgentID, currentPage, pageSize);
  }, [pageSize, currentPage]);

  const getEmployeeAgentCommActualReport = async (e) => {
    const { value } = e;
    setSelectAgentID(value);
    setLoading(true);
    getData(value, currentPage, pageSize);
  };

  const getData = async (value, currentPage, pageSize, sort) => {
    const sorting =
      sort?.orderBy && sort?.orderByType
        ? `&orderBy=${sort?.orderBy}&orderByType=${sort?.orderByType}`
        : "";
    if (value === "") return;
    try {
      const getDetails = await GET_API(
        `agentCommissionActualReport/${value}?page=${currentPage}&size=${pageSize}${sorting}`
      );
      if (getDetails?.data?.status === 200) {
        setAgentCommData(getDetails?.data?.data?.data);
        setOverallData(getDetails?.data?.overallData);
        setLastPage(getDetails?.data?.data?.last_page);
        setLoading(false);
        setAgentCode(value);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get consolidate details");
    }
  };

  const getAgentList = async () => {
    setLoading(true);
    GET_API("/agentDropdown/consultancy")
      .then(async function (data) {
        const empList = data?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.company_name}`,
          };
        });
        setAgentList(empList);
        setLoading(false);
      })

      .catch((error) => {
        setAgentList([]);
        setLoading(false);
      });
  };

  const navigateDetailsPage = (listDetail) => {
    console.log("rowwwwwwwwwww", listDetail);
    navigate("/report/agentCommActual/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const exportAsExcel = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-actualagentCommission/${selectAgentID}?page=${currentPage}&size=${pageSize}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Agent Commsion Creation Report.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const exportasPDF = async () => {
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(
        `agentCommissionActualPdf/${selectAgentID}?page=${currentPage}&size=${pageSize}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "Agent Commsion Creation Report.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow w-0">
      <div className="sm-container">
        <div className="card-wrapper" style={{ minHeight: "100%" }}>
          <h4 className="card-header">Agent Commission Actual Report</h4>
          <div className="search-block">
            <div className="field-block dropdown-select-to-third">
              <label className="label-name">Select Agent</label>
              <Select
                options={agentList}
                value={agentList?.find((option) => option?.value === agentCode)}
                onChange={(e) =>
                  getEmployeeAgentCommActualReport(e, "agentCode")
                }
                name="agentCode"
                className=""
                isSearchable
              />
            </div>

            <div className="flex gap-3">
              <div
                className="add-new-button-block"
                style={{ whiteSpace: "nowrap" }}
              >
                {isExportLoading ? (
                  <ButtonLoader isBtnDisable={isExportLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => exportAsExcel()}
                  >
                    <span>Export as Excel</span>
                    <img
                      src={ExcelExport}
                      style={{ height: "25px" }}
                      alt="export"
                    />
                  </button>
                )}
              </div>

              <div
                className="add-new-button-block"
                style={{ whiteSpace: "nowrap" }}
              >
                {isExportPdfLoading ? (
                  <ButtonLoader isBtnDisable={isExportPdfLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => exportasPDF()}
                  >
                    <span>Export as PDF</span>
                    <img
                      src={PDFExport}
                      style={{ height: "25px" }}
                      alt="export"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="px-5 py-5 pt-0">
            <h2 className="text-xl font-karla">
              Total Pending Amount : <b>{overallData}</b>
            </h2>
          </div>
          <div className="px-5">
            <AgentCommActualReportTable
              list={agentCommData}
              columns={columns}
              pageSize={pageSize}
              currentPage={currentPage}
              setPageNo={setCurrentPage}
              setPageSize={setPageSize}
              lastPage={lastPage}
              navigateDetailsPage={navigateDetailsPage}
              sorting={sorting}
              handleSort={handleSort}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentCommActualReport;
