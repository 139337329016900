import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const PolarChart = ({ data }) => {
  const [labels, setLabels] = useState([]);
  const [value, setValue] = useState([]);
  useEffect(() => {
    const labelsArr = [];
    const valueArr = [];
    data.map((chartData) => {
      labelsArr.push(chartData?.employee_type_name);
      valueArr.push(chartData?.count);
      setLabels(labelsArr);
      setValue(valueArr);
    });
  }, [data]);

  const series = value;
  const options = {
    chart: {
      type: "polarArea",
    },
    labels: labels,
  };

  return (
    <div className="polar-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="polarArea"
        height={350}
      />
    </div>
  );
};

export default PolarChart;
