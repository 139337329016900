import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GET_API, BASE_PATH, BASE_URL } from "../../Services/api";
import Spinner from "../Spinner";
import ConsolidateAttendanceReportTable from "../DataTable/ConsolidateAttendanceReportTable";
import alertify from "alertifyjs";

function ConsolidateAttendanceReport() {
  const [employeeList, setEmployeeList] = useState([]);
  const [empCode, setEmpCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [columnData, setColumnsData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isExportLoading, setIsExportLoading] = useState(false);
  useEffect(() => {
    getEmployeeList();
  }, []);

  const getEmployeeConsolidateAttendanceReport = async (e, name) => {
    const { value } = e;
    setLoading(true);
    try {
      const getDetails = await GET_API(
        `consolidatedAttendance?employee_id=${value}`
      );
      if (getDetails?.data?.status === 200) {
        const conAttendance = getDetails?.data?.data?.map((item) => {
          return {
            month: item?.month,
            ...item?.attendance,
          };
        });
        const date = getDetails?.data?.dates;
        const data = conAttendance;
        setColumnsData(["S.NO", "Month", ...date]);
        setAttendanceData(data);
        setLoading(false);
        setEmpCode(value);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get consolidate details");
    }
  };

  const getEmployeeList = async () => {
    setLoading(true);
    GET_API("/employeeDropdown")
      .then(async function (data) {
        const empList = data?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        setEmployeeList(empList);
        setLoading(false);
      })

      .catch((error) => {
        setEmployeeList([]);
        setLoading(false);
      });
  };

  // const getExport = async () => {
  //   try {
  //     setIsExportLoading(true);
  //     const downloadForm = await GET_API(`${BASE_URL}export-attendance-list`);
  //     if (downloadForm?.data?.status === 200) {
  //       const alink = document.createElement("a");
  //       alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
  //       alink.setAttribute("target", "_blank");
  //       alink.download = "Attendance.xlsx";
  //       alink.click();
  //       setIsExportLoading(false);
  //     }
  //   } catch (error) {
  //     setIsExportLoading(false);
  //   }
  // };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow w-0">
      <div className="sm-container">
        <h1 className="heading-text">Report</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Consolidate Attendance</h4>
          <div className="search-block">
            <div className="field-block dropdown-select-to-third">
              <label className="label-name">Select Employee</label>
              <Select
                options={employeeList}
                value={employeeList?.find(
                  (option) => option?.value === empCode
                )}
                onChange={(e) =>
                  getEmployeeConsolidateAttendanceReport(e, "empCode")
                }
                name="empCode"
                className=""
                isSearchable
              />
            </div>
            {/* <div className="add-new-button-block">
              {isExportLoading ? <ButtonLoader isBtnDisable={isExportLoading} /> :
                <button className="add-new-button" onClick={() => getExport()}>
                  <span>Export</span>
                  <img src={Export} alt="export" />
                </button>}
            </div> */}
          </div>
          <div className="px-5">
            <ConsolidateAttendanceReportTable
              list={attendanceData}
              columns={columnData}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsolidateAttendanceReport;
