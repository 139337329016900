import React, { useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import config from "../../Services/config.json";
import LoanPrintVoucher from '../Finance/Loan/LoanPrintVoucher'; // Import LoanPrintVoucher to handle printing

export default function LoanTable({
  list,
  totalAmount,
  showDetails,
  columns,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const componentRef = useRef();

  // Function to handle checkbox change for individual rows
  const handleCheckboxChange = (event, rowId) => {
    if (event.target.checked) {
      setSelectedRows((prevSelected) => [...prevSelected, rowId]);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => id !== rowId)
      );
    }
  };

  // Function to handle select all checkbox
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(list.map((row) => row.id)); // Assuming 'id' is the unique identifier for each row
    } else {
      setSelectedRows([]);
    }
  };

  // Handle Print button click
  const handlePrint = () => {
    // Filter the selected rows based on selected row IDs
    const selectedData = list.filter((row) => selectedRows.includes(row.id));

    // If selected data exists, pass it to LoanPrintVoucher for printing
    if (selectedData.length > 0) {
      componentRef.current.printVoucherData(selectedData);
    } else {
      alert("Please select at least one row to print.");
    }
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 320 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 && selectedRows.length < list.length
                    }
                    checked={selectedRows.length === list.length}
                    onChange={handleSelectAll}
                  />
                </TableCell> */}
                {columns.map((column, i) => (
                  <TableCell
                    className="table-header"
                    key={i}
                    align={column.align}
                  >
                    {column?.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan="8"
                    className="table-body td-data-not-found h-[220px]"
                  >
                    Data Not Found..!
                  </TableCell>
                </TableRow>
              ) : (
                list?.map((row, i) => {
                  const father_name = row?.fathers_name ? row?.fathers_name : "";
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className="table-column"
                      style={{
                        background:
                          row.print_status === 0 ? "#ffffff" : "#f3d9ac",
                      }}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.includes(row.id)} // Check if the row is selected
                          onChange={(event) => handleCheckboxChange(event, row.id)}
                        />
                      </TableCell> */}
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                      <TableCell className="!w-[7%] table-body">
                        {row?.profile_image !== "" ? (
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "40px",
                            }}
                            src={config?.path + row?.employee_image}
                            alt="profile_image"
                          />
                        ) : (
                          <div
                            className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                            style={{ backgroundColor: "#ccc" }}
                          >
                            {row?.first_name?.charAt(0).toUpperCase()}
                          </div>
                        )}
                      </TableCell>
                      <TableCell
                        className="table-body"
                        onClick={() => showDetails(row)}
                      >
                        <span
                          className="text-sky-600 border-b-[1px] border-b-[#0284C7] cursor-pointer"
                          style={{ color: "#0284C7" }}
                        >
                          {row.EmployeeCode}
                        </span>
                      </TableCell>
                      <TableCell className="table-body">
                        {row?.employee_name + " " + father_name}
                      </TableCell>
                      <TableCell className="table-body">{row?.payment_type}</TableCell>
                      <TableCell className="table-body loan-advance-expence-table">
                        {row?.finance_date &&
                          new Date(row?.finance_date)
                            .toLocaleDateString("en-GB")
                            .replace(/\//g, "-")}
                      </TableCell>
                      <TableCell className="table-body loan-advance-expence-table">
                        {row.amount}
                      </TableCell>
                      <TableCell className="table-body loan-advance-expence-table">
                        {row.paid_amount}
                      </TableCell>
                      <TableCell className="table-body loan-advance-expence-table">
                        {row.amount - row.paid_amount}
                      </TableCell>
                      <TableCell className="table-body">
                        {row.voucher_status === 1 ? (
                          <span style={{ color: "#3EAB82" }}>Paid</span>
                        ) : (
                          <span style={{ color: "#ed452a" }}>Unpaid</span>
                        )}
                      </TableCell>
                      <TableCell className="table-body">
                        {row?.status === 0 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Approved
                          </span>
                        ) : row?.status === 3 ? (
                          <span
                            className="bg-[#EEC9C9] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Pending
                          </span>
                        ) : row?.status === 2 ? (
                          <span
                            className="bg-[#ed452a] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Rejected
                          </span>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="grid grid-cols-6 p-2 bg-white-color">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div className="text-text-dark font-karla text-end">Total Amount</div>
          <div className="text-center text-text-dark font-karla">
            {totalAmount}
          </div>
        </div>
        <Pagination
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          lastPage={lastPage}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </Paper>
     
    </>
  );
}
