import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../../MoreOption";
import Pagination from "../Pagination";

export default function StickyHeadTable({
  list,
  columns,
  icon,
  editStatus,
  editList,
  multiple,
  deleteRow,
  pagination,
  setState,
  setSearch,
  editPage,
  showDetails,
  multiplePage,
}) {
  const [currentPage, setCurrentPage] = React.useState(1);
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={1}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body">
                        {row?.productname}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.hsncode}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.quantity}
                      </TableCell>
                    }
                    {<TableCell className="table-body">{row?.uom}</TableCell>}
                    {
                      <TableCell className="table-body">
                        {row?.createdat}
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
