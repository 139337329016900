import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Save from "../../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../../Validation/POValidation";
import { GET_API, PUT_API } from "../../../../Services/api";
import Select from "react-select";
import Spinner from "../../../Spinner";

function EditWarehouse() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);

  const { state } = useLocation();
  const id = state?.list;
  const [errors, seterrors] = useState({});
  const [editWarehouseDetails, setEditWarehouseDetails] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setwarehouselist();
  }, []);

  const setwarehouselist = async () => {
    setLoading2(true);
    try {
      const warehouseData = await GET_API(`po/getWarehouse/${id}`);
      if (warehouseData?.data?.status === 200) {
        const listData = warehouseData?.data?.data;
        setEditWarehouseDetails({
          id: listData?.id,
          name: listData?.name,
          department: listData?.department,
          description: listData?.description,
          landline_no: listData?.landline_no,
          address: listData?.address,
          contact_person_name: listData?.contact_person_name,
          contact_person_mobile: listData?.contact_person_mobile,
          contact_person_email: listData?.contact_person_email,
        });
        setLoading2(false);
        getDepartmentList(listData?.department);
      }
    } catch (error) {
      setLoading2(false);
      alertify?.error("Failed to get warehouse details");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditWarehouseDetails(() => ({ ...editWarehouseDetails, [name]: value }));
  };

  const updateWarehouse = async (e) => {
    e?.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.Warehouse(editWarehouseDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const updateWarehouse = await PUT_API(
        "/po/updateWarehouse",
        editWarehouseDetails
      );
      if (updateWarehouse?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(updateWarehouse?.data?.message);
        navigate("/master/po/warehouse/");
      } else {
        alertify.error(updateWarehouse?.data?.message);
        setButtonDisabled(false);
      }
    } catch (e) {
      setButtonDisabled(false);
      for (const key in e?.response?.data?.data) {
        if (e?.response?.data?.data.hasOwnProperty(key)) {
          e?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };

  const getDepartmentList = async (deptID) => {
    setLoading(true);
    try {
      const department = await GET_API("po/poDepartmentDropdown");
      if (department?.data?.status === 200) {
        const deptList = department?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        deptList.unshift({ value: "", label: "Select..." });
        setDepartmentList(deptList);
        setDepartment(deptList, deptID);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get department list...");
    }
  };

  const setDepartment = (list, id) => {
    const values = [];
    list?.map((dept) => {
      if (id.includes(dept?.value)) {
        return values.push(dept);
      }
    });
    setSelectedDept(values);
  };

  const handleSelectChange = (e) => {
    setSelectedDept(e);
    let deptID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    setEditWarehouseDetails(() => ({
      ...editWarehouseDetails,
      department: deptID,
    }));
  };

  const resetForm = () => {
    setEditWarehouseDetails({
      id: editWarehouseDetails?.id,
      name: editWarehouseDetails?.name,
      department: editWarehouseDetails?.department,
      description: editWarehouseDetails?.description,
      landline_no: editWarehouseDetails?.landline_no,
      address: editWarehouseDetails?.address,
      contact_person_name: editWarehouseDetails?.contact_person_name,
      contact_person_mobile: editWarehouseDetails?.contact_person_mobile,
      contact_person_email: editWarehouseDetails?.contact_person_email,
    });
  };

  if (loading || loading2) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/po/warehouse" className="in-active">
              Warehouse List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Warehouse</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Warehouse</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => updateWarehouse(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Warehouse Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Warehouse Name"
                  value={editWarehouseDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Department
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={departmentList}
                  value={selectedDept ? selectedDept : []}
                  onChange={(e) => handleSelectChange(e)}
                  isMulti
                />
                {errors.code ? (
                  <div className="validation-error-label">{errors.code}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Person
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person_name"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Contact Person Name"
                  value={editWarehouseDetails?.contact_person_name}
                />
                {errors.contact_person_name ? (
                  <div className="validation-error-label">
                    {errors.contact_person_name}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">LandLine Number</label>
                <input
                  className="input-field"
                  type="text"
                  name="landline_no"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter LandLine Number"
                  value={editWarehouseDetails?.landline_no}
                />
                {errors.landline_no ? (
                  <div className="validation-error-label">
                    {errors.landline_no}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Number
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person_mobile"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Contact Number"
                  value={editWarehouseDetails?.contact_person_mobile}
                />
                {errors.contact_person_mobile ? (
                  <div className="validation-error-label">
                    {errors.contact_person_mobile}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Email
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person_email"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Email"
                  value={editWarehouseDetails?.contact_person_email}
                />
                {errors.contact_person_email ? (
                  <div className="validation-error-label">
                    {errors.contact_person_email}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Address</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="address"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Address"
                  value={editWarehouseDetails?.address}
                />
                {errors.address ? (
                  <div className="validation-error-label">{errors.address}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="description"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Description"
                  value={editWarehouseDetails?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Save</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditWarehouse;
