import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import dayjs from "dayjs";
import config from "../../Services/config.json";
import { Link } from "react-router-dom";

export default function StickyHeadTable({
  list,
  columns,
  totalPerDaySalarySum,
  totalPerDayBattaSum,
  totalPerDayPetrolSum,
  totalPerDayAutoSum,
  totalPerDayHRASum,
  totalFoodAllow,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                      {
                                          <TableCell className="table-body !w-[7%]">
                                            {(currentPage - 1) * pageSize + (i + 1)}
                                          </TableCell>
                                        }
                    {
                      <TableCell className="!w-[7%] table-body ">
                        {row?.profile_image !== "" ? (
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "40px",
                            }}
                            src={config?.path + row?.profile_image}
                            alt="profile_image"
                          />
                        ) : (
                          <div
                            className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                            style={{ backgroundColor: "#ccc" }}
                          >
                            {row?.first_name?.charAt(0).toUpperCase()}
                          </div>
                        )}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <Link
                          target="_blank"
                          to={`/employee/details/${row?.employee_Id}`}
                          style={{ color: "#0284C7" }}
                          className="text-sky-600 border-b-[1px] border-b-[#0284C7]"
                        >
                          {row.emp_code}
                        </Link>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.first_name}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        <b>{row?.departmentname}</b>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        <b>{row?.designationname}</b>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        <b>{row?.floorname}</b>
                      </TableCell>
                    }
                    
                    {/* {
                      <TableCell className="table-body">
                        <b>{row?.amount}</b>
                      </TableCell>
                    } */}

                    {
                      <TableCell className="table-body">
                        <b>{row?.per_day_salary}</b>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        <b>{row?.batta}</b>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        <b>{row?.petrol}</b>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        <b>{row?.auto}</b>
                      </TableCell>
                    } 

                    {
                      <TableCell className="table-body">
                        <b>{row?.hra}</b>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        <b>{row?.food_allowance}</b>
                      </TableCell>
                    }


                    {/* <MoreOption
                      icon={icon}
                      row={row}
                      showDetails={showDetails}
                      multiple={multiple}
                      deleteRow={deleteRow}
                      editPage={editPage}
                      allowance={allowance}
                      termination={termination}
                      terminateEmployee={terminateEmployee}
                      activeEdit={activeEdit}
                      activeDelete={activeDelete}
                      foodAllowance={foodAllowance}
                      download={download}
                      downloadEmployeeForm={downloadEmployeeForm}
                      isEnableIDCard={isEnableIDCard}
                      idCardPreviewPage={idCardPreviewPage}
                    /> */}
                  </TableRow>
                );
              })
            )}
            <TableRow style={{height: "75px"}}>
                <TableCell colSpan={6} className="table-body"></TableCell>
                <TableCell className="table-body"><b style={{ fontSize: '20px' }}>Total</b></TableCell>
                <TableCell className="table-body">
                    <b style={{ color: '#d99719', fontSize: '20px' }}>{totalPerDaySalarySum}</b>
                </TableCell>
                <TableCell className="table-body">
                    <b style={{ color: '#d99719', fontSize: '20px' }}>{totalPerDayBattaSum}</b>
                </TableCell>
                <TableCell className="table-body">
                    <b style={{ color: '#d99719', fontSize: '20px' }}>{totalPerDayPetrolSum}</b>
                </TableCell>
                <TableCell className="table-body">
                    <b style={{ color: '#d99719', fontSize: '20px' }}>{totalPerDayAutoSum}</b>
                </TableCell>
                <TableCell className="table-body">
                    <b style={{ color: '#d99719', fontSize: '20px' }}>{totalPerDayHRASum}</b>
                </TableCell>
                <TableCell className="table-body">
                    <b style={{ color: '#d99719', fontSize: '20px' }}>{totalFoodAllow}</b>
                </TableCell>
            </TableRow>
          </TableBody>
        </Table>

       
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
