import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import Select from "react-select";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";

function CreateSeasonalSalary() {
  const navigate = useNavigate();

  useEffect(() => {
    getEmploymentTypeList();
  }, []);

  const [seasonalSalaryDetails, setbranchDetails] = useState({
    employee_type_id: "",
    from_date: "",
    to_date: "",
    remarks: "",
    per_day_salary: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employementTypeList, setEmployementTypeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmploymentTypeList = async () => {
    setLoading(true);
    GET_API("/employeeTypeDropdown")
      .then(async function (data) {
        const empType = data?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        empType.unshift({ value: "", label: "Select..." });
        setEmployementTypeList(empType);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setbranchDetails(() => ({ ...seasonalSalaryDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setbranchDetails(() => ({ ...seasonalSalaryDetails, [name]: e?.value }));
  };

  const createSeasonalSalary = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateSeasonalSalary(seasonalSalaryDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/addSeasonalSalary", seasonalSalaryDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        navigate("/master/hrms/seasonalsalary");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const resetForm = () => {
    setbranchDetails({
      employee_type_id: "",
      from_date: "",
      to_date: "",
      per_day_salary: "",
      remarks: "",
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/seasonalsalary" className="in-active">
              Seasonal Salary List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Seasonal Salary</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Seasonal Salary</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createSeasonalSalary(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Type
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={employementTypeList}
                  value={employementTypeList?.find(
                    (option) =>
                      option?.value === seasonalSalaryDetails.employee_type_id
                  )}
                  onChange={(e) => handleSelectChange(e, "employee_type_id")}
                  name="employee_type_id"
                  placeholder="Please select"
                  className=""
                  isSearchable
                />
                {errors.employee_type_id ? (
                  <div className="validation-error-label">
                    {errors.employee_type_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  From Date
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="date"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="from_date"
                  placeholder="Enter From Date"
                  value={seasonalSalaryDetails.from_date}
                />
                {errors.from_date ? (
                  <div className="validation-error-label">
                    {errors.from_date}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  To Date
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="to_date"
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter To Date"
                  value={seasonalSalaryDetails?.to_date}
                />
                {errors.to_date ? (
                  <div className="validation-error-label">{errors.to_date}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Per Day Salary
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  name="per_day_salary"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Per Day Salary"
                  value={seasonalSalaryDetails?.per_day_salary}
                />
                {errors.per_day_salary ? (
                  <div className="validation-error-label">
                    {errors.per_day_salary}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Remarks
                  <span className="required-symbol">*</span>
                </label>
                <textarea
                  className="input-field"
                  name="remarks"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Remarks"
                >
                  {seasonalSalaryDetails?.remarks}
                </textarea>
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateSeasonalSalary;
