import React, { useEffect, useState } from "react";
import InvoiceDetailsTable from "../../../Components/DataTable/PurchaseOrder/InvoiceDetailsTable";
import { useLocation } from "react-router-dom";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";

function InviceDetails() {
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setLoading(true);
    try {
      const invoiceDetails = await GET_API(`po/invoiceById/${state}`);
      if (invoiceDetails?.data?.status === 200) {
        setInvoiceDetails(invoiceDetails?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get invoice list");
      setLoading(false);
    }
  };

  const Details = [
    {
      label: "PO Number",
      value: invoiceDetails?.poInvoice?.po_number,
    },

    {
      label: "Invoice Number",
      value: invoiceDetails?.poInvoice?.invoice_number,
    },
    {
      label: "Payment",
      value: invoiceDetails?.poInvoice?.payment_status,
    },
    {
      label: "Total Amount",
      value: invoiceDetails?.poInvoice?.amount,
    },

    {
      label: "Paid Amount",
      value: invoiceDetails?.poInvoice?.amount,
    },

    {
      label: "Invoice Date",
      value: invoiceDetails?.poInvoice?.invoice_date,
    },
  ];

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "product_name", label: "Product Name" },
    { id: "hsn", label: "HSN Number" },
    { id: "brand", label: "Brand" },
    { id: "uom", label: "UOM" },
    { id: "quantity", label: "Quantity" },
    { id: "price", label: "Price" },
  ];

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="po-container">
      <div className="po-header">Invoice</div>
      <div className="po-contect-box">
        <div className="header-name">Invoice Details</div>
        <div className="po-pr-details-content-block">
          {/* <div className="flex justify-end">
            <Link to="/po/invoice/edit" className="view-list-button">
              <span>Edit</span>
            </Link>
          </div> */}
          <div className="list-content-block">
            {Details.map((list, i) => {
              return (
                <div className="list " key={i}>
                  <label className="list-label">{list.label}</label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="list-value"
                  >
                    {list.value}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pt-5">
            <InvoiceDetailsTable
              columns={columns}
              list={invoiceDetails?.poInvoiceItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviceDetails;
