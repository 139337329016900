import React from "react";
import { Link } from "react-router-dom";

function SettingsCard() {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_role");
    localStorage.removeItem("user_name");
  };
  return (
    <div className="absolute z-10 top-7 right-2.5 min-w-[200px] bg-[#fff] p-1.5 rounded shadow-lg border-[1px] border-primary flex flex-col">
      <Link
        to="userPrivileges"
        className="font-karla text-base p-2 border-b-[1px] border-b-[#D9D9D9] cursor-pointer text-text-light"
      >
        User Privilege
      </Link>
      <Link
        to="general-settings"
        className="font-karla text-base p-2 border-b-[1px] border-b-[#D9D9D9] cursor-pointer text-text-light"
      >
        General Settings
      </Link>
      <Link
        onClick={() => handleLogout()}
        to="/login"
        className="font-karla text-base p-2 cursor-pointer text-text-light"
      >
        Logout
      </Link>
    </div>
  );
}

export default SettingsCard;
