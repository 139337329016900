import React from "react";
import { NavLink } from "react-router-dom";

function SubMenuItems({ label, name, setSubtitle, submenu, selected, title }) {
  const currentRoute = window.location.pathname;
  return (
    <>
      <NavLink
        to={label?.to}
        className={`flex justify-start items-center gap-2 w-full p-1 pl-2 `}
      >
        <span
          className={`w-full flex justify-start items-center gap-2 font-montserrat text-sm flex ml-[35px] px-1 pt-[4px] pb-[4px] ${
            label?.active?.includes(currentRoute)
              ? "text-text-dark"
              : "text-text-light"
          }`}
        >
          <div
            className={`h-[6px] w-[6px] ${
              label?.active?.includes(currentRoute)
                ? "bg-primary-color"
                : "bg-white-color"
            } rounded-full`}
          ></div>
          {label?.label}
        </span>
      </NavLink>
    </>
  );
}

export default SubMenuItems;
