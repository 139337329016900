import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import dayjs from "dayjs";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";
import config from "../../../Services/config.json";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";
import Joi from "joi";

function LoanDeductionEntry() {
  const navigate = useNavigate();
  const [loanDetails, setLoanDetails] = useState({
    employee_id: "",
    amount: "",
    finance_date: dayjs(new Date()).format("YYYY-MM-DD"),
    image: "",
    paid_type: "cash",
    description: "",
  });
  const [errors, seterrors] = useState({});
  const [disableBtn, setdisableBtn] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getEmployeeList();
  }, []);

  const getEmployeeList = async () => {
    setIsLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const res = employeeList?.data?.data.map((emp, i) => {
          return {
            label: `${emp?.first_name} ${
              emp?.fathers_name ? emp?.fathers_name : ""
            } (${emp?.emp_code})`,
            value: emp?.id,
          };
        });
        setEmployeeList(res);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify?.error("Failed to get Employee List...");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setLoanDetails(() => ({ ...loanDetails, [name]: value }));
  };

  const createLoan = async (e) => {
    e.preventDefault();
    const isValid = Validation.validateLoanDeductionEntry(loanDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
      try {
        const response = await POST_API("/addLoanDeductionEntry", loanDetails);
        if (response?.data?.status === 200) {
          alertify.success(response?.data?.message);
          navigate("/expense/loan");
        }
        if (response?.data?.status === 403) {
          alertify.error(response?.data?.message);
          navigate("/expense/loan");
        }
      } catch (error) {
        alertify.error(e?.response?.data?.message);
      }
    }
  };

  const handleEmployeeName = async (e, name) => {
    const { value } = e;
    setLoanDetails(() => ({ ...loanDetails, [name]: value }));
  };

  const resetForm = () => {
    setLoanDetails({
      ...loanDetails,
      employee_id: "",
      amount: "",
    });
  };

  const schema = Joi.object({
    size: Joi.number()
      .required()
      .max(5 * 1024 * 1024)
      .message("Image size must be less than 5MB."),

    type: Joi.string().valid("image/jpeg", "image/png").required().messages({
      "any.only": "Invalid image format. Only JPEG and PNG are allowed.",
    }),
  });

  const handleImageChange = (e) => {
    const fileReader = new FileReader();
    const { error } = schema.validate({
      size: e?.target?.files[0].size,
      type: e?.target?.files[0].type,
    });
    error?.message ? seterrors({ image: error?.message }) : seterrors({});
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setLoanDetails(() => ({
          ...loanDetails,
          image: fileReader.result,
          image_format: {
            size: e?.target?.files[0].size,
            type: e?.target?.files[0].type,
          },
        }));
      }
    };
    fileReader?.readAsDataURL(e?.target?.files[0]);
  };

  if (isLoading) {
    return <Spinner />;
  }
  const baseurl = config?.baseurl;

  const selectTypes = [
    { value: "bank", label: "Bank" },
    { value: "cash", label: "Cash" },
  ];
  const handleSelectChange = (e, name) => {
    setLoanDetails(() => ({ ...loanDetails, [name]: e?.value }));
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/loan" className="in-active">
              Loan List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Loan Deduction Entry</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Loan Deduction Entry</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createLoan(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={employeeList}
                  value={employeeList?.find(
                    (option) => option?.value === loanDetails?.employee_id
                  )}
                  onChange={(e) => handleEmployeeName(e, "employee_id")}
                  name="employee_id"
                  className=""
                  isSearchable
                />
                {errors.employee_id ? (
                  <div className="validation-error-label">
                    {errors.employee_id}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="amount"
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={loanDetails?.amount}
                  placeholder="Enter Amount"
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Payment Type</label>
                <Select
                  options={selectTypes}
                  value={
                    loanDetails.selecttype
                      ? selectTypes?.find(
                          (option) => option?.value === loanDetails.selecttype
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "selecttype")}
                  name="selecttype"
                  className=""
                  isSearchable
                />
                {errors.selecttype ? (
                  <div className="validation-error-label">
                    {errors.selecttype}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Date</label>
                <input
                  type="date"
                  name="finance_date"
                  className="input-field"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={loanDetails?.finance_date}
                />
                {errors.finance_date ? (
                  <div className="validation-error-label">
                    {errors.finance_date}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Image <span className="required-symbol">*</span>
                  <div className="">
                    <div className="flex gap-2 items-center">
                      {!baseurl && !loanDetails.image ? (
                        <CropOriginalIcon className="employee-profile-picture" />
                      ) : (
                        <img
                          name="image"
                          className="h-[35px] w-[35px]"
                          src={loanDetails.image}
                          alt=""
                        />
                      )}
                      <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                        <label
                          for="files"
                          className="label-name cursor-pointer"
                        >
                          {loanDetails.image === "" ? "Choose" : "Replace"}
                        </label>
                        <input
                          className="w-0 cursor-pointer"
                          id="files"
                          style={{ visibility: "hidden" }}
                          type="file"
                          accept=".jpeg,.jpg,.png"
                          onChange={(e) => handleImageChange(e)}
                        />
                        <img src={FileUpload} alt="" />
                      </div>
                    </div>
                    {errors?.image ? (
                      <div
                        className="validation-error-label"
                        key={errors?.image}
                      >
                        {errors?.image}
                      </div>
                    ) : null}
                  </div>
                </label>
              </div>
              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  rows={5}
                  name="description"
                  className="input-field"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={loanDetails?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span
                  className="submit-button-label"
                  style={{ disabled: disableBtn }}
                >
                  Add
                </span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoanDeductionEntry;
