import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "./Pagination";
import config from "../../Services/config.json";
import { Link } from "react-router-dom";


export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  // Function to convert minutes to hours and minutes
  const convertToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${hours} hour(s) and ${remainingMinutes} minute(s)`;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i} className="table-column">
                  <TableCell className="table-body !w-[7%]">
                    {(currentPage - 1) * pageSize + (i + 1)}
                  </TableCell>

                  <TableCell className="!w-[7%] table-body">
                    {row?.profile_image !== "" ? (
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "40px",
                        }}
                        src={config?.path + row?.profile_image}
                        alt="profile_image"
                      />
                    ) : (
                      <div
                        className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                        style={{ backgroundColor: "#ccc" }}
                      >
                        {row?.first_name?.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </TableCell>

                
                  <TableCell className="table-body">
                      <Link to={`/report/movement/details/${row.id}`} target="_blank" onClick={() => {
                        // Store data in localStorage before opening the new tab
                        localStorage.setItem('selectedEmployeeDetails', JSON.stringify(row));
                      }} style={{ color : 'blue' }}>
                      <u>{row.employeeCode}</u>
                    </Link>
                  </TableCell>
                
                  <TableCell className="table-body">{row?.EmployeeName}</TableCell>
                  <TableCell className="table-body">{row.floorname}</TableCell>
                  {/* <TableCell className="table-body">{row.department_name}</TableCell> */}
                  {/* <TableCell className="table-body">{row.designation_name}</TableCell> */}
                  <TableCell className="table-body">{row.clock_in_time}</TableCell>
                  <TableCell className="table-body">{row.clock_out_time}</TableCell>
                  {/* <TableCell className="table-body">{row.break_pair}</TableCell> */}

                  {/* <TableCell className="table-body">
                    <table className="w-[100%] border border-[#d9d9d9] border-collapse">
                      <thead>
                        <tr>
                          <th className="p-1">Out</th>
                          <th className="p-1">In</th>
                          <th className="p-1">Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.break_detail && row.break_detail !== "null" ? (
                          JSON.parse(row.break_detail).map((logs, index) => (
                            <tr key={index}>
                              <td className="p-1">{logs?.clock_out}</td>
                              <td className="p-1">{logs?.clock_in}</td>
                              <td className="p-1">{logs?.break_time}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="p-1 text-center">
                              No break details available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableCell> */}

                  {/* Display break duration in hours and minutes */}
                  <TableCell className="table-body">
                    {convertToHoursAndMinutes(row.break_duration)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
