import React from "react";

function Home({ fill }) {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5612 0.647461L23.3529 10.4388C24.2128 11.3005 24.2124 12.7013 23.3518 13.5624C22.9342 13.9799 22.3794 14.2097 21.7894 14.2097H21.4276V21.4144C21.4276 22.84 20.2676 24 18.8416 24H15.0114H14.3083H13.3174H12.7664H11.0674H10.4704H9.91919H8.98647H5.15628C3.73025 24 2.57028 22.84 2.57028 21.4144V14.2097H2.18008C2.15719 14.2097 2.13449 14.2086 2.11196 14.2064C1.55734 14.1826 1.0386 13.955 0.645838 13.5621C-0.213843 12.7024 -0.215307 11.304 0.641626 10.4421L0.651514 10.4321L10.4365 0.647278C10.854 0.229797 11.4088 0 11.9989 0C12.5891 0 13.1439 0.22998 13.5612 0.647461ZM12.1639 15.2441C10.293 15.2441 8.77637 16.7608 8.77637 18.6317V23.9999H15.5515V18.6317C15.5515 16.7608 14.0348 15.2441 12.1639 15.2441Z"
          fill={fill}
        />
        <path
          opacity="0.5"
          d="M8.77606 18.6317C8.77606 16.7608 10.2927 15.2441 12.1636 15.2441C14.0345 15.2441 15.5512 16.7608 15.5512 18.6317V23.9999H8.77606V18.6317Z"
          fill={fill}
        />
      </svg>
    </>
  );
}

export default Home;
