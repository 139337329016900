import React, { useState } from "react";
import { Link } from "react-router-dom";
import Save from "../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { PUT_API } from "../../Services/api";

function EditHalfDayVariant() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [editHalfDayVariant, setEditHalfDayVariant] = useState({
    id: listData?.id,
    name: listData?.name,
    half_day_amount: listData?.half_day_amount,
    full_day_amount: listData?.full_day_amount,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditHalfDayVariant(() => ({ ...editHalfDayVariant, [name]: value }));
  };

  const saveList = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateHalfDayVariant(editHalfDayVariant);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const updateList = await PUT_API(
        "/updateHalfDayVariant",
        editHalfDayVariant
      );
      if (updateList?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(updateList?.data?.message);
        navigate(`/master/hrms/halfDayVariant/`);
      } else {
        alertify.error(updateList?.data?.message);
        setButtonDisabled(false);
      }
    } catch (e) {
      setButtonDisabled(false);
      alertify.error("Failed to Update List");
    }
  };

  const resetForm = () => {
    setEditHalfDayVariant({
      id: listData?.id,
      name: listData?.name,
      half_day_amount: listData?.half_day_amount,
      full_day_amount: listData?.full_day_amount,
    });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/halfDayVariant" className="in-active">
              Half Day Variant List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Half Day Variant</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Half Day Variant</h4>

          {/***************Form************* **/}

          <form className="form">
            <div className="field-block">
              <label className="label-name">
                Name
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field "
                type="text"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="name"
                placeholder="Enter Name"
                value={editHalfDayVariant.name}
              />
              {errors.name ? (
                <div className="validation-error-label">{errors.name}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">
                Hald Day Amount
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field "
                type="number"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="half_day_amount"
                placeholder="Enter Half Day amount"
                value={editHalfDayVariant.half_day_amount}
              />
              {errors.half_day_amount ? (
                <div className="validation-error-label">
                  {errors.half_day_amount}
                </div>
              ) : null}
            </div>

            <div className="field-block">
              <label className="label-name">
                Full Day Amount
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field "
                type="number"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="full_day_amount"
                placeholder="Enter Full Day amount"
                value={editHalfDayVariant.full_day_amount}
              />
              {errors.full_day_amount ? (
                <div className="validation-error-label">
                  {errors.full_day_amount}
                </div>
              ) : null}
            </div>
          </form>

          <div className="submit-reset-button">
            <button onClick={() => resetForm()}>
              <button type="button" className="cancel-button">
                <span className="reset-button-label">Reset</span>
              </button>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveList()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditHalfDayVariant;
