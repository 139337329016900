import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import alertify from "alertifyjs";
import SalaryDetailsTable from "./SalaryDetailsTable";
import FoodAllowanceDetailsTable from "./FoodAllowanceDetailsTable";
import SeasonalSalaryTable from "./SeasonalSalaryTable";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";

function TerminationReportDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [costList, setCostList] = useState([]);
  const [details, setDetails] = useState([]);

  const salaryColumns = [
    { id: "s.no", label: "S.No" },
    { id: "allowance_name", label: "Allowance Name" },
    { id: "allowance_type", label: "Daily/Monthly" },
    { id: "allowance_description", label: "Description" },
    { id: "amount", label: "Amount" },
  ];

  const foodAllowanceColumns = [
    { id: "s.no", label: "S.No" },
    { id: "allowance_name", label: "Allowance Name" },
    { id: "amount", label: "Amount" },
    { id: "actual_entry_time", label: "Shift Start Timie" },
    { id: "actual_exit_time", label: "Shift End Time" },
  ];

  const seasonalSalaryColumns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_type_name", label: "Employee Type" },
    { id: "per_day_salary", label: "Salary" },
    { id: "from_date", label: "From" },
    { id: "to_date", label: "To" },
  ];

  useEffect(() => {
    if (id) {
      getEmployeeCostDetails();
    }
  }, [id]);

  useEffect(() => {
    if (costList?.employeeDetails?.length) {
      const list = [
        {
          label: "Employee Name",
          value: costList?.employeeDetails[0]?.first_name,
        },
        {
          label: "Employee Code",
          value: costList?.employeeDetails[0]?.emp_code,
        },
        {
          label: "Department",
          value: costList?.employeeDetails[0]?.departmentname,
        },
        {
          label: "Designation",
          value: costList?.employeeDetails[0]?.designationname,
        },
        {
          label: "Shift name",
          value: costList?.employeeDetails[0]?.shiftname,
        },
      ];
      setDetails(list);
    }
  }, [id, costList?.employeeDetails?.length]);

  const getEmployeeCostDetails = async () => {
    setIsLoading(true);
    try {
      const empList = await GET_API(`/employeeCostReportView/${id}`);
      if (empList?.data?.status === 200) {
        setCostList(empList?.data?.data);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      alertify.error("Failed to Get Cost Report Details List");
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/cost" className="in-active">
              Cost Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Cost Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Cost Report Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {details?.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="card-wrapper mt-6">
          <h4 className="card-header">Total Amount:</h4>
          <div className="list mx-4 mt-4">
            <label className="list-label">Total Amount</label>
            <div
              style={{ wordBreak: "break-word" }}
              className="list-value mb-4"
            >
              {costList?.total}
            </div>
          </div>
        </div>

        <div className="card-wrapper mt-6">
          <h4 className="card-header">Food Allowance Details:</h4>
          <FoodAllowanceDetailsTable
            list={costList?.foodallowance}
            columns={foodAllowanceColumns}
          />
        </div>
        {costList?.salaryDetails?.length ? (
          <div className="card-wrapper mt-6">
            <h4 className="card-header">Salary Details:</h4>
            <SalaryDetailsTable
              list={costList?.salaryDetails}
              columns={salaryColumns}
            />
          </div>
        ) : (
          ""
        )}
        {costList?.seasonalSalary?.length ? (
          <div className="card-wrapper mt-6">
            <h4 className="card-header">Seasonal Salary Details:</h4>
            <SeasonalSalaryTable
              list={costList?.seasonalSalary}
              columns={seasonalSalaryColumns}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default TerminationReportDetails;
