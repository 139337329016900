import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DELETE_API, GET_API, POST_API } from "../../../Services/api";
import BiometricsListTable from "../../DataTable/BiometricsTable";
import Edit from "../../../Assets/Icons/Edit.svg";
import Search from "../../../Assets/Icons/Search.svg";
import More from "../../../Assets/Icons/More.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import "alertifyjs/build/css/alertify.css";

function BiometricsList() {
  const [filteredBranch, setFilteredBiometric] = useState([]);
  const [biometricsList, setBiometricsList] = useState([]);
  const [errors, seterrors] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [biometricsDetails, setbiometricsDetails] = useState({
    name: "",
    device_id: "",
  });

  const handleBiometricSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = biometricsList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredBiometric(searchedList);
  };

  const header = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Biometrics Name" },
    { id: "device_id", label: "Device ID" },
    { id: "created_at", label: "Created at" },
    { id: "", label: "" },
  ];

  useEffect(() => {
    getBiometricsList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getBiometricsList(1, pageSize, searchKey);
  };

  const getBiometricsList = async (page, size, search) => {
    setLoading(true);
    try {
      const biometricsList = await GET_API(
        `/biometricsList?page=${page}&size=${size}&search=${search}`
      );
      if (biometricsList.status === 200) {
        if (
          biometricsList?.data?.data?.current_page >
          biometricsList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLoading(false);
        setBiometricsList(biometricsList?.data?.data?.data);
        setFilteredBiometric(biometricsList?.data?.data?.data);
        setLastPage(biometricsList?.data?.data?.last_page);
      }
    } catch (error) {
      setLoading(false);
      setBiometricsList([]);
      setFilteredBiometric([]);
      setLastPage("");
      setCurrentPage(1);
      alertify.error("Failed to get Biometrics List");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setbiometricsDetails({ ...biometricsDetails, [name]: value });
  };

  const addBiometric = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.ValidateBiometrics(biometricsDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const createFloor = await POST_API("/addBiometrics", biometricsDetails);
      if (createFloor.status === 200) {
        setButtonDisabled(false);
        setbiometricsDetails({ name: "", device_id: "" });
        getBiometricsList(currentPage, pageSize, searchKey);
        setCurrentPage(1);
        alertify.success(createFloor?.data?.message);
        return;
      }
    } catch (error) {
      setButtonDisabled(false);
      setbiometricsDetails({ name: "", device_id: "" });
      setCurrentPage(1);
    }
  };

  const editBiometrics = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.ValidateBiometrics(biometricsDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const editFloor = await POST_API("/updateBiometrics", biometricsDetails);
      if (editFloor.status === 200) {
        setButtonDisabled(false);
        setbiometricsDetails({ name: "", device_id: "" });
        getBiometricsList(currentPage, pageSize, searchKey);
        setCurrentPage(1);
        setEditStatus(false);
        alertify.success(editFloor?.data?.message);
        return;
      }
    } catch (error) {
      setButtonDisabled(false);
      setbiometricsDetails({ name: "", device_id: "" });
      setCurrentPage(1);
      setEditStatus(false);
    }
  };

  const resetForm = () => {
    setEditStatus(false);
    setbiometricsDetails({ ...biometricsDetails, name: "", device_id: "" });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Biometrics Name..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteBiometrics/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getBiometricsList(currentPage, pageSize, searchKey);
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete Biometrics");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Add Biometrics</h4>
          <form
            onSubmit={() => getBiometricsList(currentPage, pageSize, searchKey)}
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
            </div>
          </form>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Biometrics Name <span className="required-symbol">*</span>
              </label>

              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Enter Biometrics name"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={biometricsDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">
                Device ID <span className="required-symbol">*</span>
              </label>
              <textarea
                rows={5}
                name="device_id"
                className="input-field"
                placeholder="Enter Your Device ID"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={biometricsDetails.device_id}
                autoComplete="off"
              />
              {errors.device_id ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.device_id}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      editBiometrics(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      addBiometric(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Cancel</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>
          <BiometricsListTable
            list={filteredBranch}
            columns={header}
            icon={More}
            editStatus={setEditStatus}
            editList={setbiometricsDetails}
            multiple={false}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default BiometricsList;
