import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import "alertifyjs/build/css/alertify.css";
import Spinner from "../../Spinner";

function FoodAllowance() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [foodDetails, setFoodDetails] = useState({
    name: "",
    description: "",
    amount: "",
    from_time: "",
    to_time: "",
  });

  useEffect(() => {
    getFoodList();
  }, [id]);

  const getFoodList = async () => {
    setIsLoading(true);
    try {
      const foodList = await GET_API(`/FoodAllowanceList/${id}`);
      if (foodList.status === 200) {
        setFoodDetails(foodList?.data?.data[0]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setFoodDetails(() => ({ ...foodDetails, [name]: value }));
  };

  const createFoodAllowance = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateFoodAllowance(foodDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }

    if (!foodDetails?.from_time && !foodDetails?.to_time) {
      seterrors({
        from_time: "Please select From Time or To Time",
        to_time: "Please select From Time or To Time",
      });
      setButtonDisabled(false);
    } else {
      seterrors({});
      POST_API("/updateFoodAllowanceDetails", foodDetails)
        .then(async function (data) {
          setButtonDisabled(false);
          alertify.success(data?.data?.message);
          navigate("/master/hrms/foodallowance");
        })

        .catch((error) => {
          setButtonDisabled(false);
          alertify.error(error?.response?.data?.message);
        });
    }
  };

  const resetForm = () => {
    setFoodDetails({
      name: "",
      description: "",
      amount: "",
      from_time: "",
      to_time: "",
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/foodallowance" className="in-active">
              Food Allowance List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Food Allowance</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Food Allowance</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createFoodAllowance(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Name"
                  value={foodDetails?.name}
                />
                {errors?.name ? (
                  <div className="validation-error-label">{errors?.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Before Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="from_time"
                  placeholder=""
                  value={foodDetails?.from_time}
                />
                {errors?.from_time ? (
                  <div className="validation-error-label">
                    {errors?.from_time}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  After Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="to_time"
                  placeholder=""
                  value={foodDetails?.to_time}
                />
                {errors?.to_time ? (
                  <div className="validation-error-label">
                    {errors?.to_time}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="amount"
                  placeholder="Enter Amount"
                  value={foodDetails?.amount}
                />
                {errors?.amount ? (
                  <div className="validation-error-label">{errors?.amount}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="description"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Description"
                  value={foodDetails?.description}
                />
                {errors?.address ? (
                  <div className="validation-error-label">
                    {errors?.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Save</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FoodAllowance;
