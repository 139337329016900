import React from "react";
import { Route } from "react-router-dom";
import EmployeeMastersRoutes from "../../Components/Routes/EmployeeMastersRoutes";
import BranchMasterRoutes from "../../Components/Routes/BranchMasterRoutes";
import AttendanceRoutes from "../../Components/Routes/AttendanceMasterRoutes";
import FinanceMastersRoutes from "../../Components/Routes/FinanceMasterRoutes";
import HolidayMasterRoutes from "../../Components/Routes/HolidayMasterRoutes";
import AllowanceRoutes from "../../Components/Routes/AllowanceRoutes";

/* HRMS */
import Categories from "../../Components/PurchaseOrder/Masters/Categories";

import Warehouse from "../../Components/PurchaseOrder/Masters/Warehouse/Warehouse";
import CreateWarehouse from "../../Components/PurchaseOrder/Masters/Warehouse/CreateWarehouse";
import EditWarehouse from "../../Components/PurchaseOrder/Masters/Warehouse/EditWarehouse";
import WarehouseDetails from "../../Components/PurchaseOrder/Masters/Warehouse/WarehouseDetails";

/* import Section from "../../Components/PurchaseOrder/Masters/Section/WarehouseSection.js";
import CreateSection from "../../Components/PurchaseOrder/Masters/Section/CreateWarehouseSection.js";
import EditSection from "../../Components/PurchaseOrder/Masters/Section/EditWarehouseSection.js";
import SectionDetails from "../../Components/PurchaseOrder/Masters/Section/SectionDetails.js"; */

import Supplier from "../../Components/PurchaseOrder/Masters/Supplier/Supplier";
import CreateSupplier from "../../Components/PurchaseOrder/Masters/Supplier/CreateSupplier";
import EditSupplier from "../../Components/PurchaseOrder/Masters/Supplier/EditSupplier";
import SupplierDetails from "../../Components/PurchaseOrder/Masters/Supplier/SupplierDetails";

import ProductPrice from "../../Components/PurchaseOrder/Masters/ProductPrice";

import Attributes from "../PurchaseOrder/Masters/Attributes";

import Attributevalue from "../PurchaseOrder/Masters/Attributevalue";

import Tax from "../PurchaseOrder/Masters/Tax";

import Discount from "../PurchaseOrder/Masters/Discount";

import Terms from "../PurchaseOrder/Masters/Terms";

import Agent from "../PurchaseOrder/Masters/Agent/Agent.jsx";
import CreateAgent from "../PurchaseOrder/Masters/Agent/CreateAgent.jsx";
import EditAgent from "../PurchaseOrder/Masters/Agent/EditAgent.jsx";
import AgentDetails from "../PurchaseOrder/Masters/Agent/AgentDetails.jsx";

import Transport from "../PurchaseOrder/Masters/Transport.jsx";

import Department from "../PurchaseOrder/Masters/Department/Department.jsx";
import CreatePODepartment from "../PurchaseOrder/Masters/Department/CreatePODepartment.jsx";
import PODepartmentDetails from "../PurchaseOrder/Masters/Department/PODepartmentDetails.jsx";
import EditPODepartment from "../PurchaseOrder/Masters/Department/EditPoDepartment.jsx";

import DepartmentSection from "../PurchaseOrder/Masters/DepartmentSection.js";

import Less from "../PurchaseOrder/Masters/Less.js";

function MasterRoutes() {
  return (
    <>
      {/* HRMS */}
      <Route path="hrms">{EmployeeMastersRoutes()}</Route>
      <Route path="hrms">{BranchMasterRoutes()}</Route>
      <Route path="hrms">{AttendanceRoutes()}</Route>
      <Route path="hrms">{HolidayMasterRoutes()}</Route>
      <Route path="hrms">{AllowanceRoutes()}</Route>
      <Route path="hrms">{FinanceMastersRoutes()}</Route>

      {/* PURCHASE ORDER */}
      <Route path="po/categories" element={<Categories />}></Route>
      <Route
        path="po/departmentSection"
        element={<DepartmentSection />}
      ></Route>
      <Route path="po/warehouse" element={<Warehouse />}></Route>
      <Route path="po/warehouse/create" element={<CreateWarehouse />}></Route>
      <Route path="po/warehouse/edit/:id" element={<EditWarehouse />}></Route>
      <Route
        path="po/warehouse/details/:id"
        element={<WarehouseDetails />}
      ></Route>

      <Route path="po/department" element={<Department />}></Route>
      <Route
        path="po/department/create/"
        element={<CreatePODepartment />}
      ></Route>
      <Route
        path="po/department/edit/:id"
        element={<EditPODepartment />}
      ></Route>
      <Route
        path="po/department/details/:id"
        element={<PODepartmentDetails />}
      ></Route>

      {/*  <Route path="po/warehouseSection" element={<Section />}></Route>
      <Route
        path="po/warehouseSection/create"
        element={<CreateSection />}
      ></Route>
      <Route path="po/warehouseSection/edit" element={<EditSection />}></Route>
      <Route
        path="po/warehouseSection/details"
        element={<SectionDetails />}
      ></Route> */}

      <Route path="po/suppliers" element={<Supplier />}></Route>
      <Route path="po/supplier/create" element={<CreateSupplier />}></Route>
      <Route path="po/supplier/edit" element={<EditSupplier />}></Route>
      <Route path="po/supplier/details" element={<SupplierDetails />}></Route>

      <Route path="po/productPrice" element={<ProductPrice />}></Route>

      <Route path="po/attributes" element={<Attributes />}></Route>
      <Route path="po/attributevalue" element={<Attributevalue />}></Route>

      <Route path="po/tax" element={<Tax />}></Route>

      <Route path="po/discount" element={<Discount />}></Route>

      <Route path="po/terms" element={<Terms />}></Route>

      <Route path="po/agent" element={<Agent />}></Route>

      <Route path="po/agent/create" element={<CreateAgent />}></Route>
      <Route path="po/agent/edit" element={<EditAgent />}></Route>
      <Route path="po/agent/details" element={<AgentDetails />}></Route>

      <Route path="po/transport" element={<Transport />}></Route>

      <Route path="po/less" element={<Less />}></Route>
    </>
  );
}

export default MasterRoutes;
