import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "react-select";
import Pagination from "./Pagination";

export default function StickyHeadTable({
  list,
  columns,
  advance,
  paymentType,
  bulkAdvance,
  setBulkAdvance,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const handleBankAmount = (e, empID) => {
    const { value } = e?.target;
    var isEmployeeExist = bulkAdvance?.data?.some((item) => {
      return item?.employee_id === empID;
    });

    if (!isEmployeeExist) {
      const advanceList = {
        employee_id: empID,
        bank_amount: value,
        cash_amount: 0,
      };
      const totalList = { ...bulkAdvance };
      totalList?.data?.push(advanceList);
      setBulkAdvance(totalList);
    } else {
      const changedRow = bulkAdvance?.data?.map((list) => {
        if (list?.employee_id === empID) {
          return { ...list, bank_amount: value };
        } else {
          return list;
        }
      });
      setBulkAdvance({ ...bulkAdvance, data: [] });
      setBulkAdvance({ ...bulkAdvance, data: changedRow });
    }
  };

  const handleCashAmount = (e, empID) => {
    const { value } = e?.target;
    var isEmployeeExist = bulkAdvance?.data?.some((item) => {
      return item?.employee_id === empID;
    });

    if (!isEmployeeExist) {
      const advanceList = {
        employee_id: empID,
        bank_amount: 0,
        cash_amount: value,
      };
      const totalList = { ...bulkAdvance };
      totalList?.data?.push(advanceList);
      setBulkAdvance(totalList);
    } else {
      const changedRow = bulkAdvance?.data?.map((list) => {
        if (list?.employee_id === empID) {
          return { ...list, cash_amount: value };
        } else {
          return list;
        }
      });
      setBulkAdvance({ ...bulkAdvance, data: [] });
      setBulkAdvance({ ...bulkAdvance, data: changedRow });
    }
  };


  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.first_name}
                      </TableCell>
                    }
                    {<TableCell className="table-body">{row?.emp_code}</TableCell>}
                    {
                      <TableCell className="table-body"><b>{row?.salaryToBePaid}</b></TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <input
                          type="number"
                          name="bank_amount"
                          className="border-[1px] border-[#D9D9D9] p-1 rounded"
                          onChange={(e) => handleBankAmount(e, row?.employee_Id)}
                        />
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <input
                          type="number"
                          name="cash_amount"
                          className="border-[1px] border-[#D9D9D9] p-1 rounded"
                          onChange={(e) => handleCashAmount(e, row?.employee_Id)}
                        />
                      </TableCell>
                    }
                   
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
