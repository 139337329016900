import React, { useState } from "react";
import { Link } from "react-router-dom";
import Save from "../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
function EditHolidayScheme() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [editHolidaySchemeDetails, setEditHolidaySchemeDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    description: listData?.description,
    grace_days: listData?.grace_leave_days,
    working_days_calculation: listData?.working_days_calculation,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditHolidaySchemeDetails(() => ({
      ...editHolidaySchemeDetails,
      [name]: value,
    }));
  };

  const saveScheme = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateHolidaysScheme(editHolidaySchemeDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateHolidayScheme", editHolidaySchemeDetails)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        navigate("/master/hrms/holidayscheme");
        setButtonDisabled(false);
      })

      .catch((error) => {
        alertify.error(error.response.data.message);
        setButtonDisabled(false);
      });
  };

  const resetForm = () => {
    setEditHolidaySchemeDetails({
      id: listData?.id,
      name: listData?.name,
      description: listData?.description,
      // special_notes: listData?.special_notes,
      // no_of_days: listData?.no_of_days,
      grace_days: listData?.grace_leave_days,
      working_days_calculation: listData?.working_days_calculation,
    });
  };
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/holidayscheme" className="in-active">
              Holiday Scheme List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Holiday Scheme</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Holiday Scheme</h4>

          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Holidays Scheme Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={editHolidaySchemeDetails.name}
                />
                {errors.name && (
                  <span className="validation-error-label">{errors.name}</span>
                )}
              </div>

              {/* <div className="field-block">
                <label className="label-name">
                  Special note
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="special_notes"
                  value={editHolidaySchemeDetails.special_notes}
                />
                {errors.special_notes && (
                  <span className="validation-error-label">
                    {errors.special_notes}
                  </span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">
                  No Of Days
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Holiday Scheme Name"
                  value={editHolidaySchemeDetails?.no_of_days}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="no_of_days"
                />
                {errors.no_of_days && (
                  <span className="validation-error-label">
                    {errors.no_of_days}
                  </span>
                )} 
              </div>*/}
              <div className="field-block">
                <label className="label-name">Grace Days</label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Grace Leave Days"
                  value={editHolidaySchemeDetails?.grace_days}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="grace_days"
                />
                {errors.no_of_days && (
                  <span className="validation-error-label">
                    {errors.no_of_days}
                  </span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">Calculating Working Days</label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Holiday Scheme Name"
                  value={editHolidaySchemeDetails?.working_days_calculation}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="working_days_calculation"
                />
                {errors.no_of_days && (
                  <span className="validation-error-label">
                    {errors.no_of_days}
                  </span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="description"
                  value={editHolidaySchemeDetails.description}
                  rows={5}
                />
                {errors.description && (
                  <span className="validation-error-label">
                    {errors.description}
                  </span>
                )}
              </div>
            </div>
          </form>

          <div className="submit-reset-button">
            <button
              type="button"
              className="reset-button"
              onClick={() => resetForm()}
            >
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveScheme()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditHolidayScheme;
