import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Validation from "../../Validation/Validation";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import FileUpload from "../../Assets/Icons/FileUpload.svg";
import Spinner from "../Spinner";
import dayjs from "dayjs";
import "alertifyjs/build/css/alertify.css";

function CreateLeaveOnduty() {
  const navigate = useNavigate();
  const [employeeList, setemployeeList] = useState([]);
  const [approvedBy, setApprovedBy] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [leaveDetail, setLeaveDetail] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [fileType, setFileType] = useState("");
  const today = new Date().toISOString().split('T')[0];
  const [leaveDetails, setleaveDetails] = useState({
    employee_id: "",
    type: "",
    employee_name: "",
    leave_type_id: "",
    from_date: dayjs(new Date()).format("YYYY-MM-DD"),
    to_date: dayjs(new Date()).format("YYYY-MM-DD"),
    approved_by: "",
    remarks: "",
    no_of_days: 0,
    file: "",
    created_by: localStorage.getItem("user_id"),
  });
  const [errors, seterrors] = useState({});
  useEffect(() => {
    getEmployeeList();
    getLeaveTypeList();
  }, []);

  const getEmployeeList = async () => {
    setLoading1(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.status === 200) {
        const empCode = employeeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.emp_code };
        });
        const appBy = employeeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.first_name };
        });
        setemployeeList(empCode);
        setApprovedBy(appBy);
        setLoading1(false);
      }
    } catch (error) {
      setLoading1(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const getLeaveTypeList = async () => {
    try {
      setLoading2(true);
      const leaveTypeList = await GET_API("/leaveTypeListDropdown");
      if (leaveTypeList.status === 200) {
        const leave = leaveTypeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setLeaveDetail(leave);
        setLoading2(false);
      }
    } catch (error) {
      alertify.error("Failed to get Leave Type List");
      setLoading2(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setleaveDetails(() => ({ ...leaveDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setleaveDetails(() => ({ ...leaveDetails, [name]: e?.value }));
  };

  const handleProfileImage = (e) => {
    const type = e?.target?.files[0]?.type?.split("/").shift();
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setleaveDetails({ ...leaveDetails, file: fileReader?.result });
        setFileType(type);
      }
    };
    fileReader?.readAsDataURL(e?.target?.files[0]);
  };

  useEffect(() => {
    if (
      leaveDetails.from_date !== "" &&
      leaveDetails?.to_date !== "" &&
      leaveDetails.from_date <= leaveDetails.to_date
    ) {
      const fromDate = new Date(leaveDetails?.from_date);
      const toDate = new Date(leaveDetails?.to_date);
      const diffTime = Math.abs(fromDate - toDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      setleaveDetails(() => ({ ...leaveDetails, no_of_days: diffDays }));
    } else {
      setleaveDetails(() => ({ ...leaveDetails, no_of_days: 0 }));
    }
  }, [leaveDetails.to_date]);

  const getEmployeeName = async (key, e) => {
    if (e?.value) {
      setLoading3(true);
      try {
        const getEmployeeName = await GET_API(`/employeeGet/${e?.value}`);
        if (getEmployeeName.status === 200) {
          setleaveDetails({
            ...leaveDetails,
            [key]: e?.value,
            employee_name:
              getEmployeeName?.data?.data?.first_name +
              " " +
              getEmployeeName?.data?.data?.fathers_name,
          });
          setLoading3(false);
        }
      } catch (error) {
        setLoading3(false);
        alertify.error("Failed to load Employee Name");
      }
    }
  };

  const createLeave = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateLeave(leaveDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const addLeave = await POST_API("/AddemployeeLeave", leaveDetails);
      if (addLeave.status === 200) {
        alertify.success(addLeave?.data?.message);
        setButtonDisabled(false);
        navigate("/leaveonduty");
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error(error?.respose?.data?.message);
    }
  };

  const resetForm = () => {
    setleaveDetails({
      employee_id: "",
      type: "",
      leave_type_id: "",
      from_date: "",
      to_date: "",
      approved_by: "",
      remarks: "",
      no_of_days: 0,
      file: "",
      employee_name: "",
    });
    setFileType("");
  };

  if (loading1 || loading2 || loading3) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/leaveonduty" className="in-active">
              Leave & On Duty List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Leave & On Duty</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Leave & On Duty</div>
          <form onSubmit={(e) => createLeave(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name ">
                  Type
                  <span className="required-symbol">*</span>
                </label>
                <div className="flex gap-3">
                  <input
                    type="radio"
                    value="leave"
                    name="type"
                    className="input-field"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={leaveDetails.type === "leave"}
                  />
                  Leave
                  <input
                    type="radio"
                    value="onduty"
                    name="type"
                    className="input-field"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={leaveDetails.type === "onduty"}
                  />
                  On Duty
                </div>
                {errors.type ? (
                  <div className="validation-error-label">{errors.type}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Employee Code
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={employeeList}
                  value={
                    leaveDetails.employee_id
                      ? employeeList?.find(
                          (option) => option?.value === leaveDetails.employee_id
                        )
                      : ""
                  }
                  onChange={(e) => getEmployeeName("employee_id", e)}
                  name="employee_id"
                  className=""
                  isSearchable
                />
                {errors.employee_id ? (
                  <div className="validation-error-label">
                    {errors.employee_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Employee Name"
                  name="emp_name"
                  value={`${leaveDetails?.employee_name}`}
                  readOnly
                  disabled
                />
                {errors.employee_name ? (
                  <div className="validation-error-label">
                    {errors.employee_name}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Leave Type
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={leaveDetail}
                  value={
                    leaveDetails.leave_type_id
                      ? leaveDetail?.find(
                          (option) =>
                            option?.value === leaveDetails.leave_type_id
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "leave_type_id")}
                  name="leave_type_id"
                  className=""
                  isSearchable
                />
                {errors?.leave_type_id ? (
                  <div className="validation-error-label">
                    {errors?.leave_type_id}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  From
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  placeholder="Select From Date"
                  name="from_date"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.from_date}
                  // min={today}
                  max={today}
                />
                {errors?.from_date ? (
                  <div className="validation-error-label">
                    {errors?.from_date}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  To
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  placeholder="Select To Date"
                  name="to_date"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.to_date}
                  // min={today}
                  max={today}
                />
                {errors?.to_date ? (
                  <div className="validation-error-label">
                    {errors?.to_date}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  No Of Days
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter No.Of.Days"
                  name="no_of_days"
                  readOnly
                  disabled
                  value={leaveDetails?.no_of_days}
                />
                {errors?.no_of_days ? (
                  <div className="validation-error-label">
                    {errors?.no_of_days}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Approved by
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={approvedBy}
                  value={
                    leaveDetails.approved_by
                      ? approvedBy?.find(
                          (option) => option?.value === leaveDetails.approved_by
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "approved_by")}
                  name="approved_by"
                  className=""
                  isSearchable
                />
                {errors.approved_by ? (
                  <div className="validation-error-label">
                    {errors.approved_by}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Select File
                  {/* <span className="required-symbol">*</span> */}
                </label>
                <div className="">
                  <div className="flex gap-2 items-center">
                    {fileType !== "image" ? (
                      <FilePresentIcon className="employee-profile-picture" />
                    ) : (
                      <img
                        name="file"
                        className="h-[35px] w-[35px]"
                        src={leaveDetails?.file}
                        alt=""
                      />
                    )}
                    <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                      <label for="files" className="label-name cursor-pointer">
                        {leaveDetails?.file === "" ? "Choose" : "Replace"}
                      </label>
                      <input
                        className="w-0 cursor-pointer"
                        id="files"
                        style={{ visibility: "hidden" }}
                        type="file"
                        // accept=".jpeg,.jpg,.png"
                        onChange={(e) => handleProfileImage(e)}
                      />
                      <img src={FileUpload} alt="" />
                    </div>
                  </div>
                  {errors?.file ? (
                    <div className="validation-error-label" key={errors?.file}>
                      {errors?.file}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="px-6 py-3 grid grid gap-2">
              <label className="label-name">
                Remarks
                <span className="font-bold" style={{ color: "#FF5C42" }}>
                  *
                </span>
              </label>
              <textarea
                className="input-field"
                type="date"
                autoComplete="off"
                placeholder="Remarks"
                name="remarks"
                onChange={(e) => {
                  handleChange(e);
                }}
                rows={5}
                value={leaveDetails?.remarks}
              />
              {errors?.remarks ? (
                <div className="validation-error-label">{errors?.remarks}</div>
              ) : null}
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateLeaveOnduty;
