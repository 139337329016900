import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import ExpensePrintVoucher from "./ExpensePrintVoucher";
import dayjs from "dayjs";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";

function CreateExpense() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const [fileType, setFileType] = useState("");
  const [voiceDet, setVoiceDet] = useState("");
  const [expenseDetails, setExpenseDetails] = useState({
    employee_id: "",
    type: "expense",
    employee_name: "",
    designation: "",
    department: "",
    finance_category_id: "",
    amount: "",
    total_paid_amount: "",
    duration: "",
    expense_date: dayjs(new Date()).format("YYYY-MM-DD"),
    remarks: "",
    approved_by: "",
    file: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [designationName, setdesignationName] = useState("");
  const [departmentName, setdepartmentName] = useState("");
  const [branchName, setbranchName] = useState("");

  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  const [printVoucherData, setPrintVoucherData] = useState({});
  const [expenseList, setExpenseList] = useState([]);

  useEffect(() => {
    getEmployeeList();
    getExpensesList();
  }, []);


  const handleProfileImage = (e) => {
    const type = e?.target?.files[0]?.type?.split("/").shift();
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setExpenseDetails({ ...expenseDetails, file: fileReader?.result });
        setFileType(type);
      }
    };
    fileReader?.readAsDataURL(e?.target?.files[0]);
  };


  const handleVoiceNote = (e) => {
    const type = e?.target?.files[0]?.type?.split("/").shift();
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setExpenseDetails({ ...expenseDetails, voice_note: fileReader?.result });
        setVoiceDet(type);
      }
    };
    fileReader?.readAsDataURL(e?.target?.files[0]);
  };

  const handleDesignation = async (key, e) => {
    const { value } = e;
    setExpenseDetails(() => ({ ...expenseDetails, [key]: value }));
    // try {
    //   const designation = await GET_API(`/employeeGet/${value}`);
    //   if (designation?.data?.status === 200) {
    //     setdesignationName(designation?.data?.data.designation_name);
    //     setdepartmentName(designation?.data?.data.department_name);
    //     setbranchName(designation?.data?.data.branch_name);
    //   }
    // } catch (error) {
    //   setExpenseDetails(() => ({ ...expenseDetails, [key]: value }));
    // }
  };

  const getExpensesList = async () => {
    setLoading(true);
    GET_API("/financeCategoriesDropdownExpense")
      .then((response) => {
        const expense = response?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setExpenseList(expense);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setExpenseList([]);
      });
  };

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList.status === 200) {
        const empList = employeeList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        setEmployeeList(empList);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to get Employee List...");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setExpenseDetails(() => ({ ...expenseDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setExpenseDetails(() => ({ ...expenseDetails, [name]: e?.value }));
  };

  // const printVoucher = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  // useEffect(() => {
  //   if (isPrintVoucher) {
  //     printVoucher();
  //     navigate("/expense");
  //   }
  // }, [isPrintVoucher, printVoucher]);

  const createExpense = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateExpense(expenseDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const userId = localStorage.getItem("user_id");
    POST_API("/addExpenseDetails", { ...expenseDetails, created_by: userId })
      .then(async function (data) {
        alertify.success(data?.data?.message);
        navigate("/expense");
        // setPrintVoucherData(data?.data?.data[0]);
        // setTimeout(() => {
        //   setButtonDisabled(false);
        //   printVoucher();
        //   setIsPrintVoucher(true);
        // }, 1000);
      })
      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const today = new Date().toISOString().split('T')[0];

  const resetForm = () => {
    setExpenseDetails({
      employee_id: "",
      type: "expense",
      employee_name: "",
      designation: "",
      department: "",
      finance_category_id: "",
      amount: "",
      total_paid_amount: "",
      duration: "",
      approved_by: "",
    });
    setdesignationName("");
    setdepartmentName("");
    setbranchName("");
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense" className="in-active">
              Expense List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Expense</Link>
          </div>
        </div>

        <div className="card-wrapper">
          <div className="card-header">Add Expense</div>

          {isPrintVoucher && (
            <ExpensePrintVoucher
              ref={componentRef}
              printVoucherData={printVoucherData}
            />
          )}
          {/***************Form************* **/}

          <form onSubmit={(e) => createExpense(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={employeeList}
                  value={
                    expenseDetails.employee_id
                      ? employeeList?.find(
                          (option) =>
                            option?.value === expenseDetails.employee_id
                        )
                      : ""
                  }
                  onChange={(e) => handleDesignation("employee_id", e)}
                  name="employee_id"
                  className=""
                  isSearchable
                />
                {errors["employee_id"] ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {errors["employee_id"]}
                  </div>
                ) : null}
              </div>

              {/* <div className="flex flex-col gap-1 min-h-[95px]">
                <label className="label-name">
                  Designation
                  <span className="required-symbol">*</span>
                </label>

                <input
                  className="input-field"
                  name="designation"
                  placeholder="select department"
                  value={designationName}
                  readOnly
                  disabled
                />

                {errors.designation ? (
                  <div className="validation-error-label">
                    {errors.designation}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-1 min-h-[95px]">
                <label className="label-name">
                  Department Name
                  <span className="required-symbol">*</span>
                </label>

                <input
                  className="input-field"
                  name="department"
                  placeholder="select department"
                  value={departmentName}
                  readOnly
                  disabled
                />

                {errors.department ? (
                  <div className="validation-error-label">
                    {errors.department}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-1 min-h-[95px]">
                <label className="label-name">
                  Branch Name
                  <span className="required-symbol">*</span>
                </label>

                <input
                  className="input-field"
                  name="branch"
                  placeholder="select department"
                  value={branchName}
                  readOnly
                  disabled
                />

                {errors.branch ? (
                  <div className="validation-error-label">{errors.branch}</div>
                ) : null}
              </div> */}

              <div className="field-block">
                <label className="label-name">
                  Expense Type<span className="required-symbol">*</span>
                </label>
                <Select
                  options={expenseList}
                  value={
                    expenseDetails.finance_category_id
                      ? expenseList?.find(
                          (option) =>
                            option?.value === expenseDetails.finance_category_id
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "finance_category_id")}
                  name="finance_category_id"
                  className=""
                  isSearchable
                />
                {errors.finance_category_id ? (
                  <div className="validation-error-label">
                    {errors.finance_category_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="amount"
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Amount"
                  value={expenseDetails?.amount}
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>

              {/* <div className="field-block">
                <label className="label-name">Approval</label>
                <Select
                  options={employeeList}
                  value={
                    expenseDetails.approved_by
                      ? employeeList?.find(
                          (option) =>
                            option?.value === expenseDetails.approved_by
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "approved_by")}
                  name="approved_by"
                  className=""
                  isSearchable
                />
                {errors.approval ? (
                  <div className="validation-error-label">
                    {errors.approval}
                  </div>
                ) : null}
              </div> */}

              <div className="field-block">
                <label className="label-name">Expense Date</label>
                <input
                  type="date"
                  name="expense_date"
                  className="input-field"
                  value={expenseDetails?.expense_date}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  min={today}
                  max={today}
                />
                {errors.expense_date ? (
                  <div className="validation-error-label">
                    {errors.expense_date}
                  </div>
                ) : null}
              </div>

              

              <div className="field-block">
                <label className="label-name">
                  Select File
                  {/* <span className="required-symbol">*</span> */}
                </label>
                <div className="">
                  <div className="flex gap-2 items-center">
                    {fileType !== "image" ? (
                      <FilePresentIcon className="employee-profile-picture" />
                    ) : (
                      <img
                        name="file"
                        className="h-[35px] w-[35px]"
                        src={expenseDetails?.file}
                        alt=""
                      />
                    )}
                    <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                      <label for="files" className="label-name cursor-pointer">
                        {expenseDetails?.file === "" ? "Choose" : "Replace"}
                      </label>
                      <input
                        className="w-0 cursor-pointer"
                        id="files"
                        style={{ visibility: "hidden" }}
                        type="file"
                        // accept=".jpeg,.jpg,.png"
                        onChange={(e) => handleProfileImage(e)}
                      />
                      <img src={FileUpload} alt="" />
                    </div>
                  </div>
                  {errors?.file ? (
                    <div className="validation-error-label" key={errors?.file}>
                      {errors?.file}
                    </div>
                  ) : null}
                </div>
              </div>


              <div className="field-block">
                <label className="label-name">
                  Select Voice Note
                  {/* <span className="required-symbol">*</span> */}
                </label>
                <div className="">
                  <div className="flex gap-2 items-center">
                    {voiceDet !== "image" ? (
                      <FilePresentIcon className="employee-profile-picture" />
                    ) : (
                      <img
                        name="voice_note"
                        className="h-[35px] w-[35px]"
                        src={expenseDetails?.voice_note}
                        alt=""
                      />
                    )}
                    <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                      <label for="voice_note" className="label-name cursor-pointer">
                        {expenseDetails?.voice_note === "" ? "Choose" : "Replace"}
                      </label>
                      <input
                        className="w-0 cursor-pointer"
                        id="voice_note"
                        style={{ visibility: "hidden" }}
                        type="file"
                        // accept=".jpeg,.jpg,.png"
                        onChange={(e) => handleVoiceNote(e)}
                      />
                      <img src={FileUpload} alt="" />
                    </div>
                  </div>
                  {errors?.voice_note ? (
                    <div className="validation-error-label" key={errors?.voice_note}>
                      {errors?.voice_note}
                    </div>
                  ) : null}
                </div>
              </div>


              <div className="field-block">
                <label className="label-name">Remarks</label>
                <textarea
                  type="date"
                  name="remarks"
                  rows={5}
                  className="input-field"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>
              

            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateExpense;
