import React from "react";
import { Route } from "react-router-dom";

import Payslip from "../PayRoll/GeneratePaySlips/GeneratePayslip";
import PayslipDetails from "../PayRoll/GeneratePaySlips/payslipsDetails";
import ManualSlip from "../PayRoll/ManualPf/manualpf";
import BankSalary from "../PayRoll/BankSalary";

import PayslipDetailsList from "../PayRoll/PayslipDetails/PayslipDetailsList";
import PayslipDetailsView from "../PayRoll/PayslipDetails/PayslipDetailsView";
import SalaryDetails from "../PayRoll/SalaryDetails";
import DaySalaryDetails from "../PayRoll/DaySalaryDetails";

function PayslipRoutes() {
  return (
    <>
      <Route path="payslip" element={<Payslip />}></Route>
      <Route path="payslip/details/:id" element={<PayslipDetails />}></Route>
      <Route path="manualpf" element={<ManualSlip />}></Route>
      <Route path="banksalary" element={<BankSalary />}></Route>
      <Route path="payslipdetails" element={<PayslipDetailsList />}></Route>
      <Route path="payslipdetails/details/:id" element={<PayslipDetailsView />}></Route>
      <Route path="salaryDetails" element={<SalaryDetails />}></Route>
      <Route path="daySalaryDetails" element={<DaySalaryDetails />}></Route>
    </>
  );
}

export default PayslipRoutes;
