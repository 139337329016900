import React from "react";
import { Link, useLocation } from "react-router-dom";

function LoanReportDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const father_name =
    listDetails?.fathers_name !== "" && listDetails?.fathers_name !== undefined
      ? listDetails?.fathers_name
      : "";
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.employee_name + " " + father_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.employeeCode,
    },
    {
      label: "Branch Name",
      value: listDetails?.branchName,
    },
    {
      label: "Employee Type Name",
      value: listDetails?.employeeTypeName,
    },
    {
      label: "Department Name",
      value: listDetails?.department_name,
    },
    {
      label: "Designation Name",
      value: listDetails?.designation_name,
    },
    {
      label: "Loan Type",
      value: `${listDetails?.type}`,
    },
    {
      label: "Amount",
      value: `<div><span>&#8377; ${listDetails?.amount}</span></div>`,
    },
    {
      label: "Voucher Paid Status",
      value: `${listDetails?.voucher_status === 1 ? "Paid" : "Unpaid"}`,
    },
    {
      label: "Status",
      value: `${listDetails?.paid_amount === 1 ? "Paid" : "Unpaid"}`,
    },

    {
      label: "Paid Amount",
      value: `<div><span>&#8377; ${listDetails?.paid_amount}</span></div>`,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/terminated-employee-loan-report" className="in-active">
            Terminated Employee Loan List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Terminated Employee Loan Detail</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Terminated Employee Loan Detail</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      className="list-value"
                      dangerouslySetInnerHTML={{ __html: list?.value }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanReportDetails;
