import React from "react";
import { Link, useLocation } from "react-router-dom";

function ConsultancyDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Company Name",
      value: listDetails?.company_name,
    },

    {
      label: "Contact Person",
      value: listDetails?.contact_person,
    },
    {
      label: "Contact Number",
      value: listDetails?.phone,
    },
    {
      label: "Email",
      value: listDetails?.contact_email,
    },

    {
      label: "Address",
      value: listDetails?.address,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/agent/consultancy" className="in-active">
              Consultancy List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Consultancy Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Consultancy Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list" key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultancyDetails;
