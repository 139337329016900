import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BankInfo from "./DetailsPage/BankInfo";
import SalaryInfo from "./DetailsPage/SalaryInfo";
import Spinner from "../Spinner";
import { GET_API } from "../../Services/api";
import FoodAllowance from "./DetailsPage/FoodAllowance.js";
import Allowance from "./DetailsPage/Allowance.js";
import config from "../../Services/config.json";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import alertify from "alertifyjs";
function EmployeeDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [breakDetails, setBreakDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [empDetails, setEmpDetails] = useState({});
  const [currentTab, setCurrentTab] = useState("bank_info");
  const [loanDetails, setLoanDetails] = useState({});
  const [attendanceDetails, setAttendanceDetails] = useState([]);

  useEffect(() => {
    const getEmployeeData = async () => {
      try {
        setIsLoading(true);
        const employee = await GET_API(`/employeeGet/${id}`);
        setEmpDetails({
          ...employee?.data?.data,
          id_proof_number: employee?.data?.data?.government_proof_value,
        });
        setIsLoading(false);
      } catch (error) {
        setEmpDetails([]);
        setIsLoading(false);
      }
    };
    getEmployeeData();
  }, [id]);

  useEffect(() => {
    getBreakDetails();
    getLoanDetails();
    getAttendanceDetails();
  }, []);

  const getLoanDetails = async () => {
    try {
      const getLoan = await GET_API(`loanledgerReport/${id}`);
      if (getLoan?.data?.status === 200) {
        setLoanDetails(getLoan?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Loan details");
    }
  };

  const getAttendanceDetails = async () => {
    try {
      const getAttendance = await GET_API(`attendanceEmpwiseData/${id}`);
      if (getAttendance?.data?.status === 200) {
        setAttendanceDetails(getAttendance?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Loan details");
    }
  };

  const getBreakDetails = async () => {
    setLoading(true);
    GET_API(`/employeesView?employee_id=${id}`)
      .then(async function (data) {
        setLoading(false);
        setBreakDetails(data?.data?.data);
      })
      .catch((error) => setLoading(false));
  };

  if (loading) {
    return <Spinner />;
  }
  const handleTabHeaderChange = (dummy, newValue) => {
    setCurrentTab(newValue);
  };

  const myStyle = {
    backgroundColor: "#D99719",
  };
  return (
    <div className=" bg-[#F7F6F4] grow w-[80%]">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/employee/list" className="in-active">
              Employee List
            </Link>

            <Link>{" > "}</Link>
            <Link className="active">Employee Details</Link>
          </div>
        </div>
        <div className="card-wrapper" style={{ backgroundColor: "#efefef" }}>
          <div className="p-5 flex gap-3">
            <div className="w-[35%] shadow-lg shadow-gray-500 p-5 bg-white-color">
              {empDetails?.profile_image !== "" ? (
                <img
                  src={config?.path + empDetails?.profile_image}
                  className="h-[215px] w-full rounded-lg"
                  alt="profile_image"
                />
              ) : (
                <div
                  className="h-[215px] w-full text-[200px] rounded-3xl flex justify-center items-center text-text-dark"
                  style={{ backgroundColor: "#ccc" }}
                >
                  {empDetails?.first_name?.charAt(0).toUpperCase()}
                </div>
              )}

              <div className="p-2 py-5 flex justify-between items-center border-b-[1px] border-b-[#D9D9D9]">
                <div>
                  <h3 className="text-text-dark font-karla text-2xl ">
                    {empDetails?.first_name}
                  </h3>
                  <h6 className="font-karla text-sm text-text-light">
                    {empDetails?.emp_code}
                  </h6>
                </div>

                <div className="flex flex-col font-karla text-base">
                  <span className="text-text-light">
                    {empDetails?.employee_active === 0 ? (
                      <span
                        className=" bg-[#FF5C42] p-1"
                        style={{
                          color: "#FFF",
                          borderRadius: "4px",
                        }}
                      >
                        Deleted
                      </span>
                    ) : empDetails?.employee_active === 1 ? (
                      <span
                        className="bg-[#3EAB82] p-1"
                        style={{
                          color: "#FFF",
                          borderRadius: "4px",
                        }}
                      >
                        Approved
                      </span>
                    ) : empDetails?.employee_active === 3 ? (
                      <span
                        className="bg-[#EEC9C9] p-1"
                        style={{
                          color: "#FFF",
                          borderRadius: "4px",
                        }}
                      >
                        Pending
                      </span>
                    ) : empDetails?.employee_active === 4 ? (
                      <span
                        className="bg-[#ff0505] p-1"
                        style={{
                          color: "#FFF",
                          borderRadius: "4px",
                        }}
                      >
                        Rejected
                      </span>
                    ) : empDetails?.employee_active === 5 ? (
                      <span
                        className=" bg-[#553C7E] p-1"
                        style={{
                          color: "#FFF",
                          borderRadius: "4px",
                        }}
                      >
                        Terminated
                      </span>
                    ) : empDetails?.employee_active === 6 ? (
                      <span
                        className="bg-[#EEC9C9] p-1"
                        style={{
                          color: "#FFF",
                          borderRadius: "4px",
                        }}
                      >
                        Rejoined - Approval Pending
                      </span>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className="p-2 border-b-[1px] border-b-[#D9D9D9] flex flex-col gap-3">
                <div className="flex justify-between">
                  <div className="flex flex-col font-karla text-base">
                    <span className="text-text-dark">Branch</span>
                    <span className="text-text-light">
                      {empDetails?.branch_name}
                    </span>
                  </div>
                  <div className="flex flex-col font-karla text-base">
                    <span className="text-text-dark">Designation</span>
                    <div className="bg-[#e2e3e5] text-[#6c757d] w-max p-1 font-karla text-xs">
                      {empDetails?.designation_name?.toUpperCase()}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col font-karla text-base">
                    <span className="text-text-dark">Floor</span>
                    <span className="text-text-light">
                      {empDetails?.floorname}
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col font-karla text-base">
                    <span className="text-text-dark">Department</span>
                    <span className="text-text-light">
                      {empDetails?.department_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-2 border-b-[1px] border-b-[#D9D9D9]">
                <h3 className="text-text-dark font-karla text-lg py-2">
                  <b>Details</b>
                </h3>
                <div className="flex flex-col gap-3 font-karla">
                  <div className="flex gap-2">
                    <div className="text-text-dark">Email : </div>
                    <div className="text-text-light">{empDetails?.email}</div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">Phone : </div>
                    <div className="text-text-light ">
                      {empDetails?.phone_no}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">DOB : </div>
                    <div className="text-text-light ">
                      {empDetails?.date_of_birth}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">Blood Group : </div>
                    <div className="text-text-light ">
                      {empDetails?.blood_groupname}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">Gender : </div>
                    <div className="text-text-light ">{empDetails?.gender}</div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">Marital Status :</div>
                    <div className="text-text-light">
                      {empDetails?.marital_status}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">Qualification :</div>
                    <div className="text-text-light">
                      {empDetails?.additional_qualification}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">DOJ : </div>
                    <div className="text-text-light ">
                      {empDetails?.date_of_joining}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">Religion : </div>
                    <div className="text-text-light ">
                      {empDetails?.religionname}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">caste : </div>
                    <div className="text-text-light ">
                      {empDetails?.castename}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">Present Address </div>
                    <div className="text-text-light break-all">
                      {empDetails?.present_address}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="text-text-dark ">Permanent Address </div>
                    <div className="text-text-light break-all">
                      {empDetails?.permanent_address}
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-2">
                <div className="add-new-button-block">
                  <Link className="add-new-button" to={`/employee/edit/${id}`}>
                    <span>Edit</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="w-[65%]">
              <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleTabHeaderChange}
                    aria-label="lab API tabs example"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <Tab
                      label="Bank"
                      value="bank_info"
                      style={currentTab === "bank_info" ? myStyle : {}}
                    />
                    <Tab
                      label="Allowance"
                      value="allowance_info"
                      style={currentTab === "allowance_info" ? myStyle : {}}
                    />
                    <Tab
                      label="Others"
                      style={currentTab === "other_info" ? myStyle : {}}
                      value="other_info"
                    />
                    <Tab
                      label="Loan"
                      style={currentTab === "loan_info" ? myStyle : {}}
                      value="loan_info"
                    />

                    <Tab
                      label="Attendance"
                      style={currentTab === "attendance_info" ? myStyle : {}}
                      value="attendance_info"
                    />
                  </TabList>
                </Box>

                <TabPanel value="bank_info" style={{ backgroundColor: "#fff" }}>
                  <BankInfo listDetails={empDetails} page="details" />
                </TabPanel>

                <TabPanel
                  value="allowance_info"
                  style={{ backgroundColor: "#fff" }}
                >
                  <Allowance id={id} />
                </TabPanel>

                <TabPanel
                  value="other_info"
                  style={{ backgroundColor: "#fff" }}
                >
                  <OtherInfo listDetails={empDetails} />
                  <SalaryInfo
                    listDetails={empDetails}
                    isEditAndView={true}
                    page="detail"
                  />
                  <div className="border-t-[1px] border-t-[#D9D9D9]">
                    <FoodAllowance id={id} />
                  </div>
                </TabPanel>
                <TabPanel value="loan_info" style={{ backgroundColor: "#fff" }}>
                  <LoanDetails details={loanDetails} />
                </TabPanel>
                <TabPanel
                  value="attendance_info"
                  style={{ backgroundColor: "#fff" }}
                >
                  <AttendanceDetails details={attendanceDetails} />
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function LoanDetails({ details }) {
    return (
      <div className="list-container mt-3">
        <div className="flex gap-3 font-karla">
          <h3 className="text-text-dark">Outstanding Amount : </h3>
          <h3 className="text-text-light">
            <span>&#8377;{details?.outstandingAmount}</span>
          </h3>
        </div>
        {details.log?.length === 0 ? (
          <div className="text-center text-text-light font-karla">
            No Log Data Found
          </div>
        ) : (
          <table className="log-table mt-[4px]">
            <tr>
              <th className="log-table">Date</th>
              <th className="log-table">Remarks</th>
              <th className="log-table">Credit</th>
              <th className="log-table">Debit</th>
              <th className="log-table">Balance</th>
            </tr>
            {details.log.map((value, index) => {
              return (
                <tr>
                  <td className="log-table log-table-data content-label">
                    {value?.date}
                  </td>
                  <td className="log-table log-table-data content-label">
                    {value?.remarks}
                  </td>
                  <td className="log-table log-table-data content-label text-end">
                    <span>&#8377;{value?.credit}</span>
                  </td>
                  <td className="log-table log-table-data content-label text-end">
                    <span>&#8377;{value?.debit}</span>
                  </td>
                  <td className="log-table log-table-data content-label text-end font-bold">
                    <span>&#8377;{value?.balance}</span>
                  </td>
                </tr>
              );
            })}
          </table>
        )}
      </div>
    );
  }
  function AttendanceDetails({ details }) {
    return (
      <div className="list-container mt-3">
        <table className="log-table">
          <tr className="p-1">
            <th className="log-table p-1">Date</th>
            <th className="log-table p-1">Status</th>
            <th className="log-table p-1">In</th>
            <th className="log-table p-1">Out</th>
            <th className="log-table p-1">Late Entry</th>
          </tr>
          <tbody>
            {details.map((attendance, i) => {
              return (
                <tr>
                  <td className="log-table log-table-data p-1 text-text-light font-karla">
                    {attendance?.date}
                  </td>
                  <td className="log-table log-table-data p-1 text-text-light font-karla">
                    {attendance?.type === "absent" ? (
                      <span
                        className=" bg-[#FF5C42] p-1"
                        style={{
                          color: "#FFF",
                          borderRadius: "4px",
                        }}
                      >
                        {attendance?.type}
                      </span>
                    ) : (
                      <span
                        className="bg-[#3EAB82] p-1"
                        style={{
                          color: "#FFF",
                          borderRadius: "4px",
                        }}
                      >
                        {attendance?.type}
                      </span>
                    )}
                  </td>
                  <td className="log-table log-table-data p-1 text-text-light font-karla">
                    {attendance?.in}
                  </td>
                  <td className="log-table log-table-data p-1 text-text-light font-karla">
                    {attendance?.out}
                  </td>
                  <td
                    className={`log-table log-table-data p-1 text-text-light font-karla ${
                      attendance?.lateEntry === 1 ? "text-[#ef0000]" : ""
                    }`}
                  >
                    {attendance?.type === "present"
                      ? attendance?.lateEntry === 1
                        ? "Yes"
                        : "No"
                      : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function OtherInfo({ listDetails }) {
    const list = [
      {
        label: "District",
        value: listDetails?.district,
      },
      {
        label: "Town",
        value: listDetails?.town,
      },
      {
        label: "Employee Type Name",
        value: listDetails?.employeeTypeName,
      },
      {
        label: "Shift",
        value: listDetails?.shift_name,
      },
      {
        label: "Reporting",
        value: listDetails?.reportingtoname,
      },

      {
        label: "HR Agent",
        value: listDetails?.agentname,
      },
      {
        label: "Alternative Mobile No",
        value: listDetails?.alternate_phone_no,
      },
      {
        label: "ID Prood",
        value: listDetails?.proofname,
      },
      {
        label: "Proof No",
        value: listDetails?.id_proof_number,
      },
      {
        label: "Passed out",
        value: listDetails?.passed_out,
      },
      {
        label: "Account Holder Name",
        value: listDetails?.account_holder_name,
      },
      {
        label: "Account Number",
        value: listDetails?.account_number,
      },
      {
        label: "IFSC Code",
        value: listDetails?.ifsc_code,
      },
      {
        label: "Bank Name",
        value: listDetails?.bank_name,
      },
      {
        label: "Bank Branch",
        value: listDetails?.bank_branch,
      },
      {
        label: "PF Number",
        value: listDetails?.pf_number,
      },
      {
        label: "PF Complaint",
        value: listDetails?.pf_complaint === 1 ? "Yes" : "No",
      },
      {
        label: "ESI Number",
        value: listDetails?.esi_number,
      },
      {
        label: "Bonus",
        value: listDetails?.bonus,
      },
      {
        label: "PRPY Scheme",
        value: listDetails?.pmrpy_scheme === 1 ? "Yes" : "No",
      },
      {
        label: "Halfday Variant Name",
        value: listDetails?.halfday_variant_name,
      },
    ];
    return (
      <div className="list-container mt-3">
        <div className="list-content-block">
          {list.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default EmployeeDetails;
