import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BreakItems from "./BreakItems";
import Delete from "../../../Assets/Icons/Delete.svg";
import "alertifyjs/build/css/alertify.css";

function EmployeeBreak({ breakValue, empBreakValue, setBreakValue, deleteBreak }) {

    const [isOpen, setIsOpen] = useState(false);
    const addMoreList = () => {
        const newList = {
            name: "",
            start: "",
            end: "",
            duration: "",
        };
        setBreakValue([...breakValue, { ...newList }]);
    };

    const deleteList = (index) => {
        const newItems = [...breakValue];
        newItems.splice(index, 1);
        setBreakValue(newItems);
    };

    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const errorModal = () => {
        return (
            <div>
                <Dialog
                    className="testing"
                    open={isOpen}
                    onClose={handleModal}
                    disableBackdropClick={false}
                    PaperProps={{
                        style: {
                            minWidth: "400px",
                            minHeight: "220px",
                        },
                    }}
                >
                    <DialogTitle></DialogTitle>
                    <DialogContent className="text-center">
                        <CancelOutlinedIcon className="text-[#ef4444] text-7xl emp-allowance-error" />
                        <p className="mt-4">
                            Please Enter/Select all the Mandatory fields...
                        </p>
                    </DialogContent>
                    <DialogActions className="text-center">
                        <button
                            type="button"
                            className="reset-button"
                            onClick={handleModal}
                        >
                            <span className="reset-button-label">Close</span>
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    return (
        <div className="border-t-[1px] border-t-[#D9D9D9]">
            <div
                className="w-3/12 p-3 bg-primary-color text-center"
                style={{ borderRadius: "0px 0px 100px 0px" }}
            >
                <span className="text-white-color font-karla font-base ">
                    Break Info
                </span>
            </div>
            <div className="card-wrapper border-b-[1px] border-b-[#D9D9D9]">
                {breakValue?.map((list, i) => {
                    return (
                        <BreakItems
                            index={i}
                            addMoreList={addMoreList}
                            state={EmployeeBreak}
                            deleteList={deleteList}
                            item={list}
                            breakValue={breakValue}
                            setBreakValue={setBreakValue}
                        />
                    );
                })}
            </div>
            <div className="list-container py-4">
                <div className=" grid grid-cols-5 gap-5">
                    {empBreakValue?.length > 0 &&
                        empBreakValue?.map((item, i) => {
                            return (
                                <>
                                    <div className="list-full">
                                        <label className="list-label">Break Name </label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value">
                                            {item?.name}
                                        </div>
                                    </div>
                                    <div className="list-full">
                                        <label className="list-label">Start</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value">
                                            {item?.start}
                                        </div>
                                    </div>
                                    <div className="list-full">
                                        <label className="list-label">End</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value">
                                            {item?.end}
                                        </div>
                                    </div>
                                    <div className="list-full">
                                        <label className="list-label">Duration</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value">
                                            {item?.duration}
                                        </div>
                                    </div>
                                    <div className="list-full">
                                        {/* <label className="list-label">Amount</label> */}
                                        <div className="list-value">
                                            <button className="mt-5" onClick={() => { deleteBreak(item?.id) }} >
                                                <img src={Delete} alt="" className="w-6 h-6" />
                                            </button>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                </div>
            </div>
            {errorModal()}
        </div>
    );
}

export default EmployeeBreak;
