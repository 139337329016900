import React, { useState, useEffect } from "react";
import Select from "react-select";
import alertify from "alertifyjs";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import EmployeeDetails from "./Components/EmployeeDetails";
import FineDetailsTable from ".././../../Components/DataTable/FineDetailsTable";
import LoanTable from "../../../Components/DataTable/LoanTable";
import AdvanceTable from "../../../Components/DataTable/AdvanceTable";
import AllowanceDetailsTable from "./Components/AllowanceDetailsTable";
import TerminationTable from "./Components/TerminationTable";
import "alertifyjs/build/css/alertify.css";
import Filter from "../../../Assets/Icons/Filter.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import ButtonLoader from "../../ButtonLoader";
import ExpenseTable from "../../../Components/DataTable/ExpenseTable";
function EmployeeMasterReport() {
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [empCode, setEmpCode] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [breakDetails, setBreakDetails] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendancDayArr, setAttendanceDayArr] = useState([]);
  const [displayMonth, setDisplayMonth] = useState([]);
  const [presentDetails, setPresentDetails] = useState([]);
  const [employeeMaster, setEmployeeMaster] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [isReportLoading, setIsReportLoading] = useState(false);

  const [loanDetails, setLoanDetails] = useState([]);
  const [loanCurrentPage, setLoanCurrentPage] = useState(1);
  const [loanPageSize, setLoanPageSize] = useState(10);
  const [loanLastPage, setLoanLastPage] = useState();

  const [advanceDetails, setAdvanceDetails] = useState([]);
  const [advanceCurrentPage, setAdvanceCurrentPage] = useState(1);
  const [advancePageSize, setAdvancePageSize] = useState(10);
  const [advanceLastPage, setAdvanceLastPage] = useState();

  const [expenseDetails, setExpenseDetails] = useState([]);
  const [expenseCurrentPage, setExpenseCurrentPage] = useState(1);
  const [expensePageSize, setExpensePageSize] = useState(10);
  const [expenseLastPage, setExpenseLastPage] = useState();

  const [fineDetails, setFineDetails] = useState([]);
  const [fineCurrentPage, setFineCurrentPage] = useState(1);
  const [finePageSize, setFinePageSize] = useState(10);
  const [fineLastPage, setFineLastPage] = useState();

  const [filterList, setfilterList] = useState({
    month: "",
    year: "",
  });
  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    getLoanDetails(empCode, loanCurrentPage, loanPageSize);
  }, [loanCurrentPage, loanPageSize]);

  useEffect(() => {
    getAdvanceDetails(empCode, advanceCurrentPage, advancePageSize);
  }, [advanceCurrentPage, advancePageSize]);

  useEffect(() => {
    getExpenseDetails(empCode, expenseCurrentPage, expensePageSize);
  }, [expenseCurrentPage, expensePageSize]);

  useEffect(() => {
    getFineDetails(empCode, expenseCurrentPage, expensePageSize);
  }, [expenseCurrentPage, expensePageSize]);

  const getEmployeeList = async () => {
    setIsLoading(true);
    GET_API("/employeeDropdown")
      .then(async function (data) {
        const empList = data?.data?.data.map((dep, i) => {
          return {
            value: dep?.emp_code,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        setEmployeeList(empList);
        setIsLoading(false);
      })

      .catch((error) => {
        setEmployeeList([]);
        setIsLoading(false);
      });
  };

  const isLoanPageNotExit = () => {
    setLoanCurrentPage(1);
    getLoanDetails(empCode, 1, loanPageSize);
  };

  const isAdvancePageNotExit = () => {
    setLoanCurrentPage(1);
    getAdvanceDetails(empCode, 1, advancePageSize);
  };

  const isExpensePageNotExit = () => {
    setExpenseCurrentPage(1);
    getExpenseDetails(empCode, 1, expensePageSize);
  };

  const isFinePageNotExit = () => {
    setFineCurrentPage(1);
    getFineDetails(empCode, 1, finePageSize);
  };

  const getLoanDetails = async (Empcode, page, size) => {
    if (Empcode === "") return;
    try {
      const loanList = await GET_API(
        `/FinanceDetailsList/loan?employee_code=${Empcode}&page=${page}&size=${size}`
      );
      if (loanList.status === 200) {
        if (
          loanList?.data?.data?.loanCurrentPage >
          loanList?.data?.data?.loanLastPage
        ) {
          isLoanPageNotExit();
          return;
        }
        setLoanDetails(loanList?.data?.data?.data);
        setLoanLastPage(loanList?.data?.data?.last_page);
      }
    } catch (e) {
      setLoanDetails([]);
      alertify.error("Failed To get Loan Details");
    }
  };

  const getAdvanceDetails = async (Empcode, page, size) => {
    if (Empcode === "") return;
    try {
      const advanceList = await GET_API(
        `/FinanceDetailsList/advance?employee_code=${Empcode}&page=${page}&size=${size}`
      );
      if (advanceList?.data?.status === 200) {
        if (
          advanceList?.data?.data?.advanceCurrentPage >
          advanceList?.data?.data?.advanceLastPage
        ) {
          isAdvancePageNotExit();
          return;
        }
        setAdvanceDetails(advanceList?.data?.data?.data);
        setAdvanceLastPage(advanceList?.data?.data?.last_page);
      }
    } catch (error) {
      setAdvanceDetails(false);
      alertify?.error("Failed to get Advance Details...");
    }
  };

  const getExpenseDetails = async (Empcode, page, size) => {
    if (Empcode === "") return;
    try {
      const expenseList = await GET_API(
        `/ExpenseDetailsList?employee_code=${Empcode}&page=${page}&size=${size}`
      );
      if (expenseList.status === 200) {
        if (
          expenseList?.data?.data?.expenseCurrentPage >
          expenseList?.data?.data?.expenseLastPage
        ) {
          isExpensePageNotExit();
          return;
        }
        setExpenseDetails(expenseList?.data?.data?.data);
        setExpenseLastPage(expenseList?.data?.data?.last_page);
      }
    } catch (error) {
      alertify.error("Failed to get Expense Details");
    }
  };

  const getFineDetails = async (Empcode, page, size) => {
    if (Empcode === "") return;
    try {
      const fineList = await GET_API(
        `/employeeFineList?employee_code=${Empcode}&page=${page}&size=${size}`
      );
      if (fineList?.status === 200) {
        if (
          fineList?.data?.data?.current_page > fineList?.data?.data?.last_page
        ) {
          isFinePageNotExit();
          return;
        }
        setFineDetails(fineList?.data?.data?.data);
        setFineLastPage(fineList?.data?.data?.last_page);
      }
    } catch (error) {
      alertify?.error("Failed to get Fine Details");
    }
  };

  useEffect(() => {
    if (employeeDetails?.id) {
      getBreakDetails();
    }
  }, [employeeDetails?.id]);

  const getBreakDetails = async () => {
    setIsLoading(true);
    GET_API(`/employeesView?employee_id=${employeeDetails?.id}`)
      .then(async function (data) {
        setIsLoading(false);
        setBreakDetails(data?.data?.data);
      })
      .catch((error) => {
        setBreakDetails([]);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  const handleChange = (e) => {
    const { value } = e;
    setEmpCode(value);
  };

  const getMasterReport = async () => {
    try {
      if (empCode) {
        setIsReportLoading(true);
        const masterReport = await GET_API(
          `/masterReport?employee_code=${empCode}${filterParams}`
        );
        if (masterReport?.status === 200) {
          const list = masterReport?.data?.data;
          setEmployeeMaster(list);
          setEmployeeDetails(list?.employeeDetails);
          setAttendanceData(list?.attendance);
          setAttendanceDayArr(list?.daysArr);
          setPresentDetails(list?.presentDetails);
          setDisplayMonth(list?.displayMonth);
          setIsReportLoading(false);
          if (list?.employeeDetails) {
            setIsFilter(true);
          }
          getLoanDetails(empCode, loanCurrentPage, loanPageSize);
          getAdvanceDetails(empCode, advanceCurrentPage, advancePageSize);
          getExpenseDetails(empCode, expenseCurrentPage, expensePageSize);
          getFineDetails(empCode, fineCurrentPage, finePageSize);
        }
      } else {
        setIsFilter(false);
        alertify.alert("Please select Employee!").setHeader("<b> Alert </b> ");
      }
    } catch (error) {
      alertify?.error("Failed to get employee master report");
    }
  };

  const handleFieldChange = (e) => {
    const month = dayjs(e).format("MM");
    const year = dayjs(e).format("YYYY");
    filterList["year"] = year;
    filterList["month"] = month;
    const list = { ...filterList };
    handleFilter(list, "");
  };

  const handleFilter = async (value, name) => {
    const list = { ...value };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };
  const fineColumns = [
    { id: "s.no", label: "S.No" },
    { id: "empCode", label: "Employee Code" },
    { id: "fineDetails_name", label: "Employee Name" },
    { id: "fine_type_name", label: "Fine Type" },
    { id: "fine_amount", label: "Fine Amount" },
    { id: "remarks", label: "Remarks" },
    { id: "created_at", label: "Created At" },
  ];

  const loanColumns = [
    { id: "s_no", label: "S.No" },
    { id: "EmployeeCode", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "payment_type", label: "Payment Type" },
    { id: "amount", label: "Amount" },
    { id: "paid_amount", label: "Paid Amount" },
    { id: "pending_amount", label: "Pending Amount" },
    { id: "paid_status", label: "Voucher Paid Status" },
    { id: "status", label: "Status" },
    { id: "more_icon", label: "" },
  ];

  const advanceColumns = [
    { id: "s_no", label: "S.No" },
    { id: "employee_code", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "paid_status", label: "Paid Status" },
    { id: "created_at", label: "Date" },
    { id: "amount", label: "Amount" },
    { id: "status", label: "Status" },
    { id: "more_icon", label: "" },
  ];

  const expenseColumns = [
    { id: "s.no", label: "S.No" },
    { id: "EmployeeCode", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "amount", label: "Amount" },
    { id: "paid_status", label: "Paid Status" },
    { id: "created_at", label: "Created at" },
    { id: "status", label: "Status" },
    { id: "more_icon", label: "" },
  ];

  const allowanceDetailsColumns = [
    { id: "s_no", label: "S.No" },
    { id: "allowance_name", label: "Allowance Name" },
    { id: "allowance_description", label: "Description" },
    { id: "calculation_method", label: "Calculation Method" },
    { id: "amount", label: "Amount" },
  ];

  const terminationColumns = [
    { id: "s_no", label: "S.No" },
    { id: "termination_name", label: "Termination Name" },
    { id: "termination_description", label: "Description" },
    { id: "remarks", label: "Remarks" },
    { id: "terminated_by", label: "Terminated By" },
    { id: "created_at", label: "Terminated At" },
  ];

  return (
    <div className="bg-[#F7F6F4] grow" style={{ width: "80%" }}>
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Employee Master Report List</h4>
          <div className="search-block">
            <div
              className="flex gap-3"
              style={{ alignItems: "flex-end", flexDirection: "unset" }}
            >
              <div className="dropdown-select-to-first" style={{ width: "250px" }}>
                <label className="label-name">Select Employee</label>
                <Select
                  options={employeeList}
                  value={employeeList?.find(
                    (option) => option?.value === empCode
                  )}
                  onChange={(e) => handleChange(e, "empCode")}
                  name="empCode"
                  className=""
                  isSearchable
                />
              </div>
              {isReportLoading ? (
                <ButtonLoader isBtnDisable={isReportLoading} />
              ) : (
                <button
                  onClick={getMasterReport}
                  className="submit-button"
                  style={{ width: "30%" }}
                >
                  <span className="submit-button-label">Report</span>
                </button>
              )}
            </div>
            <div className="flex gap-3">
              {/* <div className="add-new-button-block">
                {isExportLoading ? (
                  <ButtonLoader isBtnDisable={isExportLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => getExport()}
                  >
                    <span>Export</span>
                    <img src={Export} alt="export" />
                  </button>
                )}
              </div> */}
              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
            </div>
          </div>

          {filter && (
            <form>
              <div className="pl-5 flex items-center">
                <div className="field-block mb-4">
                  <label className="label-name">Month</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker", "DatePicker"]}
                    >
                      <DatePicker
                        label={'"month" and "year"'}
                        views={["month", "year"]}
                        name="dateMonth"
                        className="custom-input-field"
                        onChange={(e) => {
                          handleFieldChange(e);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="add-new-button-block w-[13%] h-max">
                  <button
                    type="button"
                    className="add-new-button"
                    disabled={isReportLoading}
                    onClick={() => {
                      getMasterReport();
                    }}
                  >
                    <span style={{ cursor: "pointer" }}>Show Result</span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
        {employeeDetails?.id ? (
          <EmployeeDetails
            listDetails={employeeDetails ? employeeDetails : {}}
            isEditAndView={false}
            breakDetails={breakDetails}
          />
        ) : null}

        <div className="card-wrapper mt-6">
          <div className="card-header">
            <h3>
              <b>Attendance Details: </b>
            </h3>
          </div>
          <div className="grid grid-cols-2 p-6 pt-2 gap-2">
            <div>
              <p>
                Month: <b>{displayMonth}</b>
              </p>
              <p>
                Total days Present:
                <b>&nbsp;{presentDetails && presentDetails?.length}</b>
              </p>
            </div>
            <div className="flex justify-end items-center gap-3">
              <p className="flex jusitify-center items-center gap-2">
                <span className="bg-[#11B76B] border rounded border-[#11B76B] h-[14px] w-[14px]"></span>{" "}
                <span>Present</span>
              </p>
              <p className="flex jusitify-center items-center gap-2">
                <span className="bg-[#ed452a] border rounded border-[#ed452a]  h-[14px] w-[14px]"></span>
                <span>Absent</span>
              </p>
            </div>
          </div>
          <div className="p-1 pb-4 flex flex-col items-center justify-center">
            <table className="overflow-x-scroll  text-center w-2/4">
              <tr>
                {attendancDayArr &&
                  attendancDayArr?.map((list, i) => (
                    <th className="border attendance-header border-[#d9d9d9] p-3">
                      {list}
                    </th>
                  ))}
              </tr>
              {attendanceData &&
                Object.keys(attendanceData).map((key) => (
                  <>
                    <tr>
                      {attendanceData[key].map((item, index) => {
                        return (
                          <td
                            key={index}
                            className="font-karla border border-[#d9d9d9] p-3"
                          >
                            <span
                              className={`rounded px-2 py-1 ${
                                item.value === "P"
                                  ? "bg-[#11B76B] text-[#FFF]"
                                  : item.value === "A"
                                  ? "bg-[#ed452a] text-[#FFF]"
                                  : item.value === "F"
                                  ? "bg-[#DADADA] text-[#FFF]"
                                  : item.value === "-"
                                  ? "text-[#111211]"
                                  : ""
                              }`}
                            >
                              {item.date}
                            </span>
                          </td>
                        );
                      })}
                    </tr>
                  </>
                ))}
            </table>
          </div>
        </div>
        <div className="card-wrapper mt-6">
          <h4 className="card-header">Allowance Details:</h4>
          <AllowanceDetailsTable
            list={employeeMaster?.salary}
            columns={allowanceDetailsColumns}
          />
        </div>

        <div className="card-wrapper mt-6">
          <h4 className="card-header">Fine Details:</h4>
          <FineDetailsTable
            list={fineDetails}
            columns={fineColumns}
            currentPage={fineCurrentPage}
            pageSize={finePageSize}
            setPageSize={setFinePageSize}
            lastPage={fineLastPage}
            setPageNo={setFineCurrentPage}
            enableMoreOption={false}
          />
        </div>

        <div className="card-wrapper mt-6">
          <h4 className="card-header">Loan Details:</h4>
          <LoanTable
            list={loanDetails}
            columns={loanColumns}
            currentPage={loanCurrentPage}
            pageSize={loanPageSize}
            setPageSize={setLoanPageSize}
            lastPage={loanLastPage}
            setPageNo={setLoanCurrentPage}
            enableMoreOption={false}
          />
        </div>

        <div className="card-wrapper mt-6">
          <h4 className="card-header">Advance Details:</h4>
          <AdvanceTable
            list={advanceDetails}
            columns={advanceColumns}
            currentPage={advanceCurrentPage}
            pageSize={advancePageSize}
            setPageSize={setAdvancePageSize}
            lastPage={advanceLastPage}
            setPageNo={setAdvanceCurrentPage}
            enableMoreOption={false}
          />
        </div>

        <div className="card-wrapper mt-6">
          <h4 className="card-header">Expense Details:</h4>
          <ExpenseTable
            list={expenseDetails}
            columns={expenseColumns}
            currentPage={expenseCurrentPage}
            pageSize={expensePageSize}
            setPageSize={setExpensePageSize}
            lastPage={expenseLastPage}
            setPageNo={setExpenseCurrentPage}
            enableMoreOption={false}
          />
        </div>

        <div className="card-wrapper my-6">
          <h4 className="card-header">Termination Details:</h4>
          <TerminationTable
            list={employeeMaster?.termination}
            columns={terminationColumns}
          />
        </div>
      </div>
    </div>
  );
}

export default EmployeeMasterReport;
