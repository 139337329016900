import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import { useNavigate } from "react-router-dom";
import config from "../../Services/config.json";
import dayjs from "dayjs";


export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editPage,
  allowance,
  termination,
  terminateEmployee,
  activeEdit,
  activeDelete,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  
  const navigate = useNavigate();
  const showDetailsPage = (listDetail) => {
    const id = listDetail?.employeeCode;
    navigate(`/report/loan/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  }
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="9 "
                  className="table-body td-data-not-found"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list.map((row, i) => {
                const father_name = row?.father_name ? row?.father_name : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="!w-[7%] table-body ">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="!w-[7%] table-body">
                      {row?.employee_image !== "" ? (
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "40px",
                          }}
                          src={config?.path + row?.employee_image}
                          alt="profile_image"
                        />
                      ) : (
                        <div
                          className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                          style={{ backgroundColor: "#ccc" }}
                        >
                          {row?.first_name?.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <a onClick={() => showDetailsPage(row)} style={{ color: "#0284C7" }} className="cursor-pointer text-sky-600 border-b-[1px] border-b-[#0284C7]" >
                          {row.employee_code}
                        </a>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_name }
                      </TableCell>
                    }
                     {
                      <TableCell className="table-body">
                        {row?.floor_name}
                      </TableCell>
                    }
                    {/* {
                      <TableCell className="table-body">
                        {row?.department_name}
                      </TableCell>
                    } */}
                    {
                      <TableCell className="table-body">
                        {row?.designation_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.amount}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body" >
                        {dayjs(row?.deduction_date).format("DD-MM-YYYY")}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body" >
                        {dayjs(row?.reimbursement_date).format("DD-MM-YYYY")}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body" >
                        {row?.remarks}
                      </TableCell>
                    }
                   
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
