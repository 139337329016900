import React, { useState } from "react";
import MenuItems from "../MenuItems";
import Toggle from "../../Assets/Icons/Toggle.svg";
import $ from "jquery";
import { Link, useLocation } from "react-router-dom";

function PurchaseOrderMenus() {
  const [selectedMenu, setSelectedMenu] = useState("");

  const superAdminMenu = [
    {
      main_title: "Purchase Order",
      id: "po",
      key: "purchase_order",
      links: [
        {
          id: "po_menu",
          list: [
            {
              label: "New Order",
              to: "/po/new",
              active: ["/po/new"],
              key: "new_order",
            },
            {
              label: "Previous PO",
              to: "/po/previousList",
              active: ["/po/previousList"],
              key: "previous_po",
            },
          ],
        },
      ],
    },

    {
      main_title: "Purchase Request",
      key: "purchase_request",
      id: "po_request",
      links: [
        {
          id: "po_request_menu",
          list: [
            {
              label: "New Request",
              to: "/po/pr/new",
              active: ["/po/pr/new"],
              key: "new_request",
            },
            {
              label: "Previous PR",
              to: "/po/pr/previousList",
              active: ["/po/pr/previousList"],
              key: "previous_pr",
            },
          ],
        },
      ],
    },

    {
      main_title: "Inventory",
      id: "inventory",
      key: "inventory",
      links: [
        {
          id: "inventory_menus",
          list: [
            {
              label: "Product",
              to: "/po/inventory/product",
              active: ["/po/inventory/product"],
              key: "product",
            },

            {
              label: "Stock Movement",
              to: "/po/inventory/stockMovement",
              active: ["/po/inventory/stockMovement"],
              key: "stock_movement",
            },
          ],
        },
      ],
    },

    {
      main_title: "Invoice",
      id: "invoice",
      key: "invoice",
      links: [
        {
          id: "invoice-list",
          list: [
            {
              label: "New Invoice",
              to: "/po/invoice/new",
              active: ["/po/invoice/new"],
              key: "new_invoice",
            },
            {
              label: "Previous Invoice",
              to: "/po/invoice/previous",
              active: ["/po/invoice/previous"],
              key: "previous_invoice",
            },
          ],
        },
      ],
    },

    {
      main_title: "Logistics",
      id: "logistics",
      key: "logistics",
      links: [
        {
          id: "logistics-list",
          list: [
            {
              label: "New Logistics",
              to: "/po/logistics/new",
              active: ["/po/logistics/new"],
              key: "new_logistics",
            },
            {
              label: "Logistics History",
              to: "/po/logistics/previous",
              active: ["/po/logistics/previous"],
              key: "logistic_history",
            },
          ],
        },
      ],
    },
    {
      main_title: "Bundle Entry (GRN)",
      id: "bundle_entry",
      key: "bundle_entry",
      links: [
        {
          id: "bundle-entry-list",
          list: [
            {
              label: "New Entry",
              to: "/po/bundleEntry/new",
              active: ["/po/bundleEntry/new"],
              key: "new_bundle_entry",
            },
            {
              label: "Previous Entry",
              to: "/po/bundleEntry/previous",
              active: ["/po/bundleEntry/previous"],
              key: "bundle_entry_history",
            },
          ],
        },
      ],
    },
  ];

  const handleToggle = () => {
    $("#toggle-menu").animate({
      width: "toggle",
    });
  };

  let location = useLocation();
  const currentLocation = location?.pathname.split("/")[1];
  return (
    <>
      <div className="w-1/5 overflow-x-hidden shadow-md" id="toggle-menu">
        <div className=" pl-[8px] pr-[8px] pt-[12px]">
          <div className="flex justify-end">
            <img
              src={Toggle}
              alt=""
              className="cursor-pointer"
              onClick={handleToggle}
            />
          </div>
          <Link
            to="dashboard"
            className={`font-montserrat text-base whitespace-nowrap font-medium ${
              currentLocation === "po" ? "text-text-dark" : "text-text-light"
            }`}
          >
            Dashboard
          </Link>
        </div>
        <div className="flex flex-col gap-1">
          {superAdminMenu.map((nav, i) => {
            return (
              <div key={i}>
                <MenuItems
                  selected={selectedMenu === nav.main_title}
                  menuName={nav?.key}
                  title={nav?.main_title}
                  to={nav?.to}
                  links={nav?.links}
                  handleMenuClick={setSelectedMenu}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default PurchaseOrderMenus;
