import React from "react";
import { Route } from "react-router-dom";

import LogisticsList from "../../PurchaseOrder/Logistics/LogisticsList";
import LogisticsDetails from "../../../Components/PurchaseOrder/Logistics/LogisticsDetails";
import CreateLogistics from "../../../Components/PurchaseOrder/Logistics/CreateLogistics";

function LogisticsRoutes() {
  return (
    <>
      <Route path="new" element={<CreateLogistics />}></Route>
      <Route path="previous" element={<LogisticsList />}></Route>
      <Route path="details/:id" element={<LogisticsDetails />}></Route>
    </>
  );
}

export default LogisticsRoutes;
