import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API } from "../../../Services/api";
import BranchTable from "../../DataTable/BranchTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { handleDeleteList } from "../../../Helper/DeleteAPI";

function BranchList() {
  const navigate = useNavigate();
  const [branchList, setBranchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const showBranchDetails = (listDetail) => {
    navigate("/master/hrms/branch/details", {
      state: {
        list: listDetail,
      },
    });
  };
  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "branch_name", label: "Branch Name" },
    { id: "address", label: "Address" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getBranchList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getBranchList(1, pageSize);
  };

  const getBranchList = async (page, size) => {
    setLoading(true);
    try {
      const branchList = await GET_API(
        `/branchList?page=${page}&size=${size}&search=${searchKey}`
      );
      if (branchList.status === 200) {
        if (
          branchList?.data?.data?.current_page >
          branchList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setBranchList(branchList?.data?.data?.data);
        // setFilteredBranch(branchList?.data?.data?.data);
        setLastPage(branchList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setBranchList([]);
      // setFilteredBranch([]);
      setLastPage("");
      setCurrentPage(1);
      alertify.error("Failed to Get Branch List");
    }
  };

  const handleDelete = (row_id) => {
    handleDeleteList(
      row_id,
      "Branch",
      getBranchList,
      "deletebranch",
      currentPage,
      setCurrentPage,
      pageSize,
      searchKey
    );
  };
  const navigateEditPage = (listDetail) => {
    navigate("/master/hrms/branch/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Branch List</h4>
          <form onSubmit={() => getBranchList(currentPage, pageSize)}>
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="add-new-button-block">
                <Link to="/master/hrms/branch/add" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </form>

          {/**************** * Table ********************/}

          <BranchTable
            list={branchList}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
export default BranchList;
