import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import Select from "react-select";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";

function CreateEmploymentType() {
  const navigate = useNavigate();
  const [newEmployment, setNewEmployment] = useState({
    name: "",
    color: "",
    color_label: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const colorOptions = [
    { value: "#f05656", label: "#f05656" },
    { value: "#f09e56", label: "#f09e56" },
    { value: "#6348e8", label: "#6348e8" },
    { value: "#008000", label: "#008000" },
  ];

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setNewEmployment(() => ({ ...newEmployment, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setNewEmployment(() => ({
      ...newEmployment,
      [name]: e?.value,
      color_label: e,
    }));
  };

  const createEmploymentType = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateEmploymentType(newEmployment);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const addEmpType = await POST_API("/addEmployeetype", newEmployment);
      if (addEmpType?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(addEmpType?.data?.message);
        navigate("/master/hrms/employementType");
        return;
      }
      if (addEmpType?.data?.status === 403) {
        setButtonDisabled(false);
        alertify.error(addEmpType?.data?.message);
        navigate("/master/hrms/employementType");
        return;
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const resetForm = () => {
    setNewEmployment({
      name: "",
      color: "",
      color_label: "",
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/employementType" className="in-active">
              Employment Type List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Employment Type</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Employment Type</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createEmploymentType(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Tag Name<span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Tag Name"
                  value={newEmployment?.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Tag Color
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  placeholder="Select Color..."
                  options={colorOptions}
                  // value={newEmployment?.color}
                  value={
                    newEmployment?.color
                      ? colorOptions?.find(
                          (option) => option?.value === newEmployment?.color
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "color")}
                  name="color"
                  className=""
                  isSearchable
                />

                {errors.color ? (
                  <div className="validation-error-label">{errors.color}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateEmploymentType;
