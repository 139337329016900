import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

function BranchDetails() {
  const { state } = useLocation();
  const componentRef = useRef();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.first_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.empCode,
    },
    {
      label: "Fine amount",
      value: listDetails?.fine_amount,
    },
    {
      label: "Fine type",
      value: listDetails?.fine_type_name,
    },
    {
      label: "Gender",
      value: listDetails?.Gender,
    },

    {
      label: "Employee Type",
      value: listDetails?.EmployeeTypeName,
    },
    {
      label: "Department",
      value: listDetails?.DepartmentName,
    },
    {
      label: "Designation",
      value: listDetails?.DesignationName,
    },

    {
      label: "Branch",
      value: listDetails?.branchName,
    },
    {
      label: "Date",
      value: listDetails?.date,
    },
    {
      label: "Remarks",
      value: listDetails?.remarks,
    },
  ];

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/fineList" className="in-active">
                Fine List
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">Fine Details</Link>
            </div>
          </div>
          <div className="card-wrapper" ref={componentRef}>
            <h4
              className="card-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Fine Details
              <button
                type="button"
                className="submit-button"
                onClick={() => printVoucher()}
              >
                <span className="submit-button-label">Print</span>
              </button>
            </h4>

            <div className="list-container">
              <div className="list-content-block">
                {Details.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BranchDetails;
