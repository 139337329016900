import React, { useState } from "react";
import "alertifyjs/build/css/alertify.css";

function EmployeeBasicInfo({ stateChanger, empDetails, errors }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      <div
        className="p-3 bg-primary-color text-center w-3/12"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Educational Info
        </span>
      </div>
      <form>
        <div className="form">
          <div className="field-block" name="education">
            <label className="label-name">
              Qualification
              <span className="required-symbol">*</span>
            </label>

            <input
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              name="education"
              placeholder="Enter Education Qualifiaction"
              autoComplete="off"
              value={empDetails?.education}
            />
            {errors?.education ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.education}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="passed_out">
            <label className="label-name">Passed Out</label>

            <input
              className="input-field"
              type="number"
              onChange={(e) => handleChange(e)}
              name="passed_out"
              placeholder="Enter Passedout year"
              autoComplete="off"
              value={empDetails?.passed_out}
            />
            {errors?.passed_out ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.passed_out}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="additional_qualification">
            <label className="label-name">Additional Qualifiaction</label>

            <input
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              name="additional_qualification"
              placeholder="Enter Additional Qualification"
              autoComplete="off"
              value={empDetails?.additional_qualification}
            />
            {errors?.additional_qualification ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.additional_qualification}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="remarks">
            <label className="label-name">Remarks</label>

            <textarea
              rows={5}
              className="input-field"
              onChange={(e) => handleChange(e)}
              name="remarks"
              placeholder="Enter Remarks"
              autoComplete="off"
              value={empDetails?.remarks}
            />
            {errors?.remarks ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.remarks}
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
}

export default EmployeeBasicInfo;
