import React from "react";
import { Link, useLocation } from "react-router-dom";
import PayrollDetail from "./PayrollDetail";

function PayrollReportDetail() {
  const { state } = useLocation();
  const listDetails = state?.list;

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/employee" className="in-active">
              Employee Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Employee Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Employee Report Details</h4>

          <PayrollDetail
            listDetails={listDetails ? listDetails : {}}
            page="report"
          />
        </div>
      </div>
    </div>
  );
}

export default PayrollReportDetail;
