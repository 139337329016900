import React, { useEffect, useState } from "react";
import LogisticsTable from "../../DataTable/PurchaseOrder/LogisticsTable";
import Search from "../../../Assets/Icons/Search.svg";
import Select from "react-select";
import Spinner from "../../Spinner";
import { DELETE_API, GET_API } from "../../../Services/api";
import alertify from "alertifyjs";
import More from "../../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";

function LogisticsList() {
  const [loading, setLoading] = useState(false);
  const [logistics, setLogistics] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();
  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "product_name", label: "LR No" },
    { id: "hsn", label: "Consignment No" },
    { id: "brancd", label: "PO Number" },
    { id: "uom", label: "Invoice No" },
    { id: "quantity", label: "Status" },
    { id: "icon", label: "" },
  ];
  useEffect(() => {
    getLogisticsList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getLogisticsList(1, pageSize, searchKey);
  };

  const showLogisticsDetails = (listDetail) => {
    const id = listDetail?.id;
    navigate(`/po/logistics/details/${id}`, {
      state: id,
    });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Logistics..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(`/po/deleteLogistic/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getLogisticsList(currentPage, pageSize, searchKey);
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete Logistics");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const getLogisticsList = async (page, size, search) => {
    setLoading(true);
    try {
      const logisticsList = await GET_API(
        `po/logisticsList?page=${page}&size=${size}&search=${search}`
      );
      if (logisticsList?.data?.status === 200) {
        if (
          logisticsList?.data?.data?.current_page >
          logisticsList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLogistics(logisticsList?.data?.data?.data);
        setLastPage(logisticsList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get logistics list");
      setLoading(false);
      setCurrentPage(1);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="po-container">
      <div className="po-contect-box">
        <div className="header-name">Logistics History</div>
        <form className="po-form">
          <div className="grid grid-cols-3 gap-2 pt-5 ">
            <div className="input-field-block">
              <span className="label-name">Status</span>
              <Select name="product_id" isSearchable />
            </div>

            <div className="input-field-block">
              <span className="label-name">Search</span>
              <div className="po-search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => {
                    setSearchKey(e?.target?.value);
                  }}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <img
                  src={Search}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    getLogisticsList(currentPage, pageSize, searchKey);
                  }}
                />
              </div>
            </div>
          </div>
          <LogisticsTable
            columns={columns}
            list={logistics}
            icon={More}
            multiple={true}
            showDetails={showLogisticsDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            activeEdit={false}
            currentPage={currentPage}
          />
        </form>
      </div>
    </div>
  );
}

export default LogisticsList;
