import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import { GET_API } from "../../../Services/api";
import config from "../../../Services/config.json";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";

function ManualAttendanceDetails() {
  const { id } = useParams();
  const [empDetail, setEmpDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getManualAttendanceDetails();
  }, []);

  const getManualAttendanceDetails = async () => {
    setLoading(true);
    try {
      const manualAttendance = await GET_API(`/getManualAttendance/${id}`);
      if (manualAttendance?.status === 200) {
        setLoading(false);
        setEmpDetail(manualAttendance?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error(error?.response?.data?.message);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/manualAttendance" className="in-active">
              Manual attendance List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Manual Attendance Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Manual Attendance Details</div>

          <div className="flex justify-around font-montserrat ">
            <div className="">
              <div className="flex py-4 px-4 gap-8 w-full w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12 w-6/12">
                  Employee Code
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12 w-6/12"
                >
                  {empDetail?.empCode}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Employee Name
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.first_name}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Branch
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.branchName}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Fine Type
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.fine_type_name}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Fine Amount
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.fine_amount}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Approval Status
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.approval_status === "approved" ? (
                    <span
                      className=" bg-[#3EAB82] p-2"
                      style={{ color: "#FFF", borderRadius: "4px" }}
                    >
                      Approved
                    </span>
                  ) : (
                    <span
                      className=" bg-[#FF5C42] p-2"
                      style={{ color: "#FFF", borderRadius: "4px" }}
                    >
                      Pending
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="">
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Department
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.deptName}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Designation
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.desName}
                </div>
              </div>

              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Shift
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.shiftName}
                </div>
              </div>

              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Approved By
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.approver_name} {empDetail?.approver_fathers_name}
                </div>
              </div>
            </div>
            <div className="">
              <div className="flex py-4 px-4 gap-8">
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  <img
                    src={`${config.path}${empDetail?.profile_image}`}
                    alt=""
                    style={{ height: "120px", width: "120px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManualAttendanceDetails;
