import React, { useEffect, useState } from "react";
import Select from "react-select";
import alertify from "alertifyjs";
import Spinner from "../Spinner";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import "alertifyjs/build/css/alertify.css";

function CreateAllowance({ id }) {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [allowanceList, setAllowanceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllowanceLoading, setIsAllowanceLoading] = useState(false);
  const [allowance, setAllowance] = useState([]);
  const [allowanceData, setAllowanceData] = useState([]);

  useEffect(() => {
    getFoodList();
  }, []);

  useEffect(() => {
    if (id) {
      getFoodAllowance();
    }
  }, [id]);

  useEffect(() => {
    if (allowanceList?.length) {
      const val = allowanceList?.filter((item) => {
        return allowanceData?.includes(item?.value);
      });
      setAllowance(val);
    }
  }, [allowanceData, allowanceList?.length, id]);

  const getFoodList = async () => {
    setIsLoading(true);
    try {
      const foodList = await GET_API("/FoodAllowanceAll");
      if (foodList.status === 200) {
        const list = foodList?.data?.data?.map((opt) => {
          return { label: opt?.name, value: opt?.id };
        });
        setAllowanceList(list);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify.error("Failed to Get Allowance List");
    }
  };

  const getFoodAllowance = async () => {
    setIsAllowanceLoading(true);
    try {
      const foodList = await GET_API(`/mapFoodAllowanceEmp/${id}`);
      if (foodList.status === 200) {
        setAllowanceData(foodList?.data?.data);
        setIsAllowanceLoading(false);
      }
    } catch (error) {
      setIsAllowanceLoading(false);
      alertify.error("Failed to Get Allowance List");
    }
  };

  const handleFieldChange = (e) => {
    setAllowance(e);
  };

  const create_Department = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const data = {
      allowance_ids: allowance.map((opt) => opt?.value),
    };
    if (allowance?.length) {
      setIsError(false);
      setButtonDisabled(false);
      setIsLoading(true);
      POST_API(`/mapFoodAllowanceEmpAdd/${id}`, data)
        .then(async function (data) {
          if (data.data.status === 403) {
            alertify.error(data?.data?.message);
            return;
          }
          alertify.success(data?.data?.message);
          setButtonDisabled(false);
          setIsLoading(false);
          // navigate("/employee/list");
        })

        .catch((error) => {
          setButtonDisabled(false);
          setIsLoading(false);
          alertify.error(error?.response?.data?.message);
        });
    } else {
      setIsError(true);
      setButtonDisabled(false);
    }
  };

  const resetForm = () => {
    setAllowance([]);
    setIsError(false);
  };

  if (isLoading || isAllowanceLoading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <h1 className="heading-text pt-3">Food Allowance</h1>
      <div className="card-wrapper">
        <form onSubmit={(e) => create_Department(e)}>
          <div className="p-6">
            <label className="label-name">
              Food Allowance
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={allowanceList}
              value={allowance}
              onChange={(e) => handleFieldChange(e)}
              name="allowance"
              className=""
              isSearchable
              isMulti
            />
            {isError ? (
              <div className="validation-error-label">
                Please Select Allowance
              </div>
            ) : null}
          </div>

          <div className="submit-reset-button">
            <button type="button" className="reset-button" onClick={resetForm}>
              <span className="reset-button-label">Reset</span>
            </button>

            <button
              disabled={buttonDisabled}
              type="submit"
              className="submit-button"
            >
              <span className="submit-button-label">Add</span>
              <img src={ColorAdd} alt="" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateAllowance;
