import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import config from "../../Services/config.json";


export default function StickyHeadTable({
  list,
  columns,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
  handleEditModal,
  addAccess,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list.map((row, i) => {
                const father_name = row?.father_name ? row?.father_name : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                     {
                      <TableCell className="!w-[7%] table-body">
                      {row?.profile_image !== "" ? (
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "40px",
                          }}
                          src={config?.path + row?.employee_image}
                          alt="profile_image"
                        />
                      ) : (
                        <div
                          className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                          style={{ backgroundColor: "#ccc" }}
                        >
                          {row?.first_name?.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.emp_code}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.remarks}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.terminated_by_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {dayjs(row.created_at).format("DD-MM-YYYY hh:mm:ss a")}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {addAccess && (
                          <button
                            type="submit"
                            className="submit-button"
                            onClick={() => handleEditModal(row)}
                          >
                            <span className="submit-button-label">Rejoin</span>
                          </button>
                        )}
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
