import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useGeneralSettings } from "../../GeneralSettingsContext";
import dayjs from "dayjs";
import config from "../../Services/config.json";

export default function DataTable({
  list,
  totalAmount,
}) {
  const { generalSettings } = useGeneralSettings();
  const columns = [
    { field: "sno", headerName: "S.No" },
    {
      field: "profile_image",
      headerName: "User",
      renderCell: (params) => {
        const profileImageUrl = config?.path + params.row.profile_image; // Access the profile_image from each row
        return (
          <img
            src={profileImageUrl}
            alt="User Profile"
            className="h-[40px] w-[40px] rounded"
          />
        );
      },
    },
    { field: "empCode", headerName: "Code" },
    { field: "empName", headerName: "Name" },
    { field: "floor_name", headerName: "Floor", minWidth : 140 },
    { field: "allowanceName", headerName: "Allowance Name" },
    { field: "date", headerName: "Date" },
    { field: "actualEntryTime", headerName: "Entry Time" },
    { field: "actualExitTime", headerName: "Exit Time" },
    { field: "amount", headerName: "Amount" },
  ];



  const heightStyle =
    list?.length === 0 ? { height: 320, width: "100%" } : { width: "100%" };

  return (
    <div style={heightStyle} className="relative top-6 ">
      
      <DataGrid
        rows={list}
        columns={columns}
      />
      <div className="grid grid-cols-6 p-2 bg-white-color">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div className="text-text-dark font-karla text-end">Total Amount</div>
        <div className="text-center text-text-dark font-karla">
          {totalAmount}
        </div>
      </div>
    </div>
  );
}
