import React from "react";
import Select from "react-select";
import "alertifyjs/build/css/alertify.css";

function CreateFoodAllowance({
  foodAllowanceOption,
  foodAllowanceValue,
  setFoodAllowanceValue,
  errors,
}) {
  const handleFieldChange = (e) => {
    setFoodAllowanceValue(e);
  };

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      <div
        className="w-3/12 p-3 bg-primary-color text-center"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Food Allowance Info
        </span>
      </div>
      <form>
        <div className="form-full" name="allowance">
          <div className="field-block-full">
            <label className="label-name">Food Allowance</label>
            <Select
              options={foodAllowanceOption}
              value={foodAllowanceValue}
              onChange={(e) => handleFieldChange(e)}
              name="allowance"
              className=""
              isSearchable
              isMulti
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateFoodAllowance;
