import React, { useEffect, useState } from "react";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import { GET_API, POST_API } from "../../../Services/api";
import Validation from "../../../Validation/Validation";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function BreakFine() {
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [BreakFine, setBreakFine] = useState({
    break_minute: 1,
    amount: 0.0,
  });
  useEffect(() => {
    getBreakFineList();
  }, []);

  const getBreakFineList = async () => {
    try {
      const breakFineList = await GET_API("/breakfineList");
      if (breakFineList?.status === 200) {
        const list = breakFineList?.data?.data;
        list.map((item) => {
          const itemList = { ...item };
          BreakFine.break_minute = itemList?.break_minute;
          BreakFine.amount = itemList?.amount;
          delete itemList.is_active;
          delete itemList.created_at;
          delete itemList.updated_at;
          setBreakFine(itemList);
        });
      }
    } catch (error) {}
  };
  const handleChange = (e) => {
    const { name, value } = e?.target;
    setBreakFine({ ...BreakFine, [name]: value });
  };
  const updateFineAmount = async () => {
    setButtonDisabled(true);
    const isValid = Validation.validateBreakFine(BreakFine);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const updateFineAmount = await POST_API("/updateBreakFine", BreakFine);
    if (updateFineAmount?.status === 200) {
      setButtonDisabled(false);
      alertify.success(updateFineAmount?.data?.message);
    }
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Break Fine</h4>
          <div className="add-list-container pt-[12px]">
            <div className="input-block">
              <label className="add-new-input-label">
                Break Minute <span className="required-symbol">*</span>
              </label>

              <input
                type="number"
                name="break_minute"
                className="input-field"
                placeholder="Enter Minutes"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={1}
                readOnly
                disabled
                autoComplete="off"
              />
              {errors.break_minute ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.break_minute}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">
                Fine amount <span className="required-symbol">*</span>
              </label>
              <input
                type="text"
                name="amount"
                className="input-field"
                placeholder="Enter Amount"
                value={BreakFine.amount}
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
              />
              {errors.amount ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.amount}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <button
                  className="add-new-button"
                  onClick={(e) => {
                    updateFineAmount(e);
                  }}
                  disabled={buttonDisabled}
                >
                  <span className="button-label">Save</span>
                  <img src={ColorAdd} alt="add" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreakFine;
