import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "./Pagination";

export default function DataTable({
  list,
  setSelectedRows,
  setPageNo,
  setPageSize,
  lastPage,
  currentPage,
  pageSize,
}) {
  const columns = [
    {
      field: "s.no",
      headerName: "S.No",
      minWidth: 100,
      renderCell: (params) => {
        // Get the index of the row within the page
        const index = list.findIndex((row) => row === params.row);
        
        // Calculate the serial number using current page and page size
        const serialNumber = (currentPage - 1) * pageSize + (index + 1);
        return serialNumber;
      },
    },
    { field: "first_name", headerName: "Employee Name", minWidth: 200 },
    { field: "employeeCode", headerName: "Employee Code", minWidth: 150 },
    { field: "departmentname", headerName: "Designation", minWidth: 150 },
    { field: "date", headerName: "Date", minWidth: 150 },
  ];

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };
  const heightStyle =
    list.length === 0 ? { height: 320, width: "100%" } : { width: "100%" };
  return (
    <div style={heightStyle}>
      <DataGrid
        rows={list}
        columns={columns}
        checkboxSelection
        sx={{
          ".MuiDataGrid-footerContainer": {
            display: "none",
          },
        }}
        onRowSelectionModelChange={handleSelectionModelChange}
      />
      <div className="bg-[#fff]">
        <Pagination
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          lastPage={lastPage}
          currentPage={currentPage}
          pageSize={pageSize}
          shape="rounded"
        />
      </div>
    </div>
  );
}
