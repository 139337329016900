import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../../MoreOption";
import Pagination from "../../DataTable/Pagination";

export default function StickyHeadTable({
  list,
  columns,
  icon,
  editStatus,
  editList,
  multiple,
  deleteRow,
  pagination,
  setState,
  setSearch,
  editPage,
  showDetails,
}) {
  const [currentPage, setCurrentPage] = React.useState(1);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list
                // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => {
                  const createdDate = new Date(row?.created_at);
                  const year = createdDate.getFullYear();
                  const month = String(createdDate.getMonth() + 1).padStart(
                    2,
                    "0"
                  );
                  const date = String(createdDate.getDate()).padStart(2, "0");
                  const hour = createdDate.getUTCHours();
                  var ampm = hour >= 12 ? "pm" : "am";
                  const minute = createdDate.getUTCMinutes();
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={i}
                      className="table-column"
                    >
                      {
                        <TableCell className="table-body !w-[7%]">
                          {currentPage * (i + 1)}
                        </TableCell>
                      }

                      {
                        <TableCell className="table-body">
                          {row?.name}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.code}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.description}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {date +
                            "-" +
                            month +
                            "-" +
                            year +
                            " " +
                            hour +
                            ":" +
                            minute +
                            " " +
                            ampm}
                        </TableCell>
                      }
                      {
                        <MoreOption
                          icon={icon}
                          row={row}
                          deleteRow={deleteRow}
                          editList={editList}
                          editStatus={editStatus}
                          multiple={multiple}
                          editPage={editPage}
                          showDetails={showDetails}
                        />
                      }
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        pagination={pagination}
        setState={setState}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
      />
    </Paper>
  );
}
