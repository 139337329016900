import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import { GET_API } from "../../../Services/api";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import config from "../../../Services/config.json";

function WarehouseDetails() {
  const { state } = useLocation();
  const id = state?.list;

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  useEffect(() => {
    getProductDetails();
  }, []);

  const list = [
    {
      label: "Product name",
      value: details?.product?.name,
    },

    {
      label: "HSN Code",
      value: details?.product?.hsn_code,
    },

    {
      label: "Product code",
      value: details?.product?.product_code,
    },
    {
      label: "Model No",
      value: details?.product?.model_no,
    },
    {
      label: "UOM",
      value: details?.product?.uom,
    },

    {
      label: "Category name",
      value: details?.product?.category_name,
    },

    {
      label: "Quantity",
      value: details?.product?.quantity,
    },

    {
      label: "Price",
      value: `<div><span>&#8377; ${details?.product?.price}</span></div>`,
    },

    {
      label: "Description",
      value: details?.product?.description,
    },
  ];
  const getProductDetails = async () => {
    setLoading(true);
    try {
      const details = await GET_API(`po/productById/${id}`);
      if (details?.data?.status === 200) {
        setDetails(details?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Faile to get Product details...");
    }
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/po/inventory/product" className="in-active">
                Inventory List
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">Inventory Product Details</Link>
            </div>
          </div>
          <div className="card-wrapper">
            <h4 className="card-header">Inventory Product Details</h4>
            <div className="list-container">
              <div className="list-content-block">
                {list.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        className="list-value"
                        dangerouslySetInnerHTML={{ __html: list?.value }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="product-images pt-4">
                <label className="list-label">Product Images</label>
                <div className="flex w-[50%] justify-between pt-2">
                  {details?.images?.map((image) => {
                    return (
                      <img
                        src={config.path + image?.image}
                        alt=""
                        className="h-[100px] w-[100px] rounded-md"
                      />
                    );
                  })}
                </div>
              </div>
              <div className="product-images pt-4">
                <label className="list-label">Product Attribute</label>
                <div className="flex w-[50%] justify-between pt-2">
                  <table className="log-table">
                    <tr className="p-1">
                      <th className="log-table p-1">S.No</th>
                      <th className="log-table p-1">Attribute</th>
                      <th className="log-table p-1">Attribute Value</th>
                    </tr>
                    <tbody>
                      {details?.attributes?.map((attribute, i) => {
                        return (
                          <tr>
                            <td className="log-table log-table-data p-1 text-text-light font-karla">
                              {i + 1}
                            </td>
                            <td className="log-table log-table-data p-1 text-text-light font-karla">
                              {attribute?.attribute_name}
                            </td>
                            <td className="log-table log-table-data p-1 text-text-light font-karla">
                              {attribute?.attribute_value_name}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="product-images pt-4">
                <label className="list-label">Tax Details</label>
                <div className="flex w-[50%] justify-between pt-2">
                  <table className="log-table">
                    <tr className="p-1">
                      <th className="log-table p-1">S.No</th>
                      <th className="log-table p-1">From Amount</th>
                      <th className="log-table p-1">To Amount</th>
                      <th className="log-table p-1">Tax amount</th>
                    </tr>
                    <tbody>
                      {details?.poTax?.map((tax, i) => {
                        return (
                          <tr>
                            <td className="log-table log-table-data p-1 text-text-light font-karla">
                              {i + 1}
                            </td>
                            <td className="log-table log-table-data p-1 text-text-light font-karla">
                              {tax?.from_amount}
                            </td>
                            <td className="log-table log-table-data p-1 text-text-light font-karla">
                              {tax?.to_amount}
                            </td>
                            <td className="log-table log-table-data p-1 text-text-light font-karla">
                              {tax?.tax}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WarehouseDetails;
