import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "../Pagination";

export default function StickyHeadTable({
  list,
  columns,
  pagination,
  setState,
  setSearch,
  removeList,
}) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const currentDate = new Date();

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1;
                const day = currentDate.getDate();
                const hour = currentDate.getHours();
                const minute = currentDate.getMinutes();
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {currentPage * (i + 1)}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.productName}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.hsn_code}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.brandName}
                      </TableCell>
                    }
                    {<TableCell className="table-body">{row?.styleName}</TableCell>}
                    {
                      <TableCell className="table-body">
                        {row?.quantity}
                      </TableCell>
                    }
                    {<TableCell className="table-body">{row?.uom}</TableCell>}
                    {
                      <TableCell className="table-body">
                        {year +
                          "-" +
                          month +
                          "-" +
                          day +
                          " " +
                          hour +
                          ":" +
                          minute}
                      </TableCell>
                    }

                    {
                      <TableCell onClick={() => removeList(i)}>
                        <span className="required-symbol cursor-pointer">
                          Delete
                        </span>
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        pagination={pagination}
        setState={setState}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
      />
    </Paper>
  );
}
