import React from "react";
import { Route } from "react-router-dom";

import Loan from "../Finance/Loan/LoanList";
import CreateLoan from "../Finance/Loan/CreateLoan";
import UpdateLoan from "../Finance/Loan/UpdateLoan";
import LoanDetails from "../Finance/Loan/LoanDetails";
import LoanDeductionEntry from "../Finance/Loan/LoanDeductionEntry";

import AgentCommission from "../AgentCommission/agentcommisonList";
import CreateAgentCommission from "../AgentCommission/agentCreate";
import EditAgentCommission from "../AgentCommission/EditAgentCommission";
import AgentcommisionDetails from "../AgentCommission/AgentcommisionDetails";

import Bonus from "../BonusDetails/bonusList";
import CreateBonus from "../BonusDetails/createbonus";
import BulkBonus from "../BonusDetails/AddBulkBonus";
import BonusDetails from "../BonusDetails/BonusView";

import Advance from "../Finance/Advance/AdvanceList";
import CreateAdvance from "../Finance/Advance/CreateAdvance";
import BulkAdd from "../Finance/Advance/AddBulkAdvance";
import AdvanceDetails from "../Finance/Advance/AdvanceDetails";
import AdvanceDeductionEntry from "../Finance/Advance/AdvanceDeductionEntry";


import Expense from "../Finance/Expenses/ExpenseList";
import CreateExpense from "../Finance/Expenses/ExpenseCreate";
import ExpenseDetails from "../Finance/Expenses/ExpenseDetails";

function LoanAndExpenseRoutes() {
  return (
    <>
      {/**loan */}
      <Route path="loan" element={<Loan />}></Route>
      <Route path="loan/add" element={<CreateLoan />}></Route>
      <Route path="loan/update" element={<UpdateLoan />}></Route>
      <Route path="loan/details/:id" element={<LoanDetails />}></Route>
      <Route path="loan/deduction/add" element={<LoanDeductionEntry />}></Route>

      {/**agent */}
      <Route path="agentcommission" element={<AgentCommission />}></Route>
      {/* <Route
        path="agentcommission/add"
        element={<CreateAgentCommission />}
      ></Route>
      <Route
        path="agentcommission/edit"
        element={<EditAgentCommission />}
      ></Route>
      <Route
        path="agentcommission/details/:id"
        element={<AgentcommisionDetails />}
      ></Route> */}

      {/**bonus */}
      <Route path="bonus" element={<Bonus />}></Route>
      <Route path="bonus/add" element={<CreateBonus />}></Route>
      <Route path="bonus/bulkAdd" element={<BulkBonus />}></Route>
      <Route path="bonus/details" element={<BonusDetails />}></Route>

      {/**advance */}
      <Route path="advance" element={<Advance />}></Route>
      <Route path="advance/add" element={<CreateAdvance />}></Route>
      <Route path="advance/bulkAdd" element={<BulkAdd />}></Route>
      <Route path="advance/details" element={<AdvanceDetails />}></Route>
      <Route path="advance/deduction/add" element={<AdvanceDeductionEntry />}></Route>


      {/**expense */}
      <Route path="/expense" element={<Expense />}></Route>
      <Route path="/expense/add" element={<CreateExpense />}></Route>
      <Route path="/expense/details" element={<ExpenseDetails />}></Route>
    </>
  );
}

export default LoanAndExpenseRoutes;
