import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BasicInfo from "../../../Employee/DetailsPage/BasicInfo";
import PositionInfo from "../../../Employee/DetailsPage/PositionInfo";
import BankInfo from "../../../Employee/DetailsPage/BankInfo";
import SalaryInfo from "../../../Employee/DetailsPage/SalaryInfo";
import EducationalInfo from "../../../Employee/DetailsPage/EducationalInfo.js";
import PersonalInfo from "../../../Employee/DetailsPage/PersonalInfo";

function EmployeeDetails({ listDetails, isEditAndView, breakDetails }) {
  const [value, setValue] = useState("position_info");

  const handleTabChange = (newValue) => {
    setValue(newValue);
  };

  const handleTabHeaderChange = (dummy, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="card-wrapper mt-6">
      <h4 className="card-header">Employee Details</h4>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabHeaderChange}
              aria-label="lab API tabs example"
            >
              <Tab
                label="Position Info"
                style={{ fontFamily: "Montserrat" }}
                value="position_info"
              />
              <Tab
                label="Basic Info"
                style={{ fontFamily: "Montserrat" }}
                value="basic_info"
              />
              <Tab
                label="Bank Info"
                style={{ fontFamily: "Montserrat" }}
                value="bank_info"
              />
              <Tab
                label="Salary Info"
                style={{ fontFamily: "Montserrat" }}
                value="salary_info"
              />
              <Tab
                label="Personal Info"
                style={{ fontFamily: "Montserrat" }}
                value="personal_info"
              />
              <Tab
                label="Education Info"
                style={{ fontFamily: "Montserrat" }}
                value="education_info"
              />
            </TabList>
          </Box>
          <TabPanel key="position_info" value="position_info">
            <PositionInfo
              listDetails={listDetails}
              isEditAndView={isEditAndView}
              breakDetails={breakDetails}
              nextTab={handleTabChange}
            />
          </TabPanel>
          <TabPanel value="basic_info">
            <BasicInfo
              listDetails={listDetails}
              isEditAndView={isEditAndView}
              nextTab={handleTabChange}
            />
          </TabPanel>
          <TabPanel value="bank_info">
            <BankInfo
              listDetails={listDetails}
              isEditAndView={isEditAndView}
              nextTab={handleTabChange}
            />
          </TabPanel>
          <TabPanel value="salary_info">
            <SalaryInfo
              listDetails={listDetails}
              isEditAndView={isEditAndView}
              nextTab={handleTabChange}
            />
          </TabPanel>
          <TabPanel value="personal_info">
            <PersonalInfo
              listDetails={listDetails}
              isEditAndView={isEditAndView}
              nextTab={handleTabChange}
            />
          </TabPanel>
          <TabPanel value="education_info">
            <EducationalInfo
              listDetails={listDetails}
              isEditAndView={isEditAndView}
              nextTab={handleTabChange}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

export default EmployeeDetails;
