import { createContext, useContext, useState } from "react";

const GeneralSettingsContext = createContext();

export const useGeneralSettings = () => {
  return useContext(GeneralSettingsContext);
};

export const GeneralSettingsProvider = ({ children }) => {
  const [generalSettings, setGeneralSettings] = useState({
    title: "",
    logo_to_show: "",
    favicon_to_show: "",
    primary_color: "",
    secondary_color: "",
    payslipMonth: useState(new Date().getMonth() + 1),
    payslipYear: useState(new Date().getFullYear()),
  });

  return (
    <GeneralSettingsContext.Provider
      value={{ generalSettings, setGeneralSettings }}
    >
      {children}
    </GeneralSettingsContext.Provider>
  );
};
