import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "./Pagination";

export default function DataTable({
  list,
  setSelectedRows,
  columns,
  setPageNo,
  setPageSize,
  lastPage,
  currentPage,
  pageSize,
}) {
  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const updatedColumns = [
    {
      field: "s.no",
      headerName: "S.No",
      minWidth: 100,
      renderCell: (params) => {
        // Get the index of the row within the page
        const index = list.findIndex((row) => row === params.row);
        
        // Calculate the serial number using current page and page size
        const serialNumber = (currentPage - 1) * pageSize + (index + 1);
        return serialNumber;
      },
    },
    ...columns, // Add the rest of your columns here
  ];


  const heightStyle =
    list.length === 0 ? { height: 320, width: "100%" } : { width: "100%" };
  return (
    <>
      <div style={heightStyle}>
        <DataGrid
          rows={list}
          columns={updatedColumns}
          checkboxSelection
          onRowSelectionModelChange={handleSelectionModelChange}
          sx={{
            ".MuiDataGrid-footerContainer": {
              display: "none",
            },
          }}
        />
        <div className="bg-[#fff]">
          <Pagination
            setPageNo={setPageNo}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            pageSize={pageSize}
            shape="rounded"
          />
        </div>
      </div>
    </>
  );
}
