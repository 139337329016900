import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "../DataTable/Pagination";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import { Link } from "@mui/material";
import config from "../../Services/config.json";


export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  activeEdit,
  activeDelete,
  multiple,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const createdDate = new Date(row?.created_at);
                  const year = createdDate.getFullYear();
                  const month = String(createdDate.getMonth() + 1).padStart(
                    2,
                    "0"
                  );
                  const date = String(createdDate.getDate()).padStart(2, "0");
                  const hour = createdDate.getUTCHours();
                  var ampm = hour >= 12 ? "pm" : "am";
                  const minute = createdDate.getUTCMinutes();
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className="table-column"
                      onClick={() => showDetails(row)}
                    >
                     {
                        <TableCell className="table-body !w-[7%]">
                          {(currentPage - 1) * pageSize + (i + 1)}
                        </TableCell>
                      }
                      {
                      <TableCell className="!w-[7%] table-body">
                      {row?.employee_image !== "" ? (
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "40px",
                          }}
                          src={config?.path + row?.employee_image}
                          alt="profile_image"
                        />
                      ) : (
                        <div
                          className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                          style={{ backgroundColor: "#ccc" }}
                        >
                          {row?.first_name?.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </TableCell>
                    }
                      {
                        <TableCell className="table-body">
                          <Link href="#" onClick={() => showDetails()}>
                            {row.employee_code}
                          </Link>
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row.employee_name}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row.department_name}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row.designation_name}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row.floor_name}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row.total_amount}
                        </TableCell>
                      }
                       {
                        <TableCell className="table-body">
                          {row.total_paid_amount}
                        </TableCell>
                      }
                       {
                        <TableCell className="table-body">
                          { (row.total_amount - row.total_paid_amount)}
                        </TableCell>
                      }
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list?.length ? list.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />

    </Paper>
  );
}
