import React, { useState, useEffect, useContext } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link, useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import InventoryTable from "../../DataTable/PurchaseOrder/InventoryTable";
import { PrivilegeContext } from "../../../PrivilegeContext";

function InventoryList() {
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [viewAccess, setViewAccess] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "code", label: "Product Code" },
    { id: "hsn", label: "HSN Code" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getProductList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getProductList(1, pageSize, searchKey);
  };

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "product") {
        setAddAccess(item?.add);
        setEditAccess(item?.edit);
        setDeleteAccess(item?.delete);
        setViewAccess(item?.view);
      }
      return "";
    });
  };

  const getProductList = async (page, size, search) => {
    setLoading(true);
    try {
      const productList = await GET_API(
        `/po/products?page=${page}&size=${size}&search=${search}`
      );
      if (productList?.data?.status === 200) {
        if (
          productList?.data?.data?.current_page >
          productList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setProductList(productList?.data?.data?.data);
        setFilteredList(productList?.data?.data?.data);
        setLastPage(productList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Product List");
      setCurrentPage(1);
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Product..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(`/po/deleteProduct/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getProductList(currentPage, pageSize, searchKey);
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete Product");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigateEditPage = (listDetail) => {
    const id = listDetail?.id;
    navigate(`/po/inventory/product/edit/${id}`, {
      state: {
        list: listDetail?.id,
      },
    });
  };

  const showBranchDetails = (listDetail) => {
    const id = listDetail?.id;
    navigate(`/po/inventory/product/details/${id}`, {
      state: {
        list: id,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Inventory</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Products</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                style={{ outline: "none", border: "none" }}
              />

              <img
                src={Search}
                className="cursor-pointer"
                alt=""
                onClick={() => getProductList(currentPage, pageSize, searchKey)}
              />
            </div>

            {addAccess && (
              <div className="add-new-button-block">
                <Link to="/po/inventory/product/new" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            )}
          </div>

          {/**************** * Table ********************/}

          <InventoryTable
            list={filteredList}
            columns={columns}
            icon={More}
            multiple={viewAccess}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            activeDelete={deleteAccess}
            activeEdit={editAccess}
            enableMoreOption={viewAccess}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
export default InventoryList;
