import React from "react";
import ReactApexChart from "react-apexcharts";

const ReportChart = ({ date, branchName, branchData }) => {
  const options = {
    chart: {
      id: "basic-bar",
      type: "polarArea",
    },

    plotOptions: {
      bar: {
        columnWidth: "100%",
        borderRadius: 3,
        horizontal: false,
      },
    },

    xaxis: {
      categories: date,
      labels: {
        style: {
          colors: "#837676",
        },
      },
    },

    yaxis: {
      labels: {
        style: {
          fontSize: "12px",
          color: "#DADADA",
          fontFamily: "Montserrat",
        },
      },
    },
  };
  return (
    <>
      <h4 className="text-text-light font-montserrat pl-[18px] text-lg">
        {"Branch : " + branchName}
      </h4>
      <div>
        <ReactApexChart
          options={options}
          series={branchData}
          type="bar"
          height={350}
        />
      </div>
    </>
  );
};

export default ReportChart;
