import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Checkbox from "@mui/material/Checkbox";

function CreateHalfDayVariant() {
  const navigate = useNavigate();

  const [halfDayVariantDetails, setHalfDayVariantDetails] = useState({
    name: "",
    half_day_amount: "",
    full_day_amount: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked, type } = e?.target;

    setHalfDayVariantDetails(() => ({
      ...halfDayVariantDetails,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const createVariant = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateHalfDayVariant(halfDayVariantDetails);
    if (isValid && isValid?.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const addHolidayVariant = await POST_API(
        "/addHalfDayVariant",
        halfDayVariantDetails
      );
      if (addHolidayVariant?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(addHolidayVariant?.data?.message);
        navigate("/master/hrms/halfDayVariant");
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error("Failed to Add Holiday variant");
    }
  };
  const resetForm = () => {
    setHalfDayVariantDetails({
      name: "",
      half_day_amount: "",
      full_day_amount: "",
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/halfDayVariant" className="in-active">
              Half Day Variant List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Half Day Variant</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Half Day Variant</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createVariant(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Name"
                  value={halfDayVariantDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Hald Day Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="half_day_amount"
                  placeholder="Enter Half Day amount"
                  value={halfDayVariantDetails.half_day_amount}
                />
                {errors.half_day_amount ? (
                  <div className="validation-error-label">
                    {errors.half_day_amount}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Full Day Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="full_day_amount"
                  placeholder="Enter Full Day amount"
                  value={halfDayVariantDetails.full_day_amount}
                />
                {errors.full_day_amount ? (
                  <div className="validation-error-label">
                    {errors.full_day_amount}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateHalfDayVariant;
