import React from "react";
import { Link, useLocation } from "react-router-dom";

function ExpenseReportDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.employee_name + " " + listDetails?.father_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.employeeCode,
    },
    {
      label: "Branch Name",
      value: listDetails?.branchName,
    },
    {
      label: "Employee Type Name",
      value: listDetails?.employeeTypeName,
    },
    {
      label: "Department Name",
      value: listDetails?.department_name,
    },
    {
      label: "Designation Name",
      value: listDetails?.designation_name,
    },
    {
      label: "Type",
      value: listDetails?.type ? listDetails?.type : "-",
    },
    {
      label: "Amount",
      value: listDetails?.amount,
    },
    {
      label: "Interest",
      value: listDetails?.interest ? listDetails?.interest : "-",
    },
    {
      label: "Paid Amount",
      value: listDetails?.paid_amount ? listDetails?.paid_amount : "-",
    },
    {
      label: "Paid status",
      value: listDetails?.voucher_status === 1 ? "Paid" : "Unpaid",
    },
    {
      label: "Remarks",
      value: listDetails?.remarks,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/expense" className="in-active">
              Expense Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Expense Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Expense Report Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpenseReportDetails;
