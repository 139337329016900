import Joi from "joi";

class ValidationClass {
  /**Login validation */
  validateLoginForm(data) {
    const loginSchema = Joi.object({
      email: Joi.string()
        .email({
          minDomainSegments: 2,
          tlds: { allow: false },
        })
        .required("Please enter branch name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Username" `,
          "any.required": `"Username" is required`,
          "string.pattern.base": `Please enter valid "Username"`,
          "string.email": "Invalid Email format.",
        }),
      password: Joi.string()
        .required("Please enter Password")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Password" `,
          "any.required": `"Password" is required`,
          "string.pattern.base": `Please enter valid "Password"`,
        }),
    }).unknown(true);
    return loginSchema.validate(data);
  }

  findYourAccount(data) {
    const findAccountSchema = Joi.object({
      findEmail: Joi.string()
        .email({
          minDomainSegments: 2,
          tlds: { allow: false },
        })
        .required()
        .min(3)
        .max(150)
        .messages({
          "string.empty": `Please enter Email`,
          "string.min": `Min Length is 3`,
          "string.max": `Max Length is 150`,
          "any.required": `Email is required`,
          "string.email": `Email must be valid email`,
          "string.pattern.base": `Please enter valid Email`,
        }),
    }).unknown(true);
    return findAccountSchema.validate(data);
  }
  validateBranch(data) {
    const branchSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .min(3)
        .max(30)
        .required("Please enter branch name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Branch name" `,
          "string.min": `Min Length is 3`,
          "string.max": `Max Length is 30`,
          "any.required": `"Branch name" is required`,
          "string.pattern.base": `Please enter valid "Branch" name`,
        }),

      no_of_floors: Joi.number().required().messages({
        "number.base": `Please Select "Floor" `,
        "any.required": `Please Select "Floor" `,
        "number.pattern.base": `Please select valid "Floor"`,
      }),

      address: Joi.string().required().max(150).messages({
        "string.empty": `Please Enter "Address" `,
        "any.required": `Please Enter "Address" `,
        "string.pattern.base": `Please Enter valid "Address"`,
        "string.max": `Max Length is 150 character`,
      }),

      town: Joi.string()
        .pattern(/^\S.*$/)
        .regex(/^[A-Za-z\s]+$/)
        .required()
        .min(3)
        .max(30)
        .messages({
          "string.empty": `Please Enter "Town / City" `,
          "any.required": `Please Enter "Town / City" `,
          "string.pattern.base": `Please Enter valid "Town / City"`,
          "string.min": `Min Length is 3`,
          "string.max": `Max Length is 30`,
        }),

      state: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[A-Za-z' ']+$"))
        .required()
        .messages({
          "string.empty": `Please Enter "State" `,
          "any.required": `Please Enter "State" `,
          "string.pattern.base": `Please Enter valid "State"`,
        }),

      pincode: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .min(6)
        .max(6)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Pincode" `,
          "any.required": `Please Enter "Pincode" `,
          "string.pattern.base": `Please Enter valid "Pincode"`,
          "string.min": "Pincode must be 6 Digit",
          "string.max": "Pincode must be 6 Digit",
        }),

      contact_person: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .required()
        .messages({
          "string.empty": `Please Enter "Contact Person name" `,
          "any.required": `Please Enter "Contact Person name" `,
          "string.pattern.base": `Enter valid "Contact Person Name"`,
        }),

      phone: Joi.string()
        .pattern(/^\S.*$/)
        .min(10)
        .max(12)
        .pattern(/^[0-9]+$/)
        .allow("", null)
        .invalid(Joi.ref("phone"))
        .messages({
          "string.empty": `Please Enter "Alternative Mobile Number" `,
          "any.required": `Please Enter "Alternative Mobile Number" `,
          "string.pattern.base": `Please Enter valid "Alternative Mobile Number"`,
          "string.min": "Phone number must be 10 or 12 Digit",
          "string.max": "Phone number must be 10 or 12 Digit",
          "any.invalid": "Mobile Number cannot be the same as Mobile Number",
        }),
    }).unknown(true);
    return branchSchema.validate(data);
  }

  validateHalfDayVariant(data) {
    const branchSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .min(3)
        .max(30)
        .required("Please Enter name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Name" `,
          "string.min": `Min Length is 3`,
          "string.max": `Max Length is 30`,
          "any.required": `"Name" is required`,
          "string.pattern.base": `Please enter valid "Name`,
        }),

      half_day_amount: Joi.number()
        .precision(2)
        .required("Please enter Half day amount")
        .messages({
          "string.empty": `Please enter Half day amount`,
          "any.required": `"Half day amount" is required`,
          "string.pattern.base": `Please enter valid Half day amount`,
        }),

      full_day_amount: Joi.number()
        .precision(2)
        .required("Please enter Full day amount")
        .messages({
          "string.empty": `Please enter Full day amount`,
          "any.required": `"Full day amount" is required`,
          "string.pattern.base": `Please enter valid Full day amount`,
        }),

      is_active: Joi.boolean().messages({
        "boolean.base": "The Is Active field must be a Select.",
      }),
    }).unknown(true);
    return branchSchema.validate(data);
  }
  /**Agent Validation */
  validateAgent(data) {
    const agentSchema = Joi.object({
      company_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Company Name" `,
          "any.required": `"Company Name" is required`,
          "string.pattern.base": `Please enter valid "Company" Name`,
        }),
      contact_person: Joi.string()
        .regex(new RegExp("^[a-zA-Z' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Contact Person Name" `,
          "any.required": `"Contact Person Name" is required`,
          "string.pattern.base": `Contact Person Name accept only "Alphabet Characters"`,
        }),
      phone: Joi.string()
        .pattern(/^\S.*$/)
        .min(10)
        .max(10)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Contact Number" `,
          "any.required": `Please Enter "Contact Number" `,
          "string.pattern.base": `Please Enter valid "Contact Number"`,
          "string.min": "Phone number must be 10 Digit",
          "string.max": "Phone number must be 10 Digit",
        }),
      contact_email: Joi.string()
        .pattern(/^\S.*$/)
        .required()
        .email({
          minDomainSegments: 2,
          tlds: { allow: ["com", "net"] },
        })
        .messages({
          "string.empty": `Please Enter "Email" `,
          "any.required": `Please Enter "Email" `,
          "string.pattern.base": `Please Enter valid "Email"`,
          "string.email": "Invalid Email format.",
        }),
      address: Joi.string().required().max(150).messages({
        "string.empty": `Please Enter "Address" `,
        "any.required": `Please Enter "Address" `,
        "string.pattern.base": `Please Enter valid "Address"`,
        "string.max": `Max Length is 150 character`,
      }),
    }).unknown(true);
    return agentSchema.validate(data);
  }

  /***Validate Leave List */
  validateLeaveList(data) {
    const leaveListSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Leave Name" `,
          "any.required": `"Leave Name" is required`,
          "string.pattern.base": `Please enter valid "Leave Name"`,
        }),
      // religion_id: Joi.number().integer().required().messages({
      //   "string.empty": `Please select "Religion" `,
      //   "any.required": `"Religion" is required`,
      //   "number.base": "Please select Religion",
      // }),
    }).unknown(true);
    return leaveListSchema.validate(data);
  }

  /***Validate Leave List */
  validateFine(data) {
    const leaveListSchema = Joi.object({
      employee_id: Joi.number().required().messages({
        "number.empty": `Please Select "Employee Name" `,
        "any.required": `"Employee Name" is required`,
        "number.base": `Please Select "Employee Name"`,
      }),

      fine_type_id: Joi.number().required().messages({
        "number.empty": `Please select "Fine Type" `,
        "any.required": `"Fine Type" is required`,
        "number.base": `Please select "Fine Type"`,
      }),

      fine_amount: Joi.number().required().messages({
        "string.empty": `Please select "Fine Amount" `,
        "any.required": `"Fine Amount" is required`,
        "number.base": "Please Enter valid Fine Amount",
      }),

      date: Joi.date().required().messages({
        "any.required": `Please  Select Expense Date`,
        "string.pattern.base": `Please Select valid Expense Date`,
        "date.base": `Please Select Expense Date`,
      }),

      remarks: Joi.string().max(150).allow("", null).messages({
        "string.empty": `Please enter "Remarks" `,
        "any.required": `"Remarks" is required`,
        "string.pattern.base": `Please enter valid "Remarks"`,
        "string.max": `Max Length is 150 character`,
      }),
    }).unknown(true);
    return leaveListSchema.validate(data);
  }

  /**Leave validate */
  validateLeave(data) {
    const leaveSchema = Joi.object({
      type: Joi.string().required().messages({
        "string.empty": `Please Select "Type" `,
        "any.required": `Please  Select "Type"  `,
        "string.pattern.base": `Please Select "Type" `,
      }),
      employee_id: Joi.number().required().messages({
        "number.empty": `Please Select "Employee Code" `,
        "any.required": `Please  Select "Employee Code"  `,
        "number.pattern.base": `Please Select "Employee Code" `,
        "number.base": `Please Select "Employee Code" `,
      }),
      employee_name: Joi.string()
        .pattern(/^\S.*$/)
        .required()
        .messages({
          "string.empty": `Please Select "Employee Name" `,
          "any.required": `Please  Select "Employee Name"  `,
          "string.pattern.base": `Please Select "Employee Name" `,
        }),
      leave_type_id: Joi.number().required().messages({
        "number.empty": `Please Select "Leave Type" `,
        "any.required": `Please  Select "Leave Type"  `,
        "number.pattern.base": `Please Select "Leave Type" `,
        "number.base": `Please Select "Leave Type" `,
      }),
      from_date: Joi.date().required().messages({
        "any.required": `Please  Select "From Date" `,
        "string.pattern.base": `Please Select "valid Leave Date" `,
        "date.base": `Please Select "From Date" `,
      }),
      to_date: Joi.date()
        .iso()
        .min(Joi.ref("from_date"))
        .messages({
          "any.required": `Please  Select "To Date" `,
          "string.pattern.base": `Please Select "valid Leave Date" `,
          "date.base": `Please Select "To Date" `,
        })
        .required(),
      no_of_days: Joi.number().integer().min(1).required().messages({
        "string.empty": `Please Select "valid Leave Date"`,
        "any.required": `Please Select "valid Leave Date"`,
        "string.pattern.base": `Please Select "valid Leave Date" `,
      }),

      approved_by: Joi.number().required().messages({
        "number.empty": `Please Select "Approved by" `,
        "any.required": `Please  Select "Approved by"  `,
        "number.pattern.base": `Please Select "Approved by" `,
        "number.base": `Please Select "Approved by" `,
      }),
      // file: Joi.string().required().messages({
      //   "string.empty": `Please choose "File" `,
      //   "any.required": `"File" is required`,
      //   "string.pattern.base": `Please choose valid "File"`,
      // }),
      remarks: Joi.string().required().messages({
        "string.empty": `Please Enter "Remarks" `,
        "any.required": `"Remarks" is required`,
        "number.base": "Please Enter Remarks",
      }),
    }).unknown(true);
    return leaveSchema.validate(data);
  }

  /**Allowance Type Validation */
  ValidateAllowanceType(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Allowance Type Name" `,
          "any.required": `"Allowance Type Name" is required`,
          "string.pattern.base": `Please enter valid "Allowance Type Name`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  /*Government ID Proofs Validation */
  ValidateGovernmentIdProof(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Government ID Proof Name" `,
          "any.required": `"Role Name" is required`,
          "string.pattern.base": `Please enter valid "Allowance Name`,
        }),
      mandatory: Joi.string()
        .required()
        .messages({
          "string.empty": `${data?.name} is Mandatory or Not`,
          "any.required": `${data?.name} is Mandatory or Not`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  /**user Validation */
  ValidateRole(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Role Name" `,
          "any.required": `"Role Name" is required`,
          "string.pattern.base": `Please enter valid "Role Name`,
        }),

      description: Joi.string()
        .allow("", null)
        .min(0)
        .max(150)
        .allow(null)
        .messages({
          "string.max": `Max Length is 150 character`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  ValidateDistrict(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "District Name" `,
          "any.required": `"District Name" is required`,
          "string.pattern.base": `Please enter valid "District Name`,
        }),

      description: Joi.string()
        .allow("", null)
        .min(0)
        .max(150)
        .allow(null)
        .messages({
          "string.max": `Max Length is 150 character`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  ValidateTown(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Town Name" `,
          "any.required": `"Town Name" is required`,
          "string.pattern.base": `Please enter valid "Town Name`,
        }),

      description: Joi.string()
        .allow("", null)
        .min(0)
        .max(150)
        .allow(null)
        .messages({
          "string.max": `Max Length is 150 character`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  ValidateUser(data) {
    const userSchema = Joi.object({
      employee_id: Joi.number().required().messages({
        "number.empty": `Please select "Employee Name" `,
        "any.required": `"Employee Name" is required`,
        "number.pattern.base": `Please select valid "Employee Name`,
        "number.base": `Please select "Employee Name`,
      }),

      role_id: Joi.number().required().messages({
        "number.empty": `Please select "Role Name" `,
        "any.required": `"Role Name" is required`,
        "number.pattern.base": `Please select valid "Role Name`,
        "number.base": `Please select "Role Name`,
      }),

      email: Joi.string()
        .email({
          minDomainSegments: 2,
          tlds: { allow: false },
        })
        .required()
        .messages({
          "string.pattern.base": `Please Enter valid "Email ID"`,
          "string.email": "Invalid Email format.",
        }),

      password: Joi.string()
        .min(8)
        .required()
        .pattern(new RegExp('^[a-zA-Z0-9!@#$%^&*"|,.<>/?]{3,30}$'))
        .messages({
          "string.base": "Password must be a string",
          "string.empty": "Password is required",
          "string.min": "Password must be at least {#limit} characters long",
          "any.required": "Password is required",
          "string.pattern.base":
            "Password must contain only letters, numbers, and special characters",
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  // Validate Bank page in Bank masters

  /**user Validation */
  ValidateBank(data) {
    const userSchema = Joi.object({
      bank_name: Joi.string()
        .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Bank Name" `,
          "any.required": `"Bank Name" is required`,
          "string.pattern.base": `Enter valid Bank name`,
        }),

      branch_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter Branch Name`,
          "any.required": `"Branch Name" is required`,
          "string.pattern.base": `Enter valid Branch name`,
        }),

      ifsc_code: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "IFSC Code" `,
          "any.required": `"IFSC Code" is required`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  // Validate Department page in Department masters

  validateDepartment(data) {
    const departmentSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Name" `,
          "any.required": `"Name" is required`,
          "string.pattern.base": "Please enter valid letters",
        }),
      description: Joi.string().allow("", null).min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
      // slug: Joi.string()
      //   .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
      //   .required()
      //   .pattern(/^\S.*$/)
      //   .messages({
      //     "string.empty": `Please enter "Slug Name" `,
      //     "any.required": `"Slug Name" is required`,
      //     "string.pattern.base": "Please enter valid letters",
      //   }),
    }).unknown(true);
    return departmentSchema.validate(data);
  }

  validateDesingnation(data) {
    const departmentSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Designation Name" `,
          "any.required": `"Name" is required`,
          "string.pattern.base": "Please enter valid letters",
        }),
      slug: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter Slug name `,
          "any.required": `Slug name is required`,
          "string.pattern.base": "Please enter valid letters",
        }),
      // department_id: Joi.number().integer().required().messages({
      //   "number.base": `Please select "Department" `,
      // }),

      description: Joi.string().allow(" ", null).min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
    }).unknown(true);
    return departmentSchema.validate(data);
  }

  // Validate Department page in Department masters
  validateCaste(data) {
    const casteSchema = Joi.object({
      name: Joi.string().required(),
    })
      .messages({
        "string.empty": "Please Enter the Caste Name",
        "string.pattern.base": "Please enter valid letters",
      })
      .unknown(true);
    return casteSchema.validate(data);
  }

  /**Biometrics Validation */
  ValidateBiometrics(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter Biometrics Name`,
          "any.required": `"Role Name" is required`,
          "string.pattern.base": `Please enter valid Biometrics Name`,
        }),

      device_id: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Device Id" `,
          "any.required": `"Device Id" is required`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  // Validate Religion page in Bank masters

  /**validate employment type**/
  validateReligion(data) {
    const ReligionSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Religion Name" `,
          "any.required": `"Religion Name" is required`,
          "string.pattern.base": `Please enter valid "Religion Name" `,
        }),
    }).unknown(true);
    return ReligionSchema.validate(data);
  }

  validateFinance(data, type) {
    const paymentSchema = Joi.object({
      name: Joi.string()
        // .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .required()
        .messages({
          "string.pattern.base": `Please enter valid ${type} name`,
          "string.empty": `Please enter ${type}`,
        }),
      description: Joi.string().allow(null, "").min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
    }).unknown(true);
    return paymentSchema.validate(data);
  }

  validateManualAttendance(data) {
    const attendanceSchema = Joi.object({
      employee_id: Joi.number().required().messages({
        "number.empty": `Please Select Employee Code `,
        "any.required": `Please  Select "Employee Code"`,
        "number.pattern.base": `Please Select "Employee Code" `,
        "number.base": `Please Select "Employee Code" `,
      }),
      start_date: Joi.date().required().messages({
        "any.required": `Please  Select "From Date" `,
        "string.pattern.base": `Please Select "From Date" `,
        "date.base": `Please Select "From Date" `,
      }),
      end_date: Joi.date()
        .iso()
        .min(Joi.ref("start_date"))
        .messages({
          "any.required": `Please  Select "To Date" `,
          "string.pattern.base": `Please Select "valid To Date" `,
          "date.base": `Please Select "To Date" `,
        })
        .required(),
      no_of_days: Joi.number().integer().min(1).required().messages({
        "string.empty": `Please Select "valid Leave Date"`,
        "any.required": `Please Select "valid Leave Date"`,
        "string.pattern.base": `Please Select "valid Leave Date" `,
      }),
      clock_in: Joi.string()
        .regex(/^([01]\d|2[0-3]):([0-5]\d)$/)
        .required()
        .messages({
          "string.empty": `Please enter "Clock In" time`,
          "any.required": `"Clock In" time is required`,
          "string.pattern.base": "Please enter valid 'Clock In' time",
        }),
      clock_out: Joi.string()
        .regex(/^([01]\d|2[0-3]):([0-5]\d)$/)
        .required()
        .messages({
          "string.empty": `Please enter "Clock Out" time`,
          "any.required": `"Clock Out" time is required`,
          "string.pattern.base": "Please enter valid 'Clock Out' time",
        }),
        remarks: Joi.string().required().messages({
          "string.empty": `Please Enter "Remarks" `,
          "any.required": `Please Enter "Remarks" `,
          "string.pattern.base": `Please Enter valid "Remarks"`,
        }),
    })
      .custom((data, helpers) => {
        const clockInTime = data.clock_in;
        const clockOutTime = data.clock_out;
        if (clockInTime > clockOutTime) {
          return helpers.error("any.invalid", { key: "clock_out" });
        }
        return data;
      }, "any.invalid")
      .messages({
        "any.invalid": `"Out time" must be greater than "In time"`,
      })
      .unknown(true);
    return attendanceSchema.validate(data);
  }

  /**validate holidays schema */
  validateHolidaysScheme(data) {
    const holidaysScheme = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Scheme Name" `,
          "any.required": `"Scheme Name" is required`,
          "string.pattern.base": `Please enter valid "Scheme Name`,
        }),
      description: Joi.string().allow(null, "").min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),

      grace_days: Joi.number().allow("").messages({
        "number.base": "Please enter valid Grace Days",
      }),
      working_days: Joi.number().allow("").messages({
        "number.base": "Please enter valid Working Days",
      }),
    }).unknown(true);
    return holidaysScheme.validate(data);
  }

  /**validate holiday */

  validateHoliday(data) {
    const holidaysSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Holiday Name" `,
          "any.required": `"Holiday Name" is required`,
          "string.pattern.base": `Please enter valid "Holiday Name`,
        }),

      religion_id: Joi.array().min(1).required().messages({
        "array.min": "Please select atleast one Religion",
      }),

      leave_type: Joi.string()
        .allow("", null)
        .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .messages({
          "string.pattern.base": `Please enter valid Leave Type`,
        }),
      financial_year: Joi.string()
        .allow("", null)
        .regex(new RegExp("^[0-9s' ']*$"))
        .length(4)
        .messages({
          "string.pattern.base": `Year should be following format(eg: 2020)`,
          "string.length": `Year should be following format(eg: 2020)`,
        }),

      description: Joi.string().allow(null, "").min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
    }).unknown(true);
    return holidaysSchema.validate(data);
  }

  validateShift(data) {
    const shiftSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required("Please enter shift Name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Shift Name" `,
          "any.required": `"Shift Name" is required`,
          "string.pattern.base": `Please enter valid "Shift" name`,
        }),
      from_time: Joi.string().required().messages({
        "string.empty": `Please Enter "From time" `,
        "any.required": `Please Enter "From time" `,
        "string.pattern.base": `Please Enter valid "From time"`,
      }),
      to_time: Joi.string().required().messages({
        "string.empty": `Please Enter "To time" `,
        "any.required": `Please Enter "To time" `,
        "string.pattern.base": `Please Enter valid "To time"`,
      }),
    })
      .custom((data, helpers) => {
        const fromTime = data.from_time;
        const toTime = data.to_time;
        if (fromTime > toTime) {
          return helpers.error("any.invalid", { key: "to_time" });
        }
        return data;
      }, "any.invalid")
      .messages({
        "any.invalid": `"To time" must be greater than "From time"`,
      })
      .unknown(true);
    return shiftSchema.validate(data);
  }

  validateBreakTime(data) {
    const breakTimeSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required("Please enter break time name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Break time name" `,
          "any.required": `"Break time name" is required`,
          "string.pattern.base": `Please enter valid "Break time" name`,
        }),

      shift_id: Joi.array().min(1).required().messages({
        "array.min": "Please select atleast one Shift",
      }),
      from_time: Joi.string().required().messages({
        "string.empty": `Please Enter "From time" `,
        "any.required": `Please Enter "From time" `,
        "string.pattern.base": `Please Enter valid "From time"`,
      }),
      to_time: Joi.string().required().messages({
        "string.empty": `Please Enter "To time" `,
        "any.required": `Please Enter "To time" `,
        "string.pattern.base": `Please Enter valid "To time"`,
      }),
    })
      .custom((value, helpers) => {
        const fromTime = value.from_time;
        const toTime = value.to_time;
        if (fromTime > toTime) {
          return helpers.error("any.invalid", { key: "to_time" });
        }
        return value;
      }, "any.invalid")
      .messages({
        "any.invalid": `"To time" must be greater than "From time"`,
      })
      .unknown(true);
    return breakTimeSchema.validate(data);
  }

  validateBreakFine(data) {
    const breakFine = Joi.object({
      break_minute: Joi.number().integer().required().min(1).messages({
        "string.empty": `Please Enter Break time`,
        "any.required": `Break time is required`,
        "number.base": "Please Enter Break time",
        "string.min": "Break time should atleast one min",
      }),
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter Fine amount`,
        "any.required": `Fine amount is required`,
        "number.base": "Please Enter Fine amount",
      }),
    }).unknown(true);
    return breakFine.validate(data);
  }

  validateTermination(data) {
    const terminationSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required("Please enter termination Name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Termination Name" `,
          "any.required": `"Termination Name" is required`,
          "string.pattern.base": `Please enter valid "Termination" name`,
        }),
    }).unknown(true);
    return terminationSchema.validate(data);
  }

  /**validate employment type**/
  validateEmploymentType(data) {
    const employmentTypeSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Staff Name" `,
          "any.required": `"Staff Name" is required`,
          "string.pattern.base": `Please enter valid "Staff Name" name`,
        }),

      color: Joi.string().required("Please enter break time name").messages({
        "string.empty": `Please select "Color" `,
        "any.required": `"Color" is required`,
      }),
    }).unknown(true);
    return employmentTypeSchema.validate(data);
  }

  validateFloor(data) {
    const floorSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Floor" Name `,
          "any.required": `"Floor" Name is required`,
          "string.pattern.base": `Please enter valid "Floor" name`,
        }),
      description: Joi.string()
        .allow(null, "")
        .min(0)
        .max(150)
        .allow(null)
        .messages({
          "string.max": `Max Length is 150 character`,
        }),
    }).unknown(true);
    return floorSchema.validate(data);
  }
  validateBloodGroup(data) {
    const floorSchema = Joi.object({
      name: Joi.string()
        .regex(/^(A|B|AB|O)[\+\-]$/)
        .uppercase()
        .trim()
        .required()
        .messages({
          "string.empty": `Please Enter "Blood Group" `,
          "any.required": `"Blood Group" is required`,
          "string.pattern.base": `Please select valid "Blood Group" name`,
        }),
    }).unknown(true);
    return floorSchema.validate(data);
  }

  validateAdvance(data) {
    const advanceSchema = Joi.object({
      // employee_id: Joi.number().required().messages({
      //   "string.empty": `Please enter "Employee id" `,
      //   "any.required": `"Employee id" is required`,
      //   "string.pattern.base": `Please enter valid "Employee" id`,
      // }),
      // finance_category_id: Joi.number().required().messages({
      //   "number.empty": `Please select "Loan Type" `,
      //   "any.required": `"Loan Type" is required`,
      //   "number.pattern.base": `Please Select "Loan Type"`,
      // }),
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Amount" `,
        "any.required": `Please Enter "Amount" `,
        "string.pattern.base": `Please Enter valid "Amount"`,
      }),
      selecttype: Joi.string().required().messages({
        "string.empty": `Please select "Payment Type" `,
        "any.required": `"Select Payment Type" is required`,
        "string.pattern.base": `Please Select "Payment Type"`,
      }),
      // file: Joi.string().required().messages({
      //   "string.empty": `Please choose "File" `,
      //   "any.required": `"File" is required`,
      //   "string.pattern.base": `Please choose valid "File"`,
      // }),
      finance_date: Joi.date().required().messages({
        "any.required": `Please  Select Expense Date`,
        "string.pattern.base": `Please Select valid Expense Date`,
        "date.base": `Please Select Expense Date`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateFinanceLoan(data) {
    const advanceSchema = Joi.object({
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Amount" `,
        "any.required": `Please Enter "Amount" `,
        "string.pattern.base": `Please Enter valid "Amount"`,
      }),
      // duration: Joi.string()
      //   .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
      //   .pattern(/^\S.*$/)
      //   .required()
      //   .messages({
      //     "string.empty": `Please Enter "Duration" `,
      //     "any.required": `Please Enter "Duration" `,
      //     "string.pattern.base": `Please Enter valid "Duration"`,
      //   }),
      finance_date: Joi.date().required().messages({
        "any.required": `Please  Select Finance Date`,
        "string.pattern.base": `Please Select valid Finance Date`,
        "date.base": `Please Select Finance Date`,
      }),

      // file: Joi.string().required().messages({
      //   "string.empty": `Please choose "File" `,
      //   "any.required": `"File" is required`,
      //   "string.pattern.base": `Please choose valid "File"`,
      // }),
      
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateLoanDeductionEntry(data) {
    const advanceSchema = Joi.object({
      employee_id: Joi.number().required().messages({
        "number.empty": `Please Enter "Employee Name" `,
        "any.required": `"Employee Name" is required`,
        "number.base": `Please select "Employee Name"`,
      }),
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Amount" `,
        "any.required": `Please Enter "Amount" `,
        "string.pattern.base": `Please Enter valid "Amount"`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateAgentCommission(data) {
    const advanceSchema = Joi.object({
      amount: Joi.number().required().messages({
        "number.empty": `Please Enter Amount`,
        "any.required": `Please Enter Amount`,
        "number.base": `Please Enter valid "Amount"`,
      }),
      date: Joi.string().required().messages({
        "string.empty": `Please Enter Date`,
        "any.required": `Please Enter Date`,
      }),

      deduct_from_employee: Joi.boolean().messages({
        "boolean.base": "Please choose",
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateAgentCommissionPayment(data) {
    const advanceSchema = Joi.object({
      amount: Joi.number().required().messages({
        "number.empty": `Please Enter Amount`,
        "any.required": `Please Enter Amount`,
        "number.base": `Please Enter valid "Amount"`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateBonus(data) {
    const advanceSchema = Joi.object({
      employee_id: Joi.number().required().messages({
        "number.empty": `Please Enter "Employee Name" `,
        "any.required": `"Employee Name" is required`,
        "number.base": `Please select "Employee Name"`,
      }),
      finance_category_id: Joi.number().required().messages({
        "number.empty": `Please Enter "Finance Category" `,
        "any.required": `"Finance Category" is required`,
        "number.base": `Please select "Finance Category"`,
      }),
      amount: Joi.string()
        .pattern(/^(?!0*(\.0+)?$)\d+(\.\d+)?$/)
        .required()
        .messages({
          "string.empty": 'Please Enter "Amount"',
          "any.required": '"Amount" is required',
          "string.pattern.base": 'Please Enter "Numbers or Decimals"',
        }),
      // approved_by_id: Joi.number().required().messages({
      //   "number.empty": `Please Enter "Approved Employee" `,
      //   "any.required": `"Approved Employee" is required`,
      //   "number.base": `Please select "Approved Employee"`,
      // }),
      remarks: Joi.string().max(150).allow(null, "").messages({
        "string.max": `Max length is 150`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateExpense(data) {
    const advanceSchema = Joi.object({
      employee_id: Joi.number()
        .required("Please enter Employee Name")
        .messages({
          "number.empty": `Please Select "Employee Name" `,
          "any.required": `"Employee Name" is required`,
          "number.base": `Please Select "Employee Name" `,
        }),
      finance_category_id: Joi.number().required().messages({
        "number.empty": `Please Select "Expense Type" `,
        "any.required": `Please Select "Expense Type" `,
        "number.pattern.base": `Please Select "Expense Type"`,
        "number.base": `Please Select "Expense Type"`,
      }),
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Amount" `,
        "any.required": `Please Enter "Amount" `,
        "string.pattern.base": `Please Enter valid "Amount"`,
      }),

      expense_date: Joi.date().required().messages({
        "any.required": `Please  Select Expense Date`,
        "string.pattern.base": `Please Select valid Expense Date`,
        "date.base": `Please Select Expense Date`,
      }),
      remarks: Joi.string().allow(null, "").max(150).messages({
        "string.max": `Max length is 150`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateSeasonalSalary(data) {
    const advanceSchema = Joi.object({
      employee_type_id: Joi.number().integer().required().messages({
        "string.empty": `Please select "Employee Type" `,
        "any.required": `"Employee Type" is required`,
        "number.base": "Please select Employee Type",
      }),
      from_date: Joi.date()
        .messages({ "date.base": `"From Date" must be a valid date` })
        .required(),
      to_date: Joi.date()
        .min(Joi.ref("from_date"))
        .messages({
          "date.base": `"To Date" must be a valid date`,
          "date.min": `"To Date" must be greater than "From Date"`,
        })
        .required(),
      per_day_salary: Joi.number().required().messages({
        "string.empty": `Please enter "No Of Days" `,
        "any.required": `"No Of Days" is required`,
        "string.pattern.base": `Please enter valid "No Of Days`,
        "number.base": `Please enter valid Salary`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateReimbursementScheme(data) {
    const reimbursementScheme = Joi.object({
      employee_id: Joi.number().integer().required().messages({
        "string.empty": `Please select "Employee" `,
        "any.required": `"Employee" is required`,
        "number.base": "Please select Employee",
      }),
      deduction_date: Joi.date()
        .messages({ "date.base": `"Deduction Date" must be a valid date` })
        .required(),
      reimbursement_date: Joi.date()
        .messages({
          "date.base": `"Reimbursement Date" must be a valid date`,
        })
        .required(),
      amount: Joi.number().required().messages({
        "string.empty": `Please enter amount`,
        "any.required": `Amount is required`,
        "string.pattern.base": `Please enter valid Amount`,
        "number.base": `Please enter valid Amount`,
      }),
      reason: Joi.string().allow("", null).max(150).messages({
        "string.max": `Max length is 150`,
      }),
    }).unknown(true);
    return reimbursementScheme.validate(data);
  }

  validateEmployee(data) {
    const employeeSchema = Joi.object({
      profile_image: Joi.string().required().messages({
        "string.empty": `Please choose "Profile Picture" `,
        "any.required": `"Profile Picture" is required`,
        "string.pattern.base": `Please choose valid "Profile Picture"`,
      }),

      first_name: Joi.string()
        // .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Employee Name" `,
          "any.required": `"Employee Name" is required`,
          "string.pattern.base": `Please select valid "Employee Name"`,
        }),

      employee_type_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Employee Type" `,
        "any.required": `"Employee Type" is required`,
        "string.pattern.base": `Please select valid "Employee Type"`,
        "number.base": "Please select Employee type",
      }),

      department_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Department" `,
        "any.required": `"Department" is required`,
        "string.pattern.base": `Please select valid "Department"`,
        "number.base": "Please select Department",
      }),

      designation_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Designation" `,
        "any.required": `"Designation" is required`,
        "string.pattern.base": `Please select valid "Designation"`,
        "number.base": "Please select Designation",
      }),

      branch_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Branch" `,
        "any.required": `"Branch" is required`,
        "string.pattern.base": `Please select valid "Branch"`,
        "number.base": "Please select Branch",
      }),

      floor: Joi.number().required().messages({
        "string.empty": `Please Enter "Floor" `,
        "any.required": `"Floor" is required`,
        "string.pattern.base": `Please select valid "Floor"`,
        "number.base": "Please select Floor",
      }),

      shift_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Shift" `,
        "any.required": `"Shift" is required`,
        "string.pattern.base": `Please select valid "Shift"`,
        "number.base": "Please select Shift",
      }),

      reporting_to: Joi.number().required().messages({
        "string.empty": `Please Enter "Reporting To" `,
        "string.pattern.base": `Please select valid "Reporting To"`,
        "number.base": "Please select Reporter name",
      }),

      // biometric_device: Joi.number().required().messages({
      //   "string.empty": `Please Enter "Biometric" `,
      //   "string.pattern.base": `Please select valid "Biometric"`,
      //   "number.base": "Please select Biometric",
      // }),

      date_of_joining: Joi.date().required().messages({
        "any.required": `"Joining Date" is required`,
        "date.base": `"Joining Date" must be a valid date`,
      }),

      /* Basic info */
      agent_id: Joi.number().required().messages({
        "string.empty": `Please Enter "HR Agent" `,
        "any.required": `"HR Agent" is required`,
        "string.pattern.base": `Please select valid "HR Agent"`,
        "number.base": "Please select HR Agent",
      }),

      date_of_birth: Joi.date().iso().required().messages({
        "date.base": "Please provide a valid date.",
        "date.format": "Please select valid date",
        "any.required": "DOB is required.",
      }),

      blood_group_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Blood Group" `,
        "any.required": `"Blood Group" is required`,
        "string.pattern.base": `Please select valid "Blood Group"`,
        "number.base": "Please select Blood group",
      }),

      district: Joi.string().required().messages({
        "string.empty": `Please Enter "District" `,
        "any.required": `"District" is required`,
        "string.pattern.base": `Please select valid "District"`,
        "number.base": "Please select District",
      }),

      town: Joi.string().required().messages({
        "string.empty": `Please Enter "Town" `,
        "any.required": `"Town" is required`,
        "string.pattern.base": `Please select valid "Town"`,
        "number.base": "Please select Town",
      }),

      present_address: Joi.string()
        .required()
        .pattern(/[#.0-9a-zA-Z\s,-_:;!$%^^&&*()_+='"]+$/)
        .messages({
          "string.empty": `Please Enter "Present Address" `,
          "any.required": `"Present Address" is required`,
          "string.pattern.base": `Please select valid "Present Address"`,
        }),

      permanent_address: Joi.string()
        .required()
        .pattern(/[#.0-9a-zA-Z\s,-_:;!$%^^&&*()_+='"]+$/)
        .messages({
          "string.empty": `Please Enter "Permanent Address" `,
          "any.required": `"Permanent Address" is required`,
          "string.pattern.base": `Please select valid "Permanent Address"`,
        }),

      /* Bank Info */
      ifsc_code: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .length(11)
        .allow("", null)
        .messages({
          "string.pattern.base": `Please select valid "IFSC Code"`,
          "string.length": `"IFSC Code" is an 11-character alpha numeric code`,
        }),

      bank_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .max(150)
        .allow("", null)
        .messages({
          "string.max": `Max length is 150`,
          "string.pattern.base": `Please select valid "Bank Name"`,
        }),

      // bank_branch: Joi.string()
      // .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
      // .max(150)
      // .allow("", null)
      // .messages({
      //   "string.max": `Max length is 150`,
      //   "string.pattern.base": `Please select valid Branch Name`,
      // }),

      account_holder_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .allow("", null)
        .max(150)
        .messages({
          "string.max": `Max length is 150`,
          "string.pattern.base": `Please select valid "Account Holder Name"`,
        }),

      account_number: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .allow("", null)
        .min(5)
        .max(17)
        .messages({
          "string.pattern.base": `Please select valid "Account Number"`,
          "string.min": `Account Number must be 5 charater length`,
          "string.max": `Account Number does not exist 17 character long`,
        }),

      pf_number: Joi.string()
        .regex(new RegExp("^[0-9s' ']*$"))
        .length(12)
        .allow("", null)
        .messages({
          "string.length": `PF Number is 12 digit numeric value`,
          "string.pattern.base": `Please select valid "PF Number"`,
        }),
      loan_deduction_amount: Joi.number().allow("", null).messages({
        "number.pattern.base": `Please enter valid Loan Deduction Amount`,
      }),

      /* Salary Info */
      dummy_basic: Joi.number().allow("", null).messages({
        "number.pattern.base": `Please enter valid Basic Salary`,
      }),
      pf_amount: Joi.number().allow("", null).messages({
        "number.base": "Please enter a valid PF amount",
      }),
      gross_salary: Joi.number().allow("", null).messages({
        "number.base": "Please enter a valid Gross Salary",
      }),
      account_salary: Joi.number().allow("", null).messages({
        "number.base": "Please enter a valid Through Bank Account ",
      }),
      in_cash: Joi.string().allow("", null).messages({
        "string.base": "Please enter a valid In Cash ",
      }),

      /* Personal Info */
      email: Joi.string()
        .email({
          minDomainSegments: 2,
          tlds: { allow: false },
        })
        .allow("", null)
        .messages({
          "string.pattern.base": `Please Enter valid "Email ID"`,
          "string.email": "Invalid Email format.",
        }),
      phone_no: Joi.string()
        .pattern(/^\S.*$/)
        .min(10)
        .max(12)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Mobile Number" `,
          "any.required": `Please Enter "Mobile Number" `,
          "string.pattern.base": `Please Enter valid "Mobile Number"`,
          "string.min": "Phone number must be 10,12 Digit",
          "string.max": "Phone number must be 10,12 Digit",
        }),
        
      alternate_phone_no: Joi.string()
        .pattern(/^\S.*$/)
        .min(10)
        .max(12)
        .pattern(/^[0-9]+$/)
        .allow("", null)
        .invalid(Joi.ref("phone_no"))
        .messages({
          "string.empty": `Please Enter "Alternative Mobile Number" `,
          "any.required": `Please Enter "Alternative Mobile Number" `,
          "string.pattern.base": `Please Enter valid "Alternative Mobile Number"`,
          "string.min": "Phone number must be 10 or 12 Digit",
          "string.max": "Phone number must be 10 or 12 Digit",
          "any.invalid":
            "Alternative Mobile Number cannot be the same as Mobile Number",
        }),
      gender: Joi.string().required().messages({
        "string.empty": `Please select "Gender" `,
        "any.required": `Please select "Gender" `,
        "string.pattern.base": `Please select valid "Gender"`,
      }),
      marital_status: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Select "Marital Status" `,
          "any.required": `"Marital Status" is required`,
          "string.pattern.base": `Please Select valid "Marital Status"`,
        }),
      fathers_name: Joi.string()
        .regex(new RegExp("^[a-zA-Zs' ']*$"))
        .required()
        .messages({
          "string.empty": `Please Enter "Father Name" `,
          "any.required": `Please Enter "Father Name" `,
          "string.pattern.base": `Please Enter valid "Father Name"`,
        }),
      religion_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Religion" `,
        "any.required": `"Religion" is required`,
        "string.pattern.base": `Please select valid "Religion"`,
        "number.base": "Please select Religion",
      }),
      caste_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Caster" `,
        "any.required": `"Caster" is required`,
        "string.pattern.base": `Please select valid "Caster"`,
        "number.base": "Please select Caste",
      }),
      government_proof_id: Joi.number().required().messages({
        "string.empty": `Please Enter "ID Proof" `,
        "any.required": `"ID Proof" is required`,
        "string.pattern.base": `Please select valid "ID Proof"`,
        "number.base": "Please select ID Proof",
      }),
      id_proof_number: Joi.number().required().messages({
        "string.empty": `Please Enter "Proof Number" `,
        "any.required": `Please Enter "Proof Number" `,
        "string.pattern.base": `Please Enter valid "Proof Number"`,
        "number.base": "Please select Proof number",
      }),
      proof_image: Joi.string().required().messages({
        "string.empty": `Please choose "Proof Image" `,
        "any.required": `"Proof Image" is required`,
        "string.pattern.base": `Please choose valid "Proof Image"`,
      }),

      /* Educational Info */
      education: Joi.string()
        .required()
        // .pattern(new RegExp("^[a-zA-Z0-9][a-zA-Z0-9^.()' ']*$"))
        .messages({
          "string.empty": `Please Enter "Qualification" `,
          "any.required": `Please Enter "Qualification" `,
          // "string.pattern.base": `Qualification should be a type of string`,
        }),
      passed_out: Joi.string()
        .allow("", null)
        .optional()
        .pattern(/^[0-9]+$/)
        .length(4)
        .messages({
          "string.empty": `Please enter "Passedout Year"`,
          "string.pattern.base": `Passedout Year must be an integer eg:(2020)`,
          "string.length": `Year Must be in 4 Digits eg:(2020)`,
        }),
      additional_qualification: Joi.string()
        .allow("", null)
        // .pattern(new RegExp("^[a-zA-Z][a-zA-Z^.()' ']*$"))
        .messages({
          "string.empty": `Please Enter "Additional Qualification" `,
          "any.required": `Please Enter "Additional Qualification" `,
          // "string.pattern.base": `Additional Qualification should be a type of string`,
        }),
      remarks: Joi.string().allow("", null).messages({
        "string.empty": `Please Enter "Remarks" `,
        "any.required": `Please Enter "Remarks" `,
        "string.pattern.base": `Please Enter valid "Remarks"`,
      }),
    }).unknown(true);
    return employeeSchema?.validate(data);
  }

  validateFoodAllowance(data) {
    const advanceSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Name" `,
          "any.required": `"Name" is required`,
          "string.pattern.base": `Please Enter valid "Name"`,
        }),

      from_time: Joi.string().allow(null, "").messages({
        "string.empty": `Please Enter "From time" `,
        "any.required": `Please Enter "From time" `,
        "string.pattern.base": `Please Enter valid "From time"`,
      }),

      to_time: Joi.string().allow(null, "").messages({
        "string.empty": `Please Enter "To time" `,
        "any.required": `Please Enter "To time" `,
        "string.pattern.base": `Please Enter valid "To time"`,
      }),

      amount: Joi.number().required().messages({
        "string.base": "Please enter a valid number",
        "string.empty": "Please enter the amount",
        "string.pattern.base":
          "Please enter a valid amount (e.g., 10 or 10.00)",
        "any.required": "Amount is required",
      }),
    })
      .custom((data, helpers) => {
        const fromTime = data.from_time;
        const toTime = data.to_time;
        if (toTime !== "" && fromTime > toTime) {
          return helpers.error("any.invalid", { key: "to_time" });
        }
        return data;
      }, "any.invalid")
      .messages({
        "any.invalid": `"Exit time" must be greater than "Entry time"`,
      })
      .unknown(true);
    return advanceSchema.validate(data);
  }

  validatePrivilege(data) {
    const privilegeSchema = Joi.object({
      type: Joi.string()
        .pattern(new RegExp("^[a-zA-Z0-9][a-zA-Z0-9' ']*$"))
        .min(0)
        .max(150)
        .required()
        .messages({
          "string.empty": `Please select Type`,
          "string.base": `Please select Valid Type name`,
          "string.pattern.base": `Please select valid Type`,
        }),

      id: Joi.number().integer().required().messages({
        "number.empty": `Please select Value`,
        "number.base": `Please select Value`,
        "number.pattern.base": `Please Select Value`,
      }),

      module_id: Joi.number().integer().required().messages({
        "number.empty": `Please select Module`,
        "number.base": `Please select Module`,
        "number.pattern.base": `Please Select Module`,
      }),
    }).unknown(true);
    return privilegeSchema.validate(data);
  }
}
const Validation = new ValidationClass();
export default Validation;
