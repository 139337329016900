import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import { GET_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";

function FoodAllowanceDetails() {
  const { id } = useParams();
  const [listDetails, setListDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const Details = [
    {
      label: "Name",
      value: listDetails?.name ? listDetails?.name : "-",
    },
    {
      label: "Before Time",
      value: listDetails?.from_time ? listDetails?.from_time : "-",
    },
    {
      label: "After Time",
      value: listDetails?.to_time ? listDetails?.to_time : "-",
    },
    {
      label: "Amount",
      value: listDetails?.amount ? listDetails?.amount : "-",
    },
    {
      label: "Description",
      value: listDetails?.description ? listDetails?.description : "-",
    },
  ];

  useEffect(() => {
    if (id) {
      getFoodList();
    }
  }, [id]);

  const getFoodList = async () => {
    setIsLoading(true);
    try {
      const foodList = await GET_API(`/FoodAllowanceList/${id}`);
      if (foodList.status === 200) {
        setListDetails(foodList?.data?.data[0]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/foodallowance" className="in-active">
              Food Allowance List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Food Allowance Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Food Allowance Details</h4>

          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list) => {
                return (
                  <div className="list">
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodAllowanceDetails;
