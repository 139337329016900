import React from "react";
import StaticSideBar from "../StaticSideBar";
import Header from "../Header/Header";
import { GeneralSettingsProvider } from "../../GeneralSettingsContext";

function HomeLayout({ children }) {
  return (
    <GeneralSettingsProvider>
      <div className="flex max-h-screen w-full">
        <StaticSideBar />
        <div className="grow h-screen flex flex-col justify-between w-[calc(100%-80px)]">
          <Header />
          {children}
          {/* <Footer /> */}
        </div>
      </div>
    </GeneralSettingsProvider>
  );
}

function HomePageLayout({ children }) {
  return (
    <GeneralSettingsProvider>
      <div className="flex max-h-screen w-full">
        <StaticSideBar />
        <div className="grow h-screen flex flex-col justify-between w-[calc(100%-80px)]">
          <Header />
          {children}
          {/* <Footer /> */}
        </div>
      </div>
    </GeneralSettingsProvider>
  );
}

export default HomeLayout;
